import React from 'react';
import './Sidebar.scss';
import SideLink from '../Sidebar/SideOption';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faUserCog } from "@fortawesome/free-solid-svg-icons";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import OPTIONS from '../../pages/DashboardPages/OptionTypes';
import { Link } from 'react-router-dom'
import DashHome from '../Dashboard/DashHome/DashHome'
import useRolesAccess from '../../hooks/useRolesAccess';
import { MODULES } from '../../Constants/roles_access';


const pathRoutes = [
    {
        icon: faHome,
        label: "Inicio",
        path: "/dashboard",
        module: MODULES.DASHBOARD
    },
    {
        icon: faTruck,
        label: "Distribuidores",
        path: "/dashboard/distributors",
        module: MODULES.DISTRIBUTOR_TABLE,

    },
    {
        icon: faUsers,
        label: "Clientes",
        path: "/dashboard/clients",
        module: MODULES.CLIENT_TABLE,
    },
    {
        icon: faUserCog,
        label: "Tecnicos",
        path: "/dashboard/techs",
        module: MODULES.CLIENT_TABLE,
    },
    {
        icon: faMoneyBill,
        label: "Gestion de Cobros y Pagos",
        path: "/dashboard/payments&collections/choose-flow",
        module: MODULES.GESTION_AND_PAYMENT,

    },
    {
        icon: faPrint,
        label: "Impresoras",
        path: "/dashboard/printers",
        module: MODULES.PRINTER_TABLE,

    },
    {
        icon: faFileInvoiceDollar,
        label: "Facturación",
        path: "/dashboard/billing",
        module: MODULES.GESTION_AND_PAYMENT,

    },
    {
        icon: faFileInvoiceDollar,
        label: "Pago",
        path: "/dashboard/pay",
        module: MODULES.PAYMENT,

    },
    {
        icon: faChartLine,
        label: "Tarifas",
        path: "/dashboard/rates",
        module: MODULES.RATES,
    },
    {
        icon: faEnvelope,
        label: "Tickets",
        path: "/dashboard/tickets",
        module: MODULES.TIKETS_TABLE,

    }
]



const Sidebar = ({ selectedOption, setOption }) => {

    const { getAccessView } = useRolesAccess()

    return (
        <div className="sidebarContainer">
            <div className="sidebarInner">
                <div className="blankSpace" />
                <div>
                    {pathRoutes.map(({ path, icon, label, module = "" }) => {
                        if (!getAccessView(module)) return null;
                        return <SideLink kye={path} {...{ to: path, icon, label }} />
                    })}
                    {/*
                    <SideOption icon={faHome} text={"Inicio"} selectedOption={selectedOption} setOption={setOption} type={OPTIONS.HOME} />
                    <SideOption icon={faTruck} text={"Distribuidores"} selectedOption={selectedOption} setOption={setOption} type={OPTIONS.DISTRIBUTOR} />
                    <SideOption icon={faUsers} text={"Clientes"} selectedOption={selectedOption} setOption={setOption} type={OPTIONS.CLIENTS} />
                    <SideOption icon={faUserCog} text={"Técnicos"} selectedOption={selectedOption} setOption={setOption} type={OPTIONS.TECHNICAL} />
                    <SideOption icon={faMoneyBill} text={<>Gestión de cobros<br />y pagos</>} selectedOption={selectedOption} setOption={setOption} type={OPTIONS.PAYMENT} />
                    <SideOption icon={faFileInvoiceDollar} text={"Facturación"} selectedOption={selectedOption} setOption={setOption} type={OPTIONS.BILLING} />
                    <SideOption icon={faEnvelope} text={"Tickets"} selectedOption={selectedOption} setOption={setOption} type={OPTIONS.TICKETS} />
                    <SideOption icon={faChartLine} text={"Tarifa"} selectedOption={selectedOption} setOption={setOption} type={OPTIONS.RATE} />
                    <SideOption icon={faPrint} text={<>Contratos/<br />impresoras</>} selectedOption={selectedOption} setOption={setOption} type={OPTIONS.CONTRACTS} />
                    */}
                </div>
            </div>


        </div>
    )
}

export default Sidebar;