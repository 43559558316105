import types from "./types";
import ServicesFacturacion from "../../utilities/api/services/facturacionServices";
import {PaymentSuccessPopUp,PaymentFailurePopUp} from "../../utilities/popUp";
import { handleError } from "../client/clientReducer";

export const initialState = {
  loading: false,
  facturas: [],
  fac_simulacion: [],
  facturaData: {},
};

export default function facturasReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_FACTURACION:
      return {
        ...state,
        facturas: action.payload,
        loading: false,
      };
    case types.START_FETCH:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_FACTURAS_DATA:
      return {
        ...state,
        facturaData: action.payload,
        loading: false,
      };
    case types.SET_FACTURACION:
      return {
        ...state,
        facturas: action.payload,
        loading: false,
      };
    case types.SET_SIMULACION:
      return {
        ...state,
        fac_simulacion: action.payload,
        loading: false,
      };    
    default:
      return state;
  }
}

export const getFacturasStart = () => ({
  type: types.START_FETCH,
});

export const setFacturas = (facturas = []) => ({
  type: types.SET_FACTURACION,
  payload: facturas,
});

export const setSimulacion = (facturas = []) => ({
  type: types.SET_SIMULACION,
  payload: facturas,
});

export const setFacturasData = (facturas = {}) => ({
  type: types.UPDATE_FACTURAS_DATA,
  payload: facturas,
});

export const getFacturas = (search = "") => {
  return (dispatch) => {
    dispatch(getFacturasStart());
    ServicesFacturacion.getFacturacion(search)
      .then((res) => {
        dispatch(setFacturas(res));
      })
      .catch((error) => {
        dispatch(handleError(error));
        alert("Error al encontrar cliente");
      });
  };
};

export const getFacturaData = (id_factura) => {
  return (dispatch) => {
    dispatch(getFacturasStart());
    ServicesFacturacion.getFactura(id_factura)
      .then((res) => {
        dispatch(setFacturasData(res));
      })
      .catch((error) => {
        dispatch(handleError(error));
        alert("Error al encontrar cliente");
      });
  };
};

export const startBilling = () => {
  return (dispatch) =>{
    console.log("-------dentro de startBilling");
    dispatch(getFacturasStart());
    ServicesFacturacion.startBilling()
    .then(  res =>{
          console.log("RES___START-billing:", res);
          dispatch(getStartBilling()) 
        
      })
    .catch( err => dispatch(handleError( err )) )
  }  
};

export const sendBilling = async ( facs ) => {
  return async (dispatch) =>{
    console.log("dentro de sendBilling");
    dispatch(getFacturasStart());
    const serie = `ECO/${Date.now()}`;
    const sending = [];
    try{
      for (let bill of facs){
        //const bill = fac;
        bill['n_serie'] = serie;
        const response = await ServicesFacturacion.sendBilling(bill);
        sending.push(response);
      }
      console.log("sendBilling RES: ", sending);
      if ( sending != [] ) {
        console.log("post if sendBill:", sending);
        dispatch(getBilling()); 
      }
    }catch(err){ 
      dispatch(handleError( err ))
    }
   
  }  
};

export const getStartBilling = () =>{
  console.log("dentro de getStart");
  return (dispatch) =>{
    dispatch(getFacturasStart());
    ServicesFacturacion.getStartBilling()
    .then(  res =>{
        console.log("startBilling RES: ", res);
          if (res.length >= 1){
            dispatch(setSimulacion(res)) 
          }
      })
    .catch( err => dispatch(handleError( err )) )
  }  
}

export const getBilling = () =>{
  console.log("dentro de getBilling");
  return (dispatch) =>{
    dispatch(getFacturasStart());
    ServicesFacturacion.getBilling()
    .then(  res => {
        console.log("Billing RES: ", res);
        if ( res.length >= 1 ){ 
          dispatch(setFacturas(res)) 
        }
      })
    .catch( err => dispatch(handleError( err )) )
  }  
}

export const editingBilling = ( data ) => {
  return (dispatch) => {
    console.log("dentro de editingBilling");
    dispatch(getFacturasStart());
    ServicesFacturacion.editingBilling(data)
    .then(  res =>{
        console.log("EDITINGBilling RES: ", res);
        if( res === "ok" ){
          PaymentSuccessPopUp("La edición fue exitosa");
        }
        if( res === "ko" ){
          PaymentFailurePopUp("La edición falló. Inténtelo de nuevo más tarde");
        }
        
        dispatch(getStartBilling());
      })
    .catch( err => dispatch(handleError( err )) )
  }  
}
