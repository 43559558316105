import React from "react";
import "./TotalIncome.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { INCOME_DATA } from '../../utilities/constants';
import income from '../../assets/dashboard/home/income.svg';

const TotalIncome = () => {


  return (
    <div className="totalIncomeContainer">
      <div className="totalIncomeInner">
        <div className="titleAndButton">
          <p className="title">TOTAL FACTURADO</p>
          <div className="customSelect">
            <select>
              <option value="1">Año 2020</option>
              <option value="1">Año 2019</option>
              <option value="1">Año 2018</option>
              <option value="1">Año 2017</option>
            </select>
          </div>
        </div>
        <div className="incomeContent">
          <div className="graph">
            <ResponsiveContainer width="100%" height={250}>
              <BarChart
                data={INCOME_DATA}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="$" stackId="a" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="incomeInfo">
            <div className="title">
              GANANCIAS
            </div>
            <div className="incomeValue">
                <img src={income} alt="income" className="incomeDesign"/>
                <p className="number">10.000,56</p>
                <p className="comparison">+53% sobre el año anterior</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalIncome;
