import React, { useState } from 'react';
import classNames from 'classnames';
import './TextInput.scss';
import ErrorMessage from '../../../Text/ErrorMessage';
import Icon from '../../../IconCustom';

const TextInput = ({ onChange, value, defaultValue, className, readOnly = false, modeEdit = false, errorMessage, errors, ...props }) => {

    const handleOnChange = (e) => {
        onChange(e.target.value)
    }

    const [visibilityPassword, setVisibilityPassword] = useState(false);




    return (
        <>
            <div className={'position-relative w-100'}>
                <input
                    className={classNames(`TextInputEcoColor ${className}`, { 'modeEditTextInputEcoColor': modeEdit })}
                    onChange={handleOnChange}
                    {...props}
                    readOnly={!modeEdit || readOnly}
                    {...{ defaultValue, value }}
                    type={visibilityPassword ? 'text' : 'password'}
                    autocomplete={"new-password"}
                >
                </input>
                <Icon onClick={() => setVisibilityPassword(pre => !pre)} type={'clara'} icon={visibilityPassword ? 'Eye-Open' : 'Eye-Closed'} size={'1.25rem'} className={'position-absolute top-center icon-eyes-password right-3'} />
            </div>
            {errorMessage && <ErrorMessage message={errorMessage} />}
        </>
    )
}

export default TextInput;
