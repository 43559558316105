import React, { useState, useEffect, useRef, useMemo } from 'react';
import Form from '../../../../Form';
import { TextInput, ControllerInput, FormLabel, ButtonState, SubmitButton, TextArea, SelectInput } from '../../../../Form/components';
import { ButtonEdit, ButtonRemove } from '../../../../Buttons'
import { Button, Grid, Select } from '@material-ui/core';
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import './TechnicalDetail.scss';
import { ServiceTechs, ServiceDistributors } from '../../../../../utilities/api/services';
import { SuccessDialog, AlertDialog } from '../../../../Modal';
import { useParams, useHistory } from 'react-router-dom';
import { useFetchService } from '../../../../../hooks/useFetch';

const validateSchema = Yup.object().shape({
    nombre: Yup.string().required('El Nombre es requerido'),
    email: Yup.string().email('Email invalido').required('El email requerido'),
    movil: Yup.string().required('El Movil es requerido'),
    telefono: Yup.string().required('El Telefono es requerido'),
    pass: Yup.string().required('La contrasenia  es requerida'),
    observaciones: Yup.string().required("Las notas son requeridas")
})


const TechData = ({ data, goToTable }) => {


    const dispatch = useDispatch()
    const [modeEdit, setModeEdit] = useState(false);
    const techData = useSelector(state => state.tecnico.techData);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalChangeState, setOpenModalChangeState] = useState(false);

    const { response: distributors, loading } = useFetchService(ServiceTechs.getDistributors);

    const listDistributor = useMemo(() => {
        return _.map(distributors, dist => ({ label: dist.nombre, value: dist.id_cliente }));
    }, [loading,])

    const params = useParams();

    const history = useHistory();

    const handleSubmit = async (values) => {
        console.log("handleSUBM--TECH:", values);
        await ServiceTechs.updateTechData({ id: params.id, data: values });
        setOpenModalSuccess(true);
        setModeEdit(false)
    }

    const handleDeleteDistributor = async () => {
        await ServiceTechs.deleteTech(params.id);
        history.push('/dashboard/techs');
    }

    const refForm = useRef();

    const handleChangeState = async () => {
        const { email, ...rest } = refForm.current.watch();
        await ServiceTechs.updateTechData({ id: params.id, data: { ...rest } });
        setOpenModalChangeState(false);
    }

    const handleCloseModalChangeState = () => {
        const state = refForm.current.watch('estado_cliente');
        refForm.current.setValue('estado_cliente', state ? 0 : 1);
        setTimeout(() => setOpenModalChangeState(false), 100);
    }


    return (
        <>  <Form
            innerRef={refForm}
            initialValues={techData}
            schemaForm={validateSchema}
            onSubmit={handleSubmit}
            className={'containerView'}  >

                < >
                    {
                        !modeEdit ? <ButtonEdit className={'align-self-end'} label={"Editar"} onClick={() => setModeEdit(true)} />
                            : <ButtonRemove className={'align-self-end'} label={"Eliminar Tecnico"} onClick={() => setOpenModalDelete(true)}></ButtonRemove>
                    }
                </>
                <Grid spacing={1} style={{ display: "flex", flexDirection: "flex-row" }} >
                    <Grid item xs={12}  >
                        <Grid item xs={12} className={'mt-1'}  >
                            <FormLabel className="text-primary">
                                Nombre
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"nombre"}
                                modeEdit={modeEdit}
                                className={''}
                            />
                        </Grid >

                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Email
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"email"}
                                modeEdit={modeEdit}
                            />

                        </Grid >

                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Contraseña
                                                    </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"pass"}
                                type={"password"}
                                modeEdit={modeEdit}
                            />

                        </Grid >

                    </Grid>

                    <Grid item xs={12}  >
                        <Grid item xs={12} className={'mt-1'} >
                            <FormLabel className="text-primary">
                                Distribuidor
                            </FormLabel>
                            <ControllerInput
                                render={SelectInput}
                                list={listDistributor}
                                loading={loading}
                                name={"id_distribuidor"}
                                modeEdit={modeEdit}
                                classNameContainer={'w-75'}
                                className={'w-75'}
                                classNameMenu={'menuDistributorSelect'}
                            />
                        </Grid >

                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Movil
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"movil"}
                                modeEdit={modeEdit}
                            />

                        </Grid >


                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Telefono
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"telefono"}
                                modeEdit={modeEdit}
                            />


                        </Grid >


                    </Grid>

                    <Grid item xs={12}  >

                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Notas
                            </FormLabel>
                            <ControllerInput
                                render={TextArea}
                                name={"observaciones"}
                                modeEdit={modeEdit}
                                className={'w-75'}
                            />

                        </Grid >


                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Estado
                            </FormLabel>
                            <ControllerInput
                                render={ButtonState}
                                name={"estado_cliente"}
                                disabled={!modeEdit}
                                defaultValue={false}
                                onChange={() => {
                                    setOpenModalChangeState(true)
                                }}
                            />
                        </Grid >

                    </Grid>


                </Grid>
                {
                    modeEdit &&
                    <div className="formButtons">
                        <Button variant="contained" onClick={() => setModeEdit(false)} className="prevButton btn">Cancelar</Button>
                        <SubmitButton> Guardar Cambios </SubmitButton>
                    </div>

                }

                <AlertDialog text={`Seguro que desea Eliminar al Tecnico:`} name={techData.nombre} isOpen={openModalDelete} handleYes={handleDeleteDistributor} handleNo={() => setOpenModalDelete(false)} />
                <AlertDialog text={refForm?.current?.watch('estado_cliente') ? `Seguro que desea Activar al Tecnico:` : `Seguro que desea Suspender al Tecnico:`} name={techData.nombre} isOpen={openModalChangeState}
                    handleYes={handleChangeState}
                    handleNo={handleCloseModalChangeState} />
            </Form >

            <SuccessDialog isOpen={openModalSuccess} handleClose={() => setOpenModalSuccess(false)} text={"Cambios Guardados con Exito"} />

        </>
    )
}

export default TechData
