
export const ENDPOINT =  'https://api.ecocolor.es' //"https://node-ecocolor.herokuapp.com"

//export const ENDPOINT = "http://localhost:8080";

export const INCOME_DATA = [{
  name: "Ene",
  $: 4000
},
{
  name: "Feb",
  $: 3000
},
{
  name: "Mar",
  $: 2000,
},
{
  name: "Abr",
  $: 2780,
},
{
  name: "May",
  $: 1890,

},
{
  name: "Jun",
  $: 2390,
},
{
  name: "Jul",
  $: 5490
},
{
  name: "Ago",
  $: 3490
},
{
  name: "Sep",
  $: 2490
},
{
  name: "Oct",
  $: 3490
},
{
  name: "Nov",
  $: 3290
},
{
  name: "Dic",
  $: 1490
},
];

export const distributorHeader = [

  {
    name: "Nombre fiscal",
    prop: "nombre"
  },
  {
    name: "Fecha de creación",
    prop: "fecha"
  },
  {
    name: "CIF",
    prop: "cif"
  },
  {
    name: "Comisión",
    prop: "comi_ini"
  },
  {
    name: "Clientes",
    prop: "id_cliente"
  },
  {
    name: "Saldo a Favor",
    prop: "positiveBalance"
  },
  {
    name: "Estado",
    prop: "estado_cliente"
  }
]


export const distributorRows = [ //para testear
  { name: "Buis Daniel Orduña Carmona", createdAt: "2017-10-01T08:28:05.074Z", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Cuis Daniel Orduña Carmona", createdAt: "2020-9-01T08:28:05.074Z", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Zuis Daniel Orduña Carmona", createdAt: "2016-2-01T08:28:05.074Z", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Duis Daniel Orduña Carmona", createdAt: "27/08/2012", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Wuis Daniel Orduña Carmona", createdAt: "12/08/2015", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Euis Daniel Orduña Carmona", createdAt: "11/08/2011", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Auis Daniel Orduña Carmona", createdAt: "14/08/2004", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Puis Daniel Orduña Carmona", createdAt: "27/08/2006", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Ruis Daniel Orduña Carmona", createdAt: "13/08/2002", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Duit Daniel Orduña Carmon", createdAt: "30/08/2001", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Ouis Daniel Orduña Carmona", createdAt: "24/08/2008", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Luis Daniel Orduña Carmona", createdAt: "04/08/2003", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Suis Daniel Orduña Carmona", createdAt: "06/08/2005", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Fuis Daniel Orduña Carmona", createdAt: "21/08/2002", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Kuis Daniel Orduña Carmona", createdAt: "12/08/2008", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Nuis Daniel Orduña Carmona", createdAt: "17/08/2005", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
]

export const distributorHeader2 = [{
  name: "Nombre fiscal",
  prop: "nombre"
},
{
  name: "Fecha de creación",
  prop: "fecha"
},
{
  name: "CIF",
  prop: "cif"
},
{
  name: "Comisión",
  prop: "comi_ini"
},
{
  name: "Clientes",
  prop: "id_cliente"
},
{
  name: "Saldo a Favor",
  prop: "positiveBalance"
},
{
  name: "Estado",
  prop: "estado_cliente"
}
]


export const distributorRows2 = [ //para testear
  { name: "Buis Daniel Orduña Carmona", createdAt: "2017-10-01T08:28:05.074Z", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Cuis Daniel Orduña Carmona", createdAt: "2020-9-01T08:28:05.074Z", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Zuis Daniel Orduña Carmona", createdAt: "2016-2-01T08:28:05.074Z", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Duis Daniel Orduña Carmona", createdAt: "27/08/2012", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Wuis Daniel Orduña Carmona", createdAt: "12/08/2015", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Euis Daniel Orduña Carmona", createdAt: "11/08/2011", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Auis Daniel Orduña Carmona", createdAt: "14/08/2004", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Puis Daniel Orduña Carmona", createdAt: "27/08/2006", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Ruis Daniel Orduña Carmona", createdAt: "13/08/2002", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Duit Daniel Orduña Carmon", createdAt: "30/08/2001", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Ouis Daniel Orduña Carmona", createdAt: "24/08/2008", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Luis Daniel Orduña Carmona", createdAt: "04/08/2003", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Suis Daniel Orduña Carmona", createdAt: "06/08/2005", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Fuis Daniel Orduña Carmona", createdAt: "21/08/2002", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Kuis Daniel Orduña Carmona", createdAt: "12/08/2008", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
  { name: "Nuis Daniel Orduña Carmona", createdAt: "17/08/2005", cif: "78528673L", comission: "20%", client: 45, positiveBalance: "$ 300", state: "Activo" },
]

export const clientsHeader = [{
  name: "Nombre fiscal",
  prop: "nombre"
},
{
  name: "Fecha de creación",
  prop: "fecha"
},
{
  name: "CIF",
  prop: "cif"
},
{
  name: "Distribuidor",
  prop: "dist_ini"
},
{
  name: "Impresoras",
  prop: "id_impresora"
},
{
  name: "Copias",
  prop: "copies"
},
{
  name: "Estado",
  prop: "estado_cliente"
}
]

export const ClientRows = [ //para testear
  { name: "Buis Daniel Orduña Carmona", createdAt: "2017-10-01T08:28:05.074Z", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Cuis Daniel Orduña Carmona", createdAt: "2020-9-01T08:28:05.074Z", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Zuis Daniel Orduña Carmona", createdAt: "2016-2-01T08:28:05.074Z", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Duis Daniel Orduña Carmona", createdAt: "27/08/2012", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Wuis Daniel Orduña Carmona", createdAt: "12/08/2015", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Euis Daniel Orduña Carmona", createdAt: "11/08/2011", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Auis Daniel Orduña Carmona", createdAt: "14/08/2004", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Puis Daniel Orduña Carmona", createdAt: "27/08/2006", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Ruis Daniel Orduña Carmona", createdAt: "13/08/2002", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Duit Daniel Orduña Carmon", createdAt: "30/08/2001", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Ouis Daniel Orduña Carmona", createdAt: "24/08/2008", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Luis Daniel Orduña Carmona", createdAt: "04/08/2003", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Suis Daniel Orduña Carmona", createdAt: "06/08/2005", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Fuis Daniel Orduña Carmona", createdAt: "21/08/2002", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Kuis Daniel Orduña Carmona", createdAt: "12/08/2008", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
  { name: "Nuis Daniel Orduña Carmona", createdAt: "17/08/2005", cif: "78528673L", client: 45, printer: 105, dist_ini: "$ 300", state: "Activo" },
]


export const printersHeader = [
  {
    name: "Numero de Serie",
    prop: "numSerie"
  },
  {
    name: "Fecha de alta",
    prop: "createdAt"
  },
  {
    name: "Direccion",
    prop: "direction"
  },
  {
    name: "Gama de maquina",
    prop: "machine_gama"
  },
  {
    name: "Ubicacion",
    prop: "ubication"
  },
  {
    name: "Estado",
    prop: "state"
  }

]

export const PrinterRows = [ //para testear
  { IP: "0123456789", createdAt: "2017-10-01T08:28:05.074Z", direction: "Las Toninas", machine_gama: "Alta", ubication: "Administracion", state: "Activo" },
  { IP: "0121746799", createdAt: "2020-9-01T08:28:05.074Z", direction: "Udaondo", machine_gama: "Media", ubication: "Secretaria", state: "Activo" },
  { IP: "0963356789", createdAt: "2016-2-01T08:28:05.074Z", direction: "78528673L", machine_gama: "Alta", ubication: 105, state: "Activo" },
  { IP: "0123456789", createdAt: "27/08/2012", direction: "78528673L", machine_gama: 45, ubication: 105, state: "Activo" },
  { IP: "0153456789", createdAt: "12/08/2015", direction: "78528673L", machine_gama: 45, ubication: 105, state: "Activo" },
  { IP: "0123456789", createdAt: "11/08/2011", direction: "78528673L", machine_gama: 45, ubication: 105, state: "Activo" },
  { IP: "0123456789", createdAt: "14/08/2004", direction: "78528673L", machine_gama: 45, ubication: 105, state: "Activo" },
  { IP: "0121456789", createdAt: "27/08/2006", direction: "78528673L", machine_gama: 45, ubication: 105, state: "Activo" },
  { IP: "0123556789", createdAt: "13/08/2002", direction: "78528673L", machine_gama: 45, ubication: 105, state: "Activo" },
  { IP: "0123456789", createdAt: "30/08/2001", direction: "78528673L", machine_gama: 45, ubication: 105, state: "Activo" },
  { IP: "0123436789", createdAt: "24/08/2008", direction: "78528673L", machine_gama: 45, ubication: 105, state: "Activo" },
  { IP: "0123456789", createdAt: "04/08/2003", direction: "78528673L", machine_gama: 45, ubication: 105, state: "Activo" },
  { IP: "0123456789", createdAt: "06/08/2005", direction: "78528673L", machine_gama: 45, ubication: 105, state: "Activo" },
  { IP: "0123456789", createdAt: "21/08/2002", direction: "78528673L", machine_gama: 45, ubication: 105, state: "Activo" },
  { IP: "0123456789", createdAt: "12/08/2008", direction: "78528673L", machine_gama: 45, ubication: 105, state: "Activo" },
  { IP: "0123452789", createdAt: "17/08/2005", direction: "78528673L", machine_gama: 45, ubication: 105, state: "Activo" },
]


export const technicalHeader = [{
  name: "Nombre",
  prop: "name"
},
{
  name: "Fecha de creacion",
  prop: "createdAt"
},
{
  name: "Distribuidor",
  prop: "distributor"
},
{
  name: "Estado",
  prop: "state"
}
]

export const technicalRows = [ //para testear
  { name: "Luis Daniel Carmona", createdAt: "17/08/2007", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "17/08/2007", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "17/08/2007", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "27/08/2012", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "12/08/2015", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "11/08/2011", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "14/08/2004", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "27/08/2006", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "13/08/2002", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "30/08/2001", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "24/08/2008", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "04/08/2003", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "06/08/2005", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "21/08/2002", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "12/08/2008", distributor: "Luis Daniel Carmona", state: "Activo" },
  { name: "Luis Daniel Carmona", createdAt: "17/08/2005", distributor: "Luis Daniel Carmona", state: "Activo" },
]

export const printerHeader = [{
  name: "Nº de serie",
  prop: "serie"
},
{
  name: "Marca",
  prop: "marc"
},
{
  name: "Modelo",
  prop: "model"
},
{
  name: "Gama de maquina",
  prop: "gama"
},
{
  name: "Asignada a",
  prop: "asigned"
},
{
  name: "Estado",
  prop: "state"
},
]

export const printerRows = [ //para testear
  { serie: "0123456789", marc: "Epson", model: "Administracion", gama: "Media", asigned: "Asignado", state: "Activo" },
  { serie: "0123456789", marc: "Epson", model: "Secretaria", gama: "Alta", asigned: "Asignado", state: "Activo" },
  { serie: "0123456789", marc: "Epson", model: "45", gama: "Baja", asigned: "Asignado", state: "Activo" },
  { serie: "0123456789", marc: "Hp", model: "45", gama: "Baja", asigned: "No asignado", state: "Activo" },
  { serie: "0123456789", marc: "Hp", model: "45", gama: "Media", asigned: "Asignado", state: "Activo" },
  { serie: "0123456789", marc: "Hp", model: "45", gama: "Media", asigned: "No asignado", state: "Activo" },
  { serie: "0123456789", marc: "Hp", model: "45", gama: "Media", asigned: "Asignado", state: "Activo" },
  { serie: "0123456789", marc: "Hp", model: "45", gama: "Alta", asigned: "Asignado", state: "Activo" },
  { serie: "0123456789", marc: "Hp", model: "45", gama: "Alta", asigned: "Asignado", state: "Activo" },
  { serie: "0123456789", marc: "Hp", model: "45", gama: "Alta", asigned: "Asignado", state: "Activo" },
  { serie: "0123456789", marc: "Epson", model: "45", gama: "Baja", asigned: "Asignado", state: "Activo" },
  { serie: "0123456789", marc: "Epson", model: "45", gama: "Baja", asigned: "Asignado", state: "Activo" },
  { serie: "0123456789", marc: "Epson", model: "45", gama: "Media", asigned: "Asignado", state: "Activo" },
  { serie: "0123456789", marc: "Hp", model: "45", gama: "Alta", asigned: "Asignado", state: "Activo" },
  { serie: "0123456789", marc: "Hp", model: "45", gama: "Alta", asigned: "Asignado", state: "Activo" },
  { serie: "0123456789", marc: "Hp", model: "45", gama: "Alta", asigned: "Asignado", state: "Activo" },
]

export const billingHeader = [{
  name: "Cliente",
  prop: "client"
},
{
  name: "Ubicacion",
  prop: "ubication"
},
{
  name: "Total",
  prop: "total"
},
{
  name: "Estado",
  prop: "state"
},
{
  name: "Detalle",
  prop: "detail"
},
{
  name: "Seleccionar",
  prop: "select"
},
]

export const billingRows = [ //para testear
  { client: "Luis Daniel", ubication: "Administracion", total: "841$", state: "Activo", detail: "Listo", select: "" },
  { client: "Luis Daniel", ubication: "Administracion", total: "1025,24$", state: "Activo", detail: "Listo", select: "" },
  { client: "Luis Daniel", ubication: "Administracion", total: "750,06$", state: "Activo", detail: "Listo", select: "" },
  { client: "Luis Daniel", ubication: "Contable", total: "841$", state: "Activo", detail: "No Listo", select: "" },
  { client: "Luis Daniel", ubication: "Contable", total: "841$", state: "Activo", detail: "Listo", select: "" },
  { client: "Luis Daniel", ubication: "Contable", total: "841$", state: "Activo", detail: "No Listo", select: "" },
  { client: "Luis Daniel", ubication: "RRHH", total: "841$", state: "Activo", detail: "Listo", select: "" },
  { client: "Luis Daniel", ubication: "RRHH", total: "841$", state: "Activo", detail: "Listo", select: "" },
  { client: "Luis Daniel", ubication: "RRHH", total: "841$", state: "Activo", detail: "Listo", select: "" },
]

export const ticketHeader = [{
  name: "Fecha de emision",
  prop: "date"
},
{
  name: "Usuario",
  prop: "user"
},
{
  name: "Nombre",
  prop: "name"
},
{
  name: "Asunto",
  prop: "affair"
},
{
  name: "Estado",
  prop: "state"
}
]

export const ticketRows = [ //para testear
  { date: "17-08-2021", user: "Cliente", name: "Pablo", affair: "Cambio de contraseña", state: "Activo" },
  { date: "03-05-2021", user: "Distribuidor", name: "Juan", affair: "RE:Usuario bloqueado", state: "Activo" },
  { date: "16-04-2021", user: "Distribuidor", name: "Roberto", affair: "Cambio de permisos", state: "Activo" },
  { date: "09-02-2021", user: "Cliente", name: "Anibal", affair: "Cambio de tarifas", state: "Activo" },
  { date: "02-021-2021", user: "Tecnico", name: "Oscar", affair: "Cambio de impresoras", state: "Activo" },
]


export const labelStatus = ["Suspend", "Active"]