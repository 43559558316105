import React, { useState } from "react";
import "./BillingDetailOverview.scss";
import Tabs from "../../../Tabs/Tabs";
import TAB_VIEW from "./Tab";
import VIEW from "../BillingView";
import BillingDetail from "./BillingDetail/BillingDetail";

const BillingDetailOverview = ({ setBillingView }) => {
  const [actualTab, setActualTab] = useState(TAB_VIEW.DATA);

  const handlePathClick = () => {
    setBillingView(VIEW.HOME);
  };

  return (
    <div className="distriDetailContainer">
      <div className="distriDetailInner">
        <div className="title">
          <div className="titleText">Ver Factura</div>
        </div>
        <div className="detailContent">
          <div className="actualPath">
            <p className="previous" onClick={handlePathClick}>
              Facturacion{" "}
            </p>
            <p className="actual">{"< Factura"}</p>
          </div>
          <Tabs
            actualTab={actualTab}
            setActualTab={setActualTab}
            texts={[<p>Datos de la factura</p>, <p>Detalles</p>]}
          />
          <BillingDetail actualTab={actualTab} />
        </div>
      </div>
    </div>
  );
};

export default BillingDetailOverview;
