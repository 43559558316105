import React, { useEffect } from 'react';
import IconCustom from '../../../IconCustom';
import classNames from 'classnames';
import _ from 'lodash';


const ButtonState = ({ value, onChange, labelActive = '', labelSuspend = '', defaultValue, className, ...props }) => {

    const handleOnClick = () => {
        onChange(!value);
    }

    useEffect(() => {
        if (_.isUndefined(value)) {
            onChange && onChange(defaultValue);
        }
    }, [])

    return (
        <button className={classNames(`btn-neutro btn-border ${className}`, {
            'text-orange': !value,
            'text-green-light': value,
        })}
            type={'button'} onClick={handleOnClick} {...props}>
            {value ?
                <>
                    <IconCustom size={'.5rem'} type={'ecoColor'} icon={'ecoColor-allowed'} className={'text-green-light'}  ></IconCustom>
                    <span className={'text-green-light text-font-bold'}>{labelActive || "Active"}</span>
                </> :
                <>
                    <IconCustom size={'.5rem'} type={'ecoColor'} icon={'ecoColor-allowed'} className={'text-orange'}  ></IconCustom>
                    <span className={'text-orange text-font-bold'}>{labelSuspend || "Suspendido"}</span>
                </>
            }
        </button>
    )
}

export default ButtonState
