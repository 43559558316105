import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './TechnicalDetailOverview.scss';
import Tabs from '../../../Tabs/Tabs';
import TAB_VIEW from './Tab';
import VIEW from '../TechnicalView';
import TechicalDetail from './TechnicalDetail/TechnicalDetail';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { TabPanel } from '../../../Tabs/components';
import { getTech } from '../../../../redux/technical/technicalReducer';


const TechnicalDetailOverview = ({ setTechnicalView }) => {

    const dispatch = useDispatch();
    const { techData, loading } = useSelector(state => ({ techData: state.tecnico.techData, loading: state.tecnico.loading }));
    const [actualTab, setActualTab] = useState(0);

    const params = useParams();
    const history = useHistory();

    useEffect(() => {
        dispatch(getTech(params.id))
    }, [])

    const handlePathClick = () => {
        history.push('/dashboard/techs');
    }

    return (
        <div className='distriDetailContainer'>
            <div className="distriDetailInner">
                <div className="title">
                    <div className="titleText">Tenico:  {techData.nombre}</div>
                </div>
                <div className='detailContent'>
                    <div className="actualPath">
                        <p className="previous" onClick={handlePathClick}>Tecnicos </p>
                        <p className="actual">{"< Tecnico"}</p>
                    </div>
                    <Tabs actualTab={actualTab} setActualTab={setActualTab} texts={[<p>Datos del tecnico</p>]} />
                    {!loading &&
                        <TabPanel index={0} selectTab={actualTab}>
                            <TechicalDetail actualTab={actualTab} handlePathClick={handlePathClick} />
                        </TabPanel>}
                </div>
            </div>
        </div>
    )
}

export default TechnicalDetailOverview;