const types = {
    GET_PAYMENT: "GET_PAYMENT",
    START_FETCH: "START_FETCH",
    PAY_REDSYS: "PAY_REDSYS",
    REDSYS_ASSIGNMENT: "REDSYS_ASSIGNMENT",
    STATE_SUBSCRIPTION: "STATE_SUBSCRIPTION",
    ASSIGNAMENT_INVOICES: "ASSIGNAMENT_INVOICES",
    FAILURE: 'FAILURE'
}

export default types;