import React, { useMemo, useState } from 'react';
import useDebounce from './useDebounce';
import _ from 'lodash'

export interface ConfigSearch<Value = any> {
    debounce?: number,
    predicate?(value?: Value, query?: string): boolean,
    initialQuery?: string,
    [key: string]: any
}



const defaultConfig: ConfigSearch<any> = {
    debounce: 200,
    predicate: (value, query) => (value?.label?.toLowerCase().indexOf(query?.toLowerCase()) >= 0),
    initialQuery: ''
}




function useSearch<ElementList extends object = any>(list: ElementList[], config?: ConfigSearch<ElementList>): [string, Function, ElementList[]] {


    const configSearch = useMemo(() => ({ ...defaultConfig, ...config }), [JSON.stringify(config)]);


    const [query, onChangeQuery] = useState(defaultConfig.initialQuery);


    const valueSearch = useDebounce(query, configSearch.debounce)

    const resultList = React.useMemo(() => {
        return _.filter(list, element => defaultConfig.predicate(element, valueSearch));
    }, [JSON.stringify(list), valueSearch]);

    return [query, onChangeQuery, resultList]
}

export default useSearch
