import React, { useState } from 'react';
import './TicketOverview.scss';
import DashTicket from './DashTicket/DashTicket';
import VIEW from './TicketView';

const TechnicalOverview = () => {
    const [TicketView, setTicketView] = useState(VIEW.HOME);
    return (
        <></>)

}

export default TechnicalOverview;