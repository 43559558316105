import React, { useState, useEffect } from 'react';
import Form from '../../Form';
import { TextInput, ControllerInput, FormLabel, TextArea, SubmitButton } from '../../Form/components';
import { ButtonEdit } from '../../Buttons'
import { Button, Grid } from '@material-ui/core';
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import './DashHome.scss';
import { ServiceDistributors } from '../../../utilities/api/services';
import { SuccessDialog } from '../../Modal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Tabs from '../../Tabs/Tabs';
import { useFetchService } from '../../../hooks/useFetch';
import ServicesUser from '../../../utilities/api/services/User';

const validateSchema = Yup.object().shape({
    nombre: Yup.string().required('El nombre  es requerido'),
    email: Yup.string().email('Email Invalido').required('El correo electronico requerido'),
    pass: Yup.string().required('La contrasenia  es requerida')
})


const Settings = ({ data }) => {


    const [modeEdit, setModeEdit] = useState(false);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);


    const user = JSON.parse(localStorage.getItem('user'));


    const handleSubmit = async (values) => {
        
        await ServicesUser.updateUserData({ id: user.idCliente, data: values });
        const { nombre } = values;

        localStorage.setItem('user', JSON.stringify({ ...user, nombre }));

        setOpenModalSuccess(true);
        setModeEdit(false)
    }

    const history = useHistory();
    const handlePathClick = () => {
        history.push('/dashboard')
    }


    const { response: userData, loading } = useFetchService(ServicesUser.getUser, user.idCliente);

    return (
        <div className="px-6">
            <div className="title w-100 d-flex text-lg justify-content-center   text-primary">
                <h3>Ajustes De la cuenta</h3>
            </div>
            <div className='detailContent'>
                <div className="d-flex flex-row text-primary">
                    <p className="previous" onClick={handlePathClick}>Inicio </p>
                    <p className="actual">{"< Ajustes"}</p>
                </div>

                <Tabs actualTab={0} setActualTab={() => { }} texts={[<p>Ajustes</p>]} />

                <>
                    {!loading &&
                        <Form
                            initialValues={userData?.[0]}
                            schemaForm={validateSchema}
                            onSubmit={handleSubmit}
                            className={'containerView'}  >

                            < >
                                {
                                    !modeEdit && <ButtonEdit className={'align-self-end'} label={"Editar"} onClick={() => setModeEdit(true)} />
                                }
                            </>
                            <Grid spacing={1} style={{ display: "flex", flexDirection: "flex-row" }} >
                                <Grid item xs={12}  >
                                    <FormLabel className="text-primary">
                                        Nombre Fiscal

                                     </FormLabel>
                                    <ControllerInput
                                        render={TextInput}
                                        name={"nombre"}
                                        modeEdit={modeEdit}
                                        className={''}
                                    />

                                </Grid>

                                <Grid item xs={12}  >

                                    <FormLabel className="text-primary">
                                        Email
                                     </FormLabel>
                                    <ControllerInput
                                        render={TextInput}
                                        name={"email"}
                                        modeEdit={modeEdit}
                                        className={'w-75'}
                                    />

                                </Grid>

                                <Grid item xs={12} className={''} >


                                    <FormLabel className="text-primary">
                                        Contraseña
                        </FormLabel>
                                    <ControllerInput
                                        render={TextInput}
                                        name={"pass"}
                                        modeEdit={modeEdit}
                                        type={'password'}
                                    />



                                </Grid>


                            </Grid>
                            {
                                modeEdit &&
                                <div className="formButtons">
                                    <Button variant="contained" onClick={() => setModeEdit(false)} className="prevButton btn">Cancelar</Button>
                                    <SubmitButton  >Guardar Cambios</SubmitButton>
                                </div>

                            }


                        </Form >
                    }
                    <SuccessDialog isOpen={openModalSuccess} handleClose={() => setOpenModalSuccess(false)} text={"Cambios Guardados con Exito"} />

                </>

            </div>
        </div>
    )
}

export default Settings
