import React from 'react';
import './AuthHeader.scss';
import Logo from '../../assets/logo/eco-full.png';
import {useHistory} from "react-router-dom";

const AuthHeader = () => {
    const history = useHistory();
    return (
        <div className="loginHeaderContainer">
            <img className="loginLogo" src={Logo} alt="logo" onClick={() => history.push('/login')}/>
        </div>
    )
}

export default AuthHeader;