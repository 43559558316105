import React from 'react';
import './ErrorMessage.scss';

const ErrorMessage = ({ message, className, children }) => {
    return (
        <p className={`text-error-message text-red ${className}`}>
            {children ? children : message}
        </p>
    )
}

export default ErrorMessage
