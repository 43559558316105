import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ClientDetailOverview.scss';
import { getDataClientDetail } from '../../../../redux/client/clientReducer';
import Tabs from '../../../Tabs/Tabs';
import Consumed from '../../../Consumed/Consumed';
import waterLogo from '../../../../assets/dashboard/home/water.svg';
import trashLogo from '../../../../assets/dashboard/home/trash.svg';
import forestLogo from '../../../../assets/dashboard/home/forest.svg';
import lampLogo from '../../../../assets/dashboard/home/lamp.svg';
import cloudLogo from '../../../../assets/dashboard/home/cloud.svg';
import carLogo from '../../../../assets/dashboard/home/car.svg';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { TabPanel } from '../../../Tabs/components';

import { ClientData, ContactData, Printers, ComercialData } from './view'





const ClientDetailOverview = ({ setClientsView }) => {

    const params = useParams();
    const dispatch = useDispatch()
    const { clientDetail, loading } = useSelector(state => ({ clientDetail: state.client.clientData, loading: state.client.loading }));

    const [actualTab, setActualTab] = useState(0);

    useEffect(() => {
        dispatch(getDataClientDetail(params.id));
    }, [])


    const history = useHistory()

    const handlePathClick = () => {
        history.push('/dashboard/clients');
    }

    return (
        <div className='clientDetailContainer ml-6'>
            <div className="clientDetailInner">
                <div className="title">
                    <div className="titleText">CLIENTE:  {clientDetail?.nombre}</div>
                </div>
                <div className="consumedAmount">
                    <Consumed logo={waterLogo} title={<p>Agua<br />Consumida</p>} amount={"204 L"} />
                    <Consumed logo={trashLogo} title={<p>Desechos de<br />residuos</p>} amount={"25,3 kg"} />
                    <Consumed logo={forestLogo} title={<p>Árboles<br />consumida</p>} amount={"850"} />
                    <Consumed logo={lampLogo} title={<p>Energía<br />consumida</p>} amount={"126,6 hs"} />
                    <Consumed logo={cloudLogo} title={<p>CO2<br />producido</p>} amount={"25,3 kg"} />
                    <Consumed logo={carLogo} title={<p>Km<br />recorrido</p>} amount={"306 km"} />
                </div>
                <div className='detailContent'>
                    <div className="actualPath">
                        <p className="previous" onClick={handlePathClick}> Clientes </p>
                        <p className="actual">{"> Cliente"}</p>
                    </div>

                    <Tabs actualTab={actualTab} setActualTab={setActualTab} texts={[<p>Datos del cliente</p>, <p>Contacto</p>, <p>Comerciales</p>, <p>Impresoras</p>]} />
                    {!loading &&
                        <>

                            <TabPanel index={0} selectTab={actualTab}>
                                <ClientData clientDetail={clientDetail} />
                            </TabPanel>
                            <TabPanel index={1} selectTab={actualTab}>
                                <ContactData clientDetail={clientDetail} />
                            </TabPanel>
                            <TabPanel index={2} selectTab={actualTab}>
                                <ComercialData clientDetail={clientDetail} />
                            </TabPanel>
                            <TabPanel index={3} selectTab={actualTab}>
                                <Printers />
                            </TabPanel>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default ClientDetailOverview;