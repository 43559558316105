import React, { useEffect, useState } from 'react'
import Tabs from '../../../../../Tabs/Tabs'
import { TabPanel } from '../../../../../Tabs/components';
import { ContactComercial, Ubication, TechsSchedules } from './Components';
import { useHistory } from 'react-router-dom';





const CommercialDataExtra = ({ idAddressCommercial }) => {

    const [actualTab, setActualTab] = useState(0);

    

    return (
        <div className={'my-5'} >
            <Tabs actualTab={actualTab} setActualTab={setActualTab} texts={[<p>Contactos Comerciales</p>, <p>Ubicaciones</p>, <p>Horarios de servicio tecnico </p>]} />

            <TabPanel index={0} selectTab={actualTab} >
                <ContactComercial idAddressCommercial={idAddressCommercial} />
            </TabPanel>

            <TabPanel index={1} selectTab={actualTab} >
                <Ubication idAddressCommercial={idAddressCommercial} />
            </TabPanel>


            <TabPanel index={2} selectTab={actualTab} >
                <TechsSchedules idAddressCommercial={idAddressCommercial} />
            </TabPanel>


        </div>
    )
}

export default CommercialDataExtra
