import axios from "axios";
import { ENDPOINT } from "../../utilities/constants";
import clientTypes from "./types";
import { ServiceClients } from "../../utilities/api/services";
import _ from 'lodash'

const initialState = {
    loading: false,
    clients: [],
    error: '',
    openSucess: false,
    openError: false,
    clientCreated: false,
    clientCreate: {
        step: 0,
        data: {}
    },
    clientDelete: false,
    contactDelete: false,
    direccionComercialAdd: false,
    contactAdd: false,
    disbutorsclients: [],
    selectedId: null,
    clientDetail: {},
    clientData: {},
    clientState: [],
    selectedClient: '',
    selectDireccion: {},
    selectedDireccion: 0,
    selectedNumeroSerie: 0,
    dataDireccion: {},
    dataContacts: [],
    dataContact: {},
    impresoraNumber: [],
    impresoraGama: [],
    impresoraEstado: [],
    impresoraData: [],
    impresoraId: 0,
    ubicacionId: 0,
    gamaId: 0,
    selectTarifa: [],
    tarifaData: [],
    formasPago: [],
    ubicacion: [],
    ubicacionData: [],
    clientImpresoras: []
};

// Reducer
export default function clientReducer(state = initialState, action) {
    switch (action.type) {
        case clientTypes.START_FETCH_CLIENTS:
            return {
                ...state,
                loading: true,
            }
        case clientTypes.GET_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: action.payload,
                loading: false
            }
        case clientTypes.CREATE_CLIENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                openSucess: true,
                clientCreated: true
            }
        case clientTypes.FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
                openError: true
            }
        case clientTypes.CLOSE_DIALOG:
            return {
                ...state,
                openSucess: false,
                openError: false
            }
        case clientTypes.SET_OFF_REDIRECT:
            return {
                ...state,
                clientCreated: false
            }
        case clientTypes.GET_CLIENTS_BY_ID:
            return {
                ...state,
                distributorsclients: action.payload
            }
        case clientTypes.SELECT_CLIENT:
            return {
                ...state,
                selectedClient: action.payload,
            }
        case clientTypes.GET_CLIENT_DETAIL:
            return {
                ...state,
                clientData: action.payload,
                clientState: action.payload.estado_cliente,
                loading: false
            }
        case clientTypes.UPDATE_CLIENT_DETAIL:
            return {
                ...state,
                clientData: action.payload,
                loading: false
            }
        case clientTypes.GET_DATA_CLIENT:
            return {
                ...state,
                clientData: action.payload,
                loading: false,
            }
        case clientTypes.DELETE_CLIENT_SUCCESS:
            return {
                ...state,
                loading: false,
                openSucess: true,
                clientDelete: true
            }
        case clientTypes.SUSPENDED_CLIENT:
            return {
                ...state,
                loading: false,
                clientState: 0
            }
        case clientTypes.ACTIVE_CLIENT:
            return {
                ...state,
                loading: false,
                clientState: 1
            }
        case clientTypes.ADD_DIRECCION_COMERCIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                openSucess: true,
                direccionComercialAdd: true,
            }
        case clientTypes.GET_SELECT_DIRECCION:
            return {
                ...state,
                selectDireccion: action.payload,
                loading: false,
            }
        case clientTypes.SET_SELECT_DIRECCION:
            return {
                ...state,
                selectedDireccion: action.payload,
            }
        case clientTypes.GET_DATA_DIRECCION:
            return {
                ...state,
                dataDireccion: action.payload,
                loading: false,
            }
        case clientTypes.ADD_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                openSucess: true,
                contactAdd: true,
            }
        case clientTypes.GET_DATA_CONTACTS:
            return {
                ...state,
                dataContacts: action.payload,
                loading: false,
            }
        case clientTypes.GET_DATA_CONTACT:
            return {
                ...state,
                dataContact: action.payload,
                loading: false,
            }
        case clientTypes.DELETE_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                openSucess: true,
                contactDelete: true
            }
        case clientTypes.GET_NUMBER_IMPRESORA:
            return {
                ...state,
                impresoraNumber: action.payload,
                loading: false,
            }
        case clientTypes.GET_GAMA_IMPRESORA:
            return {
                ...state,
                impresoraGama: action.payload,
                loading: false,
            }
        case clientTypes.GET_ESTADO_IMPRESORA:
            return {
                ...state,
                impresoraEstado: action.payload,
                loading: false,
            }
        case clientTypes.GET_DATA_IMPRESORA:
            return {
                ...state,
                impresoraData: action.payload,
                impresoraId: action.payload[0].id_impresora,
                ubicacionId: action.payload[0].id_ubica_cli,
                gamaId: action.payload[0].id_gama,
                loading: false,
            }
        case clientTypes.SELECT_NUMERO_SERIE:
            return {
                ...state,
                selectedNumeroSerie: action.payload,
            }
        case clientTypes.GET_SELECT_TARIFA:
            return {
                ...state,
                selectTarifa: action.payload,
                loading: false,
            }
        case clientTypes.GET_TARIFA_DATA:
            return {
                ...state,
                tarifaData: action.payload,
                loading: false,
            }
        case clientTypes.GET_FORMAS_PAGO:
            return {
                ...state,
                formasPago: action.payload,
                loading: false,
            }
        case clientTypes.GET_UBICACION:
            return {
                ...state,
                ubicacion: action.payload,
                loading: false,
            }
        case clientTypes.GET_UBICACION_DATA:
            return {
                ...state,
                ubicacionData: action.payload,
                loading: false,
            }
        case clientTypes.GET_CLIENT_IMPRESORAS:
            return {
                ...state,
                clientImpresoras: action.payload,
                loading: false,
            }
        case clientTypes.NEXT_STEP_CREATED:
            return {
                ...state,
                clientCreate: {
                    data: { ...state.clientCreate.data, ...action.payload },
                    step: ++state.clientCreate.step
                }

            }
        case clientTypes.PREV_STEP_CREATED:
            return {
                ...state,
                clientCreate: {
                    data: { ...state.clientCreate.data, ...action.payload },
                    step: --state.clientCreate.step
                }

            }
        case clientTypes.FINISH_CREATED:
            return {
                ...state,
                clientCreate: {
                    data: {},
                    step: 0
                }
            }
        default:
            {
                return state;
            }
    }
}

// Action Creators
export const getClientsStart = () => ({
    type: clientTypes.START_FETCH_CLIENTS
});

export const getClientsSuccess = (res) => ({
    type: clientTypes.GET_CLIENTS_SUCCESS,
    payload: res
})

export const handleError = (error) => ({
    type: clientTypes.FAILURE,
    payload: error
})

export const createClientStart = () => ({
    type: clientTypes.START_FETCH_CLIENTS
})

export const createClientSuccess = () => ({
    type: clientTypes.CREATE_CLIENTS_SUCCESS
})

export const closeDialog = () => ({
    type: clientTypes.CLOSE_DIALOG
})

export const setRedirectOff = () => ({
    type: clientTypes.SET_OFF_REDIRECT
})

export const getDistributorCli = (res) => ({
    type: clientTypes.GET_CLIENTS_BY_ID,
    payload: res
})

export const getDetailClient = (res) => ({
    type: clientTypes.GET_CLIENT_DETAIL,
    payload: res
})

export const getDataClient = (res) => ({
    type: clientTypes.GET_DATA_CLIENT,
    payload: res
})

export const selectClient = (client) => ({
    type: clientTypes.SELECT_CLIENT,
    payload: client
})

export const deleteClientSuccess = () => ({
    type: clientTypes.DELETE_CLIENT_SUCCESS
})

export const suspendedClientSuccess = () => ({
    type: clientTypes.SUSPENDED_CLIENT
})

export const activeClientSuccess = () => ({
    type: clientTypes.ACTIVE_CLIENT
})

export const addDireccionComercialSuccess = () => ({
    type: clientTypes.ADD_DIRECCION_COMERCIAL_SUCCESS
})

export const getSelectDireccionComercial = (res) => ({
    type: clientTypes.GET_SELECT_DIRECCION,
    payload: res.data
})

export const setSeletedDireccion = (res) => ({
    type: clientTypes.SET_SELECT_DIRECCION,
    payload: res
})

export const getDataDireccionComercial = (res) => ({
    type: clientTypes.GET_DATA_DIRECCION,
    payload: res.data
})

export const addContactSuccess = () => ({
    type: clientTypes.ADD_CONTACT_SUCCESS
})

export const getDataContacts = (res) => ({
    type: clientTypes.GET_DATA_CONTACTS,
    payload: res.data
})

export const getDataContact = (res) => ({
    type: clientTypes.GET_DATA_CONTACT,
    payload: res.data
})

export const deleteContactSuccess = () => ({
    type: clientTypes.DELETE_CONTACT_SUCCESS
})

export const getImpresoraNumber = (res) => ({
    type: clientTypes.GET_NUMBER_IMPRESORA,
    payload: res.data
})

export const getImpresoraGama = (res) => ({
    type: clientTypes.GET_GAMA_IMPRESORA,
    payload: res.data
})

export const getImpresoraEstado = (res) => ({
    type: clientTypes.GET_ESTADO_IMPRESORA,
    payload: res.data
})

export const getImpresoraData = (res) => ({
    type: clientTypes.GET_DATA_IMPRESORA,
    payload: res.data
})

export const selectNumeroSerie = (num) => ({
    type: clientTypes.SELECT_NUMERO_SERIE,
    payload: num
})

export const getContratoTarifa = (res) => ({
    type: clientTypes.GET_SELECT_TARIFA,
    payload: res.data
})

export const getTarifaData = (res) => ({
    type: clientTypes.GET_TARIFA_DATA,
    payload: res.data
})

export const getFormasPago = (res) => ({
    type: clientTypes.GET_FORMAS_PAGO,
    payload: res.data
})

export const getUbi = (res) => ({
    type: clientTypes.GET_UBICACION,
    payload: res.data
})

export const getUbicacionData = (res) => ({
    type: clientTypes.GET_UBICACION_DATA,
    payload: res.data
})

export const getClientDataImpresoras = (res) => ({
    type: clientTypes.GET_CLIENT_IMPRESORAS,
    payload: res.data
})

export const updateClientDate = (res) => ({
    type: clientTypes.UPDATE_CLIENT_DETAIL,
    payload: res
})


export const nextStepClientCreate = (values) => ({
    type: clientTypes.NEXT_STEP_CREATED,
    payload: values
});

export const prevStepClientCreate = (values) => ({
    type: clientTypes.PREV_STEP_CREATED,
    payload: values
});

export const finishClientCreate = () => ({
    type: clientTypes.FINISH_CREATED
});



// Side effects
export const getClients = (search) => {
    return (dispatch) => {
        dispatch(getClientsStart());
        ServiceClients.getClients(search)
            .then((res) => {
                dispatch(getClientsSuccess(res));

            })

            .catch((error) => {
                dispatch(handleError(error));
                alert("Error al encontrar cliente");
            });
    };
};

export const createClient = (clientData) => {
    return (dispatch) => {
        dispatch(createClientStart());

        axios.post(ENDPOINT + "/clientes/createClient", clientData, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(createClientSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}


export const getDataCliDistributor = (id) => {

    return (dispatch) => {

        axios.get(ENDPOINT + `/distributors/dataClient/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getDistributorCli(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}
//revisar porq array
export const getDataClientDetail = (id) => {
    return (dispatch) => {

        dispatch(createClientStart());

        ServiceClients.getDataClient(id)
            .then(res => {
                dispatch(getDetailClient(res))
            })
            .catch(error => {
                console.log('error', error)
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}


export const deleteClient = (id) => {
    return (dispatch) => {
        dispatch(createClientStart());

        axios.delete(ENDPOINT + `/clientes/deleteCliente/${id}`)
            .then(res => {
                dispatch(deleteClientSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const suspendedClient = (id) => {
    return (dispatch) => {
        dispatch(createClientStart());

        axios.put(ENDPOINT + `/permisos/disable/${id}`)
            .then(res => {
                dispatch(suspendedClientSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const activeClient = (id) => {
    return (dispatch) => {
        dispatch(createClientStart());

        axios.put(ENDPOINT + `/permisos/enable/${id}`)
            .then(res => {
                dispatch(activeClientSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const updateClient = (id, data, cli) => {
    return (dispatch) => {

        axios.put(ENDPOINT + `/clientes/${id}`, data)
            .then(res => {
                alert("Cambios Guardados");
            })
            .catch(error => {
                dispatch(handleError(error.response));
                alert(error.response);
            });

        axios.put(ENDPOINT + `/clientes/updateContactCliente/${id}`, cli)
            .then(res => {
                
            })
            .catch(error => {
                dispatch(handleError(error.response));
                alert(error.response);
            });

    }
}

export const addDireccionComercial = (id, data) => {
    return (dispatch) => {
        dispatch(createClientStart());

        axios.post(ENDPOINT + `/clientes/addDirecc/${id}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(addDireccionComercialSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getSelectDireccion = (id) => {

    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/dataDirecc/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getSelectDireccionComercial(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getDataDireccion = (id) => {

    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/getDataDirecc/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getDataDireccionComercial(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const updateDataDireccion = (id, data) => {
    return (dispatch) => {

        axios.put(ENDPOINT + `/clientes/direcc/${id}`, data)
            .then(res => {
                alert("Cambios de la dirección guardados");
            })
            .catch(error => {
                dispatch(handleError(error.response));
                alert(error.response);
            });

    }
}

export const addContact = (id, data) => {
    return (dispatch) => {
        dispatch(createClientStart());

        axios.post(ENDPOINT + `/clientes/createContact/${id}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(addContactSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getContacts = (id) => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/contacto/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getDataContacts(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getContact = (id) => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/contact/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getDataContact(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const updateContact = (id, data) => {
    return (dispatch) => {

        axios.put(ENDPOINT + `/clientes/updateContact/${id}`, data)
            .then(res => {
                alert("Cambios de la dirección guardados");
            })
            .catch(error => {
                dispatch(handleError(error.response));
                alert(error.response);
            });

    }
}

export const deleteContact = (id) => {
    return (dispatch) => {
        dispatch(createClientStart());

        axios.delete(ENDPOINT + `/clientes/deleteContact/${id}`)
            .then(res => {
                dispatch(deleteContactSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getSerieNumber = () => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/getSerieNumber`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getImpresoraNumber(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getGama = () => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/getGama`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getImpresoraGama(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getEstados = () => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/estados`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getImpresoraEstado(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getData = (id) => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/dataImp/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                console.log(res)
                dispatch(getImpresoraData(res))
            })
            .catch(error => {
                console.log(error.response)
                dispatch(handleError(error.response));
                alert(error.response);
            });
    }
}

export const createImpresora = (data) => {
    return (dispatch) => {

        axios.post(ENDPOINT + `/clientes/createImpresora/321`, data)
            .then(res => {
                alert("Se creo exitosamente");
                dispatch(getSerieNumber())
            })
            .catch(error => {
                dispatch(handleError(error.response));
                alert(error.response);
            });

    }
}

export const getTarifa = () => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/tarifa`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getContratoTarifa(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getDataTarifa = (id) => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/tarifa/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getTarifaData(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getFormaPago = () => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/getFormaPago`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getFormasPago(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const createContrato = (id, data) => {
    return (dispatch) => {

        axios.post(ENDPOINT + `/clientes/CreateContrato/${id}/321`, data)
            .then(res => {
                alert("Se creo exitosamente");
                dispatch(getSerieNumber())
            })
            .catch(error => {
                dispatch(handleError(error.response));
                alert(error.response);
            });

    }
}

export const getUbicacion = (id) => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/getUbicacion/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getUbi(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getDataUbicacion = (id) => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/data/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getUbicacionData(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const updateContract = (id_cliente, id_distribuidor, data) => {
    return (dispatch) => {

        axios.put(ENDPOINT + `/clientes/updateContratoClient/${id_cliente}/${id_distribuidor}`, data)
            .then(res => {
            })
            .catch(error => {
                dispatch(handleError(error.response));
                alert(error.response);
            });

    }
}

export const getDataImpresoras = (id) => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/clientes/dataCliente/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getClientDataImpresoras(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

