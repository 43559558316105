import React, { useRef } from 'react';
import classNames from 'classnames';
import './TextInput.scss';
import ErrorMessage from '../../../Text/ErrorMessage';
import useMaskedInput from '@viewstools/use-masked-input'


const TextInput = ({ onChange, customMask = false, value, defaultValue, className, readOnly = false, modeEdit = false, errorMessage, errors, ...props }) => {


    let input = useRef(null)

    let onChangeTime = useMaskedInput({
        input,
        mask: [/[0-2]/, /[0-9]/,':', /[0-5]/, /[0-9]/],
        onChange,
        value,
        showMask:true
    })

    return (
        <>
            <input
                className={classNames(`TextInputEcoColor ${className}`, { 'modeEditTextInputEcoColor': modeEdit })}
                {...props}
                value={value}
                defaultValue={defaultValue}
                ref={input}
                onChange={onChangeTime}
                readOnly={!modeEdit || readOnly}
                {...{ defaultValue, value }}
            >
            </input>
            {errorMessage && <ErrorMessage message={errorMessage} />}
        </>
    )
}

export default TextInput;
