import React, { useState, useRef, useMemo } from 'react';
import Form from '../../../../../Form';
import {
    TextInput, ControllerInput, FormLabel,
    ButtonState, SubmitButton, TextArea,
    SelectInput
} from '../../../../../Form/components';
import { Grid, Avatar, Button } from '@material-ui/core';
import { useFormContext, useController } from 'react-hook-form';
import { ButtonAdd } from '../../../../../Buttons';
import * as Yup from 'yup'
import { ServiceClients } from '../../../../../../utilities/api/services';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useFetchService } from '../../../../../../hooks/useFetch';
import _ from 'lodash';
import '../View.scss';
import CommercialDataExtra from './CommercialDataExtra'
import { useDispatch } from 'react-redux';
import { setSeletedDireccion } from '../../../../../../redux/client/clientReducer';
import FormComercialAddress from './Components/FormComercialAddress';

const validationSchema = Yup.object().shape({
    estado_cliente: Yup.boolean()
})


const ComercialData = ( {clientDetail} ) => {

    const params = useParams();
    const dispatch = useDispatch()

    const formRef = useRef();

    const { response: addressesCommercial, refetch } = useFetchService(ServiceClients.getAddressesCommercialByClient, params.id, []);

    const listAddressesCommercial = useMemo(() => {
        return _.map(addressesCommercial, (addressComercial) => ({ value: addressComercial, label: addressComercial.nombre_comercial }))
    },
        [JSON.stringify(addressesCommercial)])

    const handleAddNewAddressComercial = async (value) => {
        formRef.current.reset({});
        setModeEdit(true);
        setIdAddressCommercial(null);

    }

    const [modeEdit, setModeEdit] = useState(false);
    const [idAddressCommercial, setIdAddressCommercial] = useState(null)

    const handleSelectAddressCommercial = async (value) => {
        setIdAddressCommercial(_.get(value, 'id_direcc_cli'));
        formRef.current.reset({ ...value, comercialAddress: _.get(value, 'id_direcc_cli') });
        dispatch(setSeletedDireccion(value));

    }

    const handleCancel = () => {
        setModeEdit(false);
        !idAddressCommercial && setIdAddressCommercial(null);
    }

    const handleSubmit = async (values) => {
        console.log("comercialdata", values);
        const { id } = await ServiceClients.addAddressCommercial({ id: params.id, data: values });
        setIdAddressCommercial(id);
        setModeEdit(false);

    }

    return (
        <>
            <Form
                initialValues={{}}
                onSubmit={handleSubmit}
                className={'containerView'}
                innerRef={formRef}
                schemaForm={validationSchema}
            >


                <div className={'w-100 d-flex flex-row justify-content-between'}>
                    <SelectAddressComercial name={'comercialAddress'} list={listAddressesCommercial} onChange={handleSelectAddressCommercial} />

                    <ButtonAdd
                        label={'Agregar nueva direccion comercial'}
                        classNameText={'text-orange'}
                        classNameIcon={'text-orange'}
                        onClick={handleAddNewAddressComercial} />


                </div>


                {(modeEdit || idAddressCommercial) &&
                    <FormComercialAddress modeEdit={modeEdit} />
                }


                {
                    modeEdit &&
                    <div className="formButtons">
                        <Button variant="contained" onClick={handleCancel} className="prevButton btn">Cancelar</Button>
                        <SubmitButton  >Guardar Cambios</SubmitButton>
                    </div>

                }
                {idAddressCommercial && !modeEdit &&
                    <ButtonAdd
                        label={'Editar'}
                        classNameText={'text-orange'}
                        classNameIcon={'text-orange'}
                        onClick={() => setModeEdit(true)} />
                }

            </Form>

            {idAddressCommercial && <CommercialDataExtra {...{ idAddressCommercial }} />}

        </>
    )
}

export default ComercialData


const SelectAddressComercial = ({ list, name, onChange }) => {
    const { control } = useFormContext();

    const { field } = useController({ name, control })

    const handleSelect = (value) => {
        field.onChange(value);
        onChange(value);
    }

    return (
        <>
            <SelectInput
                classNameContainer={'w-50'}
                placeholder={'Seleccionar Dirección Comercial....'}
                modeEdit={true}
                list={list}
                classNameMenu={'containerSelectAddressCommercial'}
                onChange={handleSelect}
                value={field.value}


            />
        </>
    )
}