import * as Yup from 'yup'; 

export const initialValues = {
 nombre:'',
 cif: '',
 pais: '',
 provincia: '',
 direccion_fiscal: '',
 poblacion: '',
 codpost: '',
 email: '',
 pass: '',
 passwordConfirm: '',
 nombre_contacto: '',
//  pagina_web: '',
 telefono: '',
 movil: '',
 observaciones: '',
}

const emptyMsg = 'No puede estar vacío';

export const validationSchema = Yup.object({
    nombre: Yup.string().required(emptyMsg),
    cif: Yup.string().required(emptyMsg),
    email: Yup.string().email('Email inválido').required(emptyMsg),
    pais: Yup.string().required(emptyMsg),
    provincia: Yup.string().required(emptyMsg),
})