import React, { useState } from "react";
import VIEW from "../../BillingView";
import { Button } from "@material-ui/core";
import "./BillingDetail.scss";
import TAB_VIEW from "../Tab";

const BillingDetail = ({ setPrinterView, actualTab }) => {
  const [newImport, setnewImport] = useState(0);

  const handlePathClick = () => {
    setPrinterView(VIEW.HOME);
  };

  const changeNewImport = () => {
    setnewImport(1);
  };

  return (
    <div>
      {actualTab === TAB_VIEW.DATA ? (
        <div className="billingHolder">
          <div className="columnHolder">
            <div>
              <div className="column">
                <div className="info">
                  <p>Cliente</p> <p>Juan Pablo</p>
                </div>
                <div className="info">
                  <p>Nombre de la factura</p> <p>ECO-1234</p>{" "}
                </div>
                <div className="info">
                  <p>Email</p> <p>juanpablo@ecocolor.com</p>{" "}
                </div>
                <div className="info">
                  <p>Forma de pago</p> <p>Adeudo a cuenta</p>{" "}
                </div>
              </div>
            </div>
            <div>
              <div className="column">
                <div className="info">
                  <p>Direccion comercial</p> <p>Las toninas</p>
                </div>
                <div className="info">
                  <p>Marca</p> <p>Epson</p>{" "}
                </div>
                <div className="info">
                  <p>Estado de la factura</p> <p>Facturado</p>{" "}
                </div>
                <div className="dummy">
                  <p></p> <p></p>{" "}
                </div>
              </div>
            </div>
            <div>
              <div className="column">
                <div className="info">
                  <p>Ubicacion</p> <p>Administracion</p>
                </div>
                <div className="info">
                  <p>Modelo</p> <p>Ep-124</p>{" "}
                </div>
                <div className="info">
                  <p>Tarifa</p> <p>Tarifa 1</p>{" "}
                </div>
                <div className="dummy">
                  <p></p> <p></p>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="billingHolder extend">
          <div className="columnPlacer">
            <div className="tableHeader">
              <Button
                className="createButtonBillingImport"
                onClick={changeNewImport}
              >
                + Añadir nuevo importe
              </Button>
              <div className="calendarButton">15/01/2021</div>
            </div>
            <div className="topTable">
              <div className="form">
                <p>Descripcion</p>
              </div>
              <div className="form">
                <p>Cantidad de impresiones</p>
              </div>
              <div className="form">
                <p>Precio por impresiones</p>
              </div>
              <div className="form">
                <p>Importe</p>
              </div>
              <div className="form">
                <p>Facturar</p>
              </div>
            </div>
            <div className="topTableWhite">
              <div className="form">
                <p>Impresion en blanco y negro</p>
              </div>
              <div className="form">
                <p>1000</p>
              </div>
              <div className="form">
                <p>0.01000</p>
              </div>
              <div className="form">
                <p>100$</p>
              </div>
              <div className="form">
                <input type="checkbox" />
              </div>
            </div>
            <div className="topTableWhite">
              <div className="form">
                <p>Impresiones a color</p>
              </div>
              <div className="form">
                <p>1000</p>
              </div>
              <div className="form">
                <p>0.01000</p>
              </div>
              <div className="form">
                <p>100$</p>
              </div>
              <div className="form">
                <input type="checkbox" />
              </div>
            </div>
            <div className="topTableWhite">
              <div className="form">
                <p>Impresiones incluidas</p>
              </div>
              <div className="form">
                <p>1000</p>
              </div>
              <div className="form">
                <p>0.01000</p>
              </div>
              <div className="form">
                <p>100$</p>
              </div>
              <div className="form">
                <input type="checkbox" />
              </div>
            </div>
            <div className="topTableWhite">
              <div className="form">
                <p>Mantenimiento de equipo</p>
              </div>
              <div className="form">
                <p>1000</p>
              </div>
              <div className="form">
                <p>0.01000</p>
              </div>
              <div className="form">
                <p>100$</p>
              </div>
              <div className="form">
                <input type="checkbox" />
              </div>
            </div>
            <div className="topTableWhite">
              <div className="form">
                <p>Sub-total</p>
              </div>
              <div className="form">
                <p>1000</p>
              </div>
              <div className="form">
                <p>0.01000</p>
              </div>
              <div className="form">
                <p>100$</p>
              </div>
              <div className="form">
                <input type="checkbox" />
              </div>
            </div>
            <div className="topTableWhite">
              <div className="form">
                <p>Consumo minimo</p>
              </div>
              <div className="form">
                <p>1000</p>
              </div>
              <div className="form">
                <p>0.01000</p>
              </div>
              <div className="form">
                <p>100$</p>
              </div>
              <div className="form">
                <input type="checkbox" />
              </div>
            </div>
            {newImport == 0 ? (
              <div className="endTableWhite">
                <div className="form">
                  <p>Total</p>
                </div>
                <div className="form">
                  <p></p>
                </div>
                <div className="form">
                  <p></p>
                </div>
                <div className="form">
                  <p>240$</p>
                </div>
                <div className="form"></div>
              </div>
            ) : (
              <div>
                <div className="endTableWhite">
                  <div className="form">
                    <div className="searcherContainerFlex">
                      <input type="text" className="searcher" />
                    </div>
                  </div>
                  <div className="form">
                    <div className="searcherContainerFlex">
                      <input type="text" className="searcher" />
                    </div>
                  </div>
                  <div className="form">
                    <div className="searcherContainerFlex">
                      <input type="text" className="searcher" />
                    </div>
                  </div>
                  <div className="form">
                    <div className="searcherContainerFlex">
                      <input type="text" className="searcher" />
                    </div>
                  </div>
                  <div className="form">
                    <input type="checkbox" />
                  </div>
                </div>
                <div className="endTableWhite">
                  <div className="form">
                    <p>Total</p>
                  </div>
                  <div className="form">
                    <p></p>
                  </div>
                  <div className="form">
                    <p></p>
                  </div>
                  <div className="form">
                    <p>240$</p>
                  </div>
                  <div className="form"></div>
                </div>
              </div>
            )}

            <div className="tableHeader">
              <Button className="createButton">Cancelar</Button>
              <Button className="createButtonSave">Guardar</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingDetail;
