import React, { useState, useEffect, useRef } from 'react';
import Form from '../../../../../Form';
import { TextInput, ControllerInput, FormLabel, ButtonState, SubmitButton, TextArea } from '../../../../../Form/components';
import { ButtonEdit, ButtonRemove } from '../../../../../Buttons'
import { Button, Grid } from '@material-ui/core';
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import '../View.scss';
import { ServiceDistributors, ServiceClients } from '../../../../../../utilities/api/services';
import { SuccessDialog, AlertDialog } from '../../../../../Modal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import usePermissionAccess from '../../../../../../hooks/usePermissionAccess';
import { updateClientDate } from '../../../../../../redux/client/clientReducer';

const validateSchema = Yup.object().shape({
    nombre_contacto: Yup.string().required('El Nombre es requerido'),
    movil: Yup.string().required('El Movil es requerido'),
    telefono: Yup.string().required('El Telefono es requerida'),
    mail_contacto: Yup.string().required('El Email  es requerido'),

})


const ContactDa = ({ data, goToTable, clientDetail }) => {


    const dispatch = useDispatch()
    const [modeEdit, setModeEdit] = useState(false);
    const clientData = useSelector(state => state.client.clientData);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);

    const params = useParams()

    useEffect(()=>{
        //dispatch( getDataClientDetail(params.id) );
        console.log("client DATA: ");
        console.log(clientDetail);
    }, []);

    const handleSubmit = async (values) => {

        //revisar los servicios
        console.log("contactdata", values); 
        await ServiceClients.updateDataClientContact({ id: params.id, client: values });
        dispatch(updateClientDate(values));
        setOpenModalSuccess(true);
        setModeEdit(false)
    }

    const handleDeleteDistributor = async () => {
        await ServiceDistributors.deleteDistributor(data.id_cliente);
        goToTable()
    }

    const refForm = useRef();

    const { permission } = usePermissionAccess("CLIENT", "EDIT")

    return (
        <>  <Form
            innerRef={refForm}
            initialValues={clientDetail}
            schemaForm={validateSchema}
            onSubmit={handleSubmit}
            className={'containerView'}  >

            {permission && < >
                {
                    !modeEdit && <ButtonEdit className={'align-self-end'} label={"Editar"} onClick={() => setModeEdit(true)} />
                }
            </>}
            <Grid spacing={1} style={{ display: "flex", flexDirection: "flex-row" }} >
                <Grid item xs={12}  >
                    <Grid item xs={12} className={'mt-1'}  >
                        <FormLabel className="text-primary">
                            Persona de Contacto
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"nombre_contacto"}
                            modeEdit={modeEdit}
                            className={''}
                        />
                    </Grid >




                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Movil
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"movil"}
                            modeEdit={modeEdit}
                        />


                    </Grid >
                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Telefono
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"telefono"}
                            type={"text"}
                            modeEdit={modeEdit}
                        />

                    </Grid >

                </Grid>

                <Grid item xs={12}  >
                    <Grid item xs={12} className={'mt-1'} >
                        <FormLabel className="text-primary">
                            Pagina Web
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"pagina_web"}
                            modeEdit={modeEdit}
                        />
                    </Grid >

                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Email
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"mail_contacto"}
                            modeEdit={modeEdit}
                        />

                    </Grid >


                </Grid >
                <Grid item xs={12}  >

                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Notas
                        </FormLabel>
                        <ControllerInput
                            render={TextArea}
                            name={"notas_contacto"}
                            modeEdit={modeEdit}
                            className={'w-75'}
                        />

                    </Grid >
                </Grid>

            </Grid>


            {
                modeEdit &&
                <div className="formButtons">
                    <Button variant="contained" onClick={() => setModeEdit(false)} className="prevButton btn">Cancelar</Button>
                    <SubmitButton >Guardar Cambios</SubmitButton>
                </div>

            }

        </Form >

            <SuccessDialog isOpen={openModalSuccess} handleClose={() => setOpenModalSuccess(false)} text={"Cambios Guardados con Exito"} />

        </>
    )
}

export default ContactDa
