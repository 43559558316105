import React, { useState, useEffect } from 'react';
import _, { get } from 'lodash';
import { useFormContext, Controller } from "react-hook-form";
import { Autocomplete } from '@mui/material';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
//import { getApi } from '../../common/apiUtils';


function Select({
    error,
    classes,
    name,
    label,
    disabled,
    multi,
    flat,
    optionLabel = 'label',
    optionField = 'value',
    api,
    filterField,
    filterValue,
    options,
    includeNoneOption,
    noneOptionLabel,
    size,
    defaultValue,
    callbackOnClear,
    refSelect
}) {
    const { control } = useFormContext();
    const [stateOptions, setStateOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (api) {
            fetchOptions(api, flat, optionLabel, filterField, filterValue);
        } else if (options) {
            saveOptions(options);
        }
    }, [api]);

    const fetchOptions = async (api, flat, optionLabel, filterField, filterValue) => {
        let uri;
        if (filterField && filterValue) {
            uri = `/api/${api}?filterField=${filterField}&filter=${filterValue}`;
        } else {
            uri = `/api/${api}`;
        }

        if (!!flat && optionLabel) uri = `${uri}&flat=true&=${optionLabel}`;

        setLoading(true);
        const result = {}// await getApi(uri);
        setLoading(false);
        if (result && !_.isEmpty(result.data)) {
            saveOptions(result.data);
        }
    }

    const saveOptions = (options) => {
        if (includeNoneOption) {
            options.unshift({
                [optionLabel]: noneOptionLabel || "None",
                [optionField]: null
            });
        }
        setStateOptions(options);
    }

    const handleOptionLabel = (option) => {
        if (!option) return ''

        if (flat) {
            return option;
        } else {
            if (!_.isString(option)) {
                // return option[optionLabel];
                return get(option, optionLabel, '');
            } else {
                const found = _.find(stateOptions, opt => {
                    return opt[optionField] === option;
                });
                if (found) {
                    return found[optionLabel];
                } else {
                    return option;
                }
            }
        }
    }
    const { watch } = useFormContext()

    const getInputLabel = (inputValue)=>{
        debugger
        return ;
    }

    return (
        <Controller
            name={name}

            control={control}
            id={`select-${name}`}
            render={({ field: { onChange, value, ...rest } }) => (
                <Autocomplete
                    id="asynchronous-demo"
                    inputValue={getInputLabel(watch(name))}
                    //className={classes.autoComplete}
                    style={size ? { width: size } : {}}
                    options={stateOptions}
                    getOptionLabel={(option) => handleOptionLabel(option)}
                    getOptionSelected={(option, value) => {
                        if (optionField) {
                            return option[optionField] === value[optionField];
                        } else {
                            return option === value;
                        }
                    }}
                    onChange={(x, data, action) => {
                        if (callbackOnClear && action === 'clear') {
                            callbackOnClear(data)
                        }
                        if (!data) return onChange(null)
                        if (flat || data["_id"]) {
                            return onChange(data);
                        } else if (!flat && !data["_id"]) {
                            if (Array.isArray(data)) {
                                return onChange(data.map(d => d[optionField] || d));
                            } else {
                                return onChange(data[optionField]);
                            }
                        }
                    }}
                    loading={_.isEmpty(stateOptions)}
                    defaultValue={value || defaultValue}
                    multiple={multi}
                    disabled={disabled}
                    renderInput={(params) => {
                        if (refSelect && !refSelect.current) {
                            refSelect.current = params.inputProps
                        }

                        return (
                            <TextField
                                {...params}
                                label={label}
                                variant="outlined"
                                error={!!error}
                                helperText={error && `Please enter a valid ${label}`}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        )
                    }}
                />
            )}
        />
    );

}

export default Select;