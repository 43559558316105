import React from 'react';
import roles_access from '../Constants/roles_access'
import { loadState } from '../utilities/storage';
import _ from 'lodash';
import { useSelector } from 'react-redux';



export type ActionsPermission = "VIEW" | "CREATE" | "EDIT" | "";

export type ModulePermission = "CLIENT" | "TECH" | "TARIFF" | "";

const tranformAction = {
    "VIEW": "read",
    "EDIT": "modify",
    "CREATE": "create",
};

const tranformModule = {
    "CLIENT": "client",
    "TECH": "repairman",
    "TARIFF": "tariff",
}



export default function usePermissionAccess(module: ModulePermission = "", action: ActionsPermission = "") {

    const permissions = useSelector((state: any) => state.user.permissions)


    const getPermission = (module: ModulePermission, action: ActionsPermission): boolean => {
        const defaultValue = loadState("user").tipo_cliente === "ADMIN";
        return _.get(permissions, `${tranformModule[module]}.${tranformAction[action]}`, defaultValue);
    }

    const permission = React.useMemo(() => getPermission(module, action), [action, module])


    return { permission, getPermission }
}


