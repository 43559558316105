import ServerConnect, { methods } from '../ServerConnect';
import RedsysConnect from '../RedsysConnect';
import URI from './uris';


export default class ServicesCobros {

    static getIdFromGoCardLess = async ({description, amount, currency}) => await ServerConnect.fetchAxios(`${URI.COBROS}/getBillingRequestFlow`, methods.POST, {body: {description, amount, currency}});

    static getPendingInvoice = async (id_cliente) => { 
        const res = await ServerConnect.fetchAxios(`${URI.COBROS}/getPaidStatus/${id_cliente}`, methods.GET, {});
        return res;
    };

    static getAllInvoices = async () =>  await ServerConnect.fetchAxios(`${URI.COBROS}/getAllPaid`, methods.GET, {});

    static sendPaymentByRedsys = async (  {payment} ) => await RedsysConnect.fetchAxios(URI.REDSYS_SANBOX, methods.POST, { body: {payment} });

    static sendPayment = async ( invoice ) => await ServerConnect.fetchAxios( `${URI.COBROS}/sendPaymentGoCardLess`, methods.POST, { body: {invoice} })

    static allChargeInvoices = async () => await ServerConnect.fetchAxios( `${URI.COBROS}/allChargeInvoicesGoCardLess`, methods.POST, {});
 
    static processPaymentByRedsys = async ( data ) => { 
        console.log( 'DENTRO DE CONSUMIDOR API A BACK:', data);
        return await ServerConnect.fetchAxios(`${URI.COBROS}/processPaymentByRedsys`, methods.POST, {body: data});
    };

    static getStateSubscription = async ( id_cliente ) => { 
        console.log( 'consultar subscipcion' );
        return await ServerConnect.fetchAxios(`${URI.COBROS}/stateSubscriptionPayment/${id_cliente}`, methods.GET, {});
    };

    static initSubscriptionPayment = async ( token, user ) => await ServerConnect.fetchAxios(`${URI.COBROS}/initSubscriptionPayment/${token}`, methods.POST, { body: {user} });

    static confirmSubscriptionPayment = async ( operation_id, token ) => await ServerConnect.fetchAxios(`${URI.COBROS}/confirmSubscriptionPayment`, methods.POST, {body: { operation_id, token } });

    static savePaymentSuccessByRedsys = async ( invoice ) => await ServerConnect.fetchAxios(`${URI.COBROS}/SavePaymentSuccesByRedsys/${invoice}`, methods.GET, {})

    // static getRateIgic = async () => await ServerConnect.fetchAxios(`${URI.RATES}/igic`, methods.GET, {});

    // static updateRate = async ({ id, rate }) => await ServerConnect.fetchAxios(`${URI.RATES}/UpdateTariff/${id}`, methods.PUT, { body: rate });

    // static createdRate = async ( rate ) => await ServerConnect.fetchAxios(`${URI.RATES}/createTariff`, methods.POST, { body: rate });

    // static deleteRate = async (id) => await ServerConnect.fetchAxios(`${URI.RATES}/deleteTarifa/${id}`, methods.DELETE, {});



}

