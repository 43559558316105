import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { ThemeProvider } from "@material-ui/styles";
import claraTheme from "../../../../assets/themes/dateInput";
import { createMuiTheme } from "@material-ui/core";


const materialTheme = createMuiTheme(claraTheme);


const DateInput = ({ type = "date", label = '', value = null, onChange, defaultValue = null, modeEdit = true, disabled, ...props }) => {
    const handleOnChange = (value) => {
        const date = moment(value).format('YYYY-MM-DD hh:mm:ss');
        onChange(date);
    }

    const handleOnChageTime = (value) => {
        onChange(value);
    }
    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <ThemeProvider theme={materialTheme}>
                {type === "date" && <KeyboardDatePicker
                    openTo="year"
                    format="DD/MM/YYYY"
                    label={label}
                    views={["year", "month", "date"]}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={handleOnChange}
                    disabled={disabled || !modeEdit}
                    {...props}
                />}

                {type === "time" &&
                    <KeyboardTimePicker
                        ampm={false}
                        open={false}
                        label={label}
                        mask="__:__"
                        value={value}
                        onChange={handleOnChageTime}
                    />}
            </ThemeProvider>
        </MuiPickersUtilsProvider>

    )
}

export default DateInput
