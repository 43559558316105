import React, { useState, useMemo } from 'react';

import { Button, Grid } from '@material-ui/core';
//import './PrinterCreate.scss'
import editIcon from '../../../../assets/dashboard/distributor/edit.svg';
import deleteIcon from '../../../../assets/dashboard/distributor/delete.svg';
import Form from '../../../Form';
import * as Yup from 'yup';
import { FormLabel, ControllerInput, TextInput, SelectInput, DateInput, SubmitButton, FileInput } from '../../../Form/components';
import _ from 'lodash'
import { useFetchService } from '../../../../hooks/useFetch';
import { ServiceClients, ServicePrinters } from '../../../../utilities/api/services';
import { useHistory } from 'react-router-dom';
import { ButtonEdit, ButtonRemove } from '../../../Buttons';
import Tabs from '../../../Tabs/Tabs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import ServicesCobros from '../../../../utilities/api/services/cobros';

const validateSchema = Yup.object().shape({
})


const PrinterCreate = ({ setPrinterView }) => {


    const { response: listGamaServer, loading: loadingGama } = useFetchService(ServiceClients.getGamaMachine, null, []);

    const listGamaMachine = useMemo(() => {
        return _.map(listGamaServer, gm => ({ value: gm.id, label: gm.nombre }));
    }, [loadingGama])


    const { response: listStatesServer, loading: loadingState } = useFetchService(ServiceClients.getStates, null, []);

    const listStateMachine = useMemo(() => {
        return _.map(listStatesServer, state => ({ value: state.id_estado_imp, label: state.estado }));
    }, [loadingState])


    //const [user] = useLocalStorage('user');





    const history = useHistory()

    const handleOnSubmit = async ({ mount }) => {
       try {
        console.log(mount)
        const res = await ServicesCobros.getIdFromGoCardLess({
            description: 'Pago a distribuidor',
            currency: 'EUR',
            mount
        })
        //history.push('/dashboard/payments/distributor/table');
       } catch (e) {
           console.log(e)
       }
    }

    const goTo = (page) => () => {
        history.push(page)
    }


    const breadcrumbs = [
        <Link underline="hover" onClick={goTo("/dashboard/payments&collections/choose-flow")} className={'text-primary '}>Gestion de cobros y pagos</Link>,
        <Link underline="hover" onClick={goTo("/dashboard/payments/distributor/table")} className={'text-primary'}>solicitud y pagos</Link>,
        <span className={'text-primary'}>Pago a distribuidor</span>
    ]

    return (

        <>

            <div className={'d-flex flex-column flex-align-center flex-justify-center'} >
                <div className="title text-center">
                    <h2 className={'text-primary'}>Pago a Distribuidor: </h2>
                </div>


                <div className=" rounded-lg mx-8 mt-5">
                    <Breadcrumbs separator={'>'}>
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Tabs className={'mt-3'} actualTab={0} texts={[<p>Pago</p>]} />
                    <Form initialValues={{ marca: "10.00" }} schemaForm={validateSchema} className={'bg-white rounded-lg px-3 w-100 h-100'} onSubmit={handleOnSubmit}>

                        <Grid container className={'w-100 h-100 px-2 py-4'}>
                            <Grid xs={12} className={'d-flex flex-row my-4'}>
                                <Grid item xs={4}>
                                    <FormLabel>
                                        Monto Solicitado:
                                    </FormLabel>
                                    <ControllerInput
                                        render={TextInput}
                                        name={'marca'}
                                        modeEdit={false}
                                    />

                                </Grid>

                                <Grid item xs={4} >
                                    <FormLabel>
                                        Monto a Pagar:
                                    </FormLabel>
                                    <ControllerInput
                                        render={TextInput}
                                        name={'monto'}
                                        modeEdit={true}
                                    />

                                </Grid>

                                <Grid item xs={4} >
                                    <FormLabel>
                                        Adjuntar comprobante de pago:
                                    </FormLabel>
                                    <ControllerInput
                                        render={FileInput}
                                        name={'sn'}
                                        modeEdit={true}
                                    />

                                </Grid>


                            </Grid>

                        </Grid>

                        <div className="formButtons">
                            <Button variant="contained" onClick={() => history.goBack()} className="prevButton btn">Cancelar</Button>
                            <SubmitButton labelButton={'Pagar'}  ></SubmitButton>
                        </div>



                    </Form>

                </div>
            </div>
        </>
    )
}

export default PrinterCreate;