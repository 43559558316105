import React, { useState } from "react";
import "./ForgotTemplate.scss";
import BasicInput from "../../components/BasicInput/BasicInput";
import CustomButton from "../../components/CustomButton/CustomButton";
import "../../components/CustomButton/CustomButton.scss";
import Button from "@material-ui/core/Button";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { isValid } from "../../utilities/isValid";
import unlock from "../../assets/login/unlock.png";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailToReset, closeDialog } from "../../redux/user/userReducer";
import { SuccessDialog } from "../../components/SuccessDialog/SuccessDialog";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory } from "react-router-dom";

const ForgotTemplate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userEmail = useSelector(state => state.user.userEmail);
  const isOpen = useSelector(state => state.user.openSuccessDialog);

  const [userInput, setUserInput] = useState({
    emailForgot: userEmail ? userEmail : "",
  });

  const [inputError, setInputError] = useState({
    emailError: false,
    emailErrorMessage: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserInput({ ...userInput, [name]: value });
  };

  const handleSubmit = () => {
    if (isValid(userInput, setInputError, inputError)) {
      dispatch(sendEmailToReset(userInput));
    }
  };

  const handleKeyPress = (target) => {
    if (target.charCode === 13 && isValid(userInput, setInputError, inputError)) {
      dispatch(sendEmailToReset(userInput));
    }
  }

  const handleClose = () => {
    dispatch(closeDialog());
    history.push('/login');
  }

  return (
    <div className="forgotTempContainer">
      <div className="forgotTempInner">
        {
          localStorage.getItem('loginAttempt') >= 5 ?
            <Alert severity="error" className="alert">
              <AlertTitle>Has intentado ingresar muchas veces</AlertTitle>
                Por favor, recupere la contraseña para volver a ingresar
            </Alert> : null
        }
        <SuccessDialog text={"El mail ha sido enviado con éxito"} isOpen={isOpen} handleClose={handleClose} />
        <div className="content">
          <div className="iconContainer">
            <img className="unlockIcon" src={unlock} alt="unlockLogo" />
          </div>
          <div className="text">
            <div className="primaryText">
              <p className="firstText">¿Te has olvidado de</p>
              <p className="secondText">tu contraseña?</p>
            </div>
            <div className="subText">
              <p className="m0">
                No te preocupes, ¡la puedes recuperar!
                <br />
                Escribe tu mail, y haz click en el botón
              </p>
            </div>
          </div>
          <div className="basicInput">
            <BasicInput
              label="Email"
              icon={faUser}
              type="email"
              name="emailForgot"
              onChange={handleChange}
              value={userInput.emailForgot}
              errorFlag={inputError.emailError}
              radius="15px"
              handleKeyPress={handleKeyPress}
            />
            <div className="errorMsg">{inputError.emailErrorMessage}</div>
          </div>
          
          
          
          <div className="button">
           
            <CustomButton
              text={"ENVIAR"}
              radius="15px"
              onClick={handleSubmit}
            />

          </div>
          <div className="button"> 
              <Button
                variant="text"
                color="success"
                style={{  borderRadius:"15px", 
                          width: "100%",
                          height: "5.5vh",
                          fontfamily: "Lato, sans-serif",
                          fontWeight: "bold",
                          letterSpacing: "2px",
                          color: "#f4841d"
                      }}
                onClick={handleClose}
              >
                {"ATRÁS"}
              </Button>  
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotTemplate;
