import React, { useEffect, useState, useMemo } from 'react';
import '../View.scss';
import Table from '../../../../../Table/Table';
import { HeaderColumn } from '../../../../../Table/components';
import { CreateButton } from '../../../../../Buttons';
import { Grid } from '@material-ui/core';
import SearchInput from '../../../../../SearchInput';
import Select from '../../../../../Select';
import { parseDate } from '../../../../../../utilities/parse';
import { TextStatus } from '../../../../../Text'
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ServiceDistributors } from '../../../../../../utilities/api/services';
import { useFetchService } from '../../../../../../hooks/useFetch';
import _ from 'lodash'


const ClientAndTech = ({ setDistributorView }) => {

    const [searchValue, setSearchValue] = useState('');
    const [valuesOrder, setValuesOrder] = useState({})

    const params = useParams();

    const { response: clients, loading } = useFetchService(ServiceDistributors.getClientByDistributor, { search: "", id: params.id }, [])

    const handleOnClikColumn = (field, value) => {
        setValuesOrder(({ [field]: value }))
    }


    const [typeClient, setTypeClient] = useState(1);

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'distrbutorTable',
                style: { display: 'none' },
                id: 'table',
                columns: [
                    {
                        Header: () => {
                            return (<HeaderColumn headerName={'Nombre fiscal'} />)
                        },
                        accessor: "nombre",

                    },
                    {
                        Header: () => <HeaderColumn headerName={'Fecha De Creacion'} onClick={(value) => handleOnClikColumn('date_create', value)} />,
                        accessor: 'date_create',
                        Cell: ({ row }) => {
                            return <>{parseDate(row.values.date_create)}</>
                        }
                    },
                    {
                        Header: "CIF",
                        accessor: 'CIF',
                    },
                    {
                        Header: () => <HeaderColumn headerName={'impresoras'} onClick={(value) => handleOnClikColumn('impresoras', value)} />,
                        accessor: 'impresoras',
                        Cell: ({ row }) => {
                            return <>{row.values.impresoras}</>
                        }
                    },
                    {
                        Header: () => <HeaderColumn headerName={'Estado'} onClick={(value) => handleOnClikColumn('estado_cliente', value)} />,
                        accessor: 'estado_cliente',
                        Cell: ({ row }) => {
                            const { estado_cliente } = row.values;
                            return (
                                <TextStatus state={estado_cliente} />
                            )
                        }
                    }
                ],
            },
        ],
        [JSON.stringify(clients)]
    )

    const history = useHistory()

    const onClickCreate = () => {
        if (typeClient === 1) {
            history.push(`/dashboard/client-create`);
        } else {
            history.push(`/dashboard/tech-create`);
        }

    }

    const handleRowClick = ({ row: { original } }) => {
        if (original.tipo_cliente === 1) {
            history.push(`/dashboard/client/${original.id_cliente}`)
        } else {
            history.push(`/dashboard/tech/${original.id_cliente}`)
        }
    }

    const handleOnChangeSelect = (value) => {
        setTypeClient(value);
        setSearchValue('')
    }


    const dataFilter = useMemo(() => {
        return _.filter(clients, client => client.tipo_cliente === parseInt(typeClient));
    }, [typeClient, loading])


    return (
        <div className={'containerView px-0 pt-3 '}>
            <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                className={'containerHeaderTable '}
            >
                <CreateButton onClick={onClickCreate} />
                <SearchInput searchValue={searchValue} onChageSearch={setSearchValue} placeholder={'search...'} />
                <Select onChange={handleOnChangeSelect} options={[{ label: "Cliente", value: 1 }, { label: "Tecnicos", value: 2 }]} />

            </Grid>
            {!loading && <Table
                columns={columns}
                data={dataFilter}
                className={'containerTableDistributor'}
                initialState={{ rowState: { onClick: handleRowClick } }} orderByValues={valuesOrder} />}

        </div>

    );
};

export default ClientAndTech;
