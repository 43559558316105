export default {
    palette: {
        primary: {
            main: '#3a6b4e',
        }
    },
    overrides: {
        MuiPaper: { 
            root: {
                minHeight: "auto", 
            },
            elevation1: {
                boxShadow: "unset",
                background: "transparent",
            },
            elevation8:{
                boxShadow: "unset",
            }
        },
        MuiTabs: {
            root: {
                height: "100%",
                alignItems: "center",
                minHeight:"auto",
            },
            fixed: {
                height: "100%",
            },
            flexContainer: {
                height: "100%",
            },
            indicator:{
                height:"1px",
                transition: "none",
            }
        },
        MuiTab: {
            root:{
                minWidth:"auto!important",
                minHeight:"auto",
                padding: "0 0 1rem 0    ",
                margin: "0 2.5rem 0 0",
                lineHeight: "normal",
                '&.Mui-disabled': {
                    // color: theme.palette.primary.main,
                    color: "#E0E5E9!important",
                    opacity: "1",
                },
            },
            wrapper: {
                textTransform: "none",
                fontSize: "1.0769rem",
                "fontFamily": "Muli",
                "fontWeight": "normal",
            }
        },
        
        MuiPickersDay: {
            daySelected: {
                backgroundColor: "#3a6b4e",
            }
        },
        MuiPickersYear: {
            yearSelected: {
                fontWeight: 700
            }
        },
        MuiPickersMonth: {
            monthSelected: {
                fontWeight: 700
            }
        },
        MuiTypography: {
            h1:{
                "fontSize": "24px",
                "color": "#3a6b4e",
                "fontWeight": "normal",
                "fontFamily": "Muli",
                "lineHeight": "19px",
            },
            h4: {
                fontFamily: "Muli"
            },
            h5: {
                fontFamily: "Muli"
            },
            subtitle1: {
                fontFamily: "Muli"
            },
            caption: {
                fontFamily: "Muli"
            },
            body1: {
                fontFamily: "Muli"
            },
            colorPrimary: {
                color: "#3a6b4e",
            }
        },
        MuiButton: {
            root: {
                fontFamily: "Muli",
                '&:hover': {
                    backgroundColor: 'unset',
                },

            },
            textPrimary: {
                color: "#3a6b4e!important",
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#3a6b4e!important",
            }
        },
        MuiOutlinedInput: {
            root: {
                borderWidth: "1px!important",
                borderColor: "yellow!important",
                '&$focused $notchedOutline': {
                    borderColor: "#3a6b4e!important",
                },
                '&$error  $notchedOutline': {
                    borderColor: "red!important",
                },
            },
            notchedOutline: {
                borderWidth: "1px!important",
                borderColor: "#c2cfe0!important",

            },

            input: {
                padding: "11px 14px",
                height: "18px",
                fontSize: "13px",

            },
        },
        MuiTextField: {
            root: {
                background: "#FFF",
            },
        },
        MuiIconButton: {
            root: {
                padding: "5px",
                color: "#3a6b4e"
            }

        }


    },
}