import moment from 'moment';



export const parseDate = (date, defaultValue = '-') => {
    if (!date) {
        return defaultValue;
    }
    return moment(date).format('DD/MM/YYYY')
}

export const parseDateComplete = (date, defaultValue = '-') => {
    if (!date) {
        return defaultValue;
    }
    return moment(date).format('DD/MM/YYYY, hh:mm a ')
}



export const parseClientType = (value) => {
    const labelsRoles = ["Administrador", "Cliente", "Tecnico", "Distribuidor"]
    return labelsRoles[value];
}
