import * as Yup from 'yup'; 

export const initialValues = {
    nombre: '',
    nombre_comercial: '',
    cif: '',
    email: '',
    pass: '',
    passwordConfirm: '',
    direccion_fiscal: '',
    codpost: '',
    pais: '',
    provincia: '',
    poblacion: '',
    telefono: '',
    movil: '',
   
    nombre_contacto: '',
    pagina_web: '',
    comi_ini: '',
    comi_resto: '',
    datos_bancarios: [{cuenta: '', banco: ''}],
    // banco: '',
    // cuenta: '',
    observaciones: ''
}

const emptyMsg = 'No puede estar vacío';

export const validationSchema = Yup.object({
    nombre: Yup.string().required(emptyMsg),
    nombre_comercial: Yup.string().required(emptyMsg),
    cif: Yup.string().required(emptyMsg),
    email: Yup.string().email('Email inválido').required(emptyMsg),
    pais: Yup.string().required(emptyMsg),
    provincia: Yup.string().required(emptyMsg),
    poblacion: Yup.string().required(emptyMsg),
    direccion_fiscal: Yup.string().required(emptyMsg),
    movil: Yup.string().required(emptyMsg),
    comi_ini: Yup.string().required(emptyMsg),
    comi_resto: Yup.string().required(emptyMsg),
    cuenta_bancaria: Yup.array().min(1, emptyMsg),
    //cuenta: Yup.string().required(emptyMsg),
    //banco: Yup.string().required(emptyMsg)
})