import React, {useState, useEffect} from 'react';
import './DashPayment.scss';
import FormPayment from './PaymentOverview/FormPayment';


const DashPayment = () => {
   

    return (
        <div className="dashHomeContainer DashDistributorContainer">
                <div className="dashHomeInner distributorInner">
        
                    <FormPayment></FormPayment>
    
                </div>                
        </div>
    )
}

export default DashPayment;