import ServerConnect, { methods } from '../ServerConnect';
import URI, { BASE_URL } from './uris';


export default class ServiceTechs {

    static getTechs = async search => await ServerConnect.fetchAxios(`${URI.TECHS}/dataTecnicos`, methods.POST, { body: { word: search } });

    static getDistributors = async () => await ServerConnect.fetchAxios(`${URI.TECHS}/getAllDistributors`, methods.GET, { body: {} });

    static getTech = async id => await ServerConnect.fetchAxios(`${URI.TECHS}/dataTecnico/${id}`, methods.GET, { body: {} });

    static updateTechData = async ({ id, data }) => {
        console.log(`${URI.TECHS}/updateDataOfTecnico/${id}`);
        console.log("params", {id, data});
        return await ServerConnect.fetchAxios(`${URI.TECHS}/updateDataOfTecnico/${id}`, methods.PUT, { body: data })
    };

    static deleteTech = async id => await ServerConnect.fetchAxios(`${URI.TECHS}/deleteTecnico/${id}`, methods.DELETE, {});

    static createdTech = async ({ tech }) => await ServerConnect.fetchAxios(`${URI.TECHS}/createTecnico`, methods.POST, { body: tech });



}