import { Step, StepLabel, Stepper } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import './TechnicalCreate.scss';
import { finishTechCreate } from '../../../../redux/technical/technicalReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Step1, Step2, Step3 } from './Steps'

const CreateTech = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const steps = ['Datos', 'Usuario y Contraseña', 'Contacto'];

    const { step } = useSelector(state => state.tecnico.techCreate);

    useEffect(() => {
        return () => {
            dispatch(finishTechCreate());
        }
    }, [])

    const stepForm = useMemo(() => {
        switch (step) {
            case 0:
                return <Step1 />;
            case 1:
                return <Step2 />;
            case 2:
                return <Step3 />;
            default:
                return <></>;
        }
    }, [step])



    return (
        <div className="disCreateContainer">
            <div className="disCreateInner">
                <div className="title">
                    CREAR NUEVO TECNICO
            </div>
                <div className="createContainer">
                    <div className="actualPath">
                        <p className="previous" onClick={() => history.push('/dashboard/techs')}>Tecnicos</p>
                        <p>{"<"} Crear Tecnico</p>
                    </div>
                    <div className="createFormContainer" >
                        <div className="createFormInner">
                            <div className="stepperContainer">
                                <Stepper activeStep={step} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                            {stepForm}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateTech
