import React, { useRef, useMemo } from 'react';
import Form from '../../../../Form';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { nextStepDistributorCreate, prevStepDistributorCreate, finishDistributorCreate } from '../../../../../redux/distributor/distributorReducer';
import { SubmitButton, TextInput, ControllerInput, FormLabel, Switch, TextArea } from '../../../../Form/components';
import { Button, Grid } from '@material-ui/core';
import { InputAcounBack } from '../../DistributorDetailOverview/View/BalanceAndBanckAcount';
import _ from 'lodash'
import { ButtonAdd } from '../../../../Buttons';
import { useFormState } from 'react-hook-form';
import ErrorMessage from '../../../../Text/ErrorMessage';
import { ServiceDistributors } from '../../../../../utilities/api/services';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const defultValues = {
    "clientCanRead": false,
    "repairmanCanRead": false,
    "tariffCanRead": false,
    "printerCanRead": false,
    "clientCanModify": false,
    "repairmanCanModify": false,
    "tariffCanModify": false,
    "printerCanModify": false,
    "clientCanCreate": false,
    "repairmanCanCreate": false,
    "tariffCanCreate": false,
    "printerCanCreate": false
}

const validationSchema = Yup.object().shape({
    datos_bancarios: Yup.array().of(Yup.object().shape({
    })),
    clientCanCreate: Yup.boolean(),
    clientCanModify: Yup.boolean(),
    clientCanRead: Yup.boolean(),

    repairmanCanCreate: Yup.boolean(),
    repairmanCanModify: Yup.boolean(),
    repairmanCanRead: Yup.boolean(),
    tariffCanCreate: Yup.boolean(),
    tariffCanModify: Yup.boolean(),
    tariffCanRead: Yup.boolean(),
    printerCanCreate: Yup.boolean(),
    printerCanModify: Yup.boolean(),
    printerCanRead: Yup.boolean(),
    observaciones: Yup.string(),
})


const Step4 = () => {

    
    const { enqueueSnackbar } = useSnackbar();


    const history = useHistory()

    const { data } = useSelector(state => state.distributor.distributorCreate);
    const dispatch = useDispatch()
    const handleNextStep = async (values) => {
        await ServiceDistributors.createDistributor(values);
        dispatch(finishDistributorCreate());
        enqueueSnackbar('Distribuidor Creado', { variant: "success" });

        history.push('/dashboard/distributors');
    }

    const handlePrevStep = () => dispatch(prevStepDistributorCreate());

    const permissionsFieldName = ["client", "repairman", "tariff", "printer"];

    const refForm = useRef();

    const handleAddAcountBanck = () => {
        const acountsBank = refForm.current.watch('datos_bancarios');
        refForm.current.setValue('datos_bancarios', [...acountsBank, { banco: "", cuenta: "", key: _.uniqueId('key') }])
    }


    return (
        <Form
            innerRef={refForm}
            initialValues={{ ...defultValues, ...data, datos_bancarios: [{ banco: "", cuenta: "", key: _.uniqueId('key') }] }} className={''} schemaForm={validationSchema} onSubmit={handleNextStep}>

            <Grid className={'d-flex flex-column align-items-center my-3 w-100 '}>
                <ErrorMessageCustom />

                <div className={'w-100 '}>
                    <Grid item xs={12} className={' w-100 d-flex px-6 flex-column align-items-center'}>
                        <Grid item xs={12} className={' d-flex flex-row w-100 '}>
                            <Grid item xs={2} className={'mr-1 w-100'} >
                                <FormLabel className="text-primary">

                                </FormLabel>
                            </Grid>
                            <Grid item xs={2} className={'mr-1 w-100'} >
                                <FormLabel className="text-primary">
                                    Clientes
                                </FormLabel>
                            </Grid>
                            <Grid item xs={2} className={'mr-1 w-100'} >
                                <FormLabel className="text-primary">
                                    Tecnicos
                                </FormLabel>
                            </Grid>

                            <Grid item xs={2} className={'mr-1 w-100'} >
                                <FormLabel className="text-primary">
                                    Tarifas
                                </FormLabel>
                            </Grid>

                            <Grid item xs={2} className={'mr-1 w-100'} >
                                <FormLabel className="text-primary">
                                    impresoras
                                </FormLabel>
                            </Grid>

                        </Grid >

                        <Grid item xs={12} className={'mt-2 d-flex flex-row w-100'}>
                            <Grid item xs={2} className={'mr-1 pt-1 w-100'} >
                                <FormLabel className="text-primary">
                                    Leer
                                </FormLabel>

                            </Grid>

                            {
                                permissionsFieldName.map((permissionFieldName, index) => {
                                    return (
                                        <Grid key={JSON.stringify(permissionsFieldName) + 'read' + index} item xs={2} className={'mr-1 w-100'} >
                                            <ControllerInput
                                                render={Switch}
                                                name={`${permissionFieldName}CanRead`}
                                                modeEdit={true}

                                            />
                                        </Grid>
                                    )
                                })
                            }

                        </Grid >

                        <Grid item xs={12} className={'mt-2 d-flex flex-row w-100'}>
                            <Grid item xs={2} className={'mr-1 pt-1 w-100'} >
                                <FormLabel className="text-primary">
                                    Modificar
                                </FormLabel>

                            </Grid>

                            {
                                permissionsFieldName.map((permissionFieldName, index) => {
                                    return (
                                        <Grid key={JSON.stringify(permissionsFieldName) + 'modify' + index} item xs={2} className={'mr-1 w-100'} >
                                            <ControllerInput
                                                render={Switch}
                                                name={`${permissionFieldName}CanModify`}
                                                modeEdit={true}
                                            />
                                        </Grid>
                                    )
                                })
                            }

                        </Grid >

                        <Grid item xs={12} className={'mt-2 d-flex flex-row w-100'}>
                            <Grid item xs={2} className={'mr-1 pt-1 w-100'} >
                                <FormLabel className="text-primary">
                                    Crear
                                </FormLabel>

                            </Grid>

                            {
                                permissionsFieldName.map((permissionFieldName, index) => {
                                    return (
                                        <Grid key={JSON.stringify(permissionsFieldName) + 'created' + index} item xs={2} className={'mr-1 w-100'} >
                                            <ControllerInput
                                                render={Switch}
                                                name={`${permissionFieldName}CanCreate`}
                                                modeEdit={true}
                                                onChange={(value, setValue) => {

                                                }}
                                            />
                                        </Grid>
                                    )
                                })
                            }

                        </Grid >

                    </Grid>

                </div>
                <div className={'w-100 py-4 pl-5'}>
                    <InputAcounBack name={'datos_bancarios'} modeEdit={true} className={'d-flex flex-row '} />
                    <ButtonAdd className={'align-self-end'} classNameText={'text-sm'} label={"Agregar Cuenta Bancaria"} onClick={handleAddAcountBanck} />
                </div>
                <Grid item xs={12} className={' w-100 d-flex px-6 flex-column '}>
                    <FormLabel className="text-primary">
                        Notas
                    </FormLabel>
                    <ControllerInput
                        render={TextArea}
                        name={"observaciones"}
                        modeEdit={true}
                        className={'w-75'}
                        type={'text'}
                        rows={"4"}
                    />
                </Grid>

            </Grid>

            <div className="formButtons">
                <Button variant="contained" onClick={handlePrevStep} className="prevButton btn">Volver</Button>
                <SubmitButton labelButton={'Crear '}></SubmitButton>
            </div>


        </Form >
    )
}

export default Step4;

const ErrorMessageCustom = () => {
    const { errors } = useFormState()
    const errorMessage = useMemo(() => {
        const fieldName = _.head(_.map(errors, (a, key) => key));
        return _.get(errors, `${fieldName}.message`, '');
    }, [JSON.stringify(errors)])

    return (<ErrorMessage message={errorMessage} />)
}
