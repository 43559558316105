const userTypes = {
    LOGIN_START: "LOGIN_START",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    SET_ACTUAL_USER: "SET_ACUTAL_USER",
    CLOSE_USER_SESSION: "CLOSE_USER_SESSION",
    USER_CONNECTED: "USER_CONNECTED",
    KEEP_USER_EMAIL: "KEEP_USER_EMAIL",
    SEND_EMAIL_START: "SEND_EMAIL_START",
    SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
    RESET_PASSWORD_START: "RESET_PASSWORD_START",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    CLOSE_DIALOG: "CLOSE_DIALOG",
    ERROR: "ERROR",
    SET_PERMISSIONS: "SET_PERMISSIONS"
}

export default userTypes;