import React from 'react';
import Form from '../../../../Form';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { nextStepClientCreate } from '../../../../../redux/client/clientReducer';
import { SubmitButton, TextInput, ControllerInput, FormLabel, SelectInput } from '../../../../Form/components';
import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Fade } from '@mui/material'
import { ServiceDistributors } from '../../../../../utilities/api/services';
import { useFetchService } from '../../../../../hooks/useFetch';



const validationSchema = Yup.object().shape({
    nombre: Yup.string().required('Nombre Fiscal Es Requerido'),
    pais: Yup.string().required('Pais Requerido'),
    cif: Yup.string().required('CIF Es Requerido'),
})

const Step1 = () => {

    const { data } = useSelector(state => state.client.clientCreate);
    const dispatch = useDispatch()

    const user = useSelector(state => state.user.actualUser);


    const handleNextStep = (values) => {
        dispatch(nextStepClientCreate({ ...values, id_distribuidor: _.get(values, 'id_distribuidor', user.idCliente) }));
    }

    const router = useHistory()
    const handleCancelCreated = () => {
        router.goBack()
    }

    const { response: distributors } = useFetchService(ServiceDistributors.getDistributors, {}, [], user.tipo_cliente === "ADMIN");

    const listDistributors = React.useMemo( () => {
        if (user.tipo_cliente !== "ADMIN") return [];
        return _.map(distributors, dist => ({ label: dist.nombre, value: dist.id_cliente }))
    }, [JSON.stringify(distributors)]);

    return (
        <Form initialValues={data} className={''} schemaForm={validationSchema} onSubmit={handleNextStep}>

            <Grid container className={'d-flex flex-column align-items-center my-3 '}>
                <Grid item xs={12} className={'w-50'}>
                    <FormLabel className="text-primary">
                        Nombre Fiscal *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={'nombre'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >



                <Grid item xs={12} className={'mt-4 w-50'}>
                    <FormLabel className="text-primary">
                        CIF *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={'cif'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >

                <Grid item xs={12} className={'mt-4 w-50'}>
                    <FormLabel className="text-primary">
                        Pais *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={'pais'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >
                <Fade in={user.tipo_cliente === "ADMIN"} unmountOnExit={true} >
                    <Grid item xs={12} className={'mt-4 w-50'}>
                        <FormLabel className="text-primary">
                            Distribuidor *
                        </FormLabel>
                        <ControllerInput
                            render={SelectInput}
                            name={'id_distribuidor'}
                            list={listDistributors}
                            modeEdit={true}
                        //className={'w-100'}
                        />
                    </Grid >
                </Fade>
            </Grid>

            <div className="formButtons">
                <Button variant="contained" onClick={handleCancelCreated} className="prevButton btn">Cancelar</Button>
                <SubmitButton labelButton={'Siguiente'}></SubmitButton>
            </div>


        </Form >
    )
}

export default Step1
