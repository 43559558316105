import React from 'react'
import { useFormState, useFormContext } from 'react-hook-form'
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';


const SubmitButton = ({ labelButton = '', disabled = false, type = 'submit' }) => {

    const { isValid, isSubmitting } = useFormState();
    return (
        <>
            <Button variant="contained" type={type} disabled={disabled || !isValid || isSubmitting} className="nextButton btn" >{labelButton || 'Guardar Cambios'}
                {isSubmitting && <CircularProgress size={"1.75rem"} className={'position-absolute text-primary'} />}
            </Button>
        </>
    )
}

export default SubmitButton
