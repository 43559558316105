import React from 'react';
import './Consumed.scss';

const Consumed = ({logo, amount, title}) => {
    return (
        <div className="consumedContainer">
            <div className="consumedInner">
                <div className="title">
                    {title}
                </div>
                <div className="img">
                    <img src={logo} className="icon" alt="consumedIcon"/>
                </div>
                <div className="amount">
                    <p>{amount}</p>
                </div>
            </div>
        </div>
    )
}

export default Consumed;