import React, { useState } from 'react'
import Form from '../../../../../../Form'
import * as Yup from 'yup';
import { TextInput, ControllerInput, TextArea, FormLabel, SubmitButton } from '../../../../../../Form/components';
import { Grid, Button } from '@material-ui/core';
import { ButtonEdit } from '../../../../../../Buttons';

const schemaForm = Yup.object().shape({
    name: Yup.string()
})

const FormContact = ({ valuesContact = {}, onSubmit, onCancel, newContact = false}) => {

    const handleSubmit = (values) => {
        onSubmit(values);
    }

    const handleOnCancel = ()=>{
        onCancel && onCancel();
        setModeEdit(false)
    }

    const [modeEdit, setModeEdit] = useState(newContact)

    return (
        <Form
            initialValues={valuesContact}
            onSubmit={handleSubmit}
            schemaForm={schemaForm}
            className={'w-100 d-flex flex-column'}
        >

            {!modeEdit && <ButtonEdit
                label={'Editar Contacto'}
                onClick={() => setModeEdit(true)}
                className={'align-self-end mr-4'}
            />}
            <div className={'mb-3 ml-3'}>
                <FormLabel className="text-primary">
                    Nombre Del Contacto
                </FormLabel>
                <ControllerInput
                    render={TextInput}
                    name={"nombre_contacto"}
                    modeEdit={modeEdit}
                    className={''}
                />


            </div>

            <Grid className={'pl-6'} spacing={1} style={{ display: "flex", flexDirection: "flex-row" }} >
                <Grid item xs={12}  >
                    <Grid item xs={12} className={'mt-1'}  >
                        <FormLabel className="text-primary">
                            Cargo
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"cargo_contacto"}
                            modeEdit={modeEdit}
                            className={''}
                        />
                    </Grid >




                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Email
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"mail_contacto"}
                            modeEdit={modeEdit}
                        />


                    </Grid >
                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Pagina Web
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"pagina_web"}
                            type={"text"}
                            modeEdit={modeEdit}
                        />

                    </Grid >

                </Grid>

                <Grid item xs={12}  >
                    <Grid item xs={12} className={'mt-1'} >
                        <FormLabel className="text-primary">
                            Movil
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"movil"}
                            modeEdit={modeEdit}
                        />
                    </Grid >

                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Telefono
                         </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"telefono"}
                            modeEdit={modeEdit}
                        />

                    </Grid >


                </Grid >
                <Grid item xs={12}  >

                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Notas
                        </FormLabel>
                        <ControllerInput
                            render={TextArea}
                            name={"notas_contacto"}
                            modeEdit={modeEdit}
                            className={'w-75'}
                        />

                    </Grid >
                </Grid>

            </Grid>


            {
                modeEdit &&
                <div className="formButtons mr-5 ">
                    <Button variant="contained" onClick={ handleOnCancel } className="prevButton btn">Cancelar</Button>
                    <SubmitButton  >Guardar Cambios</SubmitButton>
                </div>

            }



        </Form>
    )
}

export default FormContact
