import React, { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Ubication from './Ubication';
import Printer from './Printer';
import Contract from './Contract';
import { ServiceClients } from '../../../../../../../../utilities/api/services'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

type FormTypes = "UBICATION" | "PRINTER" | "CONTRACT" | "";


const FormUbication = (props) => {


    const [dataForm, setDataForm] = useState(() => {
        return props.initialValues;
    });


    const [openTypeForm, setOpenTypeForm] = useState<FormTypes>('UBICATION');

    const handleSelectTypeForm = (type: FormTypes) => () => {
        setOpenTypeForm(type)
    }

    const { id } = useParams<any>();

    const { id_direcc_cli } = useSelector(state => _.get(state, 'client.selectedDireccion'));



    const createUbication = async (values) => {

        const data = {
            ...values,
            id_direcc_cli

        }

        const { insertId } = await ServiceClients.createUbication({ id, data });
        setDataForm({ ubication: { id: insertId, ...values } });
        setOpenTypeForm('PRINTER');

    }

    const handleSubmitUbication = async (values) => {

        await createUbication(values)

    }

    const handleAssingPrinterToUbication = async (values) => {

        const { id_impresora } = values;
        await ServiceClients.assignPrinterToUbication({ ubicationId: _.get(dataForm, 'ubication.id', ''), values: { id_impresora } });
        setDataForm(pre => ({ ...pre, printer: values }));
        setOpenTypeForm('CONTRACT');
    }

    const handleCreateContract = async (values) => {
        const { banco: nombre_banco, cuenta, rate: { id_tarifa }, fecha_ini, forma_pago_id, meses_renovacion, notas } = values;

        const data = {
            nombre_banco,
            cuenta,
            notas,
            tarifa_id: id_tarifa,
            fecha_ini,
            forma_pago_id,
            meses_renovacion,
            nombre_contrato: '',
            id_ubicacion: _.get(dataForm, 'ubication.id', ''),
            id_gama: 1
        }

        await ServiceClients.createContract({ id_cliente: id, id_distribuidor: 0, data });
        setDataForm(pre => ({ ...pre, contract: values }));
        setOpenTypeForm('')
    }


    return (
        <div className='w-100'>
            <Accordion expanded={openTypeForm === "UBICATION"} onChange={handleSelectTypeForm('UBICATION')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography >{_.get(dataForm, 'ubication.nombre', 'Ubicacion')}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ width: '100%' }} >
                    <Ubication onSubmit={handleSubmitUbication} initialValues={_.get(dataForm, 'ubication', {})} />
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={openTypeForm === "PRINTER"} onChange={handleSelectTypeForm('PRINTER')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>{_.get(dataForm, 'printer.sn', 'Asignar Impresora')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Printer onSubmit={handleAssingPrinterToUbication} initialValues={_.get(dataForm, 'printer', {})} />
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={openTypeForm === "CONTRACT"} onChange={handleSelectTypeForm('CONTRACT')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography >Contrato</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Contract onSubmit={handleCreateContract} initialValues={_.get(dataForm, 'contract', {})} />
                </AccordionDetails>
            </Accordion>


        </div>
    )
}

export default FormUbication
