import ServerConnect, { methods } from '../ServerConnect';
import URI, { BASE_URL } from './uris';


export default class ServicesPrinters {

    static getPrinters = async (search = "") => await ServerConnect.fetchAxios(`${URI.PRINTERS}/getData`, methods.GET, {});

    static createPrinter = async ({ id = 0, printer }) => await ServerConnect.fetchAxios(`${URI.PRINTERS}/createImpresora`, methods.POST, { body: printer });

    static updatePrinter = async ({ id = 0, id_printer, printer }) => await ServerConnect.fetchAxios(`${URI.PRINTERS}/updateImpresora/${id_printer}`, methods.PUT, { body: printer });

    static getPrinter = async (id_printer) => await ServerConnect.fetchAxios(`${URI.PRINTERS}/getDataOfOneImpresora/${id_printer}`, methods.GET, {});

    static deletePrinter = async (id_printer) => await ServerConnect.fetchAxios(`${URI.PRINTERS}/deleteImpresora/${id_printer}`, methods.DELETE, {});

}

