import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TextField,
} from "@material-ui/core";
import _ from "lodash";
import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import ascIcon from "../../../assets/dashboard/distributor/asc.svg";
import descIcon from "../../../assets/dashboard/distributor/desc.svg";
import searchLogo from "../../../assets/dashboard/distributor/search.svg";
import "./DashTable.scss";
import { useHistory } from "react-router-dom";
import { getFacturas } from "../../../redux/facturacion/reducer";

const DashTable = ({ header, width, borderR, row, bannerOption }) => {
  const dispatch = useDispatch();
  const { facturas, loading } = useSelector(
    ({ factura: { facturas, loading } }) => ({
      facturas,
      loading,
    })
  );
  const [searchOption, setSearchOption] = useState("nombre");
  const [rows, setRows] = useState([]);
  const [isDesc, setIsDesc] = useState(true);
  const [checked, setChecked] = useState(row.select);

  useEffect(() => {
    setRows(row);
  }, [row]);

  useEffect(() => {
    dispatch(getFacturas(""));
  }, []);

  const handleSort = (prop) => {
    if (prop === "cif") {
      return null;
    }
    const sortedRows = _.orderBy(row, [prop], [isDesc ? "desc" : "asc"]);
    setRows(sortedRows);
    setIsDesc(!isDesc);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (value === "") {
      setRows(row);
    } else if (isNaN(searchOption)) {
      filterList(value);
    }
  };

  const filterList = (input) => {
    const filterdRows = row.filter((element) => {
      return element[searchOption].toLowerCase().includes(input.toLowerCase());
    });
    setRows(filterdRows);
  };

  const history = useHistory();

  // para cambiar de pantallas de dashboard a crear
  const handleButton = () => {
    history.push("billing/create");
  };

  const handleSelectChange = (e) => {
    setSearchOption(e.target.value);
  };

  const handleRowClick = (id) => {
    history.push(`billing/create/${id}`);
  };

  return (
    <Fragment>
      <div className="listBox" style={{ width: width ? width : null }}>
        {
          //Segundo de distribuidor
          bannerOption === 1 ? (
            <div>
              <div className="tableHeader innerDist">
                <Button className="createButton">+ Crear nuevo</Button>
                <div className="searcherContainer">
                  <input
                    type="text"
                    className="searcher"
                    onChange={handleChange}
                  />
                  <img
                    type="text"
                    src={searchLogo}
                    className="icon"
                    alt="iconSearch"
                  />
                </div>
                <div className="customSelect">
                  <select onChange={handleSelectChange}>
                    <option value="nombre">Clientes</option>
                    <option value="0">Activos</option>
                    <option value="1">Suspendidos</option>
                    <option value="2">Eliminados</option>
                  </select>
                </div>
                <div className="customSelect">
                  <select onChange={handleSelectChange}>
                    <option value="nombre">Nombre fiscales</option>
                    <option value="0">Nombre Comercial</option>
                  </select>
                </div>
              </div>
            </div>
          ) : null
        }
        {bannerOption === 0 ? (
          <div
            className="tableHeader"
            style={{ borderRadius: borderR ? "0px 18px 0 0" : "18px 18px 0 0" }}
          >
            <Button className="createButton" onClick={handleButton}>
              + Crear nuevo
            </Button>
            <div className="searcherContainer">
              <input type="text" className="searcher" onChange={handleChange} />
              <img
                type="text"
                src={searchLogo}
                className="icon"
                alt="iconSearch"
              />
            </div>
            <div className="customSelect">
              <select onChange={handleSelectChange}>
                <option value="nombre">Nombre fiscal</option>
                <option value="0">Nombre comercial</option>
              </select>
            </div>
          </div>
        ) : null}
        {
          //Tecnicos
          bannerOption === 2 ? (
            <div className="tableHeader">
              <Button className="createButton" onClick={handleButton}>
                + Crear nuevo
              </Button>
              <div className="searcherContainer">
                <input
                  type="text"
                  className="searcher"
                  onChange={handleChange}
                />
                <img
                  type="text"
                  src={searchLogo}
                  className="icon"
                  alt="iconSearch"
                />
              </div>
            </div>
          ) : null
        }
        {
          //Impresoras
          bannerOption === 3 ? (
            <div className="tableHeader">
              <Button className="createButton" onClick={handleButton}>
                + Crear nuevo
              </Button>
              <div className="searcherContainer">
                <input
                  type="text"
                  className="searcher"
                  onChange={handleChange}
                />
                <img
                  type="text"
                  src={searchLogo}
                  className="icon"
                  alt="iconSearch"
                />
              </div>
              <div className="customSelect">
                <select onChange={handleSelectChange}>
                  <option value="nombre">Distribuidor</option>
                  <option value="0">Activos</option>
                  <option value="1">Suspendidos</option>
                  <option value="2">Eliminados</option>
                </select>
              </div>
            </div>
          ) : null
        }
        {
          //Tickets
          bannerOption === 4 ? (
            <div className="tableHeader">
              <Button className="createButton" onClick={handleButton}>
                + Crear nuevo
              </Button>
              <div className="customSelect">
                <select onChange={handleSelectChange}>
                  <option value="nombre">Todos</option>
                  <option value="0">Cliente</option>
                  <option value="1">Distribuidor</option>
                </select>
              </div>
            </div>
          ) : null
        }
        {
          //Facturacion
          bannerOption === 5 ? (
            <div className="tableHeader">
              <Button className="createButton" onClick={handleButton}>
                + Crear nuevo
              </Button>

              <TextField
                id="date"
                label=""
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className="searcherContainer">
                <input
                  type="text"
                  className="searcher"
                  onChange={handleChange}
                />
                <img
                  type="text"
                  src={searchLogo}
                  className="icon"
                  alt="iconSearch"
                />
              </div>
              <Button className="discardButton" onClick={handleButton}>
                Descartar
              </Button>
              <Button className="billingButton" onClick={handleButton}>
                Facturar
              </Button>
            </div>
          ) : null
        }
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
            <TableHead className="tableHead">
              <TableRow>
                {header.map((header, i) => (
                  <TableCell
                    onClick={() => handleSort(header.prop)}
                    key={i}
                    align="center"
                  >
                    {header.name}{" "}
                    {header.prop !== "cif" ? (
                      <img
                        src={isDesc ? descIcon : ascIcon}
                        alt="sortIcon"
                        className="sortIcon"
                      />
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {facturas.length !== 0
                ? facturas.map((row, i) => {
                    return (
                      <TableRow key={i} className="tableRow">
                        <TableCell
                          onClick={() => handleRowClick(row.id_cliente)}
                          align="center"
                        >
                          {row.client}
                        </TableCell>
                        <TableCell
                          onClick={() => handleRowClick(row.id_cliente)}
                          align="center"
                        >
                          {row.ubication}
                        </TableCell>
                        <TableCell
                          onClick={() => handleRowClick(row.id_cliente)}
                          align="center"
                        >
                          {row.importe}
                        </TableCell>
                        <TableCell
                          onClick={() => handleRowClick(row.id_cliente)}
                          align="center"
                        >
                          <div
                            className={
                              row.state === "Activo" ? "stateG" : "stateY"
                            }
                          >
                            <div className="activeOrInactive">
                              {row.state === "Activo" ? "Activo" : "Suspendido"}{" "}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          onClick={() => handleRowClick(row.id_cliente)}
                          align="center"
                        >
                          {row.detail}
                        </TableCell>
                        <TableCell align="center">
                          <div>
                            {
                              <Checkbox
                                color="#8AC762"
                                checked={checked}
                                onChange={() =>
                                  setChecked((prevState) => !prevState)
                                }
                              />
                            }
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {facturas.length === 0 && (
        <h2 style={{ textAlign: "center" }}>Por el momento no hay datos...</h2>
      )}
    </Fragment>
  );
};

export default DashTable;
