import React from 'react';
import './BasicInput.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BasicInput = ({ label, icon, type, name, errorFlag, value, onChange, radius, handleKeyPress, disabled }) => {

  return (
    <div className="basicInputContainer">
      <label className="label">{label}</label>
      <FontAwesomeIcon className="icon" icon={icon} />
      <input
        style={{ borderRadius: radius ? radius : "48px" }}
        type={type}
        name={name}
        className={errorFlag ? "textField hasError" : "textField"}
        value={value}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        disabled={disabled}
      />
    </div>
  )
}

export default BasicInput;