import _ from "lodash";
import React, { useState, useEffect } from 'react';
import { LayoutProvider } from './LayoutContext';

const Layout = ({ layout: Layout, children, ...props }) => {
  const [layoutProps, setLayoutProps] = useState(props);
  const updatePropsLayout = (values) => {
    setLayoutProps(pre => ({ ...pre, ...values }));
  }
  useEffect(() => {
    setLayoutProps(props)
  }, [JSON.stringify(props)])



  return (

    <LayoutProvider value={{ update: updatePropsLayout, ...layoutProps }}>
      <React.Fragment>
        <Layout {...layoutProps} >
          {children}
        </Layout>
      </React.Fragment>
    </LayoutProvider>

  );

}

export default Layout;


Layout.defaultProps = {
  layout: ({children}) => <>{children}</>
}