import React, { useState, useEffect, useRef, useMemo } from 'react';
import Form from '../../../../../Form';
import { TextInput, ControllerInput, FormLabel, Switch, SubmitButton } from '../../../../../Form/components';
import { ButtonEdit } from '../../../../../Buttons'
import { Button, Grid } from '@material-ui/core';
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import '../View.scss';
import { ServiceDistributors } from '../../../../../../utilities/api/services';
import { SuccessDialog } from '../../../../../Modal';
import contractIcon from '../../../../../../assets/dashboard/distributor/contract.svg';
import downloadIcon from '../../../../../../assets/dashboard/distributor/downloadBlue.svg';
import adjuntarArchivoIcon from '../../../../../../assets/dashboard/distributor/adjuntarArchivo.svg';
import viewIcon from '../../../../../../assets/dashboard/distributor/view.svg';
import { TextLeaf } from '../../../../../Text';


import allowedIcon from '../../../../../../assets/dashboard/distributor/allowed.svg'
import notAllowedIcon from '../../../../../../assets/dashboard/distributor/notAllowed.svg';
import classNames from 'classnames';
import { useFormContext, useWatch, useFormState } from 'react-hook-form';
import { updateDataDistributor } from '../../../../../../redux/distributor/distributorReducer';
import ErrorMessage from '../../../../../Text/ErrorMessage';

const validateSchema = Yup.object().shape({

    clientCanCreate: Yup.boolean(),
    clientCanModify: Yup.boolean(),
    clientCanRead: Yup.boolean(),

    repairmanCanCreate: Yup.boolean(),
    repairmanCanModify: Yup.boolean(),
    repairmanCanRead: Yup.boolean(),
    tariffCanCreate: Yup.boolean(),
    tariffCanModify: Yup.boolean(),
    tariffCanRead: Yup.boolean(),
    comi_ini: Yup.number().typeError("Campo Requerido").required("Campo Requerido"),
    comi_resto: Yup.number().typeError("Campo Requerido").required("Campo Requerido")

})


const ContractPermissionsCommissionData = ({ data }) => {


    const dispatch = useDispatch()
    const [modeEdit, setModeEdit] = useState(false);
    const distributorData = useSelector(state => state.distributor.distributorData);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);

    const handleSubmit = async (values) => {
        await ServiceDistributors.updateContractPermissionsCommission(values, data.id_cliente);
        dispatch(updateDataDistributor(values));

        setOpenModalSuccess(true);
        setModeEdit(false)
    }


    useEffect(() => {
        //dispatch(getDistributorDetail(data.id_cliente))
    }, [])




    const permissionsFieldName = ["client", "printer", "repairman", "tariff"];

    const refForm = useRef();


    return (
        <>{
            <Form
                initialValues={distributorData}
                schemaForm={validateSchema}
                onSubmit={handleSubmit}
                className={'containerView px-6'}
                innerRef={refForm}
            >
                < >
                    {
                        !modeEdit && <ButtonEdit className={'align-self-end'} label={"Editar"} onClick={() => setModeEdit(true)} />
                    }
                </>
                <Grid spacing={2} className={'d-flex flex-row '}  >
                    <Grid item xs={4} className={'mr-3'}  >
                        <Grid item xs={12} className={'mt-1'}  >
                            <FormLabel className="text-primary">
                                Contrato

                            </FormLabel>
                            {
                                modeEdit ?
                                    <img src={adjuntarArchivoIcon} alt="editIcon" className="editIcon" />
                                    :
                                    <div className="d-flex flex-row">
                                        <div className="d-flex flex-row">
                                            <img src={contractIcon} className="contractIcon" alt="icon" />
                                        </div>
                                        <div className="d-flex flex-column ml-3">
                                            <div className="d-flex flex-row ">
                                                <img src={viewIcon} className="settingIcon size mr-2" alt="icon" />
                                                <p className="text-gray-light">Ver</p>
                                            </div>
                                            <div className="d-flex flex-row ">
                                                <img src={downloadIcon} className="settingIcon sizeDownloadIcon mr-2" alt="icon" />
                                                <p className="text-gray-light">Descargar</p>
                                            </div>
                                            {/* <div className="row">
                                                            <img src={editIconOnly} className="settingIconDiff" alt="icon"/>
                                                            <p className="settingTextDiff">Editar contrato</p>
                                                        </div> */}
                                        </div>
                                    </div>
                            }
                        </Grid >


                    </Grid>

                    <Grid item xs={8} className={'mr-2 '}  >
                        <Grid item xs={12} className={'mt-1'} >
                            <FormLabel className="text-primary">
                                Permisos
                            </FormLabel>
                        </Grid >
                        <Grid item xs={12} className={'mt-1 d-flex  flex-row '} >
                            <label className="text-white  w-25 mr-2">
                                Clientes
                            </label>

                            <label className="text-primary  w-25 mr-2">
                                Clientes
                            </label>
                            <label className="text-primary w-25  mr-2">
                                Impresoras
                            </label>
                            <label className="text-primary w-25  mr-2">
                                Tecnicos
                            </label>
                            <label className="text-primary w-25  ">
                                Tarifas
                            </label>
                        </Grid >

                        <Grid item xs={12} className={'mt-1'}>

                            <Grid item xs={12} className={'mt-2  d-flex flex-row'} >
                                <div className={'py-2 w-25 '}>
                                    <label className="text-primary  ">
                                        Leer
                                    </label>
                                </div>

                                {
                                    permissionsFieldName.map((permissionFieldName, index) => {
                                        return (
                                            <div className={'w-25'} key={JSON.stringify(permissionsFieldName) + 'read' + index} >
                                                {modeEdit ?
                                                    <ControllerInput
                                                        render={Switch}
                                                        name={`${permissionFieldName}CanRead`}
                                                        modeEdit={modeEdit}
                                                    /> :
                                                    <div>
                                                        <IconStatePermissions value={_.get(distributorData, `${permissionFieldName}CanRead`)} />
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }

                            </Grid>


                            <Grid item xs={12} className={'mt-2  d-flex flex-row'} >
                                <Grid item xs={3} className={'py-2'}>
                                    <label className="text-primary  ">
                                        Modificar
                                    </label>
                                </Grid>
                                {
                                    permissionsFieldName.map((permissionFieldName, index) => {
                                        return (
                                            <Grid item xs={3} key={JSON.stringify(permissionsFieldName) + 'Modify' + index}>
                                                {modeEdit ?
                                                    <ControllerInput
                                                        render={Switch}
                                                        name={`${permissionFieldName}CanModify`}
                                                        modeEdit={modeEdit}

                                                    /> :

                                                    <div >
                                                        <IconStatePermissions value={_.get(distributorData, `${permissionFieldName}CanModify`)} />
                                                    </div>
                                                }

                                            </Grid>
                                        )
                                    })}
                            </Grid>
                            <Grid item xs={12} className={'mt-2  d-flex flex-row'} >
                                <Grid item xs={3} className={'py-2'}>
                                    <label className="text-primary  ">
                                        Crear
                                    </label>
                                </Grid>


                                {permissionsFieldName.map((permissionFieldName, index) => {
                                    return (
                                        <Grid item xs={3} key={JSON.stringify(permissionsFieldName) + 'Create' + index}>
                                            {modeEdit ?
                                                <ControllerInput
                                                    render={Switch}
                                                    name={`${permissionFieldName}CanCreate`}
                                                    modeEdit={modeEdit}
                                                    onChange={(value, setValue) => {
                                                        if (value) {
                                                        }

                                                    }}
                                                /> :
                                                <div>
                                                    <IconStatePermissions value={_.get(distributorData, `${permissionFieldName}CanCreate`)} />

                                                </div>
                                            }

                                        </Grid>
                                    )
                                })}



                            </Grid>

                        </Grid >




                    </Grid>

                    <Grid item xs={4} className={'mt-0'} >
                        <Grid item xs={12} className={'mt-1'} >
                            <FormLabel className="text-primary">
                                Comisión Inicial
                            </FormLabel>
                            {modeEdit ?
                                <ControllerInput
                                    render={TextInput}
                                    name={"comi_ini"}
                                    modeEdit={modeEdit}
                                    className={'w-50'}
                                    type={'text'}
                                    maxlength={"3"}

                                /> :
                                <TextLeaf className={'text-primary px-3 py-3'}>
                                    <span className={'text-primary text-xl font-weight-500'}>+{refForm?.current?.watch('comi_ini') || distributorData.comi_ini}%</span>
                                </TextLeaf>}
                        </Grid>

                        <Grid item xs={12} className={'mt-3'} >
                            <FormLabel className="text-primary">
                                Comisión Resto
                            </FormLabel>
                            {modeEdit ?
                                <ControllerInput
                                    render={TextInput}
                                    name={"comi_resto"}
                                    modeEdit={modeEdit}
                                    className={'w-50'}
                                    type={'text'}
                                    maxlength={"3"}

                                /> :
                                <TextLeaf className={'text-primary px-3 py-3'}>
                                    <span className={'text-primary text-xl font-weight-500'}>+{refForm?.current?.watch('comi_resto') || distributorData.comi_resto}%</span>
                                </TextLeaf>
                            }
                        </Grid>



                    </Grid>


                </Grid>
                {
                    modeEdit &&
                    <div className="formButtons">
                        <Button variant="contained" onClick={() => setModeEdit(false)} className="prevButton btn">Cancelar</Button>
                        <SubmitButton  >Guardar Cambios</SubmitButton>
                    </div>

                }


            </Form >
        }
            <SuccessDialog isOpen={openModalSuccess} handleClose={() => setOpenModalSuccess(false)} text={"Cambios Guardados con Exito"} />

        </>
    )
}

export default ContractPermissionsCommissionData


const ErrorMessageCustom = () => {
    const { errors } = useFormState()
    const errorMessage = useMemo(() => {
        const fieldName = _.head(_.map(errors, (a, key) => key));
        return _.get(errors, `${fieldName}.message`, '');
    }, [JSON.stringify(errors)])

    return (<ErrorMessage message={errorMessage} />)
}


const IconStatePermissions = ({ value }) => {

    return (
        <img
            src={value ? allowedIcon : notAllowedIcon}
            className="w-25 h-25"
            alt="icon" />
    )
}
