import React, { useMemo, FC } from 'react';
import { useFormContext } from 'react-hook-form'

interface FieldHiddenProps {
    nameFields: any;
    customCondition?(T: any): boolean;
}


const FieldHidden: FC<FieldHiddenProps> = ({ children, nameFields, customCondition }) => {

    const { watch } = useFormContext()

    const showField = useMemo(() => {
        if (customCondition) {
            return customCondition(watch(nameFields));
        };
        return watch(nameFields);

    }, [JSON.stringify(watch(nameFields))]);

    return (
        <>
            {showField && children}
        </>
    )
}

export default FieldHidden;
