import React from 'react';
import { useDropzone } from 'react-dropzone';

const FileInput = (props) => {


    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();


    return (
        <section className="container">
            {!acceptedFiles.length ?
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <span className={"text-orange"}>Adjuntar</span>
                </div> :
                <span className={"text-orange"}>{acceptedFiles[0].name}</span>
            }

        </section>
    );
}


export default FileInput;



