const tecniTypes = {
    START_FETCH_TECNICOS: "START_FETCH_TECNICOS",
    GET_TECNICOS_SUCCESS: "GET_TECNICOS_SUCCESS",
    FAILURE: "FAILURE",
    CREATE_TECNICOS_SUCCESS: "CREATE_TECNICOS_SUCCESS",
    CLOSE_DIALOG: "CLOSE_DIALOG",
    SET_OFF_REDIRECT: "SET_REDIRECT_OFF",
    GET_TECNICOS_BY_ID: "GET_TECNICOS_BY_ID",
    GET_TECNICO_DETAIL: "GET_TECNICO_DETAIL",
    GET_DATA_TECNICO: "GET_DATA_TECNICO",
    SELECT_TECNICO: "SELECT_TECNICO",
    DELETE_TECNICO_SUCCESS: "DELETE_TECNICO_SUCCESS",
    SUSPENDED_TECNICO: "SUSPENDED_TECNICO",
    ACTIVE_TECNICO: "ACTIVE_TECNICO",
    GET_DISTRIBUTORS: "GET_DISTRIBUTORS",


    NEXT_STEP_CREATED:"NEXT_STEP_CREATED",
    PREV_STEP_CREATED:"PREV_STEP_CREATED",
    FINISH_CREATED:"FINISH_CREATED",
}

export default tecniTypes;