const ROLES_ACCESS = {
    VIEW: {
        "DASHBOARD": ["ADMIN", "DISTRIBUIDOR", "CLIENTE"],
        "DISTRIBUTOR_TABLE": ["ADMIN"],
        "CLIENT_TABLE": ["ADMIN", "DISTRIBUIDOR"],
        "TECH_TABLE": ["ADMIN", "DISTRIBUIDOR"],
        "PRINTER_TABLE": ["ADMIN", "TECNICO", "DISTRIBUIDOR"],
        "RATES": ["ADMIN", "DISTRIBUIDOR"],
        "TIKETS_TABLE": ["ADMIN", "TECNICO", "CLIENTE", "DISTRIBUIDOR"],
        "PAYMENT": ["CLIENTE"],
        GESTION_AND_PAYMENT: ["ADMIN"]
    },
    EDIT: {

    },
    CREATE: {

    }
}

export const MODULES = {
    DASHBOARD: "DASHBOARD",
    DISTRIBUTOR_TABLE: "DISTRIBUTOR_TABLE",
    CLIENT_TABLE: "CLIENT_TABLE",
    TECH_TABLE: "TECH_TABLE",
    PRINTER_TABLE: "PRINTER_TABLE",
    RATES: "RATES",
    TIKETS_TABLE: "TIKETS_TABLE",
    PAYMENT: "PAYMENT",
    GESTION_AND_PAYMENT: "GESTION_AND_PAYMENT"
}


export default ROLES_ACCESS;