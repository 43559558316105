import React from 'react';
import PaymentIcon from '../../../assets/dashboard/payment&collection/Recurso 10 1.svg';
import GestionIcon from '../../../assets/dashboard/payment&collection/Recurso 20 1.svg';
import './ChooseFlowView.scss';
import { Card } from '@material-ui/core';
import { useHistory } from 'react-router-dom';


const ChooseFlowView = () => {


    const router = useHistory()

    const goToPage = (path) => () => {
        router.push(path);
    }


    return (
        <div className={"w-100 h-100 d-flex flex-column align-items-center  "}>
            <h2 className={'my-6 text-primary'}>Gestion de cobros y pagos</h2>

            <div className={'d-flex '}>

                <Card onClick={goToPage("/dashboard/payments/distributor/table")} className={'card-container mr-5'}>
                    <label>Gestion de solicitud y pagos</label>
                    <img src={PaymentIcon} className={'my-4'} />
                    <label>A distribuidores</label>
                </Card>
                <Card onClick={goToPage("/dashboard/invoices/gestion")} className={'card-container'}>
                    <label>Gestion de Facturas</label>
                    <img src={GestionIcon} className={'my-4'} />
                    <label>Pendientes y pagas</label>
                </Card>

            </div>
        </div>
    )
}

export default ChooseFlowView
