import React, { useState, useMemo, useRef } from 'react'
import * as Yup from 'yup';
import Form from '../../../../Form';
import { Grid } from '@material-ui/core';
import { FormLabel, ControllerInput, TextInput, SelectInput } from '../../../../Form/components';
import { ButtonEdit } from '../../../../Buttons';
import _ from 'lodash'
import Swicth from '../../../../Form/components/Switch';
import classNames from 'classnames'
import { useFetchService } from '../../../../../hooks/useFetch';
import { ServiceRates } from '../../../../../utilities/api/services';
import usePermissionAccess from '../../../../../hooks/usePermissionAccess';

const validationSchema = Yup.object().shape({});


const FormRate = ({ initialValues, index, selectForm, onSelectForm }) => {

    const modeEdit = useMemo(() => {
        return selectForm === index;
    }, [selectForm])

    const refForm = useRef()


    const handleEditMode = () => {
        onSelectForm({ index, ref: refForm })
    }


    const { response: rateIgic, loadingIgic } = useFetchService(ServiceRates.getRateIgic, null, []);

    const listIgic = useMemo(() => {
        return _.map(rateIgic, ri => ({ label: ri.nombre, value: ri.id }))
    }, [JSON.stringify(rateIgic)])


    const [stateRate, setStateRate] = useState(initialValues?.visible_cli);

    const {permission} = usePermissionAccess("TARIFF","EDIT");

    return (
        <Form initialValues={initialValues} className={'w-100'} innerRef={refForm} schemaForm={validationSchema}  >

            <Grid item xs={12} className={'d-flex mr-4 flex-column bg-gray-ecoColor px-3  pb-4  pt-2 rounded-xl'}>
                {_.isNull(selectForm) && permission && <ButtonEdit className={'align-self-end  '} label={"Editar"} onClick={handleEditMode} />}


                <h2 className={'text-primary text-center  m-0 pb-2 border-bottom border-orange'}>{initialValues?.nombre}</h2>


                <Grid item xs={12} className={'d-flex flex-column mt-2'}>
                    <FormLabel className={'text-primary'}>
                        Precio por copia B y N
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={`pvp_bn`}
                        modeEdit={modeEdit}
                        className={'bg-transparent '}
                    />

                </Grid>
                <Grid item xs={12} className={'d-flex flex-column mt-3'}>
                    <FormLabel className={'text-primary'}>
                        Precio por copia Color
                                        </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={`pvp_color`}
                        modeEdit={modeEdit}
                        className={'bg-transparent'}
                    />

                </Grid>
                <Grid item xs={12} className={'d-flex flex-column mt-3'}>
                    <FormLabel className={'text-primary'}>
                        Consumo Minimo
                                        </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={`consumo_minimo`}
                        modeEdit={modeEdit}
                        className={'bg-transparent'}
                    />

                </Grid>

                <Grid item xs={12} className={'d-flex flex-column mt-3'}>
                    <FormLabel className={'text-primary'}>
                        Precio Mantenimiento
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={`mantenimiento`}
                        modeEdit={modeEdit}
                        className={'bg-transparent'}
                    />

                </Grid>

                <Grid item xs={12} className={'d-flex flex-column mt-3'}>
                    <FormLabel className={'text-primary'}>
                        Impuesto 
                    </FormLabel>
                    <ControllerInput
                        render={SelectInput}
                        name={`id_igic`}
                        list={listIgic}
                        loading={loadingIgic}
                        modeEdit={modeEdit}
                        classNameMenu={"w-100"}
                        classNameContainer={'w-100'}
                        className={'bg-transparent w-100'}
                    />

                </Grid>

                {modeEdit ?
                    <Grid item xs={12} className={'d-flex flex-row mt-3'}>
                        <FormLabel className={classNames('p-0 mt-2', { 'text-primary': stateRate, 'text-orange': !stateRate, })}>
                            {stateRate ? 'Publico' : 'Privado'}
                        </FormLabel>
                        <ControllerInput
                            render={Swicth}
                            name={`visible_cli`}
                            modeEdit={modeEdit}
                            className={'bg-transparent  mt-1'}
                            onChange={(value) => {
                                setStateRate(value);
                            }}
                        />

                    </Grid> :
                    <Grid item xs={12} className={'d-flex flex-row mt-3'}>
                        <label className={classNames({ 'text-primary': initialValues.visible_cli, 'text-orange': !initialValues.visible_cli })}>
                            {initialValues.visible_cli ? "Publico" : "Privado"}
                        </label>
                    </Grid>
                }

            </Grid>

        </Form>
    )
}

export default FormRate