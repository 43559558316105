import React from 'react';
import classNames from 'classnames';
import './TextArea.scss';

const TextArea = ({ onChange, value, defaultValue, className, readOnly = false, modeEdit = false, ...props }) => {

    const handleOnChange = (e) => {
        onChange(e.target.value)
    }

    return (
        <textarea
            className={classNames(`TexAreaEcoColor ${className}`, { 'modeEditTextAreaEcoColor': modeEdit })}
            onChange={handleOnChange}
            {...props}
            readOnly={!modeEdit || readOnly}
            {...{ defaultValue, value }}
        >
        </textarea>
    )
}

export default TextArea;
