import React from "react";
import "./TotalPrint.scss";
import greenRadius from "../../assets/dashboard/home/greenRadius.svg";
import blueRadius from "../../assets/dashboard/home/blueRadius.svg";
import redRadius from "../../assets/dashboard/home/redRadius.svg";
import TextAmount from "../TextAmount/TextAmount";
import CircleBar from "../CircleBar/CircleBar";

const TotalPrint = ({ totalPrinter, amountPrinterEnable, amountPrinterDisabled, amountPrinterRepair }) => {
  return (
    <div className="totalPrintContainer">
      <div className="totalPrintInner">
        <div className="title">
          <p>
            TOTAL
            <br />
            IMPRESORAS
          </p>
        </div>
        <div className="graph">
          <CircleBar number={totalPrinter} />
        </div>
        <div className="textAmount">
          <TextAmount
            radiusIcon={greenRadius}
            text={"Funcionando"}
            amount={amountPrinterEnable}
          />
          <TextAmount
            radiusIcon={redRadius}
            text={"Averiadas"} amount={amountPrinterDisabled} />
          <TextAmount
            radiusIcon={blueRadius}
            text={"En reparación"}
            amount={amountPrinterRepair}
          />
        </div>
      </div>
    </div>
  );
};

export default TotalPrint;
