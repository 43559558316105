import React from 'react'

const TabPanel = ({ index, selectTab, children }) => {
    return (
        <>
            {index === selectTab && children}
        </>
    )
}

export default TabPanel;
