import React from 'react';
import './TextAmount.scss';

const TextAmount = ({radiusIcon, text, amount}) => {
    return(
        <div className="textAmountContainer">
            <img src={radiusIcon} alt="radiusIcon" />
            <p className="text">{text}</p>
            <p className="amount">{amount}</p>
        </div>
    )
}

export default TextAmount;