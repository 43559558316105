import React, { useState, useImperativeHandle, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash'
import Popper from '@material-ui/core/Popper';
import useDebounce from '../../../../hooks/useDebounce';
import useSearch from '../../../../hooks/useSearch';
import classNames from 'classnames';
import './SelectInput.scss';
import IconCustom from '../../../IconCustom'
import ErrorMessage from '../../../Text/ErrorMessage';
import { isNilCustom } from '../../../../utilities/isValid'




const ComboBox = React.forwardRef(
    (
        {
            value,
            defaultValue,
            callback,
            onChange,
            list = [],
            listAdd = [],
            isSearchable = true,
            placeholder = "Type to search",
            className,
            classNameMenu,
            componentItem,
            functionCompare,
            loading,
            modeEdit,
            readOnly,
            classNameContainer,
            errorMessage,
            disabled = false,
            customMenuComponent: CustomMenuComponent

        },
        ref
    ) => {

        const [hasMouse, setHasMouse] = useState(false);
        const [openMenu, setOpenMenu] = useState(false);
        const [anchorEl, setAnchorEl] = React.useState(null);


        const configSearch = useMemo(() => {
            const config = {};
            if (!isSearchable) _.assign(config, { predicate: () => (true) });
            return config;
        }, [isSearchable]);

        const [searchValue, onChangeQuery, resultList] = useSearch(list, configSearch);



        const clearValue = () => {
            onChangeQuery('');
            onChange(null);
        };

        useImperativeHandle(
            ref,
            () => ({
                clearValue,
                selectValue: (value) => {
                    const foundValue = findValueList(value, list);
                    foundValue && handleSelectValue(foundValue);
                }
            }),
            [JSON.stringify(list)]
        );


        useEffect(() => {
            if (!isNilCustom(value) && !searchValue) {
                onChangeQuery(_.get(findValueList(value || defaultValue, list), 'label', ''));
            }

            if (isNilCustom(value) && searchValue) {
                onChangeQuery('');
            }

        }, [JSON.stringify(value)]);

        useEffect(() => {

            if (_.get(value || defaultValue, 'label')) {
                onChangeQuery(value.label);
            } else {
                onChangeQuery(_.get(findValueList(value || defaultValue, list), 'label', ''));
            }

        }, [loading, JSON.stringify(list)]);
        /*
                useEffect(() => {
                    if (_.isNil(value) && searchValue) {
                        onChangeQuery('');
                    }
                }, [JSON.stringify(value)])
        */

        const handleSelectValue = ({ value, label = '' }) => {
            if (callback) {
                callback(value);
                onChangeQuery(label);
                onChange(value);
            } else {
                onChangeQuery(label);
                onChange(value);
            }
            setOpenMenu(false);
        };


        const handleComparationElements = (value, element) => {
            if (functionCompare) {
                return functionCompare(value, element.value);
            }
            return _.isEqual(element.value, value);
        };


        const findValueList = (value, list) => {
            return _.find(list, (element) => handleComparationElements(value, element));
        };


        const handleFocus = (event) => {
            setAnchorEl(event.currentTarget);
            setOpenMenu(true);


        };

        const handleBlur = () => {
            if (!hasMouse) {
                setOpenMenu(false);
                setAnchorEl(null);
            }
        };

        const handleMouseOverModal = () => {
            if (!hasMouse) {
                setHasMouse(true);
            }
        };

        const handleMouseOutModal = () => {
            if (hasMouse) {
                setHasMouse(false);
            }
        };

        const refInput = useRef()



        return (
            <>
                <div className={` ${classNameContainer} position-relative`}>
                    <input
                        className={classNames(` SelectInputEcoColor w-100 ${className}`, { 'modeEditSelectInputEcoColor': modeEdit })}
                        id="auto"
                        placeholder={placeholder}
                        value={searchValue}
                        onChange={event => onChangeQuery(event.target.value)}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        readOnly={readOnly || !modeEdit}
                        ref={refInput}
                        disabled={disabled}

                    />
                    {modeEdit && !disabled && !isNilCustom(value) && <IconCustom onClick={clearValue} type={''} icon={"plus_circle"} className={`iconClearSelectInput text-red `} size={'1.25rem'} ></IconCustom>}
                </div>
                {errorMessage && <ErrorMessage message={errorMessage} />}

                <Popper
                    open={openMenu && !disabled}
                    placement="bottom-start"
                    anchorEl={anchorEl}
                    disablePortal={false}
                    onMouseOver={handleMouseOverModal}
                    onMouseOut={handleMouseOutModal}
                >
                    <div className={`menuSelectInputContainer Scrollbar  ${classNameMenu}`} style={{ minWidth: `${refInput?.current?.offsetWidth}px`, background: "#FFF", maxHeight: '10rem', overflowY: "auto" }}>
                        {CustomMenuComponent ? <CustomMenuComponent list={resultList} selectValue={handleSelectValue} /> :
                            <ul >
                                {resultList.map((option, index) => (
                                    <li className={'itemMenuSelectInputContainer px-3 py-2 text-gray-light'} key={JSON.stringify(option) + index} onClick={() => handleSelectValue(option)} >{option.label}</li>
                                ))}
                            </ul>}

                    </div>
                </Popper>

            </>
        )

    })


export default ComboBox;
