import React from 'react';
import './CustomButton.scss';
import Button from "@material-ui/core/Button";

const CustomButton = ({text, onClick, radius, disabled}) => {
    return (
        <div className="customButtonContainer">
            <Button style={{ borderRadius: radius ? radius : "48px"}} disabled={disabled} onClick={onClick}>{text}</Button>
        </div>
    )
}

export default CustomButton;