import React, { useImperativeHandle } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';





const Form = ({ initialValues, schemaForm, onSubmit, className = '', placement = 'top-left', children, innerRef = null }) => {


    const getDefaultConfigForm = (initialValues, schema) => {
        return {
            defaultValues: {
                ...initialValues,
            },
            resolver: yupResolver(schema),
            mode: 'all',
            criteriaMode: 'firstErrorDetected',
            reValidateMode: 'onChange',
            shouldFocusError: true,
        };
    };


    const methods = useForm(getDefaultConfigForm(initialValues, schemaForm));

    useImperativeHandle(
        innerRef,
        () => ({
            ...methods
        }),
        [],
    )


    const handleSubmit = (data) => onSubmit(data);
    const handleError = (errors) => console.log('Errorr Form: ', errors);

    return (
        <FormProvider {...methods}>
            <form
                autoComplete={'off'}
                onSubmit={methods.handleSubmit(handleSubmit, handleError)}
                className={className}
            >
                <>{children}</>
            </form>
        </FormProvider>
    )
}

export default Form
