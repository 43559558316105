import React from "react";
import "./ChangePage.scss";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import AuthHeader from "../../components/AuthHeader/AuthHeader";

const ResetPage = () => {
  return (
    <div className="changePageContainer">
      <AuthHeader />
      <div className="changePageContent">
        <ChangePassword />
      </div>
    </div>
  );
};

export default ResetPage;
