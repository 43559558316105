import React from 'react';
import './FormLabel.scss';

interface FormLabelProps {
    className?: string,
    style?: object,
}


const FormLabel: React.FC<FormLabelProps> = ({ children, className, style }) => {
    return (
        <div {...{ style }} className={` defaultFormLabel ${className}`}>
            {children}
        </div>
    )
}

export default FormLabel
