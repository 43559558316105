import React from 'react';
import './DashHome.scss';
import Consumed from '../../../components/Consumed/Consumed';
import waterLogo from '../../../assets/dashboard/home/water.svg';
import trashLogo from '../../../assets/dashboard/home/trash.svg';
import forestLogo from '../../../assets/dashboard/home/forest.svg';
import lampLogo from '../../../assets/dashboard/home/lamp.svg';
import cloudLogo from '../../../assets/dashboard/home/cloud.svg';
import carLogo from '../../../assets/dashboard/home/car.svg';
import TotalPrint from '../../../components/TotalPrint/TotalPrint';
import TotalUsers from '../../../components/TotalUsers/TotalUsers';
import TotalIncome from '../../../components/TotalIncome/TotalIncome';
import { useFetchService } from '../../../hooks/useFetch';
import ServicesUser from '../../../utilities/api/services/User';
import _ from 'lodash'


const DashHome = () => {


    const { response } = useFetchService(ServicesUser.getStaditicDashboard, null, {});
    //totalPrinter, amountPrinterEnable, amountPrinterDisabled, amountPrinterRepair



    return (
        <div className="dashHomeContainer">
            <div className="dashHomeInner">
                <div className="consumedAmount">
                    <Consumed logo={waterLogo} title={<p>Agua<br />Consumida</p>} amount={"204 L"} />
                    <Consumed logo={trashLogo} title={<p>Desechos de<br />residuos</p>} amount={"25,3 kg"} />
                    <Consumed logo={forestLogo} title={<p>Árboles<br />consumida</p>} amount={"850"} />
                    <Consumed logo={lampLogo} title={<p>Energía<br />consumida</p>} amount={"126,6 hs"} />
                    <Consumed logo={cloudLogo} title={<p>CO2<br />producido</p>} amount={"25,3 kg"} />
                    <Consumed logo={carLogo} title={<p>Km<br />recorrido</p>} amount={"306 km"} />
                </div>
                <div className="totalRow">
                    <TotalPrint
                        totalPrinter={_.get(response, 'total_distribuidor')}
                        amountPrinterEnable={_.get(response, 'total_impresoras_funcionando')}
                        amountPrinterDisabled={_.get(response, 'total_impresoras_averiadas')}
                        amountPrinterRepair={_.get(response, 'total_impresoras_averiadas')} />

                    <div className="totalUsersColumn">
                        <TotalUsers total={_.get(response, 'total_distribuidor')} label={"DISTRIBUIDORES"} upsAndDowns={_.get(response, 'nuevos_distribuidores')} />
                        <TotalUsers total={_.get(response, 'total_clientes')} label={"CLIENTES"} upsAndDowns={_.get(response, 'nuevos_clientes')} />
                        <TotalUsers total={_.get(response, 'total_tecnicos')} label={"TECNICOS"} upsAndDowns={_.get(response, 'nuevos_tecnicos')} />
                    </div>
                    <TotalPrint
                        totalPrinter={_.get(response, 'total_distribuidor')}
                        amountPrinterEnable={_.get(response, 'total_impresoras_funcionando')}
                        amountPrinterDisabled={_.get(response, 'total_impresoras_averiadas')}
                        amountPrinterRepair={_.get(response, 'total_impresoras_averiadas')} />

                </div>
                <div className="totalIncome">
                    <TotalIncome />
                </div>
            </div>
        </div>
    )
}

export default DashHome;