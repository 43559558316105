export const addLoginAttempt = (email) => {
    if(!localStorage.getItem(`loginAttempt/${email}`)) {
        localStorage.setItem(`loginAttempt/${email}`, 1);
    } else {
    localStorage.setItem(`loginAttempt/${email}`, parseInt(localStorage.getItem(`loginAttempt/${email}`)) + 1);
    }
}

export const removeLoginAttempt = (email) => {
    localStorage.removeItem(`loginAttempt/${email}`);
};