import React, { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import { AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ButtonAdd } from '../../../../../../Buttons';
import FormContact from './FormContact'
import ServiceClients from '../../../../../../../utilities/api/services/Clients';
import { useFetchService } from '../../../../../../../hooks/useFetch';

const ContactComercial = ({ idAddressCommercial }) => {

    const [showNewContact, setShowNewContact] = useState(false);

    const handleAddNewContact = async (values) => {
        const { id_contactos_cli } = await ServiceClients.createContactByAddressCommercial({ id: idAddressCommercial, contact: values });
        setContacts(pre => [{ ...values, id_contactos_cli }, ...pre]);
        setShowNewContact(false)
    }

    const handleUpdateContact = async (values) => {
        await ServiceClients.updateContactByAddressCommercial({ id: values.id_contactos_cli, contact: values })
    }

    const [contacts, setContacts] = useState([]);

    const { response, loading } = useFetchService(ServiceClients.getContactsByAddressComercial, idAddressCommercial, []);


    useEffect(() => {
        if (!loading) {
            setContacts(response)
        }
    }, [loading])


    return (
        <div className={'containerView px-0  '}>

            <ButtonAdd
                label={'Agregar nueva Contacto'}
                classNameText={'text-orange'}
                classNameIcon={'text-orange'}
                className={'align-self-end mr-3'}
                onClick={() => setShowNewContact(true)} />
            <div className={'text-primary py-3 px-4 my-4'} style={{ background: "#DFEFF1" }}>
                Persona De Contacto
            </div>

            <>
                {showNewContact &&
                    <FormContact valuesContact={{}} onCancel={() => setShowNewContact(false)} onSubmit={handleAddNewContact} newContact={true} />

                }</>


            {contacts.map(contact => {
                return (
                    <Accordion key={JSON.stringify(contact)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >{contact?.nombre_contacto}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FormContact valuesContact={contact} onSubmit={handleUpdateContact} />
                        </AccordionDetails>
                    </Accordion>

                )
            })}

        </div>
    )
}

export default ContactComercial
