import React from 'react';
import "./TextLeaf.scss";


const TextLeaf = ({ children, className }) => {
    return (
        <div className={` containerTextLeaf ${className}`}>
            {children}
        </div>
    )
}

export default TextLeaf
