import React from "react";
import "./LoginPage.scss";
import Login from "../../components/Login/Login";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { ReactComponent as MundoLogo } from "../../assets/login/mundo.svg";
import additional from "../../assets/login/add.jpeg";
import Spinner from "../../components/Spinner/Spinner";
import { useSelector } from "react-redux";

const LoginPage = () => {
  const isLoading = useSelector(state => state.user.loading);
  
  return (
    <div className="loginPageContainer">
      <AuthHeader />
      <div className="loginPageContent">
        <div className="mundoLogoContainer">
          <MundoLogo className="mundoLogo" />
          <p>BIENVENIDO</p>
          <img src={additional} style={{ width: "100%", marginTop: "1.8rem" }}/>
        </div>
        <Login />
        <Spinner className="loginSpinner" isLoading={isLoading}/>
      </div>
    </div>
  );
};

export default LoginPage;
