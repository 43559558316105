import React from 'react';
import searchLogo from '../../assets/dashboard/distributor/search.svg';
import './SearchInput.scss'


const SearchInput = ({ searchValue = "", onChageSearch = () => { }, ...props }) => {


    const handleOnChageSearch = (e) => {
        onChageSearch(e.target.value)
    }

    return (
        <div className="searcherInputContainer">
            <input {...props} type="text" value={searchValue} className="searcher" onChange={handleOnChageSearch} />
            <img type="text" src={searchLogo} className="icon" alt="iconSearch" />
        </div>

    )
}

export default SearchInput;