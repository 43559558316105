import React, { useState } from 'react';
import Tabs from '../../../Tabs/Tabs';
import TAB_VIEW from './Tab';
import VIEW from '../RateView';
import RateDetail from './RateDetail/RateDetail';
import { Button } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import usePermissionAccess from '../../../../hooks/usePermissionAccess';

const RateDetailOverview = ({ setRateView }) => {

    const [actualTab, setActualTab] = useState(TAB_VIEW.DATA);
    const [createRetailOption, setcreateRetailOption] = useState(0);


    const router = useHistory()

    const handleChangeCreateRetailOption = () => {
        router.push('/dashboard/rate-created')
    }

    const { permissions } = usePermissionAccess("TARIFF", "EDIT");

    return (
        <div className='distriDetailContainer'>
            <div className="distriDetailInner">
                <div className="title">
                    <div className="titleText">TARIFAS</div>
                </div>
                <div className='detailContent'>
                    {permissions && <Tabs actualTab={actualTab} setActualTab={setActualTab} texts={[<Button className='createButton' onClick={handleChangeCreateRetailOption}>+ Crear nuevo</Button>]} />}
                    <RateDetail actualTab={actualTab} createRetailOption={createRetailOption} />
                </div>
            </div>
        </div>
    )
}

export default RateDetailOverview;