import React from 'react';
import Form from '../../../../Form';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { nextStepClientCreate, prevStepClientCreate } from '../../../../../redux/client/clientReducer';
import { SubmitButton, TextInput, ControllerInput, FormLabel } from '../../../../Form/components';
import { Button, Grid } from '@material-ui/core';

const validationSchema = Yup.object().shape({
    provincia: Yup.string().required('Provincia Es Requerido'),
    direccion_fiscal: Yup.string().required('Dirreccion Requerido'),
    poblacion: Yup.string().required('Poblacion Es Requerido'),
    codpost: Yup.string().required('Codigo postal Requerido'),

});


const Step2 = () => {

    const { data } = useSelector(state => state.client.clientCreate);
    const dispatch = useDispatch()

    const handleNextStep = (values) => {
        dispatch(nextStepClientCreate(values));
    }

    const handleCancelCreated = () => {
        dispatch(prevStepClientCreate())
    }

    return (
        <Form initialValues={data} className={''} schemaForm={validationSchema} onSubmit={handleNextStep}>

            <Grid container className={'d-flex flex-column align-items-center my-3 '}>
                <Grid item xs={12} className={'w-50'}>
                    <FormLabel className="text-primary">
                        Provincia *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={'provincia'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >



                <Grid item xs={12} className={'mt-4 w-50'}>
                    <FormLabel className="text-primary">
                        Direccion *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={'direccion_fiscal'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >

                <Grid item xs={12} className={'mt-4 w-50'}>
                    <FormLabel className="text-primary">
                        Poblacion *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={'poblacion'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >

                <Grid item xs={12} className={'mt-4 w-50'}>
                    <FormLabel className="text-primary">
                        CP *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={'codpost'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >

            </Grid>

            <div className="formButtons">
                <Button variant="contained" onClick={handleCancelCreated} className="prevButton btn">Volver</Button>
                <SubmitButton labelButton={'Siguiente'}></SubmitButton>
            </div>


        </Form >
    )
}

export default Step2
