import React from 'react';
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useTable, useRowSelect } from 'react-table';
import './Table.scss';
import { Paper, TableContainer } from '@material-ui/core';
import _ from 'lodash'
import useOrderData from '../../hooks/useOrderData';



const Table = ({ columns, data, initialState = {}, orderByValues = {}, className }) => {

    const { dataOrdered } = useOrderData({ data, valuesOrder: orderByValues })



    const { getTableProps, state, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: dataOrdered,
        initialState
    },
        useRowSelect
    )


    return (
        <TableContainer component={Paper} className={`tableContainer Scrollbar ${className}`} >

            <MaUTable stickyHeader {...getTableProps()}>
                <TableHead className={'tableHead'}>
                    {headerGroups.map(headerGroup => (
                        <TableRow  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <TableCell    {...column.getHeaderProps([
                                    {
                                        className: `${column.className}`,
                                        style: column.style,
                                    },
                                ])}>
                                    {column.render('Header')}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody className={'tableBody'}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        const onClick = _.get(state, 'rowState.onClick', ({ row }) => {
                            return row;
                        });
                        return (
                            <TableRow hover onClick={() => onClick({ row })}  {...row.getRowProps()} >
                                {row.cells.map(cell => {
                                    return (
                                        <TableCell  {...cell.getCellProps([
                                            {
                                                className: cell.column.className,
                                                style: cell.column.style,
                                                onClick: cell.column.onClick
                                                    ? () => cell.column.onClick({ row })
                                                    : () => { },
                                            },
                                        ])}>
                                            {cell.render('Cell')}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </MaUTable>
        </TableContainer>
    )
}

export default Table
