import axios from "axios";
import { ENDPOINT } from "../../utilities/constants";
import distriTypes from "./types";
import ServerConnect from "../../utilities/api/ServerConnect";
import { ServiceDistributors } from "../../utilities/api/services";
import ServiceDitributors from "../../utilities/api/services/Distributors";

const initialState = {
    loading: false,
    distributors: [],
    distributorCreate: {
        data: {},
        step: 0
    },
    distributorDetail: {},
    distributorData: {},
    distributorState: [],
    distributorPermission: {},
    error: '',
    openSucess: false,
    openError: false,
    distributorCreated: false,
    distributorDelete: false,
    disbutorsclients: [],
    selectedDistributor: '',
    datosBancarios: [],
    distributorClient: [],
};

// Reducer
export default function distributorReducer(state = initialState, action) {
    switch (action.type) {
        case distriTypes.START_FETCH_DISTRIBUTORS:
            return {
                ...state,
                loading: true,
            }
        case distriTypes.GET_DISTRIBUTORS_SUCCESS:
            return {
                ...state,
                distributors: action.payload,
                loading: false
            }
        case distriTypes.CREATE_DISTRIBUTORS_SUCCESS:
            return {
                ...state,
                loading: false,
                openSucess: true,
                distributorCreated: true
            }
        case distriTypes.DELETE_DISTRIBUTORS_SUCCESS:
            return {
                ...state,
                loading: false,
                openSucess: true,
                distributorDelete: true
            }
        case distriTypes.FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
                openError: true
            }
        case distriTypes.CLOSE_DIALOG:
            return {
                ...state,
                openSucess: false,
                openError: false
            }
        case distriTypes.SET_OFF_REDIRECT:
            return {
                ...state,
                distributorCreated: false
            }
        case distriTypes.GET_DISTRIBUTORS_BY_ID:
            return {
                ...state,
                distributorsclients: action.payload
            }
        case distriTypes.SELECT_DISTRIBUTOR:
            return {
                ...state,
                selectedDistributor: action.payload,
            }
        case distriTypes.GET_DISTRIBUTOR_DETAIL:
            return {
                ...state,
                loading: false,
                distributorDetail: action.payload,
                distributorState: action.payload.estado_cliente,
            }
        case distriTypes.GET_DATA_DISTRIBUTOR:
            return {
                ...state,
                distributorData: action.payload,
                loading: false,
            }
        case distriTypes.UPDATE_DATA_DISTRIBUTOR:
            return {
                ...state,
                distributorData: { ...state.distributorData, ...action.payload },
                loading: false,
            }
        case distriTypes.GET_PERMISSION_DISTRIBUTOR:
            return {
                ...state,
                distributorPermission: action.payload,
                loading: false,
            }
        case distriTypes.START_DATA_BANK:
            return {
                ...state,
                loading: true,
            }
        case distriTypes.GET_DATA_BANK:
            return {
                ...state,
                datosBancarios: action.payload,
                loading: false
            }
        case distriTypes.SUSPENDED_DISTRIBUTOR:
            return {
                ...state,
                loading: false,
                distributorState: 0
            }
        case distriTypes.ACTIVE_DISTRIBUTOR:
            return {
                ...state,
                loading: false,
                distributorState: 1
            }
        case distriTypes.NO_LOADING:
            return {
                ...state,
                loading: false,
            }
        case distriTypes.GET_CLIENT_DISTRIBUTOR:
            return {
                ...state,
                loading: false,
                distributorClient: action.payload
            }

        case distriTypes.NEXT_STEP_CREATED:
            return {
                ...state,
                distributorCreate: {
                    data: { ...state.distributorCreate.data, ...action.payload },
                    step: ++state.distributorCreate.step
                }

            }
        case distriTypes.PREV_STEP_CREATED:
            return {
                ...state,
                distributorCreate: {
                    data: { ...state.distributorCreate.data, ...action.payload },
                    step: --state.distributorCreate.step
                }

            }
        case distriTypes.FINISH_CREATED:
            return {
                ...state,
                distributorCreate: {
                    data: {},
                    step: 0
                }
            }
        default:
            {
                return state;
            }
    }
}

// Action Creators
export const getDistributorsStart = () => ({
    type: distriTypes.START_FETCH_DISTRIBUTORS
});

export const getDistributorsSuccess = (res) => ({
    type: distriTypes.GET_DISTRIBUTORS_SUCCESS,
    payload: res
})

export const handleError = (error) => ({
    type: distriTypes.FAILURE,
    payload: error
})

export const createDistributorStart = () => ({
    type: distriTypes.START_FETCH_DISTRIBUTORS
})

export const createDistributorSuccess = () => ({
    type: distriTypes.CREATE_DISTRIBUTORS_SUCCESS
})

export const deleteDistributorSuccess = () => ({
    type: distriTypes.DELETE_DISTRIBUTORS_SUCCESS
})

export const closeDialog = () => ({
    type: distriTypes.CLOSE_DIALOG
})

export const setRedirectOff = () => ({
    type: distriTypes.SET_OFF_REDIRECT
})

export const getDistributorCli = (res) => ({
    type: distriTypes.GET_DISTRIBUTORS_BY_ID,
    payload: res
})

export const selectDistributor = (distributor) => ({
    type: distriTypes.SELECT_DISTRIBUTOR,
    payload: distributor
})

export const getDetailDistributorStart = () => ({
    type: distriTypes.START_FETCH_DISTRIBUTORS
});

export const getDetailDistributor = (res) => ({
    type: distriTypes.GET_DISTRIBUTOR_DETAIL,
    payload: res
})

export const setDataDistributor = (res) => ({
    type: distriTypes.GET_DATA_DISTRIBUTOR,
    payload: res
})

export const updateDataDistributor = (res) => ({
    type: distriTypes.UPDATE_DATA_DISTRIBUTOR,
    payload: res
})

export const getPermissionDistributor = (res) => ({
    type: distriTypes.GET_PERMISSION_DISTRIBUTOR,
    payload: res
})

export const getDataBankStart = () => ({
    type: distriTypes.START_DATA_BANK,
})

export const getDataBankDistributor = (res) => ({
    type: distriTypes.GET_DATA_BANK,
    payload: res
})

export const suspendedDistributorSuccess = () => ({
    type: distriTypes.SUSPENDED_DISTRIBUTOR
})

export const activeDistributorSuccess = () => ({
    type: distriTypes.ACTIVE_DISTRIBUTOR
})

export const noLoading = () => ({
    type: distriTypes.NO_LOADING
})

export const getClientDistributor = (res) => ({
    type: distriTypes.GET_CLIENT_DISTRIBUTOR,
    payload: res
})

export const nextStepDistributorCreate = (values) => ({
    type: distriTypes.NEXT_STEP_CREATED,
    payload: values
});

export const prevStepDistributorCreate = (values) => ({
    type: distriTypes.PREV_STEP_CREATED,
    payload: values
});

export const finishDistributorCreate = () => ({
    type: distriTypes.FINISH_CREATED
});


// Side effects
export const getDistributors = (searchValue = '', type = "nombre") => {
    return (dispatch) => {
        dispatch(getDistributorsStart());

        ServiceDistributors.getDistributors({ search: searchValue, type })
            .then((res) => {
                dispatch(getDistributorsSuccess(res));
            })
            .catch((error) => {
                dispatch(handleError(error));
                alert("Error al encontrar distribuidor");
            });
    };
};

export const createDistributor = (distributorData) => {
    return (dispatch) => {
        dispatch(createDistributorStart());
        axios.post(ENDPOINT + "/distributors/createDistributor", distributorData, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(createDistributorSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const deleteDistributor = (id) => {
    return (dispatch) => {
        dispatch(createDistributorStart());

        axios.delete(ENDPOINT + `/distributors/dist/${id}`)
            .then(res => {
                dispatch(deleteDistributorSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getDataCliDistributor = (id) => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/distributors/client/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getDistributorCli(res.data))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getDataDistributor = (id) => {


    return async (dispatch) => {

        await dispatch(getDetailDistributorStart());

        ServiceDitributors.getDataDistributor(id)
            .then(res => {
                dispatch(setDataDistributor(res));

            })
            .catch(error => {
                console.log('error', error)
                dispatch(handleError(error.response.data));
            });
    }

}

export const getDistributorPermission = (id) => {
    return (dispatch) => {

        dispatch(getDetailDistributorStart());

        axios.get(ENDPOINT + `/distributors/permisos/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getPermissionDistributor(res.data))
            })
            .catch(error => {
                console.log('error', error)
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getDataBank = (id) => {
    return (dispatch) => {

        dispatch(getDataBankStart())

        axios.get(ENDPOINT + `/distributors/datosbancarios/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                console.log('res', res)
                dispatch(getDataBankDistributor(res.data))
            })
            .catch(error => {
                dispatch(handleError(error.responde.data));
                alert(error.response.data);
            })
    }
}

export const suspendedDistributor = (id) => {
    return (dispatch) => {
        dispatch(createDistributorStart());

        axios.put(ENDPOINT + `/permisos/disable/${id}`)
            .then(res => {
                dispatch(suspendedDistributorSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const activeDistributor = (id) => {
    return (dispatch) => {
        dispatch(createDistributorStart());

        axios.put(ENDPOINT + `/permisos/enable/${id}`)
            .then(res => {
                dispatch(activeDistributorSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const updateDistributor = (id, dist, data, permisos, bankAccount) => {
    const data_bank = { "datos_bancarios": bankAccount }

    return (dispatch) => {

        axios.post(ENDPOINT + `/distributors/distUpdateDatos/${id}`, dist)
            .then(res => {
                alert("Cambios Guardados");
            })
            .catch(error => {
                dispatch(handleError(error.response));
                alert(error.response);
            });

        axios.put(ENDPOINT + `/distributors/updateContactDist/${id}`, data)
            .then(res => {
                
            })
            .catch(error => {
                dispatch(handleError(error.response));
                alert(error.response);
            });

        axios.put(ENDPOINT + `/distributors/${id}`, permisos)
            .then(res => {
                
            })
            .catch(error => {
                dispatch(handleError(error.response));
                alert(error.response);
            });

        axios.put(ENDPOINT + `/distributors/datosBancarios/${id}`, data_bank)
            .then(res => {
                
            })
            .catch(error => {
                dispatch(handleError(error.response));
                alert(error.response);
            });
    }
}

export const getClientsByDistributor = ({ id, search }) => {
    return (dispatch) => {

        dispatch(getDetailDistributorStart());

        ServiceDistributors.getClientByDistributor({ id, search })
            .then(res => {
                dispatch(getClientDistributor(res))
            })
            .catch(error => {
                console.log('error', error)
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}