import React from "react";
import "./ResetPage.scss";
import ResetPassword from "../../components/ResetPassword/ResetPassword";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import Spinner from "../../components/Spinner/Spinner";
import { useSelector } from "react-redux";

const ResetPage = ({match}) => {
  const isLoading = useSelector(state => state.user.loading);
  
  return (
    <div className="resetPageContainer">
      <AuthHeader />
      <div className="resetPageContent">
        <ResetPassword id={match.params.id} token={match.params.token}/>
        <Spinner className="loginSpinner" isLoading={isLoading} />
      </div>
    </div>
  );
};

export default ResetPage;
