import React from 'react';
import  './Select.scss';

const Select = ({ options = [], onChange }) => {

    const handleOnChange = (e)=>{
        onChange(e.target.value)
    }

    return (
        <div className="customSelectContainer">
            <select onChange={handleOnChange}>
                {options.map(op => {
                    return <option value={op.value}>{op.label}</option>
                })}
             </select>
        </div>
    )
}

export default Select
