import React from 'react'
import { Collapse } from '@mui/material';
import { useFormContext } from 'react-hook-form'
import { Grid, Fade } from '@material-ui/core';
import { FormLabel, ControllerInput, SelectInput, TextArea, FieldHidden } from '../../../../Form/components';
import Line from '../../../../Line';
import './MenuColor.scss';
import { ServiceClients, ServiceDistributors, ServiceTickets } from '../../../../../utilities/api/services';
import { useFetchService } from '../../../../../hooks/useFetch';
import _ from 'lodash';

import FailureForm from './FailureForm'
import { useSelector } from 'react-redux';
import useAsync from '../../../../../hooks/useAsync';


const ConditionalFormTicket = ({ nameField }) => {


    const [dataPrinter, setDataPrinter] = React.useState({});

    const user = useSelector(state => state.user.actualUser.tipo_cliente_id);


    const { watch } = useFormContext();
    const typeTicket = watch(nameField);
    const typeUser = watch('typeUser');


    //-------------------

    const serviceUser = React.useMemo(() => {
        const services = {
            1: ServiceTickets.getClients,
            2: ServiceTickets.getTechs,
            3: ServiceTickets.getDistributors
        }

        return _.get(services, typeUser, ServiceTickets.getDistributors);
    }, [typeUser]);

    const { value } = useAsync(serviceUser)



    const listDistributor = React.useMemo(() => {
        if (!value) return [];
        return value.map(u => ({ value: u.id_cliente, label: u.nombre }));
    }, [JSON.stringify(value)]);

    //--------------


    const listUser = React.useMemo(() => {
        const users = [{ value: 0, label: "EcoColor " }, { value: 3, label: "Distribuidor" }, { value: 2, label: "Tecnico" }, { value: 1, label: "Cliente" }];
        return users.filter(u => user !== u.value);
    }, []);


    const { response: colors } = useFetchService(ServiceTickets.getColors, {}, []);

    const { response: dirrectionComercials } = useFetchService(ServiceClients.getAllDirectionComercial, null, []);

    const listDirrectionComercials = React.useMemo(() => {
        return dirrectionComercials.map(dir => ({ value: dir.id_direcc_cli, label: dir.direccion }));
    }, [JSON.stringify(dirrectionComercials)]);


    const listColor = React.useMemo(() => {
        return colors.map(color => ({ value: color, label: color.nombre }));
    }, [JSON.stringify(colors)]);


    const listUbication = []

    const listInvoices = [{ value: 1, label: "Factura A" }, { value: 2, label: "Factura B" }, { value: 3, label: "Factura C" }]


    const { response: typesErrors } = useFetchService(ServiceTickets.getTypeErrors, {}, [])

    const listErrorTypes = React.useMemo(() => {

        return typesErrors.map(typeError => ({ value: typeError.id, label: typeError.tipo_error }));
    }, [JSON.stringify(typesErrors)]);



    const { response: ubicationsData, refetch: refetchUbication } = useFetchService(ServiceClients.getUbicationsByAddressComercial, null, [])

    const listUbications = React.useMemo(() => {
        return ubicationsData.map(ubi => ({ value: ubi, label: ubi.nombre_ubi }));
    }, [JSON.stringify(ubicationsData)]);



    const handleSelectDirectionComercial = (value) => {
        value && refetchUbication(value)
    }


    return (
        <div className={'w-100'}>
            <Collapse in={typeTicket === 1} unmountOnExit={true}>

                <Grid xs={12} className={'my-4 d-flex '} >
                    <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                        <FormLabel className={'mr-3 mb-0 d-flex align-items-center  text-gray-light'}>
                            Enviar a
                        </FormLabel>
                        <ControllerInput
                            render={SelectInput}
                            name={'typeUser'}
                            className={'w-100'}
                            list={listUser}
                            loading={false}
                            modeEdit={true}
                            classNameContainer={'w-75'}

                        />
                    </Grid>
                    <Fade in={watch('typeUser')} unmountOnExit={true} >
                    <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                        <FormLabel className={'mr-3 mb-0 d-flex align-items-center  text-gray-light'}>
                            {_.get(listUser.find(user => user.value === watch('typeUser')), 'label', '')}
                        </FormLabel>
                        <ControllerInput
                            render={SelectInput}
                            name={'client_assigned_id'}
                            className={'w-100'}
                            list={listDistributor}
                            loading={false}
                            modeEdit={true}
                            classNameContainer={'w-75'}

                        />
                    </Grid>
                </Fade>
            </Grid>

                <Line />

                <Collapse in={watch("client_assigned_id")}>
                    <FailureForm rol_assigned={typeUser} id_assigned={watch("client_assigned_id")} />
                </Collapse>
            </Collapse>

            <Collapse in={typeTicket === 2 || typeTicket === 3} unmountOnExit={true}>

                <Line />
                <Grid xs={12} className={'my-4 pt-4 d-flex'} >

                    <Grid xs={10} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >

                        <FormLabel className={'mr-4 mb-3 d-flex   text-gray-light'}>
                            Notas:
                        </FormLabel>
                        <ControllerInput
                            render={TextArea}
                            name={'notas'}
                            className={'w-100'}
                            modeEdit={true}
                            customMenuComponent={MenuColor}
                            classNameContainer={'w-100'}
                            rows={10}

                        />
                    </Grid>
                </Grid>


            </Collapse>

            <Collapse in={typeTicket === 4} unmountOnExit={true} >
                <Grid xs={12} className={'my-4 d-flex '} >
                    <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                        <FormLabel className={'mr-3 mb-0 d-flex align-items-center  text-gray-light'}>
                            Enviar
                        </FormLabel>
                        <ControllerInput
                            render={SelectInput}
                            name={'typeUser'}
                            className={'w-100'}
                            list={listUser}
                            loading={false}
                            modeEdit={true}
                            classNameContainer={'w-75'}

                        />
                    </Grid>

                    <Fade in={typeUser === 1} unmountOnExit={true} >
                        <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                            <FormLabel className={'mr-3 mb-0 d-flex align-items-center  text-gray-light'}>
                                Distribuidor
                            </FormLabel>
                            <ControllerInput
                                render={SelectInput}
                                name={'distributor'}
                                className={'w-100'}
                                list={listDistributor}
                                loading={false}
                                modeEdit={true}
                                classNameContainer={'w-75'}

                            />
                        </Grid>
                    </Fade>
                </Grid>

                <Line />

                <Grid xs={12} className={'my-4 d-flex '} >
                    <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                        <FormLabel className={'mr-3 mb-0 d-flex align-items-center  text-gray-light'}>
                            Direccion Comercial
                        </FormLabel>
                        <ControllerInput
                            render={SelectInput}
                            name={'comercialAddress'}
                            className={'w-100'}
                            list={listDirrectionComercials}
                            loading={false}
                            modeEdit={true}
                            classNameContainer={'w-50'}
                        />
                    </Grid>
                    <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                        <FormLabel className={'mr-3 mb-0 d-flex align-items-center  text-gray-light'}>
                            Ubicación
                        </FormLabel>
                        <ControllerInput
                            render={SelectInput}
                            name={'ubication'}
                            className={'w-100'}
                            list={listUbication}
                            loading={false}
                            modeEdit={true}
                            classNameContainer={'w-75'}
                        />
                    </Grid>

                </Grid>

                <Grid xs={12} className={'my-4 d-flex '} >
                    <Grid xs={2}>
                        Impresora:
                    </Grid>
                    <Grid xs={2}>
                        <FormLabel className={' d-flex justify-content-center '}>
                            Marca
                        </FormLabel>
                        <FormLabel className={' d-flex justify-content-center text-gray-light '}>
                            Epson
                        </FormLabel>
                    </Grid>

                    <Grid xs={2}>
                        <FormLabel className={' d-flex  justify-content-center'}>
                            Modelo
                        </FormLabel>
                        <FormLabel className={' d-flex justify-content-center text-gray-light'}>
                            EP-1234
                        </FormLabel>

                    </Grid>
                    <Grid xs={2}>
                        <FormLabel className={' d-flex justify-content-center'}>
                            version
                        </FormLabel>
                        <FormLabel className={' d-flex justify-content-center text-gray-light'}>
                            SeriesB
                        </FormLabel>

                    </Grid>
                    <Grid xs={2}>
                        <FormLabel className={' d-flex justify-content-center'}>
                            N° de serie
                        </FormLabel>
                        <FormLabel className={' d-flex justify-content-center text-gray-light'}>
                            OAE012445
                        </FormLabel>

                    </Grid>
                    <Grid xs={2}>
                        <FormLabel className={' d-flex justify-content-center'}>
                            Gama de maquina
                        </FormLabel>
                        <FormLabel className={' d-flex justify-content-center text-gray-light'}>
                            Baja
                        </FormLabel>

                    </Grid>
                </Grid>


                <Grid xs={12} className={'my-4 d-flex '} >

                    <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                        <FormLabel className={'mr-3 mb-0 d-flex align-items-center  text-gray-light'}>
                            Color Faltante
                        </FormLabel>
                        <ControllerInput
                            render={SelectInput}
                            name={'color'}
                            className={'w-100'}
                            list={listColor}
                            loading={false}
                            modeEdit={true}
                            customMenuComponent={MenuColor}
                            classNameContainer={'w-75'}
                        />
                    </Grid>

                </Grid>
                <Line />

                <Grid xs={12} className={'my-4 d-flex '} >

                    <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                        <FormLabel className={'mr-3 mb-0 d-flex align-items-start   text-gray-light'}>
                            Notas
                        </FormLabel>
                        <ControllerInput
                            render={TextArea}
                            name={'notas'}
                            className={'w-100'}
                            modeEdit={true}
                            customMenuComponent={MenuColor}
                            classNameContainer={'w-100'}
                        />
                    </Grid>

                </Grid>
            </Collapse>


            <Collapse in={typeTicket === 5} unmountOnExit={true}>
                <Grid xs={12} className={'my-4 d-flex '} >
                    <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                        <FormLabel className={'mr-3 mb-0 d-flex align-items-center  text-gray-light'}>
                            Enviar a
                        </FormLabel>
                        <ControllerInput
                            render={SelectInput}
                            name={'typeUser'}
                            className={'w-100'}
                            list={listUser}
                            loading={false}
                            modeEdit={true}
                            classNameContainer={'w-75'}
                        />
                    </Grid>
                    <Fade in={watch('typeUser')} unmountOnExit={true} >
                        <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                            <FormLabel className={'mr-3 mb-0 d-flex align-items-center  text-gray-light'}>
                                {_.get(listUser.find(user => user.value === watch('typeUser')), 'label', '')}
                            </FormLabel>
                            <ControllerInput
                                render={SelectInput}
                                name={'client_assigned_id'}
                                className={'w-100'}
                                list={listDistributor}
                                loading={false}
                                modeEdit={true}
                                classNameContainer={'w-75'}

                            />
                        </Grid>
                    </Fade>
                </Grid>
                <Line />

                <Grid xs={12} className={'my-4 d-flex '} >
                    <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                        <FormLabel className={'mr-3 mb-0 d-flex align-items-center  text-gray-light'}>
                            Numero De Factura
                        </FormLabel>
                        <ControllerInput
                            render={SelectInput}
                            name={'numberInvoice'}
                            className={'w-100'}
                            list={listInvoices}
                            loading={false}
                            modeEdit={true}
                            classNameContainer={'w-75'}
                        />
                    </Grid>
                </Grid>

                <Grid xs={12} className={'my-4 d-flex '} >

                    <Grid xs={2}>
                        Factura:
                    </Grid>
                    <Grid xs={3}>
                        <FormLabel className={' d-flex justify-content-center '}>
                            Fecha
                        </FormLabel>
                        <FormLabel className={' d-flex justify-content-center text-gray-light '}>
                            17/08/91
                        </FormLabel>
                    </Grid>

                    <Grid xs={3}>
                        <FormLabel className={' d-flex  justify-content-center'}>
                            Ubicación
                        </FormLabel>
                        <FormLabel className={' d-flex justify-content-center text-gray-light'}>
                            Tesoreria
                        </FormLabel>

                    </Grid>
                    <Grid xs={3}>
                        <FormLabel className={' d-flex justify-content-center'}>
                            Importe
                        </FormLabel>
                        <FormLabel className={' d-flex justify-content-center text-gray-light'}>
                            700
                        </FormLabel>

                    </Grid>

                </Grid>
                <Line />


                <Grid xs={12} className={'my-4 d-flex '} >

                    <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                        <FormLabel className={'mr-3 mb-0 d-flex align-items-start   text-gray-light'}>
                            Notas
                        </FormLabel>
                        <ControllerInput
                            render={TextArea}
                            name={'notas'}
                            className={'w-100'}
                            modeEdit={true}
                            customMenuComponent={MenuColor}
                            classNameContainer={'w-100'}
                        />
                    </Grid>

                </Grid>

            </Collapse>



        </div >
    )
}

export default ConditionalFormTicket


const MenuColor = ({ list, selectValue }) => {

    return (
        <>
            {list.map(option => {
                return (
                    <div key={JSON.stringify(option)} className={'listItemColor px-5 py-2'} onClick={() => selectValue(option)}>
                        <label>{option.label}</label>
                        <span style={{ background: option?.value?.codigo_hex }} className={'iconColor'} />

                    </div>)
            })}
        </>)

}