import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import '../View.scss';
import Table from '../../../../Table/Table';
import { HeaderColumn } from '../../../../Table/components';
import { CreateButton } from '../../../../Buttons';
import { Grid, Container, Paper, Card } from '@material-ui/core';
import SearchInput from '../../../../SearchInput';
import Select from '../../../../Select';
import useFilter from '../../../../../hooks/useFilter';
import { parseDate } from '../../../../../utilities/parse';
import { TextStatus } from '../../../../Text'
import { useHistory } from 'react-router-dom';
import { getClientsByDistributor } from '../../../../../redux/distributor/distributorReducer';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ServiceClients } from '../../../../../utilities/api/services';
import { useFetchService } from '../../../../../hooks/useFetch';
import Icon from '../../../../IconCustom';
import { Button, IconButton } from '@mui/material';



const data = [
    {
        "id": 1,
        "id_distribuidor": 1,
        "nombre_distribuidor": null,
        "id_factura": 1,
        "importe": "10.00",
        "fecha": "2021-01-12T21:31:48.000Z",
        "fecha_pago": null,
        "porcentage_IRPF": "0.00",
        "IRPF": "0.00",
        "total": "10.00"
    },
    {
        "id": 2,
        "id_distribuidor": 1,
        "nombre_distribuidor": null,
        "id_factura": 2,
        "importe": "12.00",
        "fecha": "2021-01-12T21:31:48.000Z",
        "fecha_pago": null,
        "porcentage_IRPF": "0.00",
        "IRPF": "0.00",
        "total": "10.00"
    }
]


const RequestTabView = ({ setDistributorView }) => {

    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [valuesOrder, setValuesOrder] = useState({})

    const params = useParams();


    const handleOnClikColumn = (field, value) => {
        setValuesOrder(({ [field]: value }))
    }


    const [nameFirtColumn, setNameFieldColumn] = useState('nombre');

    const downloadInvoice = (event, value) => {
        event.stopPropagation();
    }

    const history = useHistory();

    const goViewInvoice = (value) => {
        history.push(`/dashboard/payments/distributor/${value.id_distribuidor}/invoice/${value.id_factura}`)
    }

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'distrbutorTable',
                style: { display: 'none' },
                id: 'table',
                columns: [
                    {
                        Header: () => <HeaderColumn headerName={'Fecha De Solicitud'} onClick={(value) => handleOnClikColumn('fecha_ini', value)} />,
                        accessor: 'fecha',
                        Cell: ({ row }) => {
                            return <>{parseDate(row.values.fecha)}</>
                        }
                    },
                    //preguntar por la dirreccion comercial
                    {
                        Header: "Distribuidor",
                        Cell: ({ row }) => {
                            return <>{row.values.nombre_distribuidor || "Distribuidor A"}</>
                        }
                    },
                    {
                        Header: () => <HeaderColumn headerName={'Saldo a Favor'} onClick={(value) => handleOnClikColumn('gama', value)} />,
                        accessor: 'importe',
                        Cell: ({ row }) => {
                            return <>{row.values.importe}</>
                        }
                    },
                    {
                        Header: () => <HeaderColumn headerName={'Solicitud de Pago'} onClick={(value) => handleOnClikColumn('gama', value)} />,
                        accessor: 'total',
                        Cell: ({ row }) => {
                            return <>{row.values.total}</>
                        }
                    },
                    {
                        Header: "Factura Recibida",
                        accessor: 'id_distribuidor',
                        Cell: ({ row }) => {

                            return <IconButton onClick={(e) => downloadInvoice(e, row.values)}><Icon className={"text-primary"} type={'clara'} icon={'Document-download'} size={'1.25rem'} /></IconButton>
                        }

                    },
                    {
                        Header: "Realizar Pago",
                        accessor: 'id_factura',
                        Cell: ({ row }) => {
                            return <Button onClick={() => goViewInvoice(row.values)} variant="contained" size={"small"} className={"nextButton btn bg-primary "} >{"Pagar"}</Button>
                        }
                    }

                ],
            },
        ],
        [JSON.stringify(data)]
    )

    const handleRowClick = ({ row: { original } }) => {
        if (original.tipo_cliente === 1) {
            history.push(`/dashboard/client/${original.id_cliente}`)
        }


    }

    const handleOnChangeSelect = (value) => {
        setNameFieldColumn(value);
        setSearchValue('')
    }


    return (
        <div className={'containerView px-0 pt-3 '}>
            <Grid
                container
                direction="row"
                justify=""
                alignItems="center"
                className={'containerHeaderTable pl-5'}
            >

                <SearchInput searchValue={searchValue} onChageSearch={setSearchValue} placeholder={'search...'} />
                {/*<Select onChange={handleOnChangeSelect} options={[{ label: "Nombre Fiscal", value: "nombre" }, { label: "Nombre Comercial", value: "nombre_comercial" }]} />*/}

            </Grid>
            <Table
                columns={columns}
                data={data}
                className={'containerTableDistributor'}
                initialState={{ rowState: { onClick: handleRowClick } }} orderByValues={valuesOrder} />

        </div>

    );
};

export default RequestTabView;
