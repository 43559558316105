import React, { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import { AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ButtonAdd } from '../../../../../../Buttons';
import FormUbication from './FormUbication'
import { ServiceClients } from '../../../../../../../utilities/api/services';
import { useParams } from 'react-router-dom';
import _ from 'lodash'
import { useFetchService } from '../../../../../../../hooks/useFetch';

const Ubication = ({ idAddressCommercial }) => {

    const [valuesUbications, setValuesUbications] = useState([]);

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const [showNewUbication, setShowNewUbication] = useState(false);

    const { id: id_cliente } = useParams();


    const handleCreatedUbication = async (values) => {

        let valuesForm = _.pick(values, ['nombre_ubi', 'cuenta', 'notas', 'rate', 'printer', 'ip_impresora', 'sn', 'fecha_ini', 'forma_pago_id', 'meses_renovacion']);

        const ubication = {
            ...valuesForm,
            tarifa_id: valuesForm.rate.id_tarifa,
            abono_mantenimiento: valuesForm.rate.precio_mantenimiento,
            id_impresora: valuesForm.printer.id_impresora,
            ip: valuesForm.ip_impresora,
            id_distribuidor: 0,
            id_gama: valuesForm.printer.id_gama
        }

        await ServiceClients.createUbication({ id_cliente, ubication });
    }

    const { response, refetch } = useFetchService(ServiceClients.getUbicationsByAddressComercial, idAddressCommercial, []);

    const mapPrinterOfUbication = (ubication) => {
        const printerValues = _.pick(ubication, ['marca', 'modelo', 'id_estado', 'id_impresora', 'id_gama', 'version_snmp', 'copiastotal', 'copiascolor', 'copiasbn', 'sn', 'fecha_inicio_impresora']);
        return { ...printerValues }
    }

    const mapContract = (ubication) => {
        const contractValues = _.pick(ubication, ['mantenimiento_equipo', 'meses_renovacion', 'fecha_ini', 'notas', 'forma_pago_id', 'cuenta', 'banco']);
        const rate = _.pick(ubication, ['id_tarifa', 'precio_blancoynegro', 'precio_color', 'precio_mantenimiento', 'consumo_minimo']);
        return { ...contractValues, rate };
    }

    const mapUbications = (ubications) => {
        return _.map(ubications, ubication => {
            const { nombre_ubi, ip_impresora, id_ubica_cli } = ubication;
            return {
                ubication: { nombre: nombre_ubi, ip_impresora, id_ubica_cli },
                printer: mapPrinterOfUbication(ubication),
                contract: mapContract(ubication)
            };
        })
    }

    useEffect(() => {

        if (response.length) {
            setValuesUbications(mapUbications(response));
        }

    }, [JSON.stringify(response)]);


    const [dataForm, setdataForm] = useState({});

    const [ubications, setUbications] = useState([]);

    return (
        <div className={'containerView px-0  '}>

            <ButtonAdd
                label={'Agregar nueva Ubicacion'}
                classNameText={'text-orange'}
                classNameIcon={'text-orange'}
                className={'align-self-end mr-3'}
                onClick={() => setShowNewUbication(true)} />
            <div className={'text-primary py-3 px-4 my-4'} style={{ background: "#DFEFF1" }}>
                Ubicacion
            </div>
            {
                <>
                    {showNewUbication && <FormUbication valuesContact={{}} onSubmit={handleCreatedUbication} />}
                </>
            }
            <>
                {!showNewUbication && valuesUbications.map(ubication => {
                    return (
                        <Accordion key={JSON.stringify(ubication)} expanded={ubication.ubication.id_ubica_cli === expanded} onChange={handleChange(ubication.ubication.id_ubica_cli)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"

                            >
                                <Typography >{ubication.ubication?.nombre}</Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                {expanded === ubication.ubication.id_ubica_cli && <FormUbication {...ubication} initialValues={ubication} onSubmit={value => setValuesUbications(pre => [...pre, value])} />}
                            </AccordionDetails>
                        </Accordion>

                    )
                })}

            </>

        </div >
    )
}

export default Ubication


