import React from 'react'
import { Button } from '@material-ui/core';
import IconCustom from '../../IconCustom';
import './ButtonAdd.scss'

const ButtonAdd = ({ label, children, className = '', classNameIcon = '', classNameText = '', ...props }) => {
    return (
        <button className={`buttonAddContainer ${className}`} type={'button'} {...props}>
            {children ? children :
                <div className={'container-text-button-edit'}>
                    <IconCustom type={"ecoColor"} icon={"ecoColor-add"} size={'1.25rem'} className={`text-primary ${classNameIcon}`} ></IconCustom>
                    <span className={`text-primary text-button-edit ${classNameText}`} >{label}</span>
                </div>}
        </button>
    )
}

export default ButtonAdd
