import ServerConnect, { methods } from '../ServerConnect';
import URI, { BASE_URL } from './uris';


export default class ServicesPrinters {

    static getRates = async () => await ServerConnect.fetchAxios(`${URI.RATES}/getdata`, methods.GET, {});

    static getRateIgic = async () => await ServerConnect.fetchAxios(`${URI.RATES}/igic`, methods.GET, {});

    static updateRate = async ({ id, rate }) => await ServerConnect.fetchAxios(`${URI.RATES}/UpdateTariff/${id}`, methods.PUT, { body: rate });

    static createdRate = async ( rate ) => await ServerConnect.fetchAxios(`${URI.RATES}/createTariff`, methods.POST, { body: rate });

    static deleteRate = async (id) => await ServerConnect.fetchAxios(`${URI.RATES}/deleteTarifa/${id}`, methods.DELETE, {});



}

