import * as Yup from 'yup'; 

export const initialValues = {
 nombre: '', 
 id_distribuidor: 0, 
 email: '', 
 pass: '', 
 passwordConfirm: '', 
 telefono: '', 
 movil: '', 
 observaciones: ' '
}

const emptyMsg = 'No puede estar vacío';

export const validationSchema = Yup.object({
    nombre: Yup.string().required(emptyMsg),
    id_distribuidor: Yup.string().required(emptyMsg),
    pass: Yup.string().required(emptyMsg),
    passwordConfirm: Yup.string().required(emptyMsg),
    email: Yup.string().email('Email inválido').required(emptyMsg),
    telefono: Yup.string().required(emptyMsg),
    movil: Yup.string().required(emptyMsg),
    observaciones: Yup.string().required(emptyMsg),
})