import React, { useState, useEffect } from 'react';
import Form from '../../../../../Form';
import { TextInput, ControllerInput, FormLabel, TextArea, SubmitButton } from '../../../../../Form/components';
import { ButtonEdit } from '../../../../../Buttons'
import { Button, Grid } from '@material-ui/core';
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import '../View.scss';
import { ServiceDistributors } from '../../../../../../utilities/api/services';
import { SuccessDialog } from '../../../../../Modal';
import { updateDataDistributor } from '../../../../../../redux/distributor/distributorReducer';

const validateSchema = Yup.object().shape({
 })


const ContactData = ({ data }) => {


    const dispatch = useDispatch()
    const [modeEdit, setModeEdit] = useState(false);
    const distributorData = useSelector(state => state.distributor.distributorData);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);

    const handleSubmit = async (values) => {
        await ServiceDistributors.updateContactDistributor(values, data.id_cliente);
        dispatch(updateDataDistributor(values));

        setOpenModalSuccess(true);
        setModeEdit(false)
    }


    useEffect(() => {
        //dispatch(getdistributorData(data.id_cliente))
    }, [])

    const refForm = React.useRef()


    const handleCancel = () => {
        refForm.current.reset(distributorData);
        setModeEdit(false)
    }


    return (
        <>{
            <Form
                initialValues={distributorData}
                schemaForm={validateSchema}
                onSubmit={handleSubmit}
                className={'containerView'}
                innerRef={refForm}

            >

                < >
                    {
                        !modeEdit && <ButtonEdit className={'align-self-end'} label={"Editar"} onClick={() => setModeEdit(true)} />
                    }
                </>
                <Grid spacing={1} style={{ display: "flex", flexDirection: "flex-row" }} >
                    <Grid item xs={12}  >
                        <Grid item xs={12} className={'mt-1'}  >
                            <FormLabel className="text-primary">
                                Persona de contacto

                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"nombre_contacto"}
                                modeEdit={modeEdit}
                                className={''}
                            />
                        </Grid >

                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Movil
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"movil"}
                                modeEdit={modeEdit}
                            />

                        </Grid >


                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Teléfono
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"telefono"}
                                modeEdit={modeEdit}
                            />


                        </Grid >

                    </Grid>

                    <Grid item xs={12}  >
                        <Grid item xs={12} className={'mt-1'} >
                            <FormLabel className="text-primary">
                                Página web
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"pagina_web"}
                                modeEdit={modeEdit}
                            />
                        </Grid >

                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Email
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"mail_contacto"}
                                modeEdit={modeEdit}
                            />

                        </Grid >




                    </Grid>

                    <Grid item xs={12} className={'mt-1'} >


                        <FormLabel className="text-primary">
                            Notas
                        </FormLabel>
                        <ControllerInput
                            render={TextArea}
                            name={"notas_contacto"}
                            modeEdit={modeEdit}
                            className={'w-75'}
                            type={'text'}
                            rows={"4"}
                        />



                    </Grid>


                </Grid>
                {
                    modeEdit &&
                    <div className="formButtons">
                        <Button variant="contained" onClick={handleCancel} className="prevButton btn">Cancelar</Button>
                        <SubmitButton  >Guardar Cambios</SubmitButton>
                    </div>

                }


            </Form >
        }
            <SuccessDialog isOpen={openModalSuccess} handleClose={() => setOpenModalSuccess(false)} text={"Cambios Guardados con Exito"} />

        </>
    )
}

export default ContactData
