import React from 'react'
import { FormLabel, Grid } from '@mui/material';
import { ControllerInput } from '../../../../Form/components'
import _ from 'lodash';
import Line from '../../../../Line';
import { useFormContext } from 'react-hook-form'

import { FieldHidden, TextArea, SelectInput } from '../../../../Form/components';
import SelectInputExample from '../../../../Form/components/SelectInput/SelectInputExample'
import { ServiceClients, ServiceTickets } from '../../../../../utilities/api/services';
import { useFetchService } from '../../../../../hooks/useFetch';

const FailureForm = ({ id_assigned, rol_assigned = 1 }) => {

    const [listDirrectionComercial, setListDirectionComercial] = React.useState([])

    React.useEffect(() => {

        const getDirections = async () => {
            const result = await ServiceTickets.getDirectionByClient({ id: id_assigned });
            setListDirectionComercial(result.map(dir => ({ value: dir.id_direcc_cli, label: dir.nombre_comercial })))
        }
        id_assigned && getDirections()
    }, [id_assigned]);

    const { watch } = useFormContext()

    const id_comercial_addres = watch('comercialAddress')



    const { response: dataComercialAddress } = useFetchService(ServiceClients.getUbicationsByAddressComercial, id_comercial_addres, [], false);

    const listUbications = React.useMemo(() => {
        return dataComercialAddress.map(com => ({ label: com.nombre_ubi, value: com }))
    }, [JSON.stringify(dataComercialAddress)]);

    const [dataPrinter, setDataPrinter] = React.useState({});

    const { response: typeErrors } = useFetchService(ServiceTickets.getTypeErrors, null, [])

    const listErrors = React.useMemo(() => {
        return typeErrors.map(com => ({ label: com.tipo_error, value: com.id }))
    }, [JSON.stringify(typeErrors)]);


    return (
        <>
            <Grid xs={12} className={'my-4 d-flex mt-5 '} >
                <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                    <FormLabel className={'mr-3 mb-0 d-flex align-items-center  text-gray-light'}>
                        Direccion Comercial
                    </FormLabel>
                    <ControllerInput
                        render={SelectInput}
                        name={'comercialAddress'}
                        className={'w-100'}
                        list={listDirrectionComercial}
                        loading={false}
                        modeEdit={true}
                        classNameContainer={'w-50'}
                    />
                </Grid>
                <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                    <FormLabel className={'mr-3 mb-0 d-flex align-items-center  text-gray-light'}>
                        Ubicación
                    </FormLabel>
                    <ControllerInput
                        render={SelectInput}
                        name={'ubication'}
                        className={'w-100'}
                        list={listUbications}
                        loading={false}
                        modeEdit={true}
                        classNameContainer={'w-75'}
                        onChange={(value) => {
                            setDataPrinter(value)
                        }}
                    />
                </Grid>

            </Grid>

            <Grid xs={12} className={'my-4 mt-5 mb-6 d-flex '} >
                <Grid xs={2}>
                    Impresora:
                </Grid>
                <Grid xs={2}>
                    <FormLabel className={' d-flex justify-content-center '}>
                        Marca
                    </FormLabel>
                    <FormLabel className={' d-flex justify-content-center text-gray-light '}>
                        {_.get(dataPrinter, 'modelo')}
                    </FormLabel>
                </Grid>

                <Grid xs={2}>
                    <FormLabel className={' d-flex  justify-content-center'}>
                        Modelo
                    </FormLabel>
                    <FormLabel className={' d-flex justify-content-center text-gray-light'}>
                        {_.get(dataPrinter, 'marca')}
                    </FormLabel>

                </Grid>
                <Grid xs={2}>
                    <FormLabel className={' d-flex justify-content-center'}>
                        version
                    </FormLabel>
                    <FormLabel className={' d-flex justify-content-center text-gray-light'}>
                        {_.get(dataPrinter, 'version_snmp')}
                    </FormLabel>

                </Grid>
                <Grid xs={2}>
                    <FormLabel className={' d-flex justify-content-center'}>
                        N° de serie
                    </FormLabel>
                    <FormLabel className={' d-flex justify-content-center text-gray-light'}>
                        {_.get(dataPrinter, 'sn')}
                    </FormLabel>

                </Grid>
                <Grid xs={2}>
                    <FormLabel className={' d-flex justify-content-center'}>
                        Gama de maquina
                    </FormLabel>
                    <FormLabel className={' d-flex justify-content-center text-gray-light'}>
                        {_.get(dataPrinter, 'nombre')}
                    </FormLabel>

                </Grid>
            </Grid>

            <Line />

            <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box mb-4  pr-3'} >
                <FormLabel className={'mr-3 mb-0 d-flex align-items-center  text-gray-light'}>
                    Tipo de averia
                </FormLabel>
                <ControllerInput
                    render={SelectInput}
                    name={'errorType'}
                    className={'w-100'}
                    list={listErrors}
                    loading={false}
                    modeEdit={true}
                    classNameContainer={'w-75'}
                />
            </Grid>
            <FieldHidden nameFields={'errorType'} customCondition={value => value === 5}>
                <Grid xs={8} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3'} >
                    <FormLabel className={'mr-3 w-content mb-0 d-flex   text-gray-light'}>
                        Notas
                    </FormLabel>
                    <ControllerInput
                        render={TextArea}
                        name={'notas'}
                        className={'w-100'}
                        modeEdit={true}
                        customMenuComponent={MenuColor}
                        classNameContainer={'w-100'}
                        rows={10}
                    />
                </Grid>
            </FieldHidden>

        </>
    )
}

export default FailureForm


const MenuColor = ({ list, selectValue }) => {

    return (
        <>
            {list.map(option => {
                return (
                    <div key={JSON.stringify(option)} className={'listItemColor px-5 py-2'} onClick={() => selectValue(option)}>
                        <label>{option.label}</label>
                        <span style={{ background: option?.value?.codigo_hex }} className={'iconColor'} />

                    </div>)
            })}
        </>)

}