import React, { useState, useMemo } from 'react';
import './DashHeader.scss';
import Logo from '../../../assets/logo/eco-full.png';
import { Button, Avatar, Popover, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { closeUserSession } from '../../../redux/user/userReducer';
import { useDispatch } from 'react-redux';
import IconCustom from '../../IconCustom'
import { useHistory } from "react-router-dom";
import Constants from '../../../Constants'




const DashHeader = () => {
    const dispatch = useDispatch();
    const router = useHistory()
    const logout = () => {
        dispatch(closeUserSession());
        router.push('/login')
    }

    const options = [
        {
            label: "Ajustes",
            icon: "Edit-Signatories",
            onClick: () => router.push('/dashboard/settings')
        },
        {
            label: "Cerrar Sesion",
            icon: "Logout",
            onClick: logout
        }

    ]


    return (
        <div className="dashHeaderContainer">
            <div className="logo">
                <img className="ecoLogo" src={Logo} alt="dashLogo" />
            </div>
            <DropdownAuth options={options} />


        </div>
    )
}

export default DashHeader;

interface OptionElement {
    label: string;
    icon: string;
    onClick(): void;

}

interface DropdownAuthProps {
    options: OptionElement[]
}

const DropdownAuth: React.FC<DropdownAuthProps> = ({ options }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setIsOpen(pre => !pre)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsOpen(false)
    };

    const user = JSON.parse(localStorage.getItem('user'));

    const avatar = useMemo(() => {
        return user?.nombre.split(' ').map(caracter => caracter[0]);
    }, [JSON.stringify(user)]);


    return (
        <>
            <Button className={'containerDropdownAuth'} onClick={handleClick}>
                <Avatar >{avatar}</Avatar>
                <div className={'containerTextNameAndRole'}>
                    <span className={'text-primary'}>{Constants.ROLES_LABEL[user.tipo_cliente]}</span>
                    <p className={'text-light'}>{user.nombre}</p>
                </div>
                <IconCustom className={'text-light'} isClara={true} icon={'Dropdown'} size={'1.25rem'} />

            </Button>

            <Popover
                open={isOpen}
                onClose={handleClose}
                anchorEl={anchorEl}
                className={'containerPoperMenuAuth'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List className={'containerListMenuAuth'} component="nav" aria-label="main mailbox folders">
                    {options.map(op => {
                        return (
                            <ListItem key={JSON.stringify(op)} onClick={op?.onClick} button>
                                {op?.icon &&
                                    <IconCustom type={'clara'} size={'1.25rem'} icon={op.icon} className={'text-primary'} />

                                }
                                <ListItemText primary={op.label} />
                            </ListItem>

                        )
                    })}

                </List>

            </Popover>
        </>
    )
}