import Swal from 'sweetalert2';

export const loginSuccessPopUp = () => {
    return Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Éxito',
        showConfirmButton: false,
        timer: 2500
      })
}

export const loginFailurePopUp = (message) => {
    return Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 2500
      })
}

export const PaymentSuccessPopUp = (message) => {
  return Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 2500
    })
}

export const PaymentFailurePopUp = (message) => {
  return Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: message,
      showConfirmButton: false,
      timer: 2500
    })
}