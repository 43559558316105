import React, { useMemo } from 'react';
import Form from '../../../../Form';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { nextStepTechCreate } from '../../../../../redux/technical/technicalReducer';
import { SubmitButton, TextInput, ControllerInput, FormLabel, SelectInput } from '../../../../Form/components';
import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useFetchService } from '../../../../../hooks/useFetch';
import { ServiceTechs } from '../../../../../utilities/api/services';
import _ from 'lodash'

const validationSchema = Yup.object().shape({
    nombre: Yup.string().required('Nombre Es Requerido'),
    id_distribuidor: Yup.string().required('El Distribuidor es Requerido').nullable(),
})

const Step1 = () => {

    const { data } = useSelector(state => state.tecnico.techCreate);
    const dispatch = useDispatch()

    const { response: distributors, loading } = useFetchService(ServiceTechs.getDistributors);


    const listDistributor = useMemo(() => {
        return _.map(distributors, dist => ({ label: dist.nombre, value: dist.id_cliente }));
    }, [loading,])

    const handleNextStep = (values) => {
        dispatch(nextStepTechCreate(values));
    }

    const router = useHistory()
    const handleCancelCreated = () => {
        router.goBack()
    }




    return (
        <Form initialValues={data} className={''} schemaForm={validationSchema} onSubmit={handleNextStep}>

            <Grid container className={'d-flex flex-column align-items-center my-3 '}>
                <Grid item xs={12} className={'w-50'}>
                    <FormLabel className="text-primary">
                        Nombre Del Tecnico *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={'nombre'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >

                <Grid item xs={12} className={'mt-4 w-50'}>
                    <FormLabel className="text-primary">
                        Seleccione un Distribuidor *
                    </FormLabel>
                    <ControllerInput
                        render={SelectInput}
                        name={'id_distribuidor'}
                        className={'w-100'}
                        list={listDistributor}
                        loading={loading}
                        modeEdit={true}
                        classNameMenu={'menuDistributorSelect'}
                    />
                </Grid >

            </Grid>

            <div className="formButtons">
                <Button variant="contained" onClick={handleCancelCreated} className="prevButton btn">Cancelar</Button>
                <SubmitButton labelButton={'Siguiente'}></SubmitButton>
            </div>


        </Form >
    )
}

export default Step1
