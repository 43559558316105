import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDataDistributor } from '../../../../redux/distributor/distributorReducer';
import './DistributorDetailOverview.scss';
import Tabs from '../../../Tabs/Tabs';
import TAB_VIEW from './Tab';
import { TabPanel } from '../../../Tabs/components';
import { DistributorData, ContactData, ContractPermissionsCommissionData, BalanceAndBanckAcount, ClientAndTech } from './View'
import { useParams, useHistory } from 'react-router-dom';


const DistributorDetailOverview = ({ setDistributorView }) => {


    const params = useParams();
    const history = useHistory()


    const { distributorData, loading } = useSelector(state =>
        ({ distributorData: state.distributor.distributorData, loading: state.distributor.loading }));
    const [actualTab, setActualTab] = useState(TAB_VIEW.DATA);
    const distributor = { id_cliente: params.id }

    const handlePathClick = () => {
        history.push('/dashboard/distributors')
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDataDistributor(params.id));
    }, [])



    return (
        <div className='distriDetailContainer'>
            <div className="distriDetailInner">
                <div className="title my-2">
                    <div className="titleText ">DISTRIBUIDOR:  {distributorData?.nombre}</div>
                </div>
                <div className='detailContent'>
                    <div className="actualPath">
                        <p className="previous" onClick={handlePathClick}>Distribuidores </p>
                        <p className="actual">{"< Distribuidor"}</p>
                    </div>
                    <Tabs actualTab={actualTab} setActualTab={setActualTab} texts={[<p>Datos del Distribuidor</p>, <p>Contacto</p>, <p>Contrato,<br /> Comisión y permisos</p>, <p>Saldo y<br /> Cuenta Bancaria</p>, <p>Clientes y<br /> Técnicos</p>]} />
                    {!loading &&
                        <>
                            <TabPanel index={0} selectTab={actualTab}  >
                                <DistributorData data={distributor} goToTable={handlePathClick} />
                            </TabPanel>

                            <TabPanel index={1} selectTab={actualTab}  >
                                <ContactData data={distributor} />
                            </TabPanel>

                            <TabPanel index={2} selectTab={actualTab}  >
                                <ContractPermissionsCommissionData data={distributor} />
                            </TabPanel>

                            <TabPanel index={3} selectTab={actualTab}  >
                                <BalanceAndBanckAcount data={distributor} />
                            </TabPanel>

                            <TabPanel index={4} selectTab={actualTab}  >
                                <ClientAndTech />
                            </TabPanel>

                        </>
                    }

                    {/*<DistributorDetail actualTab={actualTab} handlePathClick={handlePathClick} />*/}
                </div>
            </div>
        </div>
    )
}

export default DistributorDetailOverview;