import PrivateRoute from '../../PrivateRoute';
import { Home } from '../../../pages/DashboardPages';
import DashPayment from '../../../components/Dashboard/PaymentOverview/DashPayment';
import { DashboardLayout } from '../../../Layout/components'
import DashDistributor from '../../../components/Dashboard/DistributorOverview/DashDistributor/DashDistributor';
import DistributorDetailOverview from '../../../components/Dashboard/DistributorOverview/DistributorDetailOverview/DistributorDetailOverview';
import DistributorCreate from '../../../components/Dashboard/DistributorOverview/DistributorCreate';
import ClientCreate from '../../../components/Dashboard/ClientsOverview/ClientCreate';
import ClientDetailOverview from '../../../components/Dashboard/ClientsOverview/ClientDetailOverview/ClientDetailOverview';
import DashClients from '../../../components/Dashboard/ClientsOverview/DashClients/DashClients';
import PrinterCreate from '../../../components/Dashboard/PrinterOverview/PrinterCreate/PrinterCreate';
import TechnicalCreate from '../../../components/Dashboard/TechnicalOverview/TechnicalCreate';
import DashTechnical from '../../../components/Dashboard/TechnicalOverview/DashTechnical/DashTechnical';
import TechnicalDetailOverview from '../../../components/Dashboard/TechnicalOverview/TechnicalDetailOverview/TechnicalDetailOverview';
import Settings from '../../../components/Dashboard/DashHome/Settings';
import DashPrinter from '../../../components/Dashboard/PrinterOverview/DashPrinter/DashPrinter';
import PrinterDetailOverview from '../../../components/Dashboard/PrinterOverview/PrinterDetailOverview/PrinterDetailOverview';
import RateDetailOverview from '../../../components/Dashboard/RateOverview/RateDetailOverview/RateDetailOverview';
import CreateRate from '../../../components/Dashboard/RateOverview/RateDetailOverview/RateDetail/CreateRate';
import TicketCreate from '../../../components/Dashboard/TicketsOverview/TicketCreate';
import TicketView from '../../../components/Dashboard/TicketsOverview/TicketView';
import DashTicket from '../../../components/Dashboard/TicketsOverview/DashTicket/DashTicket';
import BillingOverview from '../../../components/Dashboard/BillingOverview/BillingOverview';
import DashChooseFlowView from '../../../components/Dashboard/PaymentsAndGestion/ChooseFlowView';
import TablePayment from '../../../components/Dashboard/PaymentsAndGestion/distributors/TablePayment';

import PaymentView from '../../../components/Dashboard/PaymentsAndGestion/PaymentView';
import InvoicesGestionView from '../../../components/Dashboard/PaymentsAndGestion/InvoicesGestion';
import BillingDetail from '../../../components/Dashboard/BillingOverview/BillingDetailOverview/BillingDetail/BillingDetail';
import BillingCreate from '../../../components/Dashboard/BillingOverview/BillingCreate/BillingCreate';




const DashboardRoutes = () => {

    const isLoggedIn = !!localStorage.getItem('user');

    return (
        <>
            <PrivateRoute
                layout={DashboardLayout}
                component={Home}
                path={'/dashboard'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={Settings}
                path={'/dashboard/settings'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={DashDistributor}
                path={'/dashboard/distributors'}
                isAuthenticated={isLoggedIn}
                exact
            />


            <PrivateRoute
                layout={DashboardLayout}
                component={DistributorDetailOverview}
                path={'/dashboard/distributor/:id'}
                isAuthenticated={isLoggedIn}
                exact
            />



            <PrivateRoute
                layout={DashboardLayout}
                component={DistributorCreate}
                path={'/dashboard/distributor-created'}
                isAuthenticated={isLoggedIn}
                exact
            />


            <PrivateRoute
                layout={DashboardLayout}
                component={ClientCreate}
                path={'/dashboard/client-create'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={DashClients}
                path={'/dashboard/clients'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={DashClients}
                path={'/dashboard/clients/successfull_adhesion'}
                isAuthenticated={isLoggedIn}
                exact
            /> 


            <PrivateRoute
                layout={DashboardLayout}
                component={ClientDetailOverview}
                path={'/dashboard/client/:id'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={DashTechnical}
                path={'/dashboard/techs'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={TechnicalDetailOverview}
                path={'/dashboard/tech/:id'}
                isAuthenticated={isLoggedIn}
                exact
            />


            <PrivateRoute
                layout={DashboardLayout}
                component={TechnicalCreate}
                path={'/dashboard/tech-create'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={PrinterCreate}
                path={'/dashboard/printer-create'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={DashPrinter}
                path={'/dashboard/printers'}
                isAuthenticated={isLoggedIn}
                exact
            />


            <PrivateRoute
                layout={DashboardLayout}
                component={PrinterDetailOverview}
                path={'/dashboard/printer/:id'}
                isAuthenticated={isLoggedIn}
                exact
            />


            <PrivateRoute
                layout={DashboardLayout}
                component={RateDetailOverview}
                path={'/dashboard/rates'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={CreateRate}
                path={'/dashboard/rate-created'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={TicketView}
                path={'/dashboard/ticket/:id'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={DashTicket}
                path={'/dashboard/tickets'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={TicketCreate}
                path={'/dashboard/ticket-created'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={BillingOverview}
                path={'/dashboard/billing'}
                isAuthenticated={isLoggedIn}
                exact
            />
            <PrivateRoute
                layout={DashboardLayout}
                component={BillingDetail}
                path={'/dashboard/billing/detail'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={BillingCreate}
                path={'/dashboard/billing/create'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={BillingCreate}
                path={'/dashboard/billing/create/:id'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={DashChooseFlowView}
                path={'/dashboard/payments&collections/choose-flow'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={TablePayment}
                path={'/dashboard/payments/distributor/table'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={PaymentView}
                path={'/dashboard/payments/distributor/:id_distribuidor/invoice/:id_invoice'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={InvoicesGestionView}
                path={'/dashboard/invoices/gestion'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={DashPayment}
                path={'/dashboard/pay'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={DashPayment}
                path={'/dashboard/pay/failedPayment'}
                isAuthenticated={isLoggedIn}
                exact
            />

            <PrivateRoute
                layout={DashboardLayout}
                component={DashPayment}
                path={'/dashboard/pay/successPayment'}
                isAuthenticated={isLoggedIn}
                exact
            />

        </>
    )
}


export default DashboardRoutes;