import ServerConnect, { methods } from '../ServerConnect';
import ServiceDitributors from './Distributors';
import URI, { BASE_URL } from './uris';


export default class ServicesUser {


    static getUser = async id => await ServerConnect.fetchAxios(`${URI.USER}/getDataUser/${id}`, methods.GET, { body: {} });

    static updateUserData = async ({ id, data }) => await ServerConnect.fetchAxios(`${URI.USER}/settings/${id}`, methods.PUT, { body: data });

    static validateEmailExisting = async (email) => await ServerConnect.fetchAxios(`${URI.USER}/validate`, methods.POST, { body: { email } });

    static getStaditicDashboard = async () => await ServerConnect.fetchAxios(`${URI.USER}/dashboard`, methods.GET, { body: {  } });

    static getPermissions = async (id) => await ServiceDitributors.getPermissionsDistributor(id);





}