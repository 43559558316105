
import React, { useState } from 'react'


export function useFetch(url, options,) {
    const [response, setResponse] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const res = await fetch(url, options);
                const json = await res.json();
                setResponse(json);
                setIsLoading(false)
            } catch (error) {
                setError(error);
            }
        };
        fetchData();
    }, []);
    return { response, error, isLoading };
};

export function useFetchService(service, variables, defaultResponse = null, inmediate = true) {
    console.log( "service:", service );
    console.log( "id:", variables );
    const [response, setResponse] = React.useState(defaultResponse);
    const [error, setError] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [mount, setMount] = useState(inmediate);

    const fetchData = async (value = undefined) => {
        setIsLoading(true);
        try {

            const body = value || variables;
            
            const data = await service(body);
            console.log("HOLA---data:", data);
            setResponse(data);
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    };


    React.useEffect(() => {
        mount && fetchData();
        setMount(true)
    }, [JSON.stringify(variables)]);

    return { response, error, loading: isLoading, refetch: fetchData };
};