import React from 'react';
import Form from '../../../../Form';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { nextStepDistributorCreate, prevStepDistributorCreate } from '../../../../../redux/distributor/distributorReducer';
import { SubmitButton, TextInput, ControllerInput, FormLabel } from '../../../../Form/components';
import { Button, Grid } from '@material-ui/core';

const validationSchema = Yup.object().shape({
    pais: Yup.string().required('Pais  es Requerido'),
    provincia: Yup.string().required('Provincia es Requerido'),
    poblacion: Yup.string().required('Poblacion es Requerido'),
    direccion_fiscal: Yup.string().required('Dirrecion es Requerido'),
    codpost: Yup.string().required('CP es Requerido'),
})

const Step3 = () => {

    const { data } = useSelector(state => state.distributor.distributorCreate);
    const dispatch = useDispatch()
    const handleNextStep = (values) => {
        dispatch(nextStepDistributorCreate(values));
    }

    const handlePrevStep = () => dispatch(prevStepDistributorCreate())

    return (
        <Form initialValues={data} className={''} schemaForm={validationSchema} onSubmit={handleNextStep}>

            <Grid container className={'d-flex flex-column align-items-center my-3 '}>
                <Grid item xs={12} spacing={2} className={' d-flex flex-row '}>
                    <Grid item xs={5} className={'mr-6 w-100'} >
                        <FormLabel className="text-primary">
                            Pais *
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            firstAutofocus={true}

                            name={'pais'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>

                    <Grid item xs={5}>
                        <FormLabel className="text-primary">
                            Provincia *
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'provincia'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>
                </Grid >

                <Grid item xs={12} className={'mt-4 d-flex flex-row'}>
                    <Grid item xs={5} className={'mr-6 w-100'} >
                        <FormLabel className="text-primary">
                            Poblacion *
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'poblacion'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>

                    <Grid item xs={5}>
                        <FormLabel className="text-primary">
                            Dirreccion *
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'direccion_fiscal'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>
                </Grid >



                <Grid item xs={12} className={'mt-4 '}>
                    <Grid item xs={12}>
                        <FormLabel className="text-primary">
                            CP *
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'codpost'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>

                </Grid >

            </Grid>

            <div className="formButtons">
                <Button variant="contained" onClick={handlePrevStep} className="prevButton btn">Volver</Button>
                <SubmitButton labelButton={'Siguiente'}></SubmitButton>
            </div>


        </Form>
    )
}

export default Step3;
