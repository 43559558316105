import { FormLabel, Grid } from '@material-ui/core'

import React, { useMemo } from 'react'
import { useFetchService } from '../../../../../../../../hooks/useFetch'
import { ServiceClients } from '../../../../../../../../utilities/api/services'
import Form from '../../../../../../../Form'
import { ControllerInput, DateInput, TextInput, SelectInput, SubmitSubForm } from '../../../../../../../Form/components'
import _ from 'lodash';
import * as  Yup from 'yup'

const defaultPrinter = {
    fecha_inicio_impresora: null,
    copiasbn: "",
    copiascolor: "",
    copiastotal: "",
    id_estado: null,
    id_gama: null,
    id_impresora: "",
    m_equipo: "0.00",
    marca: "",
    modelo: "",
    sn: "",
}


const validateSchema = Yup.object().shape({})

const Printer = ({ initialValues, onSubmit }) => {

    const modeEdit = true;

    const { response: listGamaServer, loading: loadingGama } = useFetchService(ServiceClients.getGamaMachine, null, []);

    const { response: seriesNumberServer, loading: loadingSeriesNumber, refetch } = useFetchService(ServiceClients.getSerieNumber, null, []);

    const listSerieNumber = useMemo(() => {
        initialValues.numeroSerieSelect && seriesNumberServer.push(initialValues.printer);
        return _.map(seriesNumberServer, sn => ({ value: sn, label: sn.sn }));
    }, [loadingSeriesNumber])


    const listGamaMachine = useMemo(() => {
        return _.map(listGamaServer, gm => ({ value: gm.id, label: gm.nombre }));
    }, [loadingGama])


    const { response: listStatesServer, loading: loadingState } = useFetchService(ServiceClients.getStates, null, []);

    const listStateMachine = useMemo(() => {
        return _.map(listStatesServer, state => ({ value: state.id_estado_imp, label: state.estado }));
    }, [loadingState])

    const refForm = React.useRef();

    const handleOnChangeSerie = async (value) => {

        if (value) {
            refForm.current.reset(value);
        } else {
            refForm.current.reset(defaultPrinter);
        }
    }

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            schemaForm={validateSchema}
            innerRef={refForm}

        >

            <Grid className={'pl-6'} spacing={1} style={{ display: "flex", flexDirection: "flex-row" }} >
                <Grid item xs={12}  >


                    <Grid item xs={8} className={'mt-0 d-flex flex-column'}>
                        <FormLabel className="text-primary mb-2">
                            Marca
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"marca"}
                            modeEdit={modeEdit}
                        />
                    </Grid >
                    <Grid item xs={8} className={'mt-4 d-flex flex-column '}>
                        <FormLabel className="text-primary mb-2">
                            Modelo
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"modelo"}
                            modeEdit={modeEdit}
                        />

                    </Grid >

                    <Grid item xs={8} className={'mt-4 d-flex flex-column '}>
                        <FormLabel className="text-primary mb-2">
                            N° de Copias
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"copiastotal"}
                            type={"text"}
                            readOnly={true}
                        />

                    </Grid >

                </Grid>

                <Grid item xs={12}  >

                    <Grid item xs={8} className={' d-flex flex-column'} key={'numeroSerieSelect'}>
                        <FormLabel className="text-primary mb-2">
                            N° de serie
                        </FormLabel>
                        <SelectInput
                            list={listSerieNumber}
                            modeEdit={true}
                            className={''}
                            classNameContainer={''}
                            value={refForm.current?.watch()}
                            onChange={handleOnChangeSerie}
                            functionCompare={(value, elementList) => value.id_impresora === elementList.id_impresora}
                        />
                    </Grid >



                    <Grid item xs={8} className={'mt-4 d-flex flex-column'}>
                        <FormLabel className="text-primary mb-2">
                            Gama de Maquina
                        </FormLabel>
                        <ControllerInput
                            render={SelectInput}
                            list={listGamaMachine}
                            name={"id_gama"}
                            className={''}
                            placeholder={modeEdit ? 'Select or Search' : ''}
                            classNameContainer={''}
                            modeEdit={modeEdit}
                            disabled={!modeEdit}
                        //               innerRef={refGama}
                        />

                    </Grid >

                    <Grid item xs={12} className={'mt-4'}>
                        <FormLabel className="text-primary">
                            N° de Copias a Color
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"copiascolor"}
                            type={"text"}
                            readOnly={true}
                        />

                    </Grid >


                </Grid >
                <Grid item xs={12}  >




                    <Grid item xs={8} className={'d-flex flex-column'}>
                        <FormLabel className="text-primary mb-2">
                            Version
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"version_snmp"}
                            modeEdit={modeEdit}
                            className={''}
                        />

                    </Grid >



                    <Grid item xs={8} className={'mt-4 d-flex flex-column'}>
                        <FormLabel className="text-primary mb-2 ">
                            Estado
                        </FormLabel>
                        <ControllerInput
                            render={SelectInput}
                            list={listStateMachine}
                            name={"id_estado"}
                            modeEdit={modeEdit}
                            placeholder={modeEdit ? 'Select or Search' : ''}
                            className={'mt-0'}
                            classNameContainer={''}
                            disabled={!modeEdit}

                        />

                    </Grid >

                    <Grid item xs={12} className={'mt-4'}>
                        <FormLabel className="text-primary">
                            N° de Copias en B y N
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"copiasbn"}
                            type={"text"}
                            readOnly={true}
                        />

                    </Grid >

                </Grid>
            </Grid>

            {_.isEmpty(initialValues) &&
                <div className="formButtons mr-6 ">
                    <SubmitSubForm onClick={onSubmit}  >Crear Ubicacion</SubmitSubForm>
                </div>
            }

        </Form>
    )
}
export default Printer
