import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./DashPrinter.scss";
import Table from '../../../Table/Table';
import { HeaderColumn } from '../../../Table/components';
import { CreateButton } from '../../../Buttons';
import { Grid, Card } from '@material-ui/core';
import SearchInput from '../../../SearchInput';
import { TextStatus } from '../../../Text'
import { useHistory } from 'react-router-dom';
import { getPrinters } from '../../../../redux/printer/reducer';
import _ from 'lodash';

const DashPrinter = ({ setDistributorView }) => {


    const dispatch = useDispatch();
    const { printers, loading } = useSelector(({ printer: { printers, loading } }) => ({ printers, loading }));
    const [searchValue, setSearchValue] = useState('');
    const [valuesOrder, setValuesOrder] = useState({})

    useEffect(() => {
        dispatch(getPrinters(searchValue));
    }, [searchValue]);

    const handleOnClikColumn = (field, value) => {
        setValuesOrder(({ [field]: value }))
    }

    const [nameFirtColumn, setNameFieldColumn] = useState('nombre');

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'distrbutorTable',
                style: { display: 'none' },
                id: 'table',
                columns: [
                    {
                        Header: () => {
                            return (<HeaderColumn onClick={(value) => handleOnClikColumn("sn", value)} headerName={"Numero De Serie"} />)
                        },
                        accessor: "sn",

                    },
                    {
                        Header: () => <HeaderColumn headerName={'Marca'} onClick={(value) => handleOnClikColumn('marca', value)} />,
                        accessor: 'marca',
                        Cell: ({ row }) => {
                            return <>{row.values.marca}</>
                        }
                    },
                    {
                        Header: "Modelo",
                        accessor: 'modelo',
                        Cell: ({ row }) => {
                            return <span>{row.values.modelo || '-'}</span>
                        }
                    },
                    {
                        Header: "Gama De Maquina",
                        accessor: 'Gama',
                        Cell: ({ row }) => {
                            return <span>{row.values.Gama || '-'}</span>
                        }
                    },
                    {
                        Header: "Asignada a",
                        accessor: 'isAssigned',
                        Cell: ({ row }) => {
                            return <span>{row.original.id_ubica_cli ? "Asignado" : "No asignado"}</span>
                        }
                    },
                    {
                        Header: () => <HeaderColumn headerName={'Estado'} onClick={(value) => handleOnClikColumn('estado_cliente', value)} />,
                        accessor: 'estado',
                        Cell: ({ row }) => {
                            const { estado } = row.values;
                            return (
                                <TextStatus state={1} label={estado} />
                            )
                        }
                    }
                ],
            },
        ],
        [nameFirtColumn]
    )

    const history = useHistory()

    const onClickCreate = () => {
        history.push(`/dashboard/printer-create`)
    }

    const handleRowClick = ({ row: { original } }) => {
        history.push(`/dashboard/printer/${original.id_impresora}`)
    }

    return (
        <div className="DashDistributorContainer">
            <div className="distributorInner">
                <div className="title">IMPRESORAS</div>
                <div className={'containerTableDasboard'}>
                    <Card className={'card'}>
                        <Grid
                            container
                            direction="row"
                            justify="space-around"
                            alignItems="center"
                            className={'containerHeaderTable'}
                        >

                            <CreateButton onClick={onClickCreate} />
                            <SearchInput searchValue={searchValue} onChageSearch={setSearchValue} placeholder={'search...'} />
                            { /* <Select onChange={handleOnChangeSelect} options={[{ label: "Nombre Fiscal", value: "nombre" }, { label: "Nombre Comercial", value: "nombre_comercial" }]} /> */}

                        </Grid>
                        <Table columns={columns} data={printers} initialState={{ rowState: { onClick: handleRowClick } }} orderByValues={valuesOrder} />
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DashPrinter;
