import React, { useState } from 'react';
import ascIcon from "../../../../assets/dashboard/distributor/asc.svg";
import descIcon from "../../../../assets/dashboard/distributor/desc.svg";
import './HeaderColumn.scss';


const HeaderColumn = ({ onClick , headerName, showArrow = true, className="" }) => {

    const [dirrectionArrowTop, setDirrectionArrowTop] = useState(false);

    const handleChangeDirectionArrow = () => {
        setDirrectionArrowTop(pre => !pre);
        onClick(dirrectionArrowTop ? "asc" : "desc")
    }

    return (
        <div onClick={handleChangeDirectionArrow} className={` HeaderColumn  ${className}`}>
            {headerName}
            {showArrow && <img src={dirrectionArrowTop ? ascIcon : descIcon} alt="sortIcon" className="sortIcon" />}
        </div>
    )
}

export default HeaderColumn
