import ServerConnect, { methods } from "../ServerConnect";
import URI, { BASE_URL } from "./uris";

export default class ServiceDitributors {
  static createDistributor = async (distributor) =>
    await ServerConnect.fetchAxios(
      `${URI.DISTRIBUTORS}/createDistributor`,
      methods.POST,
      { body: distributor }
    );

  static getDistributors = async ({ search = "", type = "nombre" }) =>
    await ServerConnect.fetchAxios(URI.DISTRIBUTORS, methods.GET, {
      params: { word: search, type },
    });

  static updateDataDistributor = async (dataDistributor, id) =>
    await ServerConnect.fetchAxios(
      `${URI.DISTRIBUTORS}/distUpdateDatos/${id}`,
      methods.POST,
      { body: dataDistributor }
    );

  static updateDistributor = async (dataDistributor, id) =>
    await ServerConnect.fetchAxios(
      `${URI.DISTRIBUTORS}/updateAllDataDistributor/${id}`,
      methods.PUT,
      { body: dataDistributor }
    );

  static updateContactDistributor = async (contactDistributor, id) =>
    await ServerConnect.fetchAxios(
      `${URI.DISTRIBUTORS}/updateContactDist/${id}`,
      methods.PUT,
      { body: contactDistributor }
    );

  static updateContractPermissionsCommission = async (data, id) =>
    await ServerConnect.fetchAxios(
      `${URI.DISTRIBUTORS}/permits/${id}`,
      methods.PUT,
      { body: data }
    );

  static updateDataBank = async (data_bank, id) =>
    await ServerConnect.fetchAxios(
      `${URI.DISTRIBUTORS}/bank/${id}`,
      methods.PUT,
      { body: data_bank }
    );

  static deleteDistributor = async (id) =>
    await ServerConnect.fetchAxios(
      `${URI.DISTRIBUTORS}/dist/${id}`,
      methods.DELETE,
      { body: {} }
    );

  static enableStateDistributor = async (id) =>
    await ServerConnect.fetchAxios(
      `${BASE_URL}/permisos/enable/${id}`,
      methods.PUT,
      { body: {} }
    );

  static disableStateDistributor = async (id) =>
    await ServerConnect.fetchAxios(
      `${BASE_URL}/permisos/disable/${id}`,
      methods.PUT,
      { body: {} }
    );

  static getClientByDistributor = async ({ id, search }) =>
    await ServerConnect.fetchAxios(
      `${URI.DISTRIBUTORS}/client/${id}`,
      methods.POST,
      { body: { word: search } }
    );

  static getDistributorDetail = async (id) =>
    await ServerConnect.fetchAxios(`${URI.DISTRIBUTORS}/${id}`, methods.GET, {
      body: {},
    });

  static getPermissionsDistributor = async (id) =>
    await ServerConnect.fetchAxios(
      `${URI.DISTRIBUTORS}/permits/${id}`,
      methods.GET,
      { body: {} }
    );

  static getDataBanckDistributor = async (id) =>
    await ServerConnect.fetchAxios(
      `${URI.DISTRIBUTORS}/bank/${id}`,
      methods.GET,
      { body: {} }
    );

  static updateDataDistributors = async ({ id, dataDistributor }) =>
    await ServerConnect.fetchAxios(
      `${URI.DISTRIBUTORS}/updateAllDataDistributor/${id}`,
      methods.PUT,
      { body: { ...dataDistributor } }
    );

  static getDataDistributor = async (id) => {
    const {
      getDataBanckDistributor,
      getDistributorDetail,
      getPermissionsDistributor,
    } = ServiceDitributors;

    const [
      detailsDistributor,
      permissionsDistributor,
      acountsBank,
    ] = await Promise.all([
      getDistributorDetail(id),
      getPermissionsDistributor(id),
      getDataBanckDistributor(id),
    ]);

    return { ...detailsDistributor, ...permissionsDistributor, acountsBank };
  };
}
