const LABEL_STATUS = ['Suspendido', 'Activo', 'Eliminado'];

const ROLES_LABEL = {
    "TECNICO": "Tecnico",
    "DISTRIBUIDOR": "Distribuidor",
    "CLIENTE": "Cliente",
    "ADMIN": "Administrador"
}


export default {
    LABEL_STATUS,
    ROLES_LABEL
}