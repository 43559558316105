import { useState, useEffect } from 'react';
import { saveState, loadState } from '../utilities/storage';

const useSessionStorage = (key, defaultValue = null) => {
  const [state, setstate] = useState(() => {
    return loadState(key) || defaultValue;
  });

  useEffect(() => {
    if (defaultValue && !loadState(key)) {
        saveState(key, defaultValue);
    }
  }, []);

  const saveLocalStorage = (value) => {
    saveState(key, value);
    setstate(value);
  };

  return [state, saveLocalStorage];
};

export default useSessionStorage;
