import ServerConnect, { methods } from "../ServerConnect";
import URI /* ,{ BASE_URL }  */ from "./uris";

export default class ServicesFacturacion {
  static getFacturacion = async (search = "") =>
    await ServerConnect.fetchAxios(
      `${URI.FACTURACION}/clientsBills`,
      methods.GET,
      { body: { word: search } }
    );

  static getAllClients = async (search = "") =>
    await ServerConnect.fetchAxios(
      `${URI.FACTURACION}/all-clients`,
      methods.GET,
      { body: { word: search } }
    );

  static createBill = async (body) =>
    ServerConnect.fetchAxios(`${URI.FACTURACION}/createBill`, methods.POST, {
      body,
    });

  static getNextBillName = async () =>
    ServerConnect.fetchAxios(
      `${URI.FACTURACION}/nextBillName`,
      methods.GET,
      {}
    );

  static startBilling = async () => {
    console.log("dentro de envio de startbilling... ");  
    return await ServerConnect.fetchAxios(`${URI.FACTURACION}/startBilling`,
                    methods.GET,
                    {}
                  );
    };

  static sendBilling = async (bill) => {
    console.log("dentro de envio de SENDbilling... ");  
    return await ServerConnect.fetchAxios(`${URI.FACTURACION}/sendBilling`,
                    methods.POST,
                    { body: bill }
                  );
    };
  
  static editingBilling = async (data) => {
    console.log("dentro de envio de eDITINgBilling... ");  
    return await ServerConnect.fetchAxios(`${URI.FACTURACION}/changeDataBilling`,
                    methods.POST,
                    { body: data }
                  );
    };  
    
  static getStartBilling = async () =>{
    return await ServerConnect.fetchAxios( `${URI.FACTURACION}/getStarting`,
                    methods.GET,
                    {}
                  )
  };

  static getBilling = async () =>{
    return await ServerConnect.fetchAxios( `${URI.FACTURACION}/getBilling`,
                    methods.GET,
                    {}
                  )
  };

  // get ubications

  // static createFacturacion = async ({ id = 0, printer }) => await ServerConnect.fetchAxios(`${URI.PRINTERS}/createImpresora`, methods.POST, { body: printer });

  // static updateFacturacion = async ({ id = 0, id_printer, printer }) => await ServerConnect.fetchAxios(`${URI.PRINTERS}/updateImpresora/${id_printer}`, methods.PUT, { body: printer });

  // static getFacturacion = async (id_printer) => await ServerConnect.fetchAxios(`${URI.PRINTERS}/getDataOfOneImpresora/${id_printer}`, methods.GET, {});

  // static deleteFacturacion = async (id_printer) => await ServerConnect.fetchAxios(`${URI.PRINTERS}/deleteImpresora/${id_printer}`, methods.DELETE, {});
}
