import React from 'react';
import './TextState.scss';
import classNames from 'classnames';
import Constants from '../../../Constants';

const TextStatus = ({ label = '', state = 1 }) => {
    return (
        <div className={classNames('containerTextStatus', {
            'state-actived': state === 1,
            'state-eliminated': state === 2,
            'state-suspend': state === 0,
        })}>
            <span>{label || Constants.LABEL_STATUS[state]}</span>
        </div>
    )
}

export default TextStatus
