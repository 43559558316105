import axios from "axios";
import { ENDPOINT } from "../../utilities/constants";
import userTypes from "./types";
import { loginSuccessPopUp, loginFailurePopUp } from "../../utilities/popUp";
import jwt from 'jwt-decode'
import { addLoginAttempt, removeLoginAttempt } from '../../utilities/loginAttempt';
import { loadState, saveSessionState, saveState } from '../../utilities/storage'
import ServicesUser from "../../utilities/api/services/User";
import _ from 'lodash';
const initialState = {
  loading: false,
  actualUser: {},
  error: {},
  userEmail: "",
  openSuccessDialog: false,
  loggedIn: false,
  changePage: false,
  permissions: {}
};

const userStorage = _.get(loadState('store'), 'user', {})

// Reducer
export default function userReducer(state = { ...initialState, ...userStorage }, action) {
  switch (action.type) {
    case userTypes.LOGIN_START:
    case userTypes.SEND_EMAIL_START:
    case userTypes.RESET_PASSWORD_START:
      return {
        ...state,
        loading: true,
      };
    case userTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false
      };
    case userTypes.SET_ACTUAL_USER:
      return {
        ...state,
        actualUser: action.payload
      }
    case userTypes.USER_CONNECTED:
      return {
        ...state,
        loggedIn: true
      }
    case userTypes.CLOSE_USER_SESSION:
      localStorage.removeItem('user');
      return {
        ...state,
        actualUser: {},
        loggedIn: false
      }
    case userTypes.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        openSuccessDialog: true
      }
    case userTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        openSuccessDialog: true
      }
    case userTypes.ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case userTypes.KEEP_USER_EMAIL:
      return {
        ...state,
        userEmail: action.payload
      }
    case userTypes.CLOSE_DIALOG:
      return {
        ...state,
        openSuccessDialog: false
      }
    case userTypes.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload
      }
    default: {
      return state;
    }
  }
}

// Action Creators
export const loginStart = () => ({
  type: userTypes.LOGIN_START,
});

export const loginSuccess = (user) => ({
  type: userTypes.LOGIN_SUCCESS,
  payload: user,
});

export const setActualUser = (actualUser) => ({
  type: userTypes.SET_ACTUAL_USER,
  payload: actualUser
})

export const handleError = (error) => ({
  type: userTypes.ERROR,
  payload: error,
});

export const keepUserEmail = (email) => ({
  type: userTypes.KEEP_USER_EMAIL,
  payload: email
})

export const sendEmailStart = () => ({
  type: userTypes.RESET_PASSWORD_START
})

export const sendEmailSuccess = () => ({
  type: userTypes.SEND_EMAIL_SUCCESS
})

export const resetPasswordStart = () => ({
  type: userTypes.RESET_PASSWORD_START
})

export const resetPasswordSuccess = () => ({
  type: userTypes.RESET_PASSWORD_SUCCESS
})

export const closeDialog = () => ({
  type: userTypes.CLOSE_DIALOG
})

export const closeUserSession = () => ({
  type: userTypes.CLOSE_USER_SESSION
})

export const userConnected = () => ({
  type: userTypes.USER_CONNECTED
})

export const setPermission = (permissions) => ({
  type: userTypes.SET_PERMISSIONS,
  payload: permissions
})

// Side effects
export const userLogin = (userInput, goTo) => {

  return (dispatch) => {
    dispatch(loginStart());
    console.log(ENDPOINT + "/users/login", userInput);
    axios
      .post(ENDPOINT + "/users/login", userInput, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*"
        },
      //  withCredentials: true //for cookie
      })
      .then((res) => {
        dispatch(setUserSession(res.data));
        loginSuccessPopUp();
        saveSessionState('token', res.data.token);
        dispatch(loginSuccess(res.data));
        if (res.data.tipo_cliente === "DISTRIBUIDOR") {
          ServicesUser.getPermissions(res.data.id_cliente).then(res => {
            dispatch(setUserPermissions(res))
          })
        }
        goTo('/dashboard');
      })
      .catch((err) => {
        addLoginAttempt(userInput.email);
        console.log(err);
        loginFailurePopUp(err.response.data.error ? err.response.data.error : err.response.data.message);
        dispatch(handleError(err));
      });
  };
};

export const sendEmailToReset = (userInput) => {
  return (dispatch) => {
    dispatch(sendEmailStart());
    console.log("SENDMAILTOREST: ", userInput);
    axios.post(ENDPOINT + "/users/forgot", userInput, {
      headers: {
        "Content-Type": "application/json",
      }//,
      //withCredentials: true
    })
      .then((res) => {
        console.log("send-email:", res);
        dispatch(sendEmailSuccess());
        removeLoginAttempt(userInput.email);
      })
      .catch((err) => {
        dispatch(handleError(err))
        alert(err.response.data.message);
      })
  }
}

export const resetPassword = (userInput) => {
  return (dispatch) => {
    dispatch(resetPasswordStart());

    axios.post(ENDPOINT + "/users/reset", userInput, {
      headers: {
        "Content-Type": "application/json",
      }//,
      //withCredentials: true
    })
      .then((res) => {
        dispatch(resetPasswordSuccess());
      })
      .catch((err) => {
        alert(err.response.data.message);
        dispatch(handleError(err))
      })
  }
}

export const setUserSession = (userToken) => {
  return (dispatch) => {
    const { token, tipo_cliente } = userToken;
    const actualUser = jwt(token);
    localStorage.setItem('user', JSON.stringify({ ...actualUser, tipo_cliente }));

    saveState("store", { user: { actualUser: { ...actualUser, tipo_cliente } } });
    dispatch(setActualUser(actualUser));
  }
}

export const setUserPermissions = (permissions) => {
  return (dispatch) => {
    let mapPermissions = {};
    _.forEach(permissions, (value, key) => {
      const [module, action] = key.split("Can");
      _.set(mapPermissions, `${module}.${action.toLocaleLowerCase()}`, value);
    });

    const storeLocal = loadState('store');
    _.set(storeLocal, 'user.permissions', mapPermissions);
    saveState('store', storeLocal);


    dispatch(setPermission(mapPermissions))
  }
}

export const checkUserSession = () => {
  return (dispatch) => {
    if (localStorage.getItem('user')) {
      dispatch(userConnected());
    } else {
      dispatch(closeUserSession());
    }
  }
}

export const logoutUser = () => {
  return (dispatch) => {
    localStorage.removeItem('user');
    dispatch(closeUserSession());
  }
}

