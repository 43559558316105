import { combineReducers } from 'redux';
import userReducer from './user/userReducer';
import distributorReducer from './distributor/distributorReducer'
import clientReducer from './client/clientReducer'
import technicalReducer from './technical/technicalReducer';
import printerReducer from './printer/reducer';
import facturaReducer from './facturacion/reducer';
import cobrospagosReducer from './cobrospagos/paymentReducer';


const rootReducer = combineReducers({
    user: userReducer,
    distributor: distributorReducer,
    client: clientReducer,
    tecnico: technicalReducer,
    printer: printerReducer,
    factura: facturaReducer,
    cobrospagos: cobrospagosReducer,
})

export default rootReducer;