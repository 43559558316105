import React, { useState, useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import Spinner from '../../../Spinner/Spinner';
import { 
    payByRedsys, 
    sendPayRedsys, 
    getPaymentOfClient, 
    getStateSubscription, 
    initSubscription,
    manageRedirectionSubs } from '../../../../redux/cobrospagos/paymentReducer';
import { Card } from '@material-ui/core';
import { setTimeout } from 'timers';
import { PaymentFailurePopUp, PaymentSuccessPopUp } from '../../../../utilities/popUp';
import { loadSessionState } from "../../../../utilities/storage";
import axios from 'axios';
import { Button } from '@mui/material';


const FormPayment = () =>{
    
    const invoicePayment = useSelector( state => state.cobrospagos.paymentData );
    //const stateSubscription = useSelector( state => state.cobrospagos.stateSubscriptionPayment );
    
    const invoicePaymentLoading = useSelector( state => state.cobrospagos.loading );

    //const payRedsys = useSelector( state => state.cobrospagos.paymentRedsys );
    const [urlForm, setUrlForm] = useState(''); 
    const merchantOrder = useSelector( state => state.cobrospagos.paymentData.factura );
    const amount = useSelector( state => state.cobrospagos.paymentData.importe );
    const issuance = useSelector( state => state.cobrospagos.paymentData.emision );
    const expiration = useSelector( state => state.cobrospagos.paymentData.vencimiento );
    const rejection = useSelector( state => state.cobrospagos.paymentData.rechazo );
    const nameClient = useSelector( state => state.cobrospagos.paymentData.nombre );
    const nameMerchant = useSelector( state => state.cobrospagos.paymentData.nombre_comercio );
    const adressMerchant = useSelector( state => state.cobrospagos.paymentData.direccion_fiscal );

    const dispatch = useDispatch();

    useEffect( () => {
        dispatch( getPaymentOfClient() );
        console.log( invoicePayment );

        document.addEventListener('storeId', e => {

            const token = e.detail.token;
            const _amount = e.detail._amount;
            const _merchantOrder = e.detail._merchantOrder;
            const errCode = e.detail.errCode;

            if( token != undefined && token != '' && token != '-1' ){
                console.log("storeID-{ AMOUNT / MERCHANT_ORDER }", _amount, _merchantOrder);
                handlePayment(token, _amount, _merchantOrder);
            }
            else if( errCode != undefined && errCode != '' && errCode != '-1' ){
                handleError(errCode);
            }
        })
        
        const urlString = window.location.href;

        if ( urlString.includes("successPayment") )
        {
            handleSuccessfulPay();
        }
        if ( urlString.includes("failedPayment") )
        {
            handleFailedfulPay();
        }
        /*    
            const urlString = window.location.href;
            if ( urlString.includes("subscription_success") )
            {
                completeSubscription();
            }else{
                dispatch( getStateSubscription() );
                console.log('en page stateSubs: ' + stateSubscription);    
            }

            console.log("page-invoicePayment:", invoicePayment);

            
        */    
    }, []);

    const handleSuccessfulPay = async () =>{
        const url = "https://api.ecocolor.es/cobrospagos/processResponseRedsys";
        const search = window.location.search;
        const urlParams = new URLSearchParams(search);
        const Ds_SignatureVersion = urlParams.get('Ds_SignatureVersion');
        const Ds_MerchantParameters = urlParams.get('Ds_MerchantParameters');
        const Ds_Signature = urlParams.get('Ds_Signature');
        const data = {
            "Ds_SignatureVersion": Ds_SignatureVersion,
            "Ds_MerchantParameters": Ds_MerchantParameters,
            "Ds_Signature": Ds_Signature
        }
        const token = loadSessionState("token");
        console.log("data SUCCESS:", data);
        const response = await axios.post(url, { data, status: "successful" }, {   
            headers: 
            {
                Accept: "*",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*"
            } 
        });
        console.log("res,redirect,success", response);
        dispatch( getPaymentOfClient() );
        PaymentSuccessPopUp("El pago se ha realizado con éxito");
    }

    const handleFailedfulPay = async () =>{
        const url = "https://api.ecocolor.es/cobrospagos/processResponseRedsys";
        const search = window.location.search;
        const urlParams = new URLSearchParams(search);
        const Ds_SignatureVersion = urlParams.get('Ds_SignatureVersion');
        const Ds_MerchantParameters = urlParams.get('Ds_MerchantParameters');
        const Ds_Signature = urlParams.get('Ds_Signature');
        const data = {
            "Ds_SignatureVersion": Ds_SignatureVersion,
            "Ds_MerchantParameters": Ds_MerchantParameters,
            "Ds_Signature": Ds_Signature
        }
        console.log("data FAILED:", data);
        const token = loadSessionState("token");
        const response = await axios.post(url, { data, status: "failed" }, {   
            headers: 
            {
                Accept: "*",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*"
            } 
        });
        console.log("res,redirect,failed", response);
        PaymentFailurePopUp("El pago falló. Por favor inténtelo de nuevo más tarde.");
    } 

    
    useEffect( () => {
        if ( Object.keys(invoicePayment).length > 0 ){
            console.log('state de pago:', invoicePayment, amount, merchantOrder);
            
            setTimeout( () => {
                
                let event = new CustomEvent("getInSiteForm", { "detail":  
                                                {
                                                    "_merchantOrder": merchantOrder,
                                                    "_amount": amount,  
                                                } 
                                            });
                document.dispatchEvent(event);
                
            }, 2000);
        }
    }, [invoicePayment] )    

    /* const handleSubscription = () => {
        dispatch(initSubscription());
    } */

    /* const completeSubscription = () => {
        const search = window.location.search;
        
        if ( !search && (search == null || search == undefined) ){
            alert("problem search")
            //window.location.href = "https://ecocolor.es/dashboard/pay";
            return;
        }
        
        const urlParams = new URLSearchParams(search);
        
        if ( !urlParams.get('redirect_flow_id')  && (urlParams.get('redirect_flow_id') == null ||  urlParams.get('redirect_flow_id') == undefined) ){
            window.location.href = "https://ecocolor.es/dashboard/pay";
            return;
        }
        
        const id_redirect = urlParams.get('redirect_flow_id');
        dispatch( manageRedirectionSubs( id_redirect ) );
        
        console.log('stateSubs:', stateSubscription);

        setTimeout( () => 
                    {
                        if ( !stateSubscription ){
                            alert("problem redirectId")
                            //window.location.href = "https://ecocolor.es/dashboard/pay";
                            return;
                        }

                        console.log("id_redirect:", urlParams.get('redirect_flow_id'));
                        PaymentSuccessPopUp("¡Se ha adherido con éxito!");
                    }
                    , 2000);
    } */

    const handlePayment = (idOper, _amount, _merchantOrder) => {
       
        console.log( "amount & merchantOrder: " + amount, merchantOrder );
        console.log( "_amount & _merchantOrder: " + _amount, _merchantOrder );
        const Ds_MerchantParameters = {
            "DS_MERCHANT_AMOUNT":_amount.trim(),
            "DS_MERCHANT_CURRENCY":"978",
            "DS_MERCHANT_EMV3DS": JSON.stringify({
                "threeDSInfo": "CardData"
              }),
            "DS_MERCHANT_MERCHANTCODE":"154112577", 
            "DS_MERCHANT_ORDER":_merchantOrder.trim(),
            "DS_MERCHANT_IDOPER":idOper,
            "DS_MERCHANT_TRANSACTIONTYPE":"0"
        } 
        console.log( 'DS_MERCHANT_PARAMETERS++: ', Ds_MerchantParameters );

        initPayForm(Ds_MerchantParameters);
    }

    const initPayForm = async (data) => {
        console.log("pago initPayForm: ", data);
        console.log("json initPayForm: ", JSON.stringify(data));
        //const url = "https://sis-t.redsys.es:25443/sis/realizarPago";
        //const url = "https://sis-t.redsys.es:25443/sis/rest/iniciaPeticionREST"; //"https://sis-t.redsys.es:25443/sis/iniciaPago";
        const uri = "https://api.ecocolor.es/cobrospagos/processPaymentByRedsys";
        const token = loadSessionState("token");
        
        try{
            const dataRedsys = await axios.post( uri, JSON.stringify(data), 
                {   
                    headers: 
                    {
                        Accept: "*",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*"
                    } 
                });
            if( dataRedsys ){
                console.log("w.countSendFormPay (prev): " + window.countSendFormPay);              
                    if ( window.countSendFormPay === 0 ) {
                        
                        console.log('REDSYS', dataRedsys);
                        console.log('redsys-data:', dataRedsys.data);
        
                    }
                window.countSendFormPay++;        
                console.log("w.countSendFormPay (post): " + window.countSendFormPay)
            }
        }catch(err){
            console.log('error[REDSYS]: ', err)
        }

    }

    const sendForm = data => {
        let url = "https://sis-t.redsys.es:25443/sis/realizarPago"; // "https://sis-t.redsys.es:25443/sis/rest/trataPeticionREST";
        //dispatch( sendPayRedsys( data ) );
        let uri = "https://api.ecocolor.es/cobrospagos/processPaymentByRedsys";
        console.log('uri:  ' + uri);
        const token = loadSessionState("token");
        axios.post(uri, JSON.stringify(data), 
                            {   
                                headers: 
                                {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${token}`,
                                    "Access-Control-Allow-Origin": "*"
                                } 
                            }
        )      
        .then( res => {

            console.log("dentro de then SENDDATA:", res);
            console.log("dentro de then SENDDATA-data:", res.data);
            /* console.log("url: " + url);
            console.log("res SERVER: ", res.data);
            console.log("res SERVER parameters: ", res.data['Ds_MerchantParameters']);
            let requestRedsys = res.data;
            let requestRedsys2 = JSON.stringify(requestRedsys);
            console.log("json redsys: ", requestRedsys2); */
        
            /* const form = document.querySelector('.formSendPayment');
            form.innerHTML = '';
            
            const fieldVersion = document.createElement("input");
            const fieldParameters = document.createElement("input");
            const fieldSignature = document.createElement("input");
            
            fieldVersion.setAttribute("type", "hidden");
            fieldParameters.setAttribute("type", "hidden");
            fieldSignature.setAttribute("type", "hidden");
            
            fieldVersion.setAttribute("name", "DS_SIGNATUREVERSION");
            fieldParameters.setAttribute("name", "DS_MERCHANTPARAMETERS");
            fieldSignature.setAttribute("name", "DS_SIGNATURE");


            fieldVersion.setAttribute("value", requestRedsys["DS_SIGNATUREVERSION"]);
            fieldParameters.setAttribute("value", requestRedsys["DS_MERCHANTPARAMETERS"]);
            fieldSignature.setAttribute("value", requestRedsys["DS_SIGNATURE"]);

            form.appendChild(fieldVersion);
            form.appendChild(fieldParameters);
            form.appendChild(fieldSignature);
            
            setUrlForm(url); 
            form.submit(); */
            //console.log(url);
            //axios.post( url, requestRedsys2 , 
            //{   
            //    headers: 
            //    {
            //        Accept: "application/json",
            //        "Content-Type": "application/x-www-form-urlencoded",//"multipart/form-data",//"application/json",
            //        "Access-Control-Allow-Origin": "*"
            //    }   // Authorization: `Bearer ${token}`,
                    //"Access-Control-Allow-Origin": "*"
                //      https://sis-t.redsys.es:25443/sis/rest/trataPeticionREST?
                //    Ds_SignatureVersion=HMAC_SHA256_V1
                //    &Ds_MerchantParameters=eyJEU19NRVJDSEFOVF9BTU9VTlQiOiIyNy4wMCIsIkRTX01FUkNIQU5UX09SREVSIjoiMjcuMDAiLCJEU19NRVJDSEFOVF9NRVJDSEFOVENPREUiOiIxMTAxIiwiRFNfTUVSQ0hBTlRfQ1VSUkVOQ1kiOiI5NzgiLCJEU19NRVJDSEFOVF9UUkFOU0FDVElPTlRZUEUiOiIwIiwiRFNfTUVSQ0hBTlRfVEVSTUlOQUwiOiIxIiwiRFNfTUVSQ0hBTlRfTUVSQ0hBTlRVUkwiOiJodHRwczovL2Vjb2NvbG9yLmVzLyIsIkRTX01FUkNIQU5UX1VSTE9LIjoiaHR0cHM6Ly9lY29jb2xvci5lcy9wYXlfc3VjY2VzcyIsIkRTX01FUkNIQU5UX1VSTEtPIjoiaHR0cHM6Ly9lY29jb2xvci5lcy9wYXlfZXJyb3IifQ==
                //    &Ds_Signature=3E1DelBIJo0oj3qT707hHiH6WpWb1JRbv8AMrz+M1O8=
                //} 
            //} //})
            //.then(res => console.log("res: " + res))
            //.catch(err => console.log("err: " + err));
            /* 
                let parameters = window.atob( requestRedsys['Ds_MerchantParameters']);
                console.log("ATOB:  " , parameters );
                parameters = JSON.parse(parameters);
                console.log("ATOB 2:  " , parameters );
                
                let parameters2 = {
                    DS_MERCHANT_AMOUNT: parameters.DS_MERCHANT_AMOUNT,
                    DS_MERCHANT_ORDER: parameters.DS_MERCHANT_ORDER,
                    DS_MERCHANT_MERCHANTCODE: parameters.DS_MERCHANT_MERCHANTCODE,
                    DS_MERCHANT_CURRENCY: parameters.DS_MERCHANT_CURRENCY,
                    DS_MERCHANT_TRANSACTIONTYPE: parameters.DS_MERCHANT_TRANSACTIONTYPE,
                    DS_MERCHANT_TERMINAL: parameters.DS_MERCHANT_TERMINAL
                }

                console.log("params  1:  " , parameters2 );
                parameters2 = JSON.stringify(parameters2);
                console.log("params  2:  " , parameters2 );
                console.log("btoa:  ", window.btoa(parameters2)); 
            */
            //requestRedsys['Ds_MerchantParameters'] = window.btoa(parameters2);
            /* const form = document.querySelector('.formSendPayment');
            for(let k in requestRedsys) {
                var field = document.createElement("input");
                field.setAttribute("type", "hidden");
                field.setAttribute("name", k);
                field.setAttribute("value", requestRedsys[k]);
                form.appendChild(field);
            }
            setUrlForm(url); 
            form.submit(); */
        })
        .catch( err => console.log( "err" + err) );
      
    }

    const handleError = (errCode) =>{
        console.log('errCode redsys', errCode);
        PaymentFailurePopUp('El pago falló. Inténtelo de nuevo más tarde.');
    }

    const styleCardForm = { marginTop: '2.5rem', height: '350px' };
    const styleContainerButton = { width: '100%', marginBottom: '1.5rem', marginTop: '1.5rem', display: 'flex', justifyContent: 'center', aligItems: 'center' };
    const styleDates = { fontSize: '0.8rem' }
    return( 
        <>
            {invoicePaymentLoading &&
                (   <Spinner isLoading={invoicePaymentLoading}></Spinner>    )
            }
            
            {
                /* !stateSubscription ? */
               /*  (<>
                    <button onClick={ handleSubscription }> Adherir a débito </button>
                </>) */
            

                /*  : */
                ((Object.keys(invoicePayment).length === 0) ?
                    <>
                        <div className="title">
                                No hay facturas a pagar.
                        </div>
                    </>
                :
                    <>
                        <div className="title">
                            FACTURA A PAGAR
                        </div>

                        <div className={"containerTableDasboard"}>
                            <Card className={'card'}>
                                <div className="itemForPayment">

                                        <p> 
                                            <span className="titleItem"> 
                                                Factura n°: 
                                            </span> 
                                            <span id='_merchantOrder'> 
                                                { merchantOrder } 
                                            </span> 
                                        </p>
                                        <p>   
                                            <span className="titleItem"> 
                                                Titular: 
                                            </span> 
                                            <span id='_merchantOrder'> 
                                                { nameMerchant ? nameMerchant : ' ---- '} / { nameClient ? nameClient : ' ---- '}
                                            </span> 
                                        </p>

                                        <p> 
                                            <span className="titleItem"> 
                                                Emisión / Vencimiento / Rechazo de pago bancario: 
                                            </span> <p></p> 
                                            <span style={ styleDates }> 
                                                { issuance } / { expiration } / { rejection ? rejection  : ' ---- '}
                                            </span> 
                                        </p> 
                                        <p> 
                                            <span className="titleItem">
                                                Importe: 
                                            </span> 
                                            <span> eu $ <span id='_amount'> { amount } </span> </span> 
                                        </p> 
                                    
                                </div>

                                {/* <div style={styleContainerButton} >
                                    <Button variant="contained" color="success" size="large" onClick={ () => handlePayment(amount, merchantOrder)}> 
                                        Pagar vía Redsys 
                                    </Button>
                                </div>  */}
                               
                                
                                <div className="divFormPayment">

                                    <div id="card-form" style={styleCardForm}></div>
                                    <input type="hidden" id="token" ></input>
                                    <input type="hidden" id="errorCode" ></input>
                                    
                                </div>

                            </Card>  

                            <form action={ urlForm } method="POST" className="formSendPayment">
                            </form>
                                    
                        </div>
                    </>
                )    
            }
            

        </>
    );
}    

export default FormPayment;