import ServerConnect, { methods } from '../ServerConnect';
import URI, { BASE_URL } from './uris';


export default class ServiceClients {


    static created = async ({ client }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/createClient`, methods.POST, { body: client });

    static getClients = async search => await ServerConnect.fetchAxios(`${URI.CLIENTS}/GetDataClients`, methods.POST, { body: { word: search } });

    static updateDataClient = async ({ id, client }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/${id}`, methods.PUT, { body: client });

    static updateDataClientContact = async ({ id, client }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/contact/${id}`, methods.PUT, { body: client });

    static getDataClient = async id => await ServerConnect.fetchAxios(`${URI.CLIENTS}/getDataClient/${id}`, methods.GET, {});

    static getAddressesCommercialByClient = async id => await ServerConnect.fetchAxios(`${URI.CLIENTS}/directionsByClient/${id}`, methods.GET, {});

    static getPrintersByClient = async ({ id, search }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/dataCliente/${id}`, methods.GET, {});

    static addAddressCommercial = async ({ id, data }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/addDirecc/${id}`, methods.POST, { body: data });

    static getRates = async clientType => await ServerConnect.fetchAxios(`${URI.CLIENTS}/tarifa/${clientType}`, methods.GET, {});

    static getRateData = async id => await ServerConnect.fetchAxios(`${URI.CLIENTS}/tarifa/${id}`, methods.GET, {});

    static getPaymentMethods = async () => await ServerConnect.fetchAxios(`${URI.CLIENTS}/getFormaPago`, methods.GET, {});

    static getTechsSchedules = async (id) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/getSchedule/${id}`, methods.GET, {});

    static getContactsByAddressComercial = async (id) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/contactDir/${id}`, methods.GET, {});

    static getUbicationsByAddressComercial = async (id) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/ubicationAndPrinterByDirection/${id}`, methods.GET, {});

    static updateTechSchedules = async ({ id, schedules }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/updateSchedule/${id}`, methods.PUT, { body: schedules });

    static createContactByAddressCommercial = async ({ id, contact }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/createContact/${id}`, methods.POST, { body: contact });

    static updateContactByAddressCommercial = async ({ id, contact }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/updateContact/${id}`, methods.PUT, { body: contact });

    static getGamaMachine = async () => await ServerConnect.fetchAxios(`${URI.CLIENTS}/getGama`, methods.GET, {});

    static getStates = async () => await ServerConnect.fetchAxios(`${URI.CLIENTS}/estados`, methods.GET, {});

    static getSerieNumber = async () => await ServerConnect.fetchAxios(`${URI.CLIENTS}/getSerieNumber`, methods.GET, {});

    static getPrinter = async (id) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/dataImp/${id}`, methods.GET, {});

    static deleteClient = async (id) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/deleteContact/${id}`, methods.DELETE, {});

    static createUbication = async ({ id = '', data = {} }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/createUbication/${id}`, methods.POST, { body: data });

    static createContract = async ({ id_cliente = '', id_distribuidor , data = {} }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/createContract/${id_cliente}/${id_distribuidor}`, methods.POST, { body: data });

    static createPrinter = async ({ printer }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/createImpresora`, methods.POST, { body: printer });

    static assignPrinterToUbication = async ({ ubicationId, values }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/assignPrinterToUbication/${ubicationId}`, methods.PATH, { body: values });


    static getAllDirectionComercial = async () => await ServerConnect.fetchAxios(`${URI.CLIENTS}/getAllDirections`, methods.GET, { body: {} });



}
