import React, {useState} from 'react';
import { isValid } from '../../utilities/isValid';
import BasicInput from '../BasicInput/BasicInput';
import CustomButton from '../CustomButton/CustomButton';
import { faLock } from "@fortawesome/free-solid-svg-icons"; 
import "./ChangePassword.scss";

const ChangePassword = () => {
    const [userInput, setUserInput] = useState({
        actualPassword: "",
        password: "",
        passwordConfirm: "",
      });
    
      //para mostrar mensajes de error en el input
      const [inputError, setInputError] = useState({
        actualPasswordError: false,
        actualPasswordErrorMessage: "",
        passwordError: false,
        passwordErrorMessage: "",
        passwordConfirmError: false,
        passwordConfirmErrorMessage: "",
      });
    
      const {
        passwordError,
        passwordErrorMessage,
        passwordConfirmError,
        passwordConfirmErrorMessage,
        actualPasswordError,
        actualPasswordErrorMessage
      } = inputError;
    
      const handleChange = (event) => {
        const { name, value } = event.target;
        setUserInput({ ...userInput, [name]: value });
      };
    
      const handleSubmit = () => {
        if (isValid(userInput, setInputError, inputError)) {
          
        }
      };

      const handleKeyPress = (target) => {
        if (target.charCode === 13 && isValid(userInput, setInputError, inputError)) {
          
        }
      }
    
  return (
    <div className="changeContainer">
      <div className="changeInner">
        <div className="title">
          <p>Cambio de contraseña</p>
        </div>
        <div className="changeForm">
          <div className="textInputs">
            <div>
              <BasicInput
                label={"Contraseña actual"}
                icon={faLock}
                type={"password"}
                name={"actualPassword"}
                errorFlag={actualPasswordError}
                value={userInput.actualPassword}
                onChange={handleChange}
                handleKeyPress={handleKeyPress}
              />
              <div className="errorMsg">{actualPasswordErrorMessage}</div>
            </div>
            <div>
              <BasicInput
                label={"Nueva Contraseña"}
                icon={faLock}
                type={"password"}
                name={"password"}
                errorFlag={passwordError}
                value={userInput.password}
                onChange={handleChange}
                handleKeyPress={handleKeyPress}
              />
              <div className="errorMsg">{passwordErrorMessage}</div>
            </div>
            <div>
              <BasicInput
                label={"Confirmar contraseña"}
                icon={faLock}
                type={"password"}
                name={"passwordConfirm"}
                errorFlag={passwordConfirmError}
                value={userInput.passwordConfirm}
                onChange={handleChange}
                handleKeyPress={handleKeyPress}
              />
              <div className="errorMsg">{passwordConfirmErrorMessage}</div>
            </div>
          </div>
        </div>
        <div>
          <CustomButton onClick={handleSubmit} text={"Enviar"} />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
