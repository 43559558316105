import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../View.scss';
import Table from '../../../../../Table/Table';
import { HeaderColumn } from '../../../../../Table/components';
import { CreateButton } from '../../../../../Buttons';
import { Grid, Container, Paper, Card } from '@material-ui/core';
import SearchInput from '../../../../../SearchInput';
import Select from '../../../../../Select';
import useFilter from '../../../../../../hooks/useFilter';
import { parseDate } from '../../../../../../utilities/parse';
import { TextStatus } from '../../../../../Text'
import { useHistory } from 'react-router-dom';
import { getClientsByDistributor } from '../../../../../../redux/distributor/distributorReducer';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ServiceClients } from '../../../../../../utilities/api/services';
import { useFetchService } from '../../../../../../hooks/useFetch';

const Printers = ({ setDistributorView }) => {

    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [valuesOrder, setValuesOrder] = useState({})

    const params = useParams();

    const { response: printers, loading } = useFetchService(ServiceClients.getPrintersByClient, { search: "", id: params.id }, [])

    const handleOnClikColumn = (field, value) => {
        setValuesOrder(({ [field]: value }))
    }


    const [nameFirtColumn, setNameFieldColumn] = useState('nombre');

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'distrbutorTable',
                style: { display: 'none' },
                id: 'table',
                columns: [
                    {
                        Header: () => {
                            return (<HeaderColumn headerName={'N° de Serie'} />)
                        },
                        accessor: "numero_serie",

                    },
                    {
                        Header: () => <HeaderColumn headerName={'Fecha De Alta'} onClick={(value) => handleOnClikColumn('fecha_ini', value)} />,
                        accessor: 'fecha_ini',
                        Cell: ({ row }) => {
                            return <>{parseDate(row.values.fecha_ini)}</>
                        }
                    },
                    //preguntar por la dirreccion comercial
                    {
                        Header: "Direccion Comercial",
                        accessor: 'nombre_comercial',
                    },
                    {
                        Header: () => <HeaderColumn headerName={'Gama De Maquina'} onClick={(value) => handleOnClikColumn('gama', value)} />,
                        accessor: 'gama',
                        Cell: ({ row }) => {
                            return <>{row.values.gama}</>
                        }
                    },
                    // pedir ubicaion
                    {
                        Header: () => <HeaderColumn headerName={'Ubicacion'} onClick={(value) => handleOnClikColumn('gama', value)} />,
                        accessor: 'id',
                        Cell: ({ row }) => {
                            return <>{row.values.gama}</>
                        }
                    },
                    {
                        Header: () => <HeaderColumn headerName={'Estado'} onClick={(value) => handleOnClikColumn('estado', value)} />,
                        accessor: 'estado',
                        Cell: ({ row }) => {

                            const { estado } = row.values;
                            return (
                                <TextStatus state={1} label={estado} />
                            )
                        }
                    }
                ],
            },
        ],
        [JSON.stringify(printers)]
    )

    const history = useHistory()

    const onClickCreate = () => {
        history.push(`/dashboard/printer-create`)
    }

    const handleRowClick = ({ row: { original } }) => {
        if (original.tipo_cliente === 1) {
            history.push(`/dashboard/client/${original.id_cliente}`)
        }


    }

    const handleOnChangeSelect = (value) => {
        setNameFieldColumn(value);
        setSearchValue('')
    }


    return (
        <div className={'containerView px-0 pt-3 '}>
            <Grid
                container
                direction="row"
                justify=""
                alignItems="center"
                className={'containerHeaderTable pl-5'}
            >

                <SearchInput  searchValue={searchValue} onChageSearch={setSearchValue} placeholder={'search...'} />
                {/*<Select onChange={handleOnChangeSelect} options={[{ label: "Nombre Fiscal", value: "nombre" }, { label: "Nombre Comercial", value: "nombre_comercial" }]} />*/}

            </Grid>
            {!loading && <Table
                columns={columns}
                data={printers}
                className={'containerTableDistributor'}
                initialState={{ rowState: { onClick: handleRowClick } }} orderByValues={valuesOrder} />}

        </div>

    );
};

export default Printers;
