import React, { useState, useMemo } from 'react';
import VIEW from '../PrinterView';
import { Button, Grid } from '@material-ui/core';
import './PrinterCreate.scss'
import editIcon from '../../../../assets/dashboard/distributor/edit.svg';
import deleteIcon from '../../../../assets/dashboard/distributor/delete.svg';
import Form from '../../../Form';
import * as Yup from 'yup';
import { FormLabel, ControllerInput, TextInput, SelectInput, DateInput, SubmitButton } from '../../../Form/components';
import _ from 'lodash'
import { useFetchService } from '../../../../hooks/useFetch';
import { ServiceClients, ServicePrinters } from '../../../../utilities/api/services';
import { useHistory } from 'react-router-dom';
import { ButtonEdit, ButtonRemove } from '../../../Buttons';

const validateSchema = Yup.object().shape({
    marca: Yup.string().required('El campo es Requerido'),
    sn: Yup.string().required('El campo es Requerido'),
    creation_date: Yup.string().required('El campo es Requerido'),
    modelo: Yup.string().required('El campo es Requerido'),
    version: Yup.string().required('El campo es Requerido'),
    estado: Yup.string().required('El campo es Requerido').nullable(),
    gama: Yup.string().required('El campo es Requerido').nullable()
})


const PrinterCreate = ({ setPrinterView }) => {


    const { response: listGamaServer, loading: loadingGama } = useFetchService(ServiceClients.getGamaMachine, null, []);

    const listGamaMachine = useMemo(() => {
        return _.map(listGamaServer, gm => ({ value: gm.id, label: gm.nombre }));
    }, [loadingGama])


    const { response: listStatesServer, loading: loadingState } = useFetchService(ServiceClients.getStates, null, []);

    const listStateMachine = useMemo(() => {
        return _.map(listStatesServer, state => ({ value: state.id_estado_imp, label: state.estado }));
    }, [loadingState])


    //const [user] = useLocalStorage('user');



    const handlePathClick = () => {
        setPrinterView(VIEW.HOME);
    }

    const history = useHistory()

    const handleOnSubmit = async (values) => {

        await ServicePrinters.createPrinter({ printer: values });

        history.push('/dashboard/printers');
    }

    return (

        <>

            <div className={'d-flex flex-column flex-align-center flex-justify-center'} >
                <div className="title text-center">
                    <h2 className={'text-primary'}>Crear impresoras</h2>
                </div>


                <div className="containerView rounded-lg mx-8 mt-5">
                    <Form initialValues={{}} schemaForm={validateSchema} className={'w-100 h-100'} onSubmit={handleOnSubmit}>



                        <Grid container className={'w-100 h-100 px-2 py-4'}>
                            <Grid xs={12} className={'d-flex flex-row my-4'}>
                                <Grid item xs={4}>
                                    <FormLabel>
                                        Marca
                                    </FormLabel>
                                    <ControllerInput
                                        render={TextInput}
                                        name={'marca'}
                                        modeEdit={true}
                                    />

                                </Grid>

                                <Grid item xs={4} >
                                    <FormLabel>
                                        N° de serie
                                    </FormLabel>
                                    <ControllerInput
                                        render={TextInput}
                                        name={'sn'}
                                        modeEdit={true}
                                    />

                                </Grid>

                            </Grid>
                            <Grid xs={12} className={'d-flex flex-row my-4'}>
                                <Grid item xs={4}>
                                    <FormLabel>
                                        Fecha De Creacion
                                    </FormLabel>
                                    <ControllerInput
                                        render={DateInput}
                                        name={'creation_date'}
                                        maxDate={new Date()}
                                        modeEdit={true}
                                    />

                                </Grid>

                                <Grid item xs={4} >
                                    <FormLabel>
                                        Modelo
                                    </FormLabel>
                                    <ControllerInput
                                        render={TextInput}
                                        name={'modelo'}
                                        modeEdit={true}
                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <FormLabel>
                                        Version
                                    </FormLabel>
                                    <ControllerInput
                                        render={TextInput}
                                        name={'version'}
                                        modeEdit={true}
                                    />

                                </Grid>
                            </Grid>

                            <Grid xs={12} spacing={2} className={'d-flex flex-row my-4'}>
                                <Grid item xs={4}>
                                    <FormLabel>
                                        Gama de Maquina
                                    </FormLabel>
                                    <ControllerInput
                                        render={SelectInput}
                                        name={'gama'}
                                        list={listGamaMachine}
                                        modeEdit={true}
                                        className={'w-auto'}
                                        classNameContainer={'w-max-contend'}

                                    />

                                </Grid>

                                <Grid item xs={4} >
                                    <FormLabel>
                                        Estado
                                    </FormLabel>
                                    <ControllerInput
                                        render={SelectInput}
                                        list={listStateMachine}
                                        name={'estado'}
                                        className={'w-auto'}
                                        classNameContainer={'w-max-contend'}

                                        modeEdit={true}

                                    />

                                </Grid>

                            </Grid>
                        </Grid>

                        <div className="formButtons">
                            <Button variant="contained" onClick={() => history.goBack()} className="prevButton btn">Cancelar</Button>
                            <SubmitButton labelButton={'Crear Impresora'}  ></SubmitButton>
                        </div>



                    </Form>

                </div>
            </div>
        </>
    )
}

export default PrinterCreate;