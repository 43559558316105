import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import _ from 'lodash';

interface ControllerInputProps {
    [key: string]: any;
}


const ControllerInput: React.FC<ControllerInputProps> = React.memo(
    ({
        name,
        render,
        onChange,
        defaultValue,
        resetFields,
        error,
        inputProps,
        className,
        innerRef,
        ...props
    }) => {

        const componentMountedRef = React.useRef(false);

        const setComponentMounted = value => {
            componentMountedRef.current = value
        };

        const {
            control,
            setError,
            formState: { errors },
            getValues,
            watch,
            trigger,
            clearErrors,
            setValue: setValueForm,
        } = useFormContext();
        const nameWatch = watch(name);

        useEffect(() => {
            onChange && componentMountedRef.current && onChange(nameWatch, setValueForm);
            setComponentMounted(true)
        }, [JSON.stringify(nameWatch)]);

        return (
            <React.Fragment>
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    render={({ field, fieldState, formState }) => {
                        return React.createElement(render, {
                            ...props,
                            ...field,
                            fieldState,
                            formState,
                            className,
                            name,
                            errors: _.get(errors, `${name}`),
                            ref: innerRef,
                            errorMessage: _.get(errors, `${name}.message`, error),
                            defaultValue: defaultValue,
                        });
                    }}
                />
            </React.Fragment>
        );
    }
);


export default ControllerInput;

