import React from 'react';
import Form from '../../../../Form';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { nextStepTechCreate, prevStepTechCreate } from '../../../../../redux/technical/technicalReducer';
import { SubmitButton, TextInput, ControllerInput, FormLabel, TextInputPassword } from '../../../../Form/components';
import { Button, Grid } from '@material-ui/core';
import ServicesUser from '../../../../../utilities/api/services/User';
import { useSnackbar } from 'notistack';

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email es Requerido').email(),
    pass: Yup.string().required('Contraseña Requerida'),
    passwordConfirm: Yup.string().required('Confirmacion de Contraseña Requerida ').test('isValidPassword', 'Contraseña Diferentes', (value, context) => {
        return context.parent.pass === value;
    }),
})

const Step2 = () => {

    const { data } = useSelector(state => state.tecnico.techCreate);
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();


    const handleNextStep = async (values) => {
        try {
            await ServicesUser.validateEmailExisting(values.email);
            dispatch(nextStepTechCreate(values));

        } catch (error) {
            enqueueSnackbar(error.response.data, { variant: "error" });
        }

    }

    const handlePrevStep = () => dispatch(prevStepTechCreate())

    return (
        <Form initialValues={data} className={''} schemaForm={validationSchema} onSubmit={handleNextStep}>

            <Grid container xs={12} direction={'column'} justify={'center'} alignItems={'center'} className={'  my-3 '}>
                <Grid item  className={'w-50'}>
                    <FormLabel className="text-primary">
                        Email *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={'email'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >

                <Grid item  className={'w-50 mt-4 '}>
                    <FormLabel className="text-primary">
                        Asignar una contraseña*
                    </FormLabel>
                    <ControllerInput
                        render={TextInputPassword}
                        name={'pass'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >

                <Grid item  className={' w-50 mt-4 '}>
                    <FormLabel className="text-primary">
                        Confirmar contraseña *
                    </FormLabel>
                    <ControllerInput
                        render={TextInputPassword}
                        name={'passwordConfirm'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >


            </Grid>

            <div className="formButtons">
                <Button variant="contained" onClick={handlePrevStep} className="prevButton btn">Volver</Button>
                <SubmitButton labelButton={'Siguiente'}></SubmitButton>
            </div>


        </Form>
    )
}

export default Step2;
