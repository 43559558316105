const clientTypes = {
    START_FETCH_CLIENTS: "START_FETCH_CLIENTS",
    GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
    FAILURE: "FAILURE",
    CREATE_CLIENTS_SUCCESS: "CREATE_CLIENTS_SUCCESS",
    CLOSE_DIALOG: "CLOSE_DIALOG",
    SET_OFF_REDIRECT: "SET_REDIRECT_OFF",
    GET_CLIENTS_BY_ID: "GET_CLIENTS_BY_ID",
    GET_CLIENT_DETAIL: "GET_CLIENT_DETAIL",
    GET_DATA_CLIENT: "GET_DATA_CLIENT",
    SELECT_CLIENT: "SELECT_CLIENT",
    DELETE_CLIENT_SUCCESS: "DELETE_CLIENT_SUCCESS",
    SUSPENDED_CLIENT: "SUSPENDED_CLIENT",
    ACTIVE_CLIENT: "ACTIVE_CLIENT",
    ADD_DIRECCION_COMERCIAL_SUCCESS: "ADD_DIRECCION_COMERCIAL_SUCCESS",
    GET_SELECT_DIRECCION: "GET_SELECT_DIRECCION",
    SET_SELECT_DIRECCION: "SET_SELECT_DIRECCION",
    GET_DATA_DIRECCION: "GET_DATA_DIRECCION",
    ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",
    GET_DATA_CONTACTS: "GET_DATA_CONTACTS",
    GET_DATA_CONTACT: "GET_DATA_CONTACT",
    DELETE_CONTACT_SUCCESS: "DELETE_CONTACT_SUCCESS",
    GET_NUMBER_IMPRESORA: "GET_NUMBER_IMPRESORA",
    GET_GAMA_IMPRESORA: "GET_GAMA_IMPRESORA",
    GET_ESTADO_IMPRESORA: "GET_ESTADO_IMPRESORA",
    GET_DATA_IMPRESORA: "GET_DATA_IMPRESORA",
    SELECT_NUMERO_SERIE: "SELECT_NUMERO_SERIE",
    GET_SELECT_TARIFA: "GET_SELECT_TARIFA",
    GET_TARIFA_DATA: "GET_TARIFA_DATA",
    GET_FORMAS_PAGO: "GET_FORMAS_PAGO",
    GET_UBICACION: "GET_UBICACION",
    GET_UBICACION_DATA: "GET_UBICACION_DATA",
    GET_CLIENT_IMPRESORAS: "GET_CLIENT_IMPRESORAS",
    UPDATE_CLIENT_DETAIL: "UPDATE_CLIENT_DETAIL",

    NEXT_STEP_CREATED:"NEXT_STEP_CREATED",
    PREV_STEP_CREATED:"PREV_STEP_CREATED",
    FINISH_CREATED:"FINISH_CREATED",
}

export default clientTypes;