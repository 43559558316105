import React from 'react'
import { Button } from '@material-ui/core';
import IconCustom from '../../IconCustom';
import './ButtonEdit.scss'

const ButtonEdit = ({ label='', children=null, className='', ...props }) => {
    return (
        <button className={`buttonEditContainer ${className}`} type={'button'} {...props}>
            {children ? children :
                <div className={'container-text-button-edit'}>
                    <IconCustom type={'ecoColor'} icon={"ecoColor-circle-edit-outline"} size={'1.25rem'} className={'text-green-light'} ></IconCustom>
                    <span className={'text-green-light text-button-edit'} >{label}</span>
                </div>}
        </button>
    )
}

export default ButtonEdit
