import React from 'react'
import { Button } from '@material-ui/core';
import IconCustom from '../../IconCustom';
import './ButtonRemove.scss'

const ButtonRemove = ({ label, children, className, classNameText, ...props }) => {
    return (
        <button className={`buttonRemoveContainer ${className}`} type={'button'} {...props}>
            {children ? children :
                <div className={'container-text-button-edit'}>
                    <IconCustom icon={"trash"} size={'1.25rem'} className={'text-red'} ></IconCustom>
                    <span className={`text-red text-button-edit ${classNameText}`} >{label}</span>
                </div>}
        </button>
    )
}

export default ButtonRemove
