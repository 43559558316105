import types from './types';
import ServicesPrinters from '../../utilities/api/services/Printers';
import { handleError } from '../client/clientReducer';


const initialState = {

    loading: false,
    printers: [],
    printerData: {},


}

export default function technicalReducer(state = initialState, action) {

    switch (action.type) {
        case types.GET_PRINTERS:
            return {
                ...state,
                printers: action.payload,
                loading: false
            }
        case types.START_FETCH:
            return {
                ...state,
                loading: true
            }
        case types.UPDATE_PRINTER_DATA:
            return {
                ...state,
                printerData: action.payload,
                loading: false
            }
        default:
            return state;
    }

}

export const getPrinterStart = () => ({
    type: types.START_FETCH
});


export const setPrinters = (printers = []) => ({
    type: types.GET_PRINTERS,
    payload: printers
});

export const setPrinterData = (printer = {}) => ({
    type: types.UPDATE_PRINTER_DATA,
    payload: printer
});


export const getPrinters = (search = '') => {
    return (dispatch) => {
        dispatch(getPrinterStart());
        ServicesPrinters.getPrinters(search)
            .then((res) => {
                dispatch(setPrinters(res));
            }).catch((error) => {
                dispatch(handleError(error));
                alert("Error al encontrar cliente");
            });



    }

}


export const getPrinterData = (id_printer) => {
    return (dispatch) => {
        dispatch(getPrinterStart());
        ServicesPrinters.getPrinter(id_printer)
            .then((res) => {
                dispatch(setPrinterData(res));
            }).catch((error) => {
                dispatch(handleError(error));
                alert("Error al encontrar cliente");
            });



    }

}
