import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Form from '../../../Form';
import { FormLabel, ControllerInput, SelectInput, FieldHidden } from '../../../Form/components';
import { useFetchService } from '../../../../hooks/useFetch';
import { ServiceTickets } from '../../../../utilities/api/services';
import { useMemo } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import moment from 'moment'
import ConditionalFormTicket from './components/ConditionalFormTicket';
import { useSelector } from 'react-redux';

const validationSchema = Yup.object().shape({

})


const TicketCreate = () => {

    const history = useHistory();

    const { response: listTypesTickets, loading } = useFetchService(ServiceTickets.getTypeTickets, null, {});

    const user = useSelector(state => state.user.actualUser.idCliente);


    const listTypes = useMemo(() => {

        const listTypes = _.concat(_.map(listTypesTickets, type => ({ value: type.id, label: type.tipo })), { value: 5, label: "Consulta Administracion" });

        //const listError = _.map(tipos_errores, typeError => ({ value: typeError.id, label: typeError.tipo_error }));

        return listTypes;
    }, [loading]);

    const refForm = useRef();

    const getFaultData = (value) => {
        const { id_ubica_cli } = value.ubication;
        const id_cliente_asignado = value.client_assigned_id;
        return {
            id_cliente_asignado,
            tipo_error: value.errorType,
            id_direccion_comercial: value.comercialAddress,
            id_ubicacion: id_ubica_cli,
            tipo: value.type,
            mensaje: value.notas || ''
        }
    };


    const onSubmit = async (values) => {
        let data = {};

        switch (values.type) {
            case 1:
                data = getFaultData(values);
                break;
            case 2:
                data = { id_cliente_asignado: values.client_assigned_id, mensaje: values.notas, tipo: values.type };
                break;

            default:
                break;
        }


        await ServiceTickets.createTicket({ user, data });

        history.push("/dashboard/tickets")
    }

    return (
        <div className={'d-flex flex-column flex-align-center flex-justify-center'}>
            <div className="title text-center">
                <h2 className={'text-primary'}>Nuevo Tickets</h2>
            </div>

            <div className={'mx-8'}>
                <div className="d-flex text-primary ">
                    <p className="previous cursor-pointer" onClick={() => history.push('/dashboard/tickets')}>Tickets</p>
                    <p>{"<"} Nuevo Tickects</p>
                </div>
                <Form innerRef={refForm} onSubmit={onSubmit} initialValues={{}} className={'bg-white   rounded-container px-6 py-3'} schemaForm={validationSchema} >
                    <Grid container xs={12} className={'d-flex align-items-center flex-column  '} >
                        <Grid xs={12} className={'w-100 d-flex flex-row align-items-center align-content-center '}>
                            <Grid xs={6} className={'w-100 d-flex flex-row justify-content-between border-box  pr-3 '} >
                                <FormLabel className={'mr-3 mb-0 d-flex align-items-center text-gray-light'}>
                                    Tipo de Ticket
                                </FormLabel>
                                <ControllerInput
                                    render={SelectInput}
                                    name={'type'}
                                    autofocus={true}
                                    className={'w-100'}
                                    list={listTypes}
                                    loading={loading}
                                    modeEdit={true}
                                    classNameContainer={'w-75'}
                                    onChange={(value) => {
                                        _.isNil(value) && refForm.current.reset({});
                                    }}
                                />
                            </Grid>
                            <Grid xs={6} className={'w-100 d-flex flex-row '}>
                                <FormLabel className={'mb-0  d-flex align-items-center text-gray-light'}>
                                    Fecha De Emision: <span className={'ml-2 d-flex align-items-center text-gray-light'}>{`${moment().format('DD-MM-YYYY')}`}</span>
                                </FormLabel>
                            </Grid>
                        </Grid>
                        <ConditionalFormTicket nameField={'type'} />
                        <div className={'d-flex flex-row align-self-end'} >
                            <button type={'button'} onClick={() => refForm?.current?.reset()} className={'btn-neutro btn btn-outline-danger mr-3 '} color={'red'}>
                                Cancelar
                            </button>
                            <FieldHidden nameFields={'type'}>
                                <button className={'btn-neutro btn   button-primary text-white'}  >
                                    Enviar
                                </button>
                            </FieldHidden>

                        </div>

                    </Grid>



                </Form>
            </div>


        </div>
    )
}

export default TicketCreate
