import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import editIcon from '../../../../../assets/dashboard/distributor/edit.svg';
import './RateDetail.scss';
import allowedIcon from '../../../../../assets/dashboard/distributor/allowed.svg';
import notAllowed from '../../../../../assets/dashboard/distributor/private.svg';
import privateChange from '../../../../../assets/dashboard/distributor/rectangleOrange.svg'
import publicChange from '../../../../../assets/dashboard/distributor/rectangleGreen.svg'
import deleteIcon from '../../../../../assets/dashboard/distributor/delete.svg'
import TAB_VIEW from '../Tab';
import { Switch, Grid, FormLabel, Button } from '@material-ui/core';
import { ControllerInput, TextInput, SubmitButton } from '../../../../Form/components';
import Form from '../../../../Form';
import { ButtonEdit, ButtonRemove } from '../../../../Buttons';
import { useFetchService } from '../../../../../hooks/useFetch';
import { ServiceRates } from '../../../../../utilities/api/services';
import FormRate from './FormRate';
import _ from 'lodash'
import classNames from 'classnames';
import { loadState } from '../../../../../utilities/storage';


const RateDetail = ({ actualTab, createRetailOption }) => {


    const { response: ratesServer, loading } = useFetchService(ServiceRates.getRates, null, []);

    const [rates, setRates] = useState([]);

    const filterByUser = (rates)=>{ 
        return loadState('user').tipo_cliente === "ADMIN"?  rates: _.filter(rates, rate => !!rate.visible_cli)
    }

    useEffect(() => {
        setRates(filterByUser(ratesServer));
    }, [loading])

    const [selecFormIndex, setSelecFormIndex] = useState(null);

    const [refForm, setRefForm] = useState(null);

    const saveChanges = async (values) => {

        await ServiceRates.updateRate({ id: values.id_tarifa, rate: values });
        setRates(pre => _.map(pre, rate => values.id_tarifa === rate.id_tarifa ? values : rate))
        setSelecFormIndex(null);
        setRefForm(null);

    }

    const handleDeleteRate = async ({ id_tarifa }) => {
        try {
            await ServiceRates.deleteRate(id_tarifa);
            setRates(pre => _.filter(pre, rate => id_tarifa !== rate.id_tarifa))

        } catch (error) {

        }

    }

    const handleSelectForm = ({ ref, index }) => {
        setSelecFormIndex(index);
        setRefForm(ref);
    }

    const handleCancel = () => {
        setSelecFormIndex(null);
        setRefForm(null);
    }



    return (
        <div className={"detail rateDetail d-flex flex-column py-3"}>
            {!loading &&
                <div className="detailInner">
                    <Grid container className={' d-flex flex-row '}>
                        {rates.map((rate, index) => {

                            return (
                                <div key={JSON.stringify(rate) + index} className={classNames('w-25 position-relative', { 'mt-3': index > 3 })}>
                                    <ButtonRemove onClick={() => handleDeleteRate(rate)} label={''} className={'position-absolute mt-2 ml-2 px-1'}></ButtonRemove>
                                    <FormRate
                                        initialValues={rate}
                                        key={JSON.stringify(rate)}
                                        index={index}
                                        innerRef={refForm}
                                        selectForm={selecFormIndex}
                                        onSelectForm={handleSelectForm}
                                    />

                                </div>
                            )
                        })}

                    </Grid>

                    {
                        !_.isNull(selecFormIndex) &&
                        <div className="formButtons">
                            <Button variant="contained" onClick={handleCancel} className="prevButton btn">Cancelar</Button>
                            <Button variant="contained" className="nextButton btn" onClick={refForm?.current.handleSubmit(saveChanges)}> Guardar Cambios</Button>
                        </div>

                    }

                </div>
            }
        </div>
    )
}

export default RateDetail;