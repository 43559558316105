import React from "react";
import "./DashTicket.scss";
import DashTable from "../../DashTable/DashTable";
import { ticketHeader, ticketRows } from "../../../../utilities/constants";
import ticketLogo from '../../../../assets/dashboard/distributor/tickets.svg';
import lockLogo from '../../../../assets/dashboard/distributor/lock.svg';
import unlockLogo from '../../../../assets/dashboard/distributor/unlock.svg';
import warningLogo from '../../../../assets/dashboard/distributor/warning.svg';
import { HeaderColumn } from "../../../Table/components";
import { TextStatus } from "../../../Text";
import { Card, Grid } from "@material-ui/core";
import { CreateButton } from "../../../Buttons";
import SearchInput from "../../../SearchInput";
import { useHistory } from "react-router";
import Table from '../../../Table/Table';
import { useFetchService } from "../../../../hooks/useFetch";
import { ServiceTickets } from "../../../../utilities/api/services";
import { useSelector } from "react-redux";
import { parseClientType, parseDate } from "../../../../utilities/parse";


const DashTicket = ({ setTicketView }) => {

    const user = useSelector(state => state.user.actualUser.idCliente);


    
    const { response: ticketsData } = useFetchService(ServiceTickets.getTickets, { user }, []);



    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'distrbutorTable',
                style: { display: 'none' },
                id: 'table',
                columns: [
                    {
                        Header: "Fecha Emision",
                        accessor: "fecha",
                        Cell: ({ row }) => {
                            return <>{parseDate(row.values.fecha)}</>
                        }

                    },
                    {
                        Header: "Usuario",
                        accessor: 'tipo_cliente',
                        Cell: ({ row }) => {
                            return <>{parseClientType(row.values.tipo_cliente)}</>
                        }
                    },
                    {
                        Header: "Nombre",
                        accessor: 'nombre',
                        Cell: ({ row }) => {
                            return <span>{row.values.nombre || '-'}</span>
                        }
                    },
                    {
                        Header: "Asunto",
                        accessor: 'tipo',
                        Cell: ({ row }) => {
                            return <span>{row.values.tipo || '-'}</span>
                        }
                    },
                    {
                        Header: () => <HeaderColumn headerName={'Estado'} onClick={(value) => { }} />,
                        accessor: 'estado',
                        Cell: ({ row }) => {
                            const { estado } = row.values;
                            return (
                                <TextStatus state={1} label={estado} />
                            )
                        }
                    }
                ],
            },
        ],
        []
    )

    const history = useHistory()

    const onClickCreate = () => {
        history.push('/dashboard/ticket-created')
    }

    const handleRowClick = ({row}) => {
        history.push(`/dashboard/ticket/${row.original.id}`);
    }


    return (
        <div className="DashDistributorContainer">
            <div className="distributorInner">
                <div className="title">Tickets</div>
                <div className="consumedAmount">
                    <div className="graficTickets">
                        <img className="cornerImg" src={ticketLogo} alt="" />
                        <h2 className="title">50</h2>
                        <p className="secondTitle">Total Tickets</p>
                    </div>
                    <div className="graficTickets">
                        <img className="cornerImg" src={unlockLogo} alt="waterLogo" />
                        <h2 className="title">10</h2>
                        <p className="secondTitle">Tickets abiertos</p>
                    </div>
                    <div className="graficTickets">
                        <img className="cornerImg" src={lockLogo} alt="" />
                        <h2 className="title">30</h2>
                        <p className="secondTitle">Tickets cerrados</p>
                    </div>
                    <div className="graficTickets">
                        <img className="cornerImg" src={warningLogo} alt="" />
                        <h2 className="title">10</h2>
                        <p className="secondTitle">Tickets sin resolver</p>
                    </div>
                </div>


                <div className={"containerTableDasboard"}>
                    <Card className={'card w-100 '}>
                        <Grid
                            container
                            direction="row"
                            //justify="space-around"
                            alignItems="center"
                            className={'containerHeaderTable'}
                        >

                            <CreateButton className={"ml-5"}  onClick={onClickCreate} />


                        </Grid>
                        <Table columns={columns} data={ticketsData} initialState={{ rowState: { onClick: handleRowClick } }} />
                    </Card>
                </div>


            </div>
        </div>
    );
};

export default DashTicket;
