import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import '../View.scss';
import Table from '../../../../Table/Table';
import { HeaderColumn } from '../../../../Table/components';
import { CreateButton } from '../../../../Buttons';
import { Grid, Container, Paper, Card } from '@material-ui/core';
import SearchInput from '../../../../SearchInput';
import Select from '../../../../Select';
import useFilter from '../../../../../hooks/useFilter';
import { parseDate } from '../../../../../utilities/parse';
import { PaymentSuccessPopUp } from '../../../../../utilities/popUp';
import { TextStatus } from '../../../../Text'
import { useHistory } from 'react-router-dom';
import { getClientsByDistributor } from '../../../../../redux/distributor/distributorReducer';
import { getAllInvoices, sendPayment, allCharge } from '../../../../../redux/cobrospagos/paymentReducer';
import { 
        sendBilling, 
        startBilling, 
        getBilling, 
        getStartBilling, 
        editingBilling } from '../../../../../redux/facturacion/reducer';
import ServicesFacturacion from '../../../../../utilities/api/services/facturacionServices';        
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ServiceClients } from '../../../../../utilities/api/services';
import { useFetchService } from '../../../../../hooks/useFetch';
import Icon from '../../../../IconCustom';
import CustomButton from '../../../../CustomButton/CustomButton';
import { Button, IconButton, TextField } from '@mui/material';
import { ButtonState } from '../../../../Form/components';



const data = [
    {
        "id": 68,
        "fecha_factura": "2021-06-22T16:39:11.000Z",
        "factura": "ECO2100001",
        "importe": "2417.92",
        "nombre_cliente": "Giselle",
        "estado_pagado": 0,
        "nombre_ubicacion": null
    },
    {
        "id": 69,
        "fecha_factura": "2021-06-22T16:39:11.000Z",
        "factura": "ECO2100002",
        "importe": "160.50",
        "nombre_cliente": "Cecilia",
        "estado_pagado": 1,
        "nombre_ubicacion": "ubic2"
    },
    {
        "id": 70,
        "fecha_factura": "2021-06-22T16:39:11.000Z",
        "factura": "ECO2100003",
        "importe": "0.00",
        "nombre_cliente": "Cecilia",
        "estado_pagado": 1,
        "nombre_ubicacion": "ubic3"
    }
]


const InvoicesTable = ({ setDistributorView }) => {

    const invoices = useSelector( state => state.cobrospagos.invoices );

    const invoices2 = useSelector( state => state.factura.facturas );
    const simulations = useSelector( state => state.factura.fac_simulacion );
    const dispatch  = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [valuesOrder, setValuesOrder] = useState({});
    const [ fieldBilling, setfieldBilling ] = useState([]);
    const [ openViewCharge, setOpenViewCharge] = useState( false );

    const params = useParams();

    const styleContainer = { width: '100%', marginBottom: '1.5rem', marginTop: '1.5rem', display: 'flex', justifyContent: 'center', aligItems: 'center' };
    const styleText= { fontSize: '1.1rem', color: 'rgb(58, 107, 78)', fontFamily: "Lato, sans-serif", fontWeight: 700 }

    const handleOnClikColumn = (field, value) => {
        setValuesOrder(({ [field]: value }))
    }

    const [nameFirtColumn, setNameFieldColumn] = useState('nombre');

    const downloadInvoice = (event, value) => {
        event.stopPropagation();
    }

    const handleChangeFields = ( id, data ) => {
        const k = data.k;
        const value = data.v;
        const v = value.target.value;
        
        const operation = { k: k, v: v };
        console.log("HANDLE----CHANGE!!", operation, id );
        console.log("preIF--field", fieldBilling);
        if ( fieldBilling.length >= 1){
            
            let arrData = fieldBilling;
            arrData = arrData.map( el => {
                if( el.id === id ){ 
                    return {id: id, operation: operation};
                }
                else{ 
                    return el;
                }  
            });
            setfieldBilling( [ ...fieldBilling, {"id": id, "operation": operation} ] );
            console.log( "cosas de IF change:", arrData );
        }else{
            setfieldBilling( [ ...fieldBilling, {"id": id, "operation": operation} ] );
            console.log("cosas del ELSE de change:", fieldBilling);        
        }
    }

    const strBilling = () =>{
        //dispatch( allCharge(invoices));
        console.log("HOLA strBilling");
        dispatch( startBilling() );
        return;
    }
    
    const edtBilling = () =>{
        console.log( "FIELD-Bill", fieldBilling );
        dispatch( editingBilling( fieldBilling ) );
        setfieldBilling([]);
        return;
    }

    const sndBilling = async () =>{
        //dispatch( allCharge(invoices));
        console.log("SND------------------:");
        const serie = `ECO/${Date.now()}`;
        let failed = [];
        let billed = [];
        let paid = [];
        try{
            for (let fac of simulations){
                const bill = fac;
                bill['n_serie'] = serie;
                const response = await ServicesFacturacion.sendBilling(bill);
                console.log(response);
                if( response.status === "-1"){
                    failed.push({"cliente":response.cliente, "nombre": response.nombre});
                }
                if( response.status === "0"){
                    billed.push({"cliente":response.cliente, "nombre": response.nombre});
                }
                if( response.status === "1"){
                    paid.push({"cliente":response.cliente, "nombre": response.nombre});
                }
            }
        }catch(err){
            console.log("ocurrió un error");
        }
        if ( paid.length >= 1 || billed.length >= 1 ) {
            console.log("post if sendBill:__::SENDING::", { "failed": failed, "billed": billed, "paid": paid });
            dispatch(getBilling());
            const msg = `Resultado: fallidas: ${failed.length}; facturadas: ${billed.length}; en proceso de cobro: ${paid.length}`;
            PaymentSuccessPopUp( msg ); 
        }  
        //dispatch( sendBilling( simulations ) );
        return;
    }

    const history = useHistory();

    const goViewInvoice = (value) => {
        history.push(`/dashboard/payments/distributor/${value.id_distribuidor}/invoice/${value.factura}`)
    }

    const sPayment = ( invoice ) => {
        dispatch( sendPayment(invoice) );
        return;
    }

    const getDisabledField = () => {
        switch( fieldBilling.length >= 1){
            case true:
                return false;
            case false:
                return true;
            default:
                return true;
        }
    }

    const getStatusInvoice = ( values ) =>{
        const isSuscripted = values.adhesion_debito;
        const status = values.pagado;
        if ( isSuscripted === 0 ){
            return "sin adherir";
        }
        
        switch( status ){
            case 0:
                return "sin enviar";
            case 1:
                return "enviado";
            case 2:
                return "pendiente";
            case 3:
                return "confirmado";
            case 4:
                return "acreditado";
            case -1:
                return "rechazado";
        }
    }

    const getColor = ( values ) =>{
        const isSuscripted = values.adhesion_debito;
        const status = values.pagado;
        if ( isSuscripted === 0 ){
            return "warning";
        }
        
        switch( status ){
            case 0:
                return "secondary";
            case 1:
                return "primary";
            case 2:
                return "warning";
            case 3:
                return "success";
            case 4:
                return "success";
            case -1:
                return "error";
        }
    }

    const isDisabledDist = ( values ) =>{
        const isSuscripted = values.adhesion_debito;
        switch( isSuscripted ){
            case 1:
                return false;
            case 0:
                return true;
            default:
                return true;
        }
    }    
    
    const isDisabledCharge = ( values ) =>{
        const status = values.pagado
        const isSuscripted = values.adhesion_debito;
        if( isSuscripted === 0 || status != 0 ){
            return true;
        }    
        return false;
    }
 
    const columnsSim = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'distrbutorTable',
                style: { display: 'none' },
                id: 'table',
                columns: [
                    {
                        Header: () => <HeaderColumn headerName={'Factura'} onClick={(value) => handleOnClikColumn('fecha_ini', value)} />,
                        accessor: 'factura',
                        Cell: ({ row }) => {
                            return <>{row.original.F_id}</>
                        }
                    },
                    //preguntar por la dirreccion comercial
                    {
                        Header: "Fecha",
                        accessor: '',
                        Cell: ({ row }) => {
                            return <>{parseDate(row.original.F_fecha)}</>
                        }
                    },
                    {
                        Header: "Cantidad tickets",
                        accessor: "tickets",
                        Cell: ({ row }) => {
                            let tickets;
                            if ( null === row.original.tickets || undefined === row.original.tickets){
                                tickets = "sin tickets";
                            }else{
                                tickets = row.original.tickets;
                            }
                            return <>{tickets}</>
                        }
                    },
                    {
                        Header: "Id de Cliente",
                        accessor: "F_id_cleinte",
                        Cell: ({ row }) => {
                            return <>{row.original.F_id_cliente}</>
                        }
                    },
                    {
                        Header: "Cliente" /* () => <HeaderColumn headerName={'Cliente'} onClick={(value) => handleOnClikColumn('gama', value)} /> */,
                        accessor: 'nombre_comercial',
                        Cell: ({ row }) => {
                            let nombre;
                            if ( null != row.original.C_nombre_comercial && undefined != row.original.C_nombre_comercial ){
                                nombre = row.original.C_nombre_comercial;
                            }else{
                                if( null != row.original.C_nombre_comercial && undefined != row.original.C_nombre_comercial ){
                                    nombre = row.original.C_nombre;
                                }else{
                                    nombre = "Sin nombre registrado";
                                }
                            }
                           
                            return <> {nombre} </>
                        }
                    },
                    {
                        Header: "Ubicacion",
                        accessor: 'direccion_fiscal',
                        Cell: ({ row }) => {
                            let direccion;
                            if( null === row.original.C_direccion_fiscal || undefined === row.original.C_direccion_fiscal ){
                                direccion = "----";
                            }else{
                                direccion = row.original.C_direccion_fiscal;
                            };
                            return <>{direccion}</>
                        }

                    },
                    {
                        Header: "Importe",
                        accessor: 'importe',
                        Cell: ({ row }) => {
                            const id = row.original.F_id;
                            const value = row.original.F_importe;
                            const key = "F_importe";
                           
                            return <TextField
                            hiddenLabel
                            id="filled-hidden-label-small"
                            defaultValue={ `${value}` }
                            variant="filled"
                            size="small"
                            onChange={ (e) => handleChangeFields( id, { k: key, v: e } ) }
                          />
                        }
                    },
                    {
                        Header: "Débito",
                        accessor: '',
                        Cell: ({ row }) => {
                            let debito;
                            if ( row.original.C_adhesion_debito === 1 ){
                                debito = "adherido";
                            }else{
                                debito = "sin adherir";
                            }                           
                            return  <>
                                        { `${debito}` }
                                    </>
                        }
                    }

                ],
            },
        ],
        [JSON.stringify(simulations)]
    )

    const columnsBill = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'distrbutorTable',
                style: { display: 'none' },
                id: 'table2',
                columns: [
                    {
                        Header: () => <HeaderColumn headerName={'Factura'} onClick={(value) => handleOnClikColumn('fecha_ini', value)} />,
                        accessor: 'id',
                        Cell: ({ row }) => {
                            return <>{row.original.id}</>
                        }
                    },
                    //preguntar por la dirreccion comercial
                    {
                        Header: "Emisión",
                        accessor: "fecha",
                        Cell: ({ row }) => {
                            return <>{parseDate(row.original.fecha)}</>
                        }
                    },
                    {
                        Header: "Cantidad tickets",
                        accessor: "tickets",
                        Cell: ({ row }) => {
                            let tickets;
                            if ( null === row.original.tickets || undefined === row.original.tickets){
                                tickets = "sin tickets";
                            }else{
                                tickets = row.original.tickets;
                            }
                            return <>{tickets}</>
                        }
                    },
                    {
                        Header: "Id de Cliente",
                        accessor: "id_cleinte",
                        Cell: ({ row }) => {
                            return <>{row.original.id_cliente}</>
                        }
                    },
                    {
                        Header: "Cliente" /* () => <HeaderColumn headerName={'Cliente'} onClick={(value) => handleOnClikColumn('gama', value)} /> */,
                        accessor: 'nombre_comercial',
                        Cell: ({ row }) => {
                            let nombre;
                            if ( null === row.original.nombre_comercial || undefined === row.original.nombre_comercial ){
                                nombre = "Sin nombre registrado";
                            }else{
                                nombre = row.original.nombre_comercial;
                            }
                            return <> {nombre} </>
                        }
                    },
                    {
                        Header: "Ubicación",
                        accessor: 'direccion_fiscal',
                        Cell: ({ row }) => {
                            let direccion;
                            if( null === row.original.direccion_fiscal || undefined === row.original.direccion_fiscal ){
                                direccion = "----";
                            }else{
                                direccion = row.original.direccion_fiscal;
                            }
                            return <>{direccion}</>
                        }
                    },
                    /* {
                        Header: "Impresora",
                        accessor: '',
                        Cell: ({ row }) => {
                            let impresora = "i"; 
                            let modelo = "m"; 
                            let marca = "m";
                            if( null === row.original.id_impresora || undefined === row.original.id_impresora ){
                                impresora = "----";
                            }else{
                                impresora = row.original.id_impresora;
                            }
                            if( null === row.original.modelo || undefined === row.original.modelo ){
                                modelo = "";
                            }else{
                                modelo = row.original.modelo;
                            }
                            if( null === row.original.marca || undefined === row.original.marca ){
                                marca = "";
                            }else{
                                marca = row.original.marca;
                            } 
                            return <>{impresora}  {modelo}  {marca}</>
                        }

                    }, */
                    {
                        Header: "Débito",
                        accessor: '',
                        Cell: ({ row }) => {
                            const estado = row.original.adhesion_debito;
                            let debito;
                            if ( null === estado || undefined === estado || 0 === estado ){
                                debito = "sin adherir";
                            }else{
                                debito = "adherido";
                            };
                            return  <>
                                        { debito }
                                    </>
                        }
                    },
                    {
                        Header: "Importe",
                        accessor: 'importe',
                        Cell: ({ row }) => {
                            
                            return <>eu $ {row.original.importe}</>
                        }
                    },
                    {
                        Header: "Estado de pago",
                        accessor: '',
                        Cell: ({ row }) => {
                            let pagado = row.original.pagado;
                            let estado;
                            if ( null ===  pagado || undefined === pagado || 0 === pagado ){
                                estado = "sin enviar";
                            }
                            if ( -1 === pagado || "-1" === pagado  ){
                                estado = "rechazado";
                            }
                            if ( 1 === pagado || "1" === pagado ){
                                estado = "enviado"
                            }
                            if ( 3 === pagado || "3" === pagado ){
                                estado = "confirmado"
                            }
                            return  <Button variant="outlined" color="success" disabled="true">
                                        {estado}
                                    </Button>
                        }
                    },
                    {
                        Header: "Descargar Factura",
                        accessor: '',
                        Cell: ({ row }) => {
                            return  <IconButton onClick={(e) => downloadInvoice(e, row.values)}>
                                        <Icon className={"text-primary"} type={'clara'} icon={'Document-download'} size={'1.25rem'} />
                                    </IconButton>
                        }
                    },
                    
                ],
            },
        ],
        [JSON.stringify(invoices2)]
    )

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'distrbutorTable',
                style: { display: 'none' },
                id: 'table',
                columns: [
                    {
                        Header: () => <HeaderColumn headerName={'Factura'} onClick={(value) => handleOnClikColumn('fecha_ini', value)} />,
                        accessor: 'factura',
                        Cell: ({ row }) => {
                            return <>{row.values.factura}</>
                        }
                    },
                    //preguntar por la dirreccion comercial
                    {
                        Header: "Fecha",
                        accessor: "fecha_factura",
                        Cell: ({ row }) => {
                            return <>{parseDate(row.values.fecha_factura)}</>
                        }
                    },
                    {
                        Header: () => <HeaderColumn headerName={'Cliente'} onClick={(value) => handleOnClikColumn('gama', value)} />,
                        accessor: 'nombre_cliente',
                        Cell: ({ row }) => {
                            return <>{row.values.nombre_cliente}</>
                        }
                    },
                    {
                        Header: "Ubicacion",
                        accessor: 'nombre_ubicacion',
                        Cell: ({ row }) => {

                            return <>{row.values.nombre_ubicacion}</>
                        }

                    },
                    {
                        Header: "Importe",
                        accessor: 'importe',
                        Cell: ({ row }) => {
                            return <>{row.values.importe}</>
                        }
                    },
                    {
                        Header: "Estado",
                        accessor: 'estado_pagado',
                        Cell: ({ row }) => {
                            return <ButtonState value={!!row.values.estado_pagado} labelActive={"Pagado"} labelSuspend={"Pendiente"} onClick={() => goViewInvoice(row.values)} size={"small"}  >{"Pagar"}</ButtonState>
                        }
                    },
                    {
                        Header: "Descargar Factura",
                        accessor: 'id_factura8',
                        Cell: ({ row }) => {
                            return <IconButton onClick={(e) => downloadInvoice(e, row.values)}><Icon className={"text-primary"} type={'clara'} icon={'Document-download'} size={'1.25rem'} /></IconButton>
                        }
                    }

                ],
            },
        ],
        [JSON.stringify(data)]
    )

    const handleRowClick = ({ row: { original } }) => {
        if (original.tipo_cliente === 1) {
            history.push(`/dashboard/client/${original.id_cliente}`)
        }
    }

    const handleOnChangeSelect = (value) => {
        setNameFieldColumn(value);
        setSearchValue('')
    }

    useEffect(() => {
        //dispatch( getAllInvoices() );
        dispatch( getStartBilling() );
        dispatch( getBilling() );
        console.log("invoice: ", invoices2);
        console.log("invoices_simulation:", simulations);
    }, []);

    return (
        <div className={'containerView px-0 pt-3 '}>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={'containerHeaderTable pl-5 pr-5'}
            >

                <SearchInput searchValue={searchValue} onChageSearch={setSearchValue} placeholder={'search...'} />
                
                {
                    (invoices2.length>=1) 
                ?
                    <p style={styleText}> Facturas del período actual </p>
                :    
                    
                    (simulations.length>=1) 
                        ? 
                            (
                            <div> 
                                <Button className={'mr-2'} variant="outlined" disabled={ getDisabledField() } color="primary" onClick={ () => edtBilling() } size="medium"> 
                                    Guardar cambios 
                                </Button>
                                <Button className={'ml-2'} variant="contained" color="success" onClick={ () => sndBilling() } size="large"> 
                                    Facturar y cobrar 
                                </Button>
                            </div> 
                            )
                        :
                            (<Button variant="outlined" color="success" onClick={ () => strBilling() } size="large"> 
                                Iniciar simulación 
                            </Button>)
                   
                }        
                {/*<Select onChange={handleOnChangeSelect} options={[{ label: "Nombre Fiscal", value: "nombre" }, { label: "Nombre Comercial", value: "nombre_comercial" }]} />*/}

            </Grid>
            {(invoices2.length>=1) 
                ? 
                    
                <Table
                    columns={columnsBill}
                    data={invoices2}
                    className={'containerTableDistributor'}
                    initialState={{ rowState: { onClick: handleRowClick } }} orderByValues={valuesOrder} /> 
                   
                :
                
                (simulations.length>=1) 
                    ?    
                
                        <Table
                            columns={columnsSim}
                            data={simulations}
                            className={'containerTableDistributor'}
                            initialState={{ rowState: { onClick: handleRowClick } }} orderByValues={valuesOrder} />
                        
                    :    
                        
                    <div style={styleContainer}> <p style={styleText}> No hay facturas generadas en este período </p> </div>
                
            }
        </div>

    );
};

export default InvoicesTable;
