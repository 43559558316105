import React from "react";
import "./Spinner.css";

const Spinner = ({isLoading}) => {

  return (
    <>
      {
        isLoading ?
          <div className="sk-chase" >
            <div className="sk-chase-dot"/>
            <div className="sk-chase-dot"/>
            <div className="sk-chase-dot"/>
            <div className="sk-chase-dot"/>
            <div className="sk-chase-dot"/>
            <div className="sk-chase-dot"/>
          </div>
          :
          null
      }
    </>
  );
};

export default Spinner;
