import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import "./DashPrinter.scss";
import Table from '../../../Table/Table';
import { HeaderColumn } from '../../../Table/components';
import { CreateButton } from '../../../Buttons';
import { Grid, Card } from '@material-ui/core';
import SearchInput from '../../../SearchInput';
import { parseDate } from '../../../../utilities/parse';
import { TextStatus } from '../../../Text'
import { useHistory } from 'react-router-dom';
import { getPrinters } from '../../../../redux/printer/reducer';
import _ from 'lodash';
import usePermissionAccess from '../../../../hooks/usePermissionAccess';

import './classes.scss';
import Tabs from '../../../Tabs/Tabs';
import { TabPanel } from '../../../Tabs/components';
import InvoicesTable from './InvoicesTable';
import PaymentHistory from './PaymentHistory';

const DashPaymentDistributor = ({ setDistributorView }) => {

    const [actualTab, setActualTab] = useState(0);

    const mapPinters = ({ asignado, no_asignado }) => {
        return _.concat(_.map(asignado, printer => ({ ...printer, isAssigned: true }), _.map(no_asignado, printer => ({ ...printer, isAssigned: false }))))
    }

    const dispatch = useDispatch();
    const { printers, loading } = useSelector(({ printer: { printers, loading } }) => ({ printers: mapPinters(printers), loading }));
    const [searchValue, setSearchValue] = useState('');
    const [valuesOrder, setValuesOrder] = useState({})

    useEffect(() => {
        dispatch(getPrinters(searchValue));
    }, [searchValue]);

    const handleOnClikColumn = (field, value) => {
        setValuesOrder(({ [field]: value }))
    }


    const [nameFirtColumn, setNameFieldColumn] = useState('nombre');

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'distrbutorTable',
                style: { display: 'none' },
                id: 'table',
                columns: [
                    {
                        Header: () => {
                            return (<HeaderColumn onClick={(value) => handleOnClikColumn("sn", value)} headerName={"Numero De Serie"} />)
                        },
                        accessor: "sn",

                    },
                    {
                        Header: () => <HeaderColumn headerName={'Marca'} onClick={(value) => handleOnClikColumn('marca', value)} />,
                        accessor: 'marca',
                        Cell: ({ row }) => {
                            return <>{row.values.marca}</>
                        }
                    },
                    {
                        Header: "Modelo",
                        accessor: 'modelo',
                        Cell: ({ row }) => {
                            return <span>{row.values.modelo || '-'}</span>
                        }
                    },
                    {
                        Header: "Gama De Maquina",
                        accessor: 'Gama',
                        Cell: ({ row }) => {
                            return <span>{row.values.Gama || '-'}</span>
                        }
                    },
                    {
                        Header: "Asignada a",
                        accessor: 'isAssigned',
                        Cell: ({ row }) => {
                            return <span>{row.values.isAssigned ? "Asignado" : "No asignado"}</span>
                        }
                    },
                    {
                        Header: () => <HeaderColumn headerName={'Estado'} onClick={(value) => handleOnClikColumn('estado_cliente', value)} />,
                        accessor: 'estado',
                        Cell: ({ row }) => {
                            const { estado } = row.values;
                            return (
                                <TextStatus state={1} label={estado} />
                            )
                        }
                    }
                ],
            },
        ],
        [nameFirtColumn]
    )

    const history = useHistory()

    const prevPage = () => {
        history.goBack()
    }

    const handleRowClick = ({ row: { original } }) => {
        history.push(`/dashboard/printer/${original.id_impresora}`)
    }

    return (
        <div className="DashDistributorContainer">
            <div className="distributorInner">
                <div className="title">Gestión de Facturas pendientes y pagas</div>
                <div className={'containerTableDasboard'}>
                    <label className="text-primary "> <span onClick={prevPage} className={"link-breadcrumb "}>{"Gestión de cobros y pagos"}</span>   {" < Gestion de Facturas"}</label>
                    <Tabs className={'mt-3'} actualTab={actualTab} setActualTab={setActualTab} texts={[<p>Facturas</p>, <p>Historial de Pagos</p>]} />
                    <TabPanel index={0} selectTab={actualTab}>
                        <InvoicesTable></InvoicesTable>
                    </TabPanel>
                    <TabPanel index={1} selectTab={actualTab}>
                        <PaymentHistory></PaymentHistory>
                    </TabPanel>
                </div>
            </div>
        </div>
    );
};

export default DashPaymentDistributor;
