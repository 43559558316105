import ServerConnect, { methods } from '../ServerConnect';
import URI, { BASE_URL } from './uris';


export default class ServiceTechs {

    static getTypeTickets = async () => await ServerConnect.fetchAxios(`${URI.TICKETS}/getTicketTypes`, methods.GET, { body: {} });

    static getTypeErrors = async () => await ServerConnect.fetchAxios(`${URI.TICKETS}/getTicketErrorTypes`, methods.GET, { body: {} });

    static getColors = async () => await ServerConnect.fetchAxios(`${URI.TICKETS}/ticketColors`, methods.GET, { body: {} });

    static getTickets = async ({ user }) => await ServerConnect.fetchAxios(`${URI.TICKETS}/getAllTickets/${user}`, methods.GET, { body: {} });

    static getDataChatTickets = async ({ user, id_ticket }) => await ServerConnect.fetchAxios(`${URI.TICKETS}/getDataChatTicket/${user}/${id_ticket}`, methods.GET, { body: {} });

    static responseTicket = async ({ user, data }) => await ServerConnect.fetchAxios(`${URI.TICKETS}/answerTicket/${user}`, methods.PUT, { body: data });

    static createTicket = async ({ user, data }) => await ServerConnect.fetchAxios(`${URI.TICKETS}/createTicket/${user}`, methods.POST, { body: data });


    static getClients = async () => await ServerConnect.fetchAxios(`${URI.CLIENTS}/getAllClientes`, methods.GET, { body: {} });

    static getTechs = async () => await ServerConnect.fetchAxios(`${URI.CLIENTS}/getAllTecnicos`, methods.GET, { body: {} });

    static getDistributors = async () => await ServerConnect.fetchAxios(`${URI.CLIENTS}/getAllDistributors`, methods.GET, { body: {} });

    static getDirectionByClient = async ({ id }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/directionsByClient/${id}`, methods.GET, { body: {} });

    static getDirectionByTech = async ({ id }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/getAllTecnicos/${id}`, methods.GET, { body: {} });

    static getDirectionByDistributor = async ({ id }) => await ServerConnect.fetchAxios(`${URI.CLIENTS}/getAllDistributors/${id}`, methods.GET, { body: {} });


    static setStatusTicket = async ({ status, id_ticket }) => await ServerConnect.fetchAxios(`${URI.TICKETS}/updateTicketStatus/${id_ticket}`, methods.PATH, { body: { status } });


}

