import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import './CreateButton.scss';


const CreateButton = ({ label = "+ Crear Nuevo", className='', ...pros }) => {
    return (
        <Button  className={` createButton ${className}  `} {...pros}>
            {label}
        </Button>
    )
}

export default CreateButton



