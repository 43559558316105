import axios from "axios";
import { ENDPOINT } from "../../utilities/constants";
import tecniTypes from "./types";
import { ServiceTechs } from "../../utilities/api/services";

const initialState = {
    loading: false,
    tecnicos: [],
    error: '',
    openSucess: false,
    openError: false,
    tecnicoCreated: false,
    tecnicoDelete: false,
    disbutorsclients: [],
    techData: {},
    tecnicoState: [],
    selectedTecnico: '',
    selectedistributors: [],
    techCreate: {
        step: 0,
        data: {}
    },
};

// Reducer
export default function technicalReducer(state = initialState, action) {
    switch (action.type) {
        case tecniTypes.START_FETCH_TECNICOS:
            return {
                ...state,
                loading: true,
            }
        case tecniTypes.GET_TECNICOS_SUCCESS:
            return {
                ...state,
                tecnicos: action.payload,
                loading: false
            }
        case tecniTypes.CREATE_TECNICOS_SUCCESS:
            return {
                ...state,
                loading: false,
                openSucess: true,
                tecnicoCreated: true
            }
        case tecniTypes.FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
                openError: true
            }
        case tecniTypes.CLOSE_DIALOG:
            return {
                ...state,
                openSucess: false,
                openError: false
            }
        case tecniTypes.SET_OFF_REDIRECT:
            return {
                ...state,
                tecnicoCreated: false
            }
        case tecniTypes.GET_TECNICOS_BY_ID:
            return {
                ...state,
                distributorsclients: action.payload
            }
        case tecniTypes.SELECT_TECNICO:
            return {
                ...state,
                selectedTecnico: action.payload,
            }
        case tecniTypes.GET_TECNICO_DETAIL:
            return {
                ...state,
                techData: action.payload,
                loading: false,
            }
        case tecniTypes.DELETE_TECNICO_SUCCESS:
            return {
                ...state,
                loading: false,
                openSucess: true,
                tecnicoDelete: true
            }
        case tecniTypes.SUSPENDED_TECNICO:
            return {
                ...state,
                loading: false,
                tecnicoState: 0
            }
        case tecniTypes.ACTIVE_TECNICO:
            return {
                ...state,
                loading: false,
                tecnicoState: 1
            }
        case tecniTypes.GET_DISTRIBUTORS:
            return {
                ...state,
                loading: false,
                selectedistributors: action.payload,
            }
        case tecniTypes.NEXT_STEP_CREATED:
            return {
                ...state,
                techCreate: {
                    data: { ...state.techCreate.data, ...action.payload },
                    step: ++state.techCreate.step
                }

            }
        case tecniTypes.PREV_STEP_CREATED:
            return {
                ...state,
                techCreate: {
                    data: { ...state.techCreate.data, ...action.payload },
                    step: --state.techCreate.step
                }

            }
        case tecniTypes.FINISH_CREATED:
            return {
                ...state,
                techCreate: {
                    data: {},
                    step: 0
                }
            }
        default:
            {
                return state;
            }
    }
}

// Action Creators
export const getTecnicosStart = () => ({
    type: tecniTypes.START_FETCH_TECNICOS
});

export const getTecnicosSuccess = (res) => ({
    type: tecniTypes.GET_TECNICOS_SUCCESS,
    payload: res
})

export const handleError = (error) => ({
    type: tecniTypes.FAILURE,
    payload: error
})

export const createTecnicoStart = () => ({
    type: tecniTypes.START_FETCH_TECNICOS
})

export const createTecnicoSuccess = () => ({
    type: tecniTypes.CREATE_TECNICOS_SUCCESS
})

export const closeDialog = () => ({
    type: tecniTypes.CLOSE_DIALOG
})

export const setRedirectOff = () => ({
    type: tecniTypes.SET_OFF_REDIRECT
})

export const getDistributorCli = (res) => ({
    type: tecniTypes.GET_TECNICOS_BY_ID,
    payload: res
})

export const getDetailTecnico = (res) => ({
    type: tecniTypes.GET_TECNICO_DETAIL,
    payload: res
})

export const selectTecnico = (client) => ({
    type: tecniTypes.SELECT_TECNICO,
    payload: client
})

export const deleteTecnicoSuccess = () => ({
    type: tecniTypes.DELETE_TECNICO_SUCCESS
})

export const suspendedTecnicoSuccess = () => ({
    type: tecniTypes.SUSPENDED_TECNICO
})

export const activeTecnicoSuccess = () => ({
    type: tecniTypes.ACTIVE_TECNICO
})

export const getDistributorsSucess = (res) => ({
    type: tecniTypes.GET_DISTRIBUTORS,
    payload: res
});

export const nextStepTechCreate = (values) => ({
    type: tecniTypes.NEXT_STEP_CREATED,
    payload: values
});

export const prevStepTechCreate = (values) => ({
    type: tecniTypes.PREV_STEP_CREATED,
    payload: values
});

export const finishTechCreate = () => ({
    type: tecniTypes.FINISH_CREATED
});


// Side effects
export const getTechs = (search = "") => {
    return (dispatch) => {
        dispatch(getTecnicosStart());

        ServiceTechs.getTechs(search)
            .then((res) => {
                dispatch(getTecnicosSuccess(res));
            }).catch((error) => {
                dispatch(handleError(error));
                alert("Error al encontrar cliente");
            });
    };
};

export const createTecnico = (tecnicoData) => {
    return (dispatch) => {
        dispatch(createTecnicoStart());

        axios.post(ENDPOINT + "/tecnico/createTecnico", tecnicoData, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(createTecnicoSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}


export const getDataCliDistributor = (id) => {

    return (dispatch) => {

        axios.get(ENDPOINT + `/distributors/dataClient/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                dispatch(getDistributorCli(res))
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const getTech = (id) => {
    return (dispatch) => {

        dispatch(createTecnicoStart());

        ServiceTechs.getTech(id)
            .then(res => {
                dispatch(getDetailTecnico(res))
            })
            .catch(error => {
                console.log('error', error)
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const deleteTecnico = (id) => {
    return (dispatch) => {
        dispatch(createTecnicoStart());

        axios.delete(ENDPOINT + `/tecnico/deleteTecnico/${id}`)
            .then(res => {
                dispatch(deleteTecnicoSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const suspendedTecnico = (id) => {
    return (dispatch) => {
        dispatch(createTecnicoStart());

        axios.put(ENDPOINT + `/permisos/disable/${id}`)
            .then(res => {
                dispatch(suspendedTecnicoSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const activeTecnico = (id) => {
    return (dispatch) => {
        dispatch(createTecnicoStart());

        axios.put(ENDPOINT + `/permisos/enable/${id}`)
            .then(res => {
                dispatch(activeTecnicoSuccess());
            })
            .catch(error => {
                dispatch(handleError(error.response.data));
                alert(error.response.data);
            });
    }
}

export const updateTecnico = (id, data) => {
    return (dispatch) => {

        axios.put(ENDPOINT + `/tecnico/updateDataOfTecnico/${id}`, data)
            .then(res => {
                alert("Cambios Guardados");
            })
            .catch(error => {
                dispatch(handleError(error.response));
                alert(error.response);
            });
    }
}

export const getDistributors = () => {
    return (dispatch) => {

        axios.get(ENDPOINT + `/tecnico/getAllDistributors`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((res) => {
                dispatch(getDistributorsSucess(res.data))
            })
            .catch((err) => {
                dispatch(handleError(err.response));
                alert(err.response);
            })


    }
}