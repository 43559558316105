import React, { useState, useEffect, useRef } from 'react';
import Form from '../../../../../Form';
import { TextInput, ControllerInput, FormLabel, SubmitButton } from '../../../../../Form/components';
import { ButtonEdit, ButtonAdd, ButtonRemove } from '../../../../../Buttons'
import { Button, Grid } from '@material-ui/core';
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux';
import _, { uniqueId } from 'lodash';
import '../View.scss';
import { ServiceDistributors } from '../../../../../../utilities/api/services';
import { SuccessDialog } from '../../../../../Modal';
import { ref } from 'yup';
import { useFormContext, useFieldArray } from 'react-hook-form';
import income from '../../../../../../assets/dashboard/home/income.svg';
import { updateDataDistributor } from '../../../../../../redux/distributor/distributorReducer';
import ErrorMessage from '../../../../../Text/ErrorMessage';


const validateSchema = Yup.object().shape({
    acountsBank: Yup.array().of(Yup.object().shape({
        banco: Yup.string().required('El Nombre de  banco es requerido'),
        cuenta: Yup.string().required('La cuenta es requerido'),
    }))
})


const BalanceAndBanckAcount = ({ data }) => {


    const dispatch = useDispatch()
    const [modeEdit, setModeEdit] = useState(false);
    const distributorData = useSelector(state => state.distributor.distributorData);

    const [openModalSuccess, setOpenModalSuccess] = useState(false);

    const handleSubmit = async (values) => {
        const datos_bancarios = values.acountsBank;
        await ServiceDistributors.updateDataBank({ datos_bancarios }, data.id_cliente);
        dispatch(updateDataDistributor(values));
        setOpenModalSuccess(true);
        setModeEdit(false)
    }



    const refForm = useRef();


    const handleAddAcountBanck = () => {
        const acountsBank = refForm.current.watch('acountsBank');
        refForm.current.setValue('acountsBank', [...acountsBank, { banco: "", cuenta: "", key: _.uniqueId('key') }])
    }



    return (
        <>{
            <Form
                initialValues={distributorData}
                schemaForm={validateSchema}
                onSubmit={handleSubmit}
                className={'containerView'}
                innerRef={refForm}
            >

                < >
                    {
                        modeEdit ?
                            <ButtonAdd className={'align-self-end'} classNameText={'text-sm'} label={"Agregar Cuenta Bancaria"} onClick={handleAddAcountBanck} />
                            : <ButtonEdit className={'align-self-end'} label={"Editar"} onClick={() => setModeEdit(true)} />

                    }
                </>
                <Grid spacing={1} style={{ display: "flex", flexDirection: "flex-row" }} >
                    <Grid item xs={8} className={'d-flex flex-column'}  >
                        <InputAcounBack name={'acountsBank'} modeEdit={modeEdit} className={'d-flex flex-row'} />
                    </Grid>

                    <Grid item xs={4}  >
                        <Grid item xs={12} className={'mt-1'} >
                            <FormLabel className="text-primary">
                                Saldo
                            </FormLabel>
                            <div className="income" style={{ position: "relative" }}>
                                <img src={income} alt="income" className="incomeDesign" />
                                <FormLabel style={{ position: "absolute", top: "20px", left: "25px" }} className=" text-gray-light text-xl  ">{50000}</FormLabel>
                            </div>
                        </Grid >

                    </Grid>



                </Grid>
                {
                    modeEdit &&
                    <div className="formButtons">
                        <Button variant="contained" onClick={() => setModeEdit(false)} className="prevButton btn">Cancelar</Button>
                        <SubmitButton  >Guardar Cambios</SubmitButton>
                    </div>

                }


            </Form >
        }
            <SuccessDialog isOpen={openModalSuccess} handleClose={() => setOpenModalSuccess(false)} text={"Cambios Guardados con Exito"} />

        </>
    )
}

export default BalanceAndBanckAcount


export const InputAcounBack = ({ name, modeEdit, className }) => {

    const { control, setError, clearErrors, formState: { errors } } = useFormContext();
    const { fields, remove } = useFieldArray({ control, name, keyName: "key" })

    const handleRemoveAcountBanck = (index) => {
        remove(index);
    }

    useEffect(() => {
        fields.length ? clearErrors(name) : setError(name, {
            type: "manual",
            message: 'Es minimo tener una cuenta de banco'
        });
    }, [fields.length])

    return (
        <>
            {fields.map((acountBack, index) => {
                return (
                    <React.Fragment key={acountBack.key}>
                        <div className={className} >


                            <Grid item xs={6} className={'mt-1'}  >
                                <FormLabel className="text-primary">
                                    Nombre del Banco*
                                </FormLabel>
                                <ControllerInput
                                    render={TextInput}
                                    name={`${name}[${index}].banco`}
                                    modeEdit={modeEdit}
                                    className={'w-75'}
                                />
                            </Grid >

                            <Grid item xs={6} className={'mt-1'}>
                                <FormLabel className="text-primary">
                                    Cuenta Bancaria*
                                </FormLabel>
                                <ControllerInput
                                    render={TextInput}
                                    name={`${name}[${index}].cuenta`}
                                    modeEdit={modeEdit}
                                    className={'w-75'}
                                />
                            </Grid >



                        </div>

                        {modeEdit && <ButtonRemove classNameText={'text-sm'} className={'align-self-start mt-2 mb-3'} label={"Remover"} onClick={() => handleRemoveAcountBanck(index)} />}
                    </React.Fragment>

                )
            })}
            {_.get(errors, `${name}.message`) && <ErrorMessage message={_.get(errors, `${name}.message`)} />}
        </>
    )

}