import React, { useState, useMemo } from 'react';
import { Button, Grid } from '@material-ui/core';
//import './PrinterCreate.scss'
import Form from '../../../../Form';
import * as Yup from 'yup';
import { FormLabel, ControllerInput, TextInput, SubmitButton, SelectInput } from '../../../../Form/components';
import _ from 'lodash'
import { useHistory } from 'react-router-dom';

import Swicth from '../../../../Form/components/Switch';
import { ServiceRates } from '../../../../../utilities/api/services';
import { useFetchService } from '../../../../../hooks/useFetch';

const validateSchema = Yup.object().shape({
    nombre: Yup.string().required('El campo es Requerido')
})


const CreateRate = ({ setPrinterView }) => {

    const { response: rateIgic, loadingIgic } = useFetchService(ServiceRates.getRateIgic, null, []);

    const listIgic = useMemo(() => {
        return _.map(rateIgic, ri => ({ label: ri.nombre, value: ri.id }))
    }, [JSON.stringify(rateIgic)])


    const handlePathClick = () => {
        history.push('/dashboard/rates');

    }

    const history = useHistory()

    const handleOnSubmit = async (values) => {

        await ServiceRates.createdRate(values);

        history.push('/dashboard/rates');
    }
    const modeEdit = true;

    return (

        <>

            <div className={'d-flex flex-column justify-content-center align-items-center'} >
                <div className="title text-center">
                    <h2 className={'text-primary'}>Crear Nueva tarifa</h2>
                </div>


                <div className="bg-white  py-3 rounded-lg mx-8 mt-3 px-6 ">
                    <Form initialValues={{ visible_cli: false }} schemaForm={validateSchema} className={'d-flex flex-column w-100  justify-content-center align-items-center h-100'} onSubmit={handleOnSubmit}>


                        <Grid item xs={12} className={'d-flex ml-10   flex-column  bg-gray-ecoColor px-3  pb-4  pt-2 rounded-xl'}>

                            <Grid item xs={12} className={'d-flex flex-column mt-2'}>
                                <FormLabel className={'text-primary'}>
                                    Nombre De Tarifa
                                </FormLabel>
                                <ControllerInput
                                    render={TextInput}
                                    name={`nombre`}
                                    modeEdit={modeEdit}
                                    className={'bg-transparent '}
                                />

                            </Grid>


                            <Grid item xs={12} className={'d-flex flex-column mt-2'}>
                                <FormLabel className={'text-primary'}>
                                    Precio por copia B y N
                                </FormLabel>
                                <ControllerInput
                                    render={TextInput}
                                    name={`pvp_bn`}
                                    modeEdit={modeEdit}
                                    className={'bg-transparent '}
                                />

                            </Grid>
                            <Grid item xs={12} className={'d-flex flex-column mt-3'}>
                                <FormLabel className={'text-primary'}>
                                    Precio por copia Color
                                        </FormLabel>
                                <ControllerInput
                                    render={TextInput}
                                    name={`pvp_color`}
                                    modeEdit={modeEdit}
                                    className={'bg-transparent'}
                                />

                            </Grid>
                            <Grid item xs={12} className={'d-flex flex-column mt-3'}>
                                <FormLabel className={'text-primary'}>
                                    Consumo Minimo
                                        </FormLabel>
                                <ControllerInput
                                    render={TextInput}
                                    name={`consumo_minimo`}
                                    modeEdit={modeEdit}
                                    className={'bg-transparent'}
                                />

                            </Grid>

                            <Grid item xs={12} className={'d-flex flex-column mt-3'}>
                                <FormLabel className={'text-primary'}>
                                    Precio Mantenimiento
                                </FormLabel>
                                <ControllerInput
                                    render={TextInput}
                                    name={`mantenimiento`}
                                    modeEdit={modeEdit}
                                    className={'bg-transparent'}
                                />

                            </Grid>

                            <Grid item xs={12} className={'d-flex flex-column mt-3'}>
                                <FormLabel className={'text-primary'}>
                                    Impuesto
                                 </FormLabel>
                                <ControllerInput
                                    render={SelectInput}
                                    name={`id_igic`}
                                    list={listIgic}
                                    loading={loadingIgic}
                                    modeEdit={modeEdit}
                                    classNameMenu={"w-100"}
                                    classNameContainer={'w-100'}
                                    className={'bg-transparent w-100'}
                                />

                            </Grid>

                            <Grid item xs={12} className={'d-flex flex-row mt-3'}>
                                <FormLabel className={'text-primary p-0 mt-2'}>
                                    Estado
                                    </FormLabel>
                                <ControllerInput
                                    render={Swicth}
                                    name={`visible_cli`}
                                    modeEdit={modeEdit}
                                    defaultValue={false}
                                    className={'bg-transparent m-0'}
                                />

                            </Grid>


                        </Grid>






                        <div className="formButtons">
                            <Button variant="contained" onClick={() => history.goBack()} className="prevButton btn">Cancelar</Button>
                            <SubmitButton labelButton={'Crear Tarifa'}  ></SubmitButton>
                        </div>



                    </Form>

                </div>
            </div>
        </>
    )
}

export default CreateRate;