import React from 'react';
import './CircleBar.scss';

const CircleBar = ({number}) => {
    return (
        <div className="circleBarContainer">
            <div className="percent">
                <svg>
                    <circle className="c1" cx="100" cy="90" r="84"></circle>
                    <circle className="c2" cx="100" cy="90" r="73"></circle>
                    <circle className="c3" cx="100" cy="90" r="60"></circle>
                </svg>
                <div className="number">
                    <p className="percentage">{number}</p>
                </div> 
            </div>
        </div>
    )
}

export default CircleBar;