import React from 'react';
import './Tabs.scss';

const Tabs = ({ texts, actualTab, setActualTab, className }) => {

    const handleTabChange = (index) => {
        setActualTab(index);
    }

    return (
        <div className={`tabs ${className}`}>
            {
                texts.map((text, i) =>
                    <div key={i} className={actualTab === i ? "tab selected" : "tab"} onClick={() => handleTabChange(i)}>
                        <div className="tabInner">
                            {text}
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Tabs;