import React from 'react';
import './SideOption.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useRouteMatch } from 'react-router-dom'

const SideOption = ({ icon, label, to, activeOnlyWhenExact = true }) => {

    let isSelected = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact
    });

    return (
        <div className={isSelected ? "sideOptionSelected" : "sideOption"} >
            <Link {...{ to }} className={'text-decoration-none'} >
                <div className="sideOptionInner">
                    <div className="icon">
                        <FontAwesomeIcon size="lg" className="awesomeIcon" icon={icon} />
                    </div>
                    <div className="text">
                        <p>{label}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default SideOption;