import React from 'react';
import Form from '../../../../Form';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { nextStepDistributorCreate, prevStepDistributorCreate } from '../../../../../redux/distributor/distributorReducer';
import { SubmitButton, TextInput, ControllerInput, FormLabel } from '../../../../Form/components';
import { Button, Grid } from '@material-ui/core';

const validationSchema = Yup.object().shape({
    comi_ini: Yup.string().required("La Comision Inicial Es Requerida"),
    comi_resto: Yup.string().required("La Comision Resto Es Requerida"),

})

const Step4 = () => {

    const { data } = useSelector(state => state.distributor.distributorCreate);
    const dispatch = useDispatch()
    const handleNextStep = (values) => {
        dispatch(nextStepDistributorCreate(values));
    }

    const handlePrevStep = () => dispatch(prevStepDistributorCreate())

    return (
        <Form initialValues={data} className={''} schemaForm={validationSchema} onSubmit={handleNextStep}>

            <Grid container className={'d-flex flex-column align-items-center my-3 '}>
                <Grid item xs={12} spacing={2} className={' d-flex flex-row '}>
                    <Grid item xs={5} className={'mr-6 w-100'} >
                        <FormLabel className="text-primary">
                            Persona de Contacto
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'nombre_contacto'}
                            firstAutofocus={true}

                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>

                    <Grid item xs={5}>
                        <FormLabel className="text-primary">
                            Telefono
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'telefono'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>
                </Grid >

                <Grid item xs={12} className={'mt-4 d-flex flex-row'}>
                    <Grid item xs={5} className={'mr-6 w-100'} >
                        <FormLabel className="text-primary">
                            Movil
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'movil'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>

                    <Grid item xs={5}>
                        <FormLabel className="text-primary">
                            Pagina Web
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'pagina_web'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>
                </Grid >



                <Grid item xs={12} className={'mt-4 d-flex flex-row '}>
                    <Grid item xs={5} className={'mr-6 w-100'} >
                        <FormLabel className="text-primary">
                            Comisión inicial *
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'comi_ini'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>

                    <Grid item xs={5}>
                        <FormLabel className="text-primary">
                            Comisión resto *
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'comi_resto'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>
                </Grid >

            </Grid>

            <div className="formButtons">
                <Button variant="contained" onClick={handlePrevStep} className="prevButton btn">Volver</Button>
                <SubmitButton labelButton={'Siguiente'}></SubmitButton>
            </div>


        </Form>
    )
}

export default Step4;
