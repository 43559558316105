import { Step, StepLabel, Stepper } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import './DistributorCreate.scss';
import { finishDistributorCreate } from '../../../../redux/distributor/distributorReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Step1, Step2, Step3, Step4, Step5 } from './Steps'


const DistributorCreate = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const steps = ['Datos personales', 'Usuario y Contraseña', 'Datos de ubicación', 'Contacto y comisión', 'Cuenta bancaria y permisos'];

    const { step } = useSelector(state => state.distributor.distributorCreate);

    useEffect(() => {
        return () => {
            dispatch(finishDistributorCreate());

        }
    }, [])

    const stepForm = useMemo(() => {
        switch (step) {
            case 0:
                return <Step1 />;
            case 1:
                return <Step2 />;
            case 2:
                return <Step3 />;
            case 3:
                return <Step4 />;
            case 4:
                return <Step5 />;
            default:
                return <></>;
        }
    }, [step])


    return (
        <div className="disCreateContainer">
            <div className="disCreateInner">
                <div className="title">
                    CREAR NUEVO DISTRIBUIDOR
                </div>
                <div className="createContainer">
                    <div className="actualPath">
                        <p className="previous" onClick={() => history.push('/dashboard/distributors')}>Distribuidores</p>
                        <p>{"<"} Crear distribuidor</p>
                    </div>
                    <div className="createFormContainer" >
                        <div className="createFormInner">
                            <div className="stepperContainer">
                                <Stepper activeStep={step} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                            {stepForm}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DistributorCreate;