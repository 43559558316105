import React from 'react'
import DashHeader from '../../../components/Dashboard/DashHeader/DashHeader';
import Sidebar from '../../../components/Sidebar';

const DashboardLayout = ({ children }) => {
    return (
        <div className="dashboardPageContainer">
            <DashHeader />
            <div className="content">
                <div className={"sidebar Scrollbar"}>
                    <Sidebar />
                </div>
                <div className="panel">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default DashboardLayout;
