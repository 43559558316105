import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './PrinterDetailOverview.scss';
import Tabs from '../../../Tabs/Tabs';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { TabPanel } from '../../../Tabs/components';

import { getPrinterData } from '../../../../redux/printer/reducer';
import { Grid, Button } from '@material-ui/core';
import Form from '../../../Form';
import { TextInput, FormLabel, ControllerInput, SelectInput, SubmitButton, DateInput } from '../../../Form/components';
import _ from 'lodash';
import * as Yup from 'yup'
import { useFetchService } from '../../../../hooks/useFetch';
import { ServiceClients } from '../../../../utilities/api/services';
import ServicesPrinters from '../../../../utilities/api/services/Printers';
import { ButtonEdit, ButtonRemove } from '../../../Buttons';
import { AlertDialog } from '../../../Modal';

const validateSchema = Yup.object().shape({
    marca: Yup.string().required('El campo es Requerido')
})


const TechnicalDetailOverview = ({ setTechnicalView }) => {

    const dispatch = useDispatch();
    const { loading, printerData } = useSelector(({ printer: { loading, printerData } }) => ({ loading, printerData }));
    const [openModalDelete, setOpenModalDelete] = useState(false);

    const [modeEdit, setModeEdit] = useState(false)


    const params = useParams();
    const history = useHistory();

    const { response: listGamaServer, loading: loadingGama } = useFetchService(ServiceClients.getGamaMachine, null, []);

    const listGamaMachine = useMemo(() => {
        return _.map(listGamaServer, gm => ({ value: gm.id, label: gm.nombre }));
    }, [loadingGama])


    const { response: listStatesServer, loading: loadingState } = useFetchService(ServiceClients.getStates, null, []);

    const listStateMachine = useMemo(() => {
        return _.map(listStatesServer, state => ({ value: state.id_estado_imp, label: state.estado }));
    }, [loadingState])

    const handleOnSubmit = async (values) => {
        await ServicesPrinters.updatePrinter({ id_printer: params.id, printer: values });
        setModeEdit(false);
    }

    const handleDeletePrinter = async () => {
        await ServicesPrinters.deletePrinter(params.id);
        history.goBack()
    }


    useEffect(() => {
        dispatch(getPrinterData(params.id))
    }, [])

    const handlePathClick = () => {
        history.push('/dashboard/printers');
    }

    return (
        <div className='distriDetailContainer'>
            <div className="distriDetailInner">
                <div className="title">
                    <div className="titleText">Ver Impresora: </div>
                </div>
                <div className='detailContent'>
                    <div className="actualPath">
                        <p className="previous" onClick={handlePathClick}>Impresoras </p>
                        <p className="actual">{"< Impresora"}</p>
                    </div>

                    {!loading &&
                        <Form initialValues={printerData} schemaForm={validateSchema} className={' d-flex flex-column bg-white px-3 py-2 rounded-xl w-100 h-100'} onSubmit={handleOnSubmit}>
                            {
                                !modeEdit ? <ButtonEdit className={'align-self-end'} label={"Editar"} onClick={() => setModeEdit(true)} />
                                    : <ButtonRemove className={'align-self-end'} label={"Eliminar Impresora"} onClick={()=>setOpenModalDelete(true)}></ButtonRemove>
                            }
                            <Grid container className={'w-100 h-100 px-2 py-4'}>
                                <Grid xs={12} className={'d-flex flex-row my-4'}>
                                    <Grid item xs={4}>
                                        <FormLabel>
                                            Marca
                                        </FormLabel>
                                        <ControllerInput
                                            render={TextInput}
                                            name={'marca'}
                                            modeEdit={modeEdit}
                                        />

                                    </Grid>

                                    <Grid item xs={4} >
                                        <FormLabel>
                                            N° de serie
                                        </FormLabel>
                                        <ControllerInput
                                            render={TextInput}
                                            name={'sn'}
                                            modeEdit={modeEdit}
                                        />

                                    </Grid>
                                </Grid>
                                <Grid xs={12} className={'d-flex flex-row my-4'}>
                                    <Grid item xs={4}>
                                        <FormLabel>
                                            Fecha De Creacion
                                         </FormLabel>
                                        <ControllerInput
                                            render={DateInput}
                                            name={'date_create'}
                                            modeEdit={modeEdit}
                                        />

                                    </Grid>

                                    <Grid item xs={4} >
                                        <FormLabel>
                                            Modelo
                                        </FormLabel>
                                        <ControllerInput
                                            render={TextInput}
                                            name={'modelo'}
                                            modeEdit={modeEdit}
                                        />

                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormLabel>
                                            Version
                                </FormLabel>
                                        <ControllerInput
                                            render={TextInput}
                                            name={'version'}
                                            modeEdit={modeEdit}
                                        />

                                    </Grid>
                                </Grid>

                                <Grid xs={12} spacing={2} className={'d-flex flex-row my-4'}>
                                    <Grid item xs={4}>
                                        <FormLabel>
                                            Gama de Maquina
                                      </FormLabel>
                                        <ControllerInput
                                            render={SelectInput}
                                            name={'id_gama'}
                                            list={listGamaMachine}
                                            modeEdit={modeEdit}
                                            className={'w-auto'}
                                            classNameContainer={'w-max-contend'}

                                        />

                                    </Grid>

                                    <Grid item xs={4} >
                                        <FormLabel>
                                            Estado
                                    </FormLabel>
                                        <ControllerInput
                                            render={SelectInput}
                                            list={listStateMachine}
                                            name={'id_estado'}
                                            className={'w-auto'}
                                            classNameContainer={'w-max-contend'}

                                            modeEdit={modeEdit}

                                        />

                                    </Grid>

                                </Grid>

                                <Grid xs={12} className={'d-flex flex-row my-4'}>
                                    <Grid item xs={4}>
                                        <FormLabel>
                                            N° de copias a Total
                                         </FormLabel>
                                        <ControllerInput
                                            render={TextInput}
                                            name={'copias_total'}
                                            modeEdit={false}
                                            defaultValue={0}
                                        />

                                    </Grid>

                                    <Grid item xs={4} >
                                        <FormLabel>
                                            N° de copias a color
                                        </FormLabel>
                                        <ControllerInput
                                            render={TextInput}
                                            name={'copias_color'}
                                            modeEdit={false}
                                            defaultValue={0}
                                        />

                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormLabel>
                                            N° de copias en B y N
                                        </FormLabel>
                                        <ControllerInput
                                            render={TextInput}
                                            name={'copias_BN'}
                                            modeEdit={false}
                                            defaultValue={0}
                                        />

                                    </Grid>
                                </Grid>


                            </Grid>

                            {
                                modeEdit &&
                                <div className="formButtons">
                                    <Button variant="contained" onClick={() => setModeEdit(false)} className="prevButton btn">Cancelar</Button>
                                    <SubmitButton labelButton={'Guardar Cambios'}  ></SubmitButton>
                                </div>
                            }


                        </Form>
                    }

                    <AlertDialog text={`Seguro que desea eliminar la impresora`} isOpen={openModalDelete}
                        handleYes={handleDeletePrinter}
                        handleNo={() => setOpenModalDelete(false)} />

                </div>
            </div>
        </div>
    )
}

export default TechnicalDetailOverview;