import React from 'react';
import Form from '../../../../Form';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { nextStepClientCreate, prevStepClientCreate, finishClientCreate, /* ClientCreated */ } from '../../../../../redux/client/clientReducer';
import { initSubscription } from '../../../../../redux/cobrospagos/paymentReducer';
import { SubmitButton, TextInput, ControllerInput, FormLabel, TextArea } from '../../../../Form/components';
import { Button, Grid } from '@material-ui/core';
import { ServiceClients } from '../../../../../utilities/api/services';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const validationSchema = Yup.object().shape({
    nombre_contacto: Yup.string().required('Nombre de Contacto Requerido'),
    pagina_web: Yup.string(),//.required('Pagina web Requerida'),
    telefono: Yup.string().required('Telefono Es Requerido'),
    movil: Yup.string().required('Movil Requerido'),
    notas: Yup.string()//.required('Notas Requerido'),

});


const Step4 = () => {

    const { data } = useSelector(state => state.distributor.distributorCreate);
    const dispatch = useDispatch();

    const router = useHistory();

    const { enqueueSnackbar } = useSnackbar();


    const handleNextStep = async (values) => {
        const data = values;
        const { id } = await ServiceClients.created({ client: data });
        console.log(id);
        data.id = id;
        dispatch( nextStepClientCreate(data) );
        dispatch( initSubscription(data) )
        //dispatch(finishClientCreate());
        //dispatch(ClientCreated());
        //router.push('/dashboard/clients');
        //enqueueSnackbar('Cliente Creado con Exito',{variant:"success"})
    }

    const handleRedirection = () =>{

    }

    const handleConfirmSubscription = () => { 

    }

    const handleCancelCreated = () => {
        dispatch(prevStepClientCreate())
    }

    return (
        <Form initialValues={data} className={''} schemaForm={validationSchema} onSubmit={handleNextStep}>

            <Grid container className={'d-flex flex-column align-items-center my-3 '}>
                <Grid item xs={12} className={'d-flex flex-row'}>
                    <Grid item xs={6} className={'mr-3'}>
                        <FormLabel className="text-primary">
                            Pesona de contacto *
                    </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'nombre_contacto'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>
                    <Grid item xs={6} className={''}>
                        <FormLabel className="text-primary">
                            Pagina Web
                    </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'pagina_web'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>
                </Grid >

                <Grid item xs={12} className={'d-flex mt-4 flex-row'}>
                    <Grid item xs={6} className={'mr-3'}>
                        <FormLabel className="text-primary">
                            Telefono *
                    </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'telefono'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>
                    <Grid item xs={6} className={''}>
                        <FormLabel className="text-primary">
                            Movil *
                    </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={'movil'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>
                </Grid >



                <Grid item xs={12} className={'mt-4 w-50'}>
                    <Grid item xs={12} className={'w-100'}>
                        <FormLabel className="text-primary">
                            Notas
                    </FormLabel>
                        <ControllerInput
                            render={TextArea}
                            name={'notas'}
                            modeEdit={true}
                            className={'w-100'}
                        />
                    </Grid>
                </Grid >





            </Grid>

            <div className="formButtons">
                <Button variant="contained" onClick={handleCancelCreated} className="prevButton btn">Volver</Button>
                <SubmitButton labelButton={'Siguiente'}></SubmitButton>
            </div>


        </Form >
    )
}

export default Step4
