import React from 'react';
import Form from '../../../../../../../Form';
import { Grid } from '@material-ui/core'
import _ from 'lodash';
import * as  Yup from 'yup'
import { FormLabel, ControllerInput, SubmitSubForm, TextInput, TextArea, SelectInput, DateInput } from '../../../../../../../Form/components';
import useLocalStorage from '../../../../../../../../hooks/useLocalStorage';
import { useFetchService } from '../../../../../../../../hooks/useFetch';
import ServiceClients from '../../../../../../../../utilities/api/services/Clients';
import Swicth from '../../../../../../../Form/components/Switch';
import { useMemo } from 'react';





const validateSchema = Yup.object().shape({})

const Contract = ({ initialValues = {}, onSubmit }) => {


    const modeEdit = true;

    const [user] = useLocalStorage('user');

    const { response: ratesServer, loading: loadingRates } = useFetchService(ServiceClients.getRates, user.tipo_cliente, []);

    const { response: paymentMethodsServer, loading: loadingPaymentMethods } = useFetchService(ServiceClients.getPaymentMethods, '', []);


    const listRates = useMemo(() => {
        return ratesServer.map(rate => ({ label: rate.nombre, value: rate }))
    }, [loadingRates]);



    const listPaymentMethods = useMemo(() => {
        return paymentMethodsServer.map(paymentMethod => ({ label: paymentMethod.nombre, value: paymentMethod.id }));
    }, [loadingPaymentMethods]);

    const refForm = React.useRef();


    const [rate, setRate] = React.useState({})


    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            className={'w-100'}
            innerRef={refForm}
            schemaForm={validateSchema}
        >

            <Grid className={'px-6'} spacing={1} direction={'row'} >

                <Grid item xs={12} className={'d-flex flex-row mt-4'}  >

                    <Grid item xs={3} className={'mt-1 '}>
                        <FormLabel className="text-primary">
                            Tarifa
                        </FormLabel>
                        <ControllerInput
                            render={SelectInput}
                            name={"rate"}
                            defaultValue={''}
                            list={listRates}
                            loading={loadingRates}
                            className={'w-75'}
                            classNameContainer={'w-75'}
                            modeEdit={modeEdit}
                            onChange={(value) => setRate(value)}
                            functionCompare={(value, element) => value.id_tarifa === element.id_tarifa}
                        />

                    </Grid >

                    <Grid item xs={3} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Tiempo de respuesta
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"tiempo_respuesta"}
                            modeEdit={true}
                            className={'w-75'}
                        />

                    </Grid >

                    <Grid item xs={3} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Fecha de inicio de contrato
                        </FormLabel>
                        <ControllerInput
                            render={DateInput}
                            name={"fecha_ini"}
                            modeEdit={modeEdit}
                            className={'w-75'}
                        />

                    </Grid >

                    <Grid item xs={3} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Duracion en Meses
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"meses_renovacion"}
                            type={"text"}
                            modeEdit={modeEdit}
                        />

                    </Grid >


                </Grid>

                <Grid item xs={12} className={'d-flex flex-row mt-4'} >

                    <Grid item xs={12} className={'mt-1'}>


                        <FormLabel className="text-primary">
                            Precion por copia B y N
                        </FormLabel>
                        <TextInput
                            value={refForm.current?.watch('rate.precio_blancoynegro', '')}
                            readOnly={true}
                            className={'w-75'}

                        />


                    </Grid >

                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Precio por copia color
                        </FormLabel>

                        <TextInput
                            value={refForm.current?.watch('rate.precio_color', '')}
                            readOnly={true}
                            className={'w-75'}

                        />

                    </Grid >

                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Impresiones Incluidas
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"imprecinesIncluidas"}
                            readOnly={true}
                            className={'w-75'}
                        />

                    </Grid >

                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Consumo minimo
                        </FormLabel>

                        <TextInput
                            value={refForm.current?.watch('rate.consumo_minimo', '')}
                            readOnly={true}
                            className={'w-75'}

                        />


                    </Grid >

                </Grid>

                <Grid item xs={12} className={'d-flex flex-row mt-4'} >

                    <Grid item xs={4} className={'mt-1 '}>

                        <FormLabel className="text-primary">
                            Mantenimiento de equipo
                        </FormLabel>
                        <ControllerInput
                            render={Swicth}
                            name={"mantenimiento_equipo"}
                            defaultValue={''}
                            modeEdit={modeEdit}

                        />


                    </Grid >



                    <Grid item xs={4} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Precio por mantenimiento
                        </FormLabel>

                        <TextInput
                            value={refForm.current?.watch('rate.precio_mantenimiento', '')}
                            readOnly={true}
                            className={'w-75'}

                        />



                    </Grid >



                    <Grid item xs={4} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Notas
                        </FormLabel>
                        <ControllerInput
                            render={TextArea}
                            name={"notas"}
                            modeEdit={modeEdit}
                            rows={4}
                            className={'w-75'}
                        />

                    </Grid >



                </Grid>

                <Grid item xs={12} className={'d-flex flex-row mt-4'} >

                    <Grid item xs={3} className={'mt-1 '}>

                        <FormLabel className="text-primary">

                            Formas de pago
                        </FormLabel>
                        <ControllerInput
                            render={SelectInput}
                            loading={loadingPaymentMethods}
                            list={listPaymentMethods}
                            name={"forma_pago_id"}
                            className={'w-75'}
                            classNameContainer={'w-75'}

                            modeEdit={modeEdit}
                        />

                    </Grid >

                    <Grid item xs={3} className={'mt-1 '}>

                        <FormLabel className="text-primary">
                            Nombre de Banco
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"banco"}
                            className={''}
                            modeEdit={modeEdit}
                        />

                    </Grid >

                    <Grid item xs={6} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            N° de cuenta bancaria
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"cuenta"}
                            modeEdit={modeEdit}
                            className={'w-75'}
                        />

                    </Grid >

                </Grid>

                <Grid item xs={12} className={'mt-4'} >

                    <FormLabel className="text-primary">
                        Contrato en PDF
                    </FormLabel>

                </Grid >



            </Grid>



            {_.isEmpty(initialValues) &&
                <div className="formButtons mr-6 ">
                    <SubmitSubForm onClick={onSubmit}  >Crear Contrato</SubmitSubForm>
                </div>
            }
        </Form>
    );
};


export default Contract;