import React from 'react';
import classNames from 'classnames';
import './TextInput.scss';
import ErrorMessage from '../../../Text/ErrorMessage';
import _ from 'lodash'

const TextInput = ({ onChange, value = "", defaultValue, className, autocomplete = "off", readOnly = false, modeEdit = false, errorMessage, errors, firstAutofocus, ...props }) => {


    const handleOnChange = (e) => {
        onChange(e.target.value)
    }

    const refInput = React.useRef(null);

    React.useEffect(() => {
        if (firstAutofocus) {
            refInput.current.focus();
        }
    }, []);


    const valueInput = React.useMemo(() => {
        return _.isNil(value) ? "" : value;
    }, [value])

    return (
        <>
            <input
                className={classNames(`TextInputEcoColor ${className}`, { 'modeEditTextInputEcoColor': modeEdit })}
                onChange={handleOnChange}
                value={valueInput}
                {...props}
                readOnly={!modeEdit || readOnly}
                {...{ defaultValue }}
                ref={refInput}
                autocomplete={autocomplete}
            >
            </input>
            {errorMessage && <ErrorMessage message={errorMessage} />}
        </>
    )
}

export default TextInput;
