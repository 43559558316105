import React, { useState, useEffect, useRef } from 'react';
import Form from '../../../../../Form';
import { TextInput, ControllerInput, FormLabel, ButtonState, SubmitButton } from '../../../../../Form/components';
import { ButtonEdit, ButtonRemove } from '../../../../../Buttons'
import { Button, Grid } from '@material-ui/core';
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import '../View.scss';
import { ServiceDistributors, ServiceClients } from '../../../../../../utilities/api/services';
import { SuccessDialog, AlertDialog } from '../../../../../Modal';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { updateClientDate, getDataClientDetail } from '../../../../../../redux/client/clientReducer';
import usePermissionAccess from '../../../../../../hooks/usePermissionAccess';

const validateSchema = Yup.object().shape({
    nombre: Yup.string().required('El Nombre es requerido'),
    cif: Yup.string().required('El CIF es requerido'),
    direccion_fiscal: Yup.string().required('La Dirrecion es requerida'),
    codpost: Yup.string().required('El Codigo Postal es requerido'),
    pass: Yup.string().required('La contrasenia  es requerida'),
    pais: Yup.string().required('El Pais  es requerido'),
    poblacion: Yup.string().required('La Poblacion  es requerida'),
    provincia: Yup.string().required('La Provincia  es requerida'),
})


const ClientData = ({ data, goToTable, clientDetail }) => {


    const dispatch = useDispatch()
    const [modeEdit, setModeEdit] = useState(false);
    const clientData = useSelector(state => state.client.clientData);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalChangeState, setOpenModalChangeState] = useState(false);

    const params = useParams();
    const history = useHistory();

    useEffect(()=>{
        //dispatch( getDataClientDetail(params.id) );
        console.log("client Contact: ");
        console.log(clientDetail);
    }, []);

    const handleSubmit = async (values) => {

        //revisar los servicios 
        console.log("contactdata", values);
        await ServiceClients.updateDataClient({ id: params.id, client: values });
        dispatch(updateClientDate(values));
        setOpenModalSuccess(true);
        setModeEdit(false)
    }

    const handleDeleteDistributor = async () => {
        await ServiceClients.deleteClient(params.id);
        history.goBack();
    }

    const refForm = useRef();

    const handleChangeState = async () => {
        const values = refForm.current.watch();
        await ServiceDistributors.updateDataDistributor(values, params.id);
        dispatch(updateClientDate(values));
        setOpenModalChangeState(false);
        setModeEdit(false)
    }

    const handleCloseModalChangeState = () => {
        const state = refForm.current.watch('estado_cliente');
        refForm.current.setValue('estado_cliente', state ? 0 : 1);
        setTimeout(() => setOpenModalChangeState(false), 100);
    }

    const { permission } = usePermissionAccess("CLIENT", "EDIT");


    return (
        <>
            <Form
                innerRef={refForm}
                initialValues={clientData}
                schemaForm={validateSchema}
                onSubmit={handleSubmit}
                className={'containerView'}  >

                {permission && < >
                    {
                        !modeEdit ? <ButtonEdit className={'align-self-end'} label={"Editar"} onClick={() => setModeEdit(true)} />
                            : <ButtonRemove className={'align-self-end'} label={"Eliminar Cliente"} onClick={() => setOpenModalDelete(true)}></ButtonRemove>
                    }
                </>}
                <Grid spacing={1} style={{ display: "flex", flexDirection: "flex-row" }} >
                    <Grid item xs={12}  >
                        <Grid item xs={12} className={'mt-1'}  >
                            <FormLabel className="text-primary">
                                Nombre Fiscal
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"nombre"}
                                modeEdit={modeEdit}
                                className={''}
                            />
                        </Grid >




                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                CIF
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"cif"}
                                modeEdit={modeEdit}
                            />


                        </Grid >
                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Contraseña
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"pass"}
                                type={"password"}
                                modeEdit={modeEdit}
                            />

                        </Grid >

                    </Grid>

                    <Grid item xs={12}  >
                        <Grid item xs={12} className={'mt-1'} >
                            <FormLabel className="text-primary">
                                País
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"pais"}
                                modeEdit={modeEdit}
                            />
                        </Grid >

                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Provincia
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"provincia"}
                                modeEdit={modeEdit}
                            />

                        </Grid >


                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Poblacion
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"poblacion"}
                                modeEdit={modeEdit}
                            />


                        </Grid >


                    </Grid>

                    <Grid item xs={12}  >

                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Direccion
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"direccion_fiscal"}
                                modeEdit={modeEdit}
                                className={'w-75'}
                            />

                        </Grid >


                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                C.P
                            </FormLabel>
                            <ControllerInput
                                render={TextInput}
                                name={"codpost"}
                                modeEdit={modeEdit}
                            />


                        </Grid >
                        <Grid item xs={12} className={'mt-1'}>
                            <FormLabel className="text-primary">
                                Estado
                            </FormLabel>
                            <ControllerInput
                                render={ButtonState}
                                name={"estado_cliente"}
                                disabled={!modeEdit}
                                defaultValue={false}

                                onChange={() => {
                                    setOpenModalChangeState(true)
                                }}
                            />
                        </Grid >

                    </Grid>


                </Grid>
                {
                    modeEdit &&
                    <div className="formButtons">
                        <Button variant="contained" onClick={() => setModeEdit(false)} className="prevButton btn">Cancelar</Button>
                        <SubmitButton  >Guardar Cambios</SubmitButton>
                    </div>

                }

                <AlertDialog text={`Seguro que desea Eliminar al Clientes:`} name={clientData.nombre} isOpen={openModalDelete} handleYes={handleDeleteDistributor} handleNo={() => setOpenModalDelete(false)} />
                <AlertDialog text={refForm?.current?.watch('estado_cliente') ? `Seguro que desea Activar al distribuidor:` : `Seguro que desea Suspender al distribuidor:`} name={clientData.nombre} isOpen={openModalChangeState}
                    handleYes={handleChangeState}
                    handleNo={handleCloseModalChangeState} />
                {/*<AlertDialog text={`Seguro que desea Activar al distribuidor:`} name={detailDist.nombre} isOpen={activeDist} handleYes={suspendedD} handleNo={onChangeEdit} />
            */}
            </Form >

            <SuccessDialog isOpen={openModalSuccess} handleClose={() => setOpenModalSuccess(false)} text={"Cambios Guardados con Exito"} />

        </>
    )
}

export default ClientData
