import React from "react";
import "./TotalUsers.scss";
import infiniteLogo from "../../assets/dashboard/home/infinite.svg";

const TotalUsers = ({ total, label, upsAndDowns }) => {
  return (
    <div className="totalUsers">
      <div className="totalUsersInner">
        <div className="title">{label}</div>
        <div className={"total center"}>

          <div className="text">
            <p className="first">
              Total
              <br />
              usuarios
            </p>
            <p className="second">
              Altas y<br />
              bajas
            </p>
          </div>
          <div className="infinite">
            <img src={infiniteLogo} alt="infiniteLogo" />
            <div className="insideText">
              <p>{total}</p>
              <p className="red">{upsAndDowns}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalUsers;
