import React from "react";
import "./ForgotPage.scss";
import ForgotTemplate from "../../components/ForgotTemplate/ForgotTemplate";
import AuthHeader from "../../components/AuthHeader/AuthHeader";


const ForgotPage = () => {
  return (
    <div className="forgotPageContainer">
      <AuthHeader />
      <div className="forgotPageContent">
        <ForgotTemplate />
      </div>
    </div>
  );
};

export default ForgotPage;
