import React from 'react';
import { Switch } from '@material-ui/core';
import './Switch.scss';



const Swicth = ({ value = false, onChange, defaultValue = false, className, name }) => {

    const handleOnChange = (e) => {
        onChange(e.target.checked);
    }


    return (
        <Switch
            className={`${className}`}
            classes={{
                checked:"text-primary",
                track:"bg-primary"
            }}
            defaultChecked={defaultValue}
            size="small"
            checked={value}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={handleOnChange} />
    )
}

export default Swicth
