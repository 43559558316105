import React, { useState, useMemo } from 'react';
import { Button, Grid } from '@material-ui/core';
import './PrinterCreate.scss'
import Form from '../../../Form';
import * as Yup from 'yup';
import { FormLabel, ControllerInput, TextInput, SelectInput, DateInput, SubmitButton, TextArea } from '../../../Form/components';
import _ from 'lodash'
import { useFetchService } from '../../../../hooks/useFetch';
import { ServiceClients, ServicePrinters, ServiceTickets } from '../../../../utilities/api/services';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonAdd, ButtonEdit, ButtonRemove, CreateButton } from '../../../Buttons';
import moment from "moment";
import Line from '../../../Line';
import { useSelector } from 'react-redux';
import { parseDate, parseDateComplete } from '../../../../utilities/parse';


import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

const validateSchema = Yup.object().shape({

})


const TicketView = ({ setPrinterView }) => {

    const user = useSelector(state => state.user.actualUser.idCliente);

    const params = useParams();


    const { response: dataChatTickets, refetch } = useFetchService(ServiceTickets.getDataChatTickets, { user, id_ticket: params.id }, {});
    const [messages, setMessages] = useState([]);

    React.useEffect(() => {
        if (!_.isEmpty(dataChatTickets)) {
            setMessages(_.get(dataChatTickets, 'mensajes'), []);
        }

    }, [JSON.stringify(dataChatTickets)])


    const dataTicket = React.useMemo(() => {
        return _.last(_.get(dataChatTickets, 'data_ticket', []))
    }, [JSON.stringify(dataChatTickets)])

    //{"chat_id": chatId, "mensage": mensaje, "id_ticket": id_ticket, "estado": estaod}

    const handleOnSubmit = async (values) => {

        const data = {
            mensage: values.message,
            chat_id: _.uniqueId(),
            "id_ticket": params.id,
            "estado": 1

        }
        await ServiceTickets.responseTicket({ user, data });
        setIsModeResponse(false)
        refetch();

    }

    const [isModeResponse, setIsModeResponse] = useState(false);



    return (

        <>

            <div className={'d-flex flex-column flex-align-center flex-justify-center'} >
                <div className="title text-center">
                    <h2 className={'text-primary'}>Ver Tickets</h2>
                </div>


                <div className="containerViewTicket   mx-8 mt-4 pb-5">
                    <Form initialValues={{}} schemaForm={validateSchema} className={'w-100 h-100'} onSubmit={handleOnSubmit}>

                        <Grid container className={'w-100 h-100 px-2 py-4'}>
                            <Grid xs={12} className={'d-flex flex-row my-4 px-6'}>
                                <Grid xs={6} className={'w-100 d-flex flex-row '}>
                                    <FormLabel className={'mb-0  d-flex align-items-center text-gray-light'}>
                                        Tipo de Ticket:
                                        <span className={'ml-2 d-flex align-items-center text-gray-light'}>
                                            {_.get(dataTicket, 'tipo_ticket')}
                                        </span>
                                    </FormLabel>
                                </Grid>
                                <Grid xs={6} className={'w-100 d-flex flex-row '}>
                                    <FormLabel className={'mb-0  d-flex align-items-center text-gray-light'}>
                                        Tipo de Error:
                                        <span className={'ml-2 d-flex align-items-center text-gray-light'}>
                                            {_.get(dataTicket, 'tipo_error')}
                                        </span>
                                    </FormLabel>
                                </Grid>
                            </Grid>

                            <Grid xs={12} className={'d-flex flex-row my-4 px-6'}>

                                <Grid xs={6} className={'w-100 d-flex flex-row '}>
                                    <FormLabel className={'mb-0  d-flex align-items-center text-gray-light'}>
                                        Enviado por:
                                        <span className={'ml-2 d-flex align-items-center text-gray-light'}>
                                            {_.get(dataTicket, 'nombre')}
                                        </span>
                                    </FormLabel>
                                </Grid>
                                <Grid xs={6} className={'w-100 d-flex flex-row '}>
                                    <FormLabel className={'mb-0  d-flex align-items-center text-gray-light'}>
                                        Fecha De Emision:
                                        <span className={'ml-2 d-flex align-items-center text-gray-light'}>
                                            {parseDate(_.get(dataTicket, 'fecha'))}
                                        </span>
                                    </FormLabel>
                                </Grid>
                            </Grid>
                            <Line />
                            <ContainerMessages messages={_.get(dataChatTickets, 'mensajes', [])} dataMessage={_.get(dataChatTickets, 'data_ticket', [])} />

                            <Line />
                            {
                                isModeResponse &&
                                <Grid xs={12} className={'w-100 my-4 d-flex flex-row '}>
                                    <Grid xs={1}>
                                        <FormLabel className={'mb-0  d-flex align-items-center text-gray-light'}>
                                            Re:
                                        </FormLabel>
                                    </Grid>
                                    <Grid xs={9}>
                                        <ControllerInput
                                            render={TextArea}
                                            modeEdit={true}
                                            className={'w-100 '}
                                            name={'message'}
                                            rows={6}
                                        />

                                    </Grid>

                                </Grid>
                            }



                        </Grid>

                        <div className="containerButtonsTicket px-5">
                            {isModeResponse ?
                                <div className={'d-flex flex-row align-items-center '}>
                                    <CreateButton className={'mr-4  buttonCancel'} onClick={() => setIsModeResponse(false)} label={"Cancelar"} ></CreateButton>
                                    <CreateButton key="button-send" id="button-send" className={'buttonSend'} label={"Enviar"} type="submit" ></CreateButton>
                                </div>
                                : <div className={'d-flex flex-row align-items-center w-100  containerButtonsTickets'}>
                                    <BasicMenu label={_.get(dataTicket, 'estado')} idTicket={params.id} value={_.get(dataTicket, 'id_estado')} updateTicket={refetch} />
                                    <CreateButton type={"button"} onClick={() => setIsModeResponse(true)} label={"Responder"} ></CreateButton>
                                </div>}


                        </div>



                    </Form>

                </div>
            </div>
        </>
    )
}

export default TicketView;


const ContainerMessages = ({ messages, dataMessage, }) => {


    return (
        <div className='w-100 containerMessages Scrollbar'>
            {messages.map((message, index) => {

                const nombre = _.get(dataMessage, `[${index}].nombre`, '');

                return (
                    <React.Fragment key={JSON.stringify(message)}>
                        <Grid xs={12} className='w-100 mt-3 d-flex '>
                            <Grid xs={1}>
                                <FormLabel className={'pt-2'}>
                                    Mensaje:
                                </FormLabel>
                            </Grid>
                            <Grid xs={9}>
                                <p className={'mt-2'}>{message.mensage}</p>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Chip size="small" avatar={<Avatar>{nombre[0]}</Avatar>} label={nombre} />
                            <Chip className="ml-3" size="small" variant={"outlined"} label={`${parseDateComplete(message.fecha)}`} />

                        </Grid>
                    </React.Fragment>
                )
            })}
        </div>
    )
};

function BasicMenu({ label, value, idTicket, updateTicket }) {
    const [anchorEl, setAnchorEl] = React.useState(null);



    const open = Boolean(anchorEl);




    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeState = (status) => async () => {
        handleClose();
        await ServiceTickets.setStatusTicket({ id_ticket: idTicket, status });
        updateTicket()
    }


    return (
        <div>
            <Button
                id="basic-button"
                color={"primary"}
                variant="outlined"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={"btn px-4"}
            >
                {label}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {value !== 1 && <MenuItem onClick={handleChangeState(1)}>{"Abierto"}</MenuItem>}
                {value !== 2 && <MenuItem onClick={handleChangeState(2)}>{"Proceso"}</MenuItem>}
                {value !== 3 && <MenuItem onClick={handleChangeState(3)}>{"Cerrado"}</MenuItem>}
            </Menu>
        </div>
    );
}