import React, { useState } from 'react'
import Form from '../../../../../../Form'
import * as Yup from 'yup'
import { useFetchService } from '../../../../../../../hooks/useFetch'
import { ServiceClients } from '../../../../../../../utilities/api/services'
import { FormLabel, ControllerInput, InputTime, TextArea, SubmitButton } from '../../../../../../Form/components'
import { Grid, Button } from '@material-ui/core'
import { ButtonEdit } from '../../../../../../Buttons';
import classNames from 'classnames'

const validationSchema = Yup.object().shape({
    horarios: Yup.array().of(Yup.object().shape({
        horario_ini: Yup.string().required('El Horario de Inicio es Requerido').matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { message: "Es un Horario Invalido" }),
        horario_fin: Yup.string().required('El Horario de fin es Requerido').matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, { message: "Es un Horario Invalido" })
    }))
})


const TechsSchedules = ({ idAddressCommercial }) => {


    const [modeEdit, setModeEdit] = useState(false)

    const handleSubmit = async (values) => {

        values.horarios.forEach((schedule, index) => {
            schedule.dia = index + 1;
        })

        await ServiceClients.updateTechSchedules({ id: idAddressCommercial, schedules: values });

        setModeEdit(false)

    }

    const { response: techsSchedulesData, loading } = useFetchService(ServiceClients.getTechsSchedules, idAddressCommercial, []);

    const days = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingos'];

    return (
        <>

            {!loading &&
                <Form
                    className={"d-flex flex-column bg-white pt-4"}
                    onSubmit={handleSubmit}
                    initialValues={{ horarios: techsSchedulesData }}
                    schemaForm={validationSchema}>

                    {!modeEdit && <ButtonEdit
                        label={'Editar'}
                        onClick={() => setModeEdit(true)}
                        className={'align-self-end mr-4'}
                    />}

                    <div className={'w-100 d-flex flex-row '}>
                        {techsSchedulesData.map((techSchedule, index) => {
                            return (
                                <Grid style={{ width: '5rem' }} xs key={JSON.stringify(techSchedule)}
                                    className={classNames('d-flex flex-column px-4 pb-3 border-right', { 'border-primary': index !== 6 })}>
                                    <label className={'text-center my-3 text-light-blue'}>{days[index]}</label>
                                    <div className={'d-flex flex-column mt-5'}>
                                        <FormLabel className={'text-center'}>
                                            Desde
                                        </FormLabel>
                                        <ControllerInput
                                            render={InputTime}
                                            name={`horarios[${index}].horario_ini`}
                                            className={'text-center '}
                                            modeEdit={modeEdit}
                                        />
                                    </div>



                                    <div className={'d-flex flex-column mt-5'}>
                                        <FormLabel className={'text-center'}>
                                            Hasta
                                    </FormLabel>
                                        <ControllerInput
                                            render={InputTime}
                                            name={`horarios[${index}].horario_fin`}
                                            className={'text-center'}
                                            modeEdit={modeEdit}
                                        />

                                    </div>


                                    <div className={'d-flex flex-column mt-5'}>
                                        <FormLabel className={'text-center'}>
                                            Notas
                                    </FormLabel>
                                        <ControllerInput
                                            render={TextArea}
                                            name={`horarios[${index}].notas`}
                                            className={'text-center'}
                                            rows={4}
                                            modeEdit={modeEdit}
                                            resize={'none'}
                                        />

                                    </div>


                                </Grid>
                            )
                        })}
                    </div>

                    {
                        modeEdit &&
                        <div className="formButtons mr-5">
                            <Button variant="contained" onClick={() => setModeEdit(false)} className="prevButton btn">Cancelar</Button>
                            <SubmitButton  >Guardar Cambios</SubmitButton>
                        </div>

                    }
                </Form>}
        </>
    )
}

export default TechsSchedules
