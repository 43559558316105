import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./DashTecnic.scss";
import Table from '../../../Table/Table';
import { HeaderColumn } from '../../../Table/components';
import { CreateButton } from '../../../Buttons';
import { Grid, Card } from '@material-ui/core';
import SearchInput from '../../../SearchInput';
import { parseDate } from '../../../../utilities/parse';
import { TextStatus } from '../../../Text'
import { useHistory } from 'react-router-dom';
import { getTechs } from '../../../../redux/technical/technicalReducer';
import usePermissionAccess from '../../../../hooks/usePermissionAccess';

const DashTechnical = ({ setDistributorView }) => {

    const dispatch = useDispatch();
    const tecnicos = useSelector(state => state.tecnico.tecnicos);
    const [searchValue, setSearchValue] = useState('');
    const [valuesOrder, setValuesOrder] = useState({})

    useEffect(() => {
        dispatch(getTechs(searchValue));
    }, [searchValue]);

    const handleOnClikColumn = (field, value) => {
        setValuesOrder(({ [field]: value }))
    }


    const [nameFirtColumn, setNameFieldColumn] = useState('nombre');

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'distrbutorTable',
                style: { display: 'none' },
                id: 'table',
                columns: [
                    {
                        Header: () => {
                            return (<HeaderColumn onClick={(value) => handleOnClikColumn("nombre", value)} headerName={"Nombre"} />)
                        },
                        accessor: "nombre",

                    },
                    {
                        Header: () => <HeaderColumn headerName={'Fecha De Creacion'} onClick={(value) => handleOnClikColumn('date_create', value)} />,
                        accessor: 'date_create',
                        Cell: ({ row }) => {
                            return <>{parseDate(row.values.date_create)}</>
                        }
                    },
                    {
                        Header: "Nombre Del Distribuidor",
                        accessor: 'nombre_distribuidor',
                        Cell: ({ row }) => {
                            return <span>{row.values.nombre_distribuidor || '-'}</span>
                        }
                    },
                    {
                        Header: () => <HeaderColumn headerName={'Estado'} onClick={(value) => handleOnClikColumn('estado_cliente', value)} />,
                        accessor: 'estado_cliente',
                        Cell: ({ row }) => {
                            const { estado_cliente } = row.values;
                            return (
                                <TextStatus state={estado_cliente} />
                            )
                        }
                    }
                ],
            },
        ],
        [nameFirtColumn]
    )

    const history = useHistory()

    const onClickCreate = () => {
        history.push(`/dashboard/tech-create`)
    }

    const handleRowClick = ({ row: { original } }) => {
        history.push(`/dashboard/tech/${original.id_cliente}`)
    }

    const { permission } = usePermissionAccess("TECH", "CREATE")


    return (
        <div className="DashDistributorContainer">
            <div className="distributorInner">
                <div className="title">TENICOS</div>
                <div className={'containerTableDasboard'}>
                    <Card className={'card'}>
                        <Grid
                            container
                            direction="row"
                            justify="space-around"
                            alignItems="center"
                            className={'containerHeaderTable'}
                        >

                            {permission && <CreateButton onClick={onClickCreate} />}
                            <SearchInput searchValue={searchValue} onChageSearch={setSearchValue} placeholder={'search...'} />
                            { /* <Select onChange={handleOnChangeSelect} options={[{ label: "Nombre Fiscal", value: "nombre" }, { label: "Nombre Comercial", value: "nombre_comercial" }]} /> */}

                        </Grid>
                        <Table columns={columns} data={tecnicos} initialState={{ rowState: { onClick: handleRowClick } }} orderByValues={valuesOrder} />
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DashTechnical;
