import React, { useMemo } from 'react';
import _ from 'lodash'

export default function useOrderData({ valuesOrder, data = [] }) {
    const dataOrdered = useMemo(() => {
        if (!_.isEmpty(valuesOrder)) {
            const fields = _.keys(valuesOrder);
            const typeOrders = _.values(valuesOrder);
            return _.orderBy(data, fields, typeOrders);
        }
        return data;
    }, [JSON.stringify(valuesOrder), JSON.stringify(data)])
    return { dataOrdered }
}
