//DEV
  //export const BASE_URL = 'http://localhost:8080';
//PROD  
  export const BASE_URL = 'https://api.ecocolor.es';


// PROD
//export const BASE_URL = "https://node-ecocolor.herokuapp.com";

const URI = {
  DISTRIBUTORS: `${BASE_URL}/distributors`,
  CLIENTS: `${BASE_URL}/clientes`,
  TECHS: `${BASE_URL}/tecnico`,
  USER: `${BASE_URL}/users`,
  PRINTERS: `${BASE_URL}/impresora`,
  RATES: `${BASE_URL}/tarifa`,
  TICKETS: `${BASE_URL}/ticket`,
  FACTURACION: `${BASE_URL}/facturacion`,
  COBROS: `${BASE_URL}/cobrospagos`,
  REDSYS_SANBOX: "https://sis-t.redsys.es:25443/sis/realizarPago", //"https://sis-t.redsys.es:25443/sis/rest/trataPeticionREST", //"https://sis-t.redsys.es:25443/sis/rest/iniciaPeticionREST",
  REDSYS_PROD: "https://sis.redsys.es/sis/rest/iniciaPeticionREST"
};

export default URI;
