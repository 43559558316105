import React from "react";
import "./DashBilling.scss";
import DashTable from "../../DashTable/DashTable";
import { billingHeader, billingRows } from "../../../../utilities/constants";

const DashBilling = ({ setBillingView }) => {
  return (
    <div className="DashDistributorContainer">
      <div className="distributorInner">
        <div className="title">Facturacion</div>
        <DashTable
          setView={setBillingView}
          header={billingHeader}
          row={billingRows}
          bannerOption={5}
        />
      </div>
    </div>
  );
};

export default DashBilling;
