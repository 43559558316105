import React, { useState, useEffect, useRef } from 'react';
import Form from '../../../../../Form';
import { TextInput, ControllerInput, FormLabel, ButtonState, SubmitButton } from '../../../../../Form/components';
import { ButtonEdit, ButtonRemove } from '../../../../../Buttons'
import { Button, Grid } from '@material-ui/core';
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import '../View.scss';
import { ServiceDistributors } from '../../../../../../utilities/api/services';
import { SuccessDialog, AlertDialog } from '../../../../../Modal';

import activeIcon from '../../../../../../assets/dashboard/distributor/active.svg';
import suspendedIcon from '../../../../../../assets/dashboard/distributor/suspended.svg';
import classNames from 'classnames';
import IconCustom from '../../../../../IconCustom'
import { updateDataDistributor } from '../../../../../../redux/distributor/distributorReducer';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const validateSchema = Yup.object().shape({
    nombre: Yup.string().required('El Nombre es requerido'),
    nombre_comercial: Yup.string().required('El Nombre Comercial es requerido'),
    cif: Yup.string().required('El CIF es requerido'),
    direccion_fiscal: Yup.string().required('La Dirrecion es requerida'),
    codpost: Yup.string().required('El Codigo Postal es requerido'),
    pass: Yup.string().required('La contrasenia  es requerida'),
    pais: Yup.string().required('El Pais  es requerido'),
    poblacion: Yup.string().required('La Poblacion  es requerida'),
    provincia: Yup.string().required('La Provincia  es requerida'),
})


const DistributorData = ({ data, goToTable }) => {


    const dispatch = useDispatch()
    const [modeEdit, setModeEdit] = useState(false);
    const distributorDetail = useSelector(state => state.distributor.distributorData);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalChangeState, setOpenModalChangeState] = useState(false);

    const handleSubmit = async (values) => {
        await ServiceDistributors.updateDataDistributor(values, data.id_cliente);
        dispatch(updateDataDistributor(values));
        setOpenModalSuccess(true);
        setModeEdit(false)
    }

    const handleDeleteDistributor = async () => {
        await ServiceDistributors.deleteDistributor(data.id_cliente);
        goToTable()
    }

    const params = useParams()


    const refForm = useRef();

    const handleChangeState = async () => {
        const values = refForm.current.watch();
        await ServiceDistributors.updateDataDistributor(values, params.id);
        dispatch(updateDataDistributor(values));
        setOpenModalChangeState(false);
    }

    const handleCloseModalChangeState = () => {
        const state = refForm.current.watch('estado_cliente');
        refForm.current.setValue('estado_cliente', state ? 0 : 1);
        setTimeout(() => setOpenModalChangeState(false), 100);
    }


    return (
        <>  <Form
            innerRef={refForm}
            initialValues={distributorDetail}
            schemaForm={validateSchema}
            onSubmit={handleSubmit}
            className={'containerView'}  >

            < >
                {
                    !modeEdit ? <ButtonEdit className={'align-self-end'} label={"Editar"} onClick={() => setModeEdit(true)} />
                        : <ButtonRemove className={'align-self-end'} label={"Eliminar Distribuidor"} onClick={() => setOpenModalDelete(true)}></ButtonRemove>
                }
            </>
            <Grid spacing={1} style={{ display: "flex", flexDirection: "flex-row" }} >
                <Grid item xs={12}  >
                    <Grid item xs={12} className={'mt-1'}  >
                        <FormLabel className="text-primary">
                            Nombre Fiscal
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"nombre"}
                            modeEdit={modeEdit}
                            className={''}
                        />
                    </Grid >

                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Nombre Comercial
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"nombre_comercial"}
                            modeEdit={modeEdit}
                        />

                    </Grid >


                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            CIF
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"cif"}
                            modeEdit={modeEdit}
                        />


                    </Grid >
                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Contraseña
                                                </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"pass"}
                            type={"password"}
                            modeEdit={modeEdit}
                        />

                    </Grid >

                </Grid>

                <Grid item xs={12}  >
                    <Grid item xs={12} className={'mt-1'} >
                        <FormLabel className="text-primary">
                            País
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"pais"}
                            modeEdit={modeEdit}
                        />
                    </Grid >

                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Provincia
                         </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"provincia"}
                            modeEdit={modeEdit}
                        />

                    </Grid >


                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Poblacion
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"poblacion"}
                            modeEdit={modeEdit}
                        />


                    </Grid >


                </Grid>

                <Grid item xs={12}  >

                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Direccion
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"direccion_fiscal"}
                            modeEdit={modeEdit}
                            className={'w-75'}
                        />

                    </Grid >


                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            C.P
                        </FormLabel>
                        <ControllerInput
                            render={TextInput}
                            name={"codpost"}
                            modeEdit={modeEdit}
                        />


                    </Grid >
                    <Grid item xs={12} className={'mt-1'}>
                        <FormLabel className="text-primary">
                            Estado
                        </FormLabel>
                        <ControllerInput
                            render={ButtonState}
                            name={"estado_cliente"}
                            disabled={!modeEdit}
                            defaultValue={false}
                            onChange={() => {
                                setOpenModalChangeState(true)
                            }}
                        />
                    </Grid >

                </Grid>


            </Grid>
            {
                modeEdit &&
                <div className="formButtons">
                    <Button variant="contained" onClick={() => setModeEdit(false)} className="prevButton btn">Cancelar</Button>
                    <SubmitButton  >Guardar Cambios</SubmitButton>
                </div>

            }

            <AlertDialog text={`Seguro que desea Eliminar al distribuidor:`} name={distributorDetail.nombre} isOpen={openModalDelete} handleYes={handleDeleteDistributor} handleNo={() => setOpenModalDelete(false)} />
            <AlertDialog text={refForm?.current?.watch('estado_cliente') ? `Seguro que desea Activar al distribuidor:` : `Seguro que desea Suspender al distribuidor:`} name={distributorDetail.nombre} isOpen={openModalChangeState}
                handleYes={handleChangeState}
                handleNo={handleCloseModalChangeState} />
            {/*<AlertDialog text={`Seguro que desea Activar al distribuidor:`} name={detailDist.nombre} isOpen={activeDist} handleYes={suspendedD} handleNo={onChangeEdit} />
            */}
        </Form >

            <SuccessDialog isOpen={openModalSuccess} handleClose={() => setOpenModalSuccess(false)} text={"Cambios Guardados con Exito"} />

        </>
    )
}

export default DistributorData
