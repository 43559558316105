import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./DashClients.scss";
import Table from '../../../Table/Table';
import { HeaderColumn } from '../../../Table/components';
import { CreateButton } from '../../../Buttons';
import { Grid, Card } from '@material-ui/core';
import SearchInput from '../../../SearchInput';
import { parseDate } from '../../../../utilities/parse';
import { TextStatus } from '../../../Text'
import { useHistory } from 'react-router-dom';
import { getClients, finishClientCreate } from '../../../../redux/client/clientReducer';
import { manageRedirectionSubs } from '../../../../redux/cobrospagos/paymentReducer';
import usePermissionAccess from '../../../../hooks/usePermissionAccess';
import { useSnackbar } from 'notistack';

const DashClients = ({ setDistributorView }) => {

    const dispatch = useDispatch();
    const stateSubscription = useSelector( state => state.cobrospagos.stateSubscriptionPayment );
    const clients = useSelector(state => state.client.clients);
    const [searchValue, setSearchValue] = useState('');
    const [valuesOrder, setValuesOrder] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(getClients(searchValue));
    }, [searchValue]);

    useEffect( () => {
        const urlString = window.location.href;
        if ( urlString.includes("successfull_adhesion") )
        {
            completeSubscription();
        }
    }, []);

    const completeSubscription = () =>{
        const search = window.location.search;
        
        if ( !search && (search == null || search == undefined) ){
            alert("problem search")
            return;
        }
        
        const urlParams = new URLSearchParams(search);
        
        if ( !urlParams.get('redirect_flow_id')  && 
            (urlParams.get('redirect_flow_id') == null ||  
             urlParams.get('redirect_flow_id') == undefined) ){
            //window.location.href = "https://ecocolor.es/dashboard/pay";
            return;
        }
        
        const id_redirect = urlParams.get('redirect_flow_id');
        
        dispatch( manageRedirectionSubs( id_redirect ) );
        if ( stateSubscription ){
            enqueueSnackbar('Cliente Creado con Exito',{variant:"success"})
        } 
        //dispatch(finishClientCreate());
        /* console.log('stateSubs:', stateSubscription);

        setTimeout( () => 
                    {
                        if ( !stateSubscription ){
                            alert("problem redirectId")
                            //window.location.href = "https://ecocolor.es/dashboard/pay";
                            return;
                        }

                        console.log("id_redirect:", urlParams.get('redirect_flow_id'));
                        PaymentSuccessPopUp("¡Se ha adherido con éxito!");
                    }
                    , 2000); */
    }

    const handleOnClikColumn = (field, value) => {
        setValuesOrder(({ [field]: value }))
    }


    const [nameFirtColumn, setNameFieldColumn] = useState('nombre');

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'distrbutorTable',
                style: { display: 'none' },
                id: 'table',
                columns: [
                    {
                        Header: () => {
                            const labeColumn = {
                                nombre: "Nombre Fiscal",
                                nombre_comercial: "Nombre Comercial"
                            }
                            return (<HeaderColumn onClick={(value) => handleOnClikColumn(nameFirtColumn, value)} headerName={labeColumn[nameFirtColumn]} />)
                        },
                        accessor: nameFirtColumn,

                    },
                    {
                        Header: () => <HeaderColumn headerName={'Fecha De Creacion'} onClick={(value) => handleOnClikColumn('date_create', value)} />,
                        accessor: 'date_create',
                        Cell: ({ row }) => {
                            return <>{parseDate(row.values.date_create)}</>
                        }
                    },
                    {
                        Header: "CIF",
                        accessor: 'cif',
                    },
                    {
                        Header: () => <HeaderColumn headerName={'Distribuidor'} onClick={(value) => handleOnClikColumn('nombre_distribuidor', value)} />,
                        accessor: 'nombre_distribuidor',
                        Cell: ({ row }) => {
                            return <>{row.values.nombre_distribuidor || '-'}</>
                        }
                    },
                    {
                        Header: () => <HeaderColumn headerName={'Impresoras'} onClick={(value) => handleOnClikColumn('impresoras', value)} />,
                        accessor: 'impresoras',
                    },
                    {
                        Header: () => <HeaderColumn headerName={'Estado'} onClick={(value) => handleOnClikColumn('estado_cliente', value)} />,
                        accessor: 'estado_cliente',
                        Cell: ({ row }) => {
                            const { estado_cliente } = row.values;
                            return (
                                <TextStatus state={estado_cliente} />
                            )
                        }
                    }
                ],
            },
        ],
        [nameFirtColumn]
    )

    const history = useHistory()

    const onClickCreate = () => {
        history.push(`/dashboard/client-create`)
    }

    const handleRowClick = ({ row: { original } }) => {
        history.push(`/dashboard/client/${original.id_cliente}`)
    }

    const handleOnChangeSelect = (value) => {
        setNameFieldColumn(value);
        setSearchValue('')
    }


    const { permission: permisionCreate } = usePermissionAccess("CLIENT", "CREATE");


    return (
        <div className="DashDistributorContainer">
            <div className="distributorInner">
                <div className="title">CLIENTES</div>
                <div className={'containerTableDasboard'}>
                    <Card className={'card'}>
                        <Grid
                            container
                            direction="row"
                            justify="space-around"
                            alignItems="center"
                            className={'containerHeaderTable'}
                        >

                            {permisionCreate && <CreateButton onClick={onClickCreate} />}
                            <SearchInput searchValue={searchValue} onChageSearch={setSearchValue} placeholder={'search...'} />
                            { /* <Select onChange={handleOnChangeSelect} options={[{ label: "Nombre Fiscal", value: "nombre" }, { label: "Nombre Comercial", value: "nombre_comercial" }]} /> */}

                        </Grid>
                        <Table columns={columns} data={clients} initialState={{ rowState: { onClick: handleRowClick } }} orderByValues={valuesOrder} />
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DashClients;
