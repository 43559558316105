const distriTypes = {
    START_FETCH_DISTRIBUTORS: "START_FETCH_DISTRIBUTORS",
    GET_DISTRIBUTORS_SUCCESS: "GET_DISTRIBUTORS_SUCCESS",
    FAILURE: "FAILURE",
    CREATE_DISTRIBUTORS_SUCCESS: "CREATE_DISTRIBUTORS_SUCCESS",
    DELETE_DISTRIBUTORS_SUCCESS: "DELETE_DISTRIBUTORS_SUCCESS",
    CLOSE_DIALOG: "CLOSE_DIALOG",
    SET_OFF_REDIRECT: "SET_REDIRECT_OFF",
    GET_DISTRIBUTORS_BY_ID: "GET_DISTRIBUTORS_BY_ID",
    SELECT_DISTRIBUTOR: "SELECT_DISTRIBUTIR",
    GET_DISTRIBUTOR_DETAIL: "GET_DISTRIBUTOR_DETAIL",
    GET_DATA_DISTRIBUTOR: "GET_DATA_DISTRIBUTOR",
    GET_PERMISSION_DISTRIBUTOR: "GET_PERMISSION_DISTRIBUTOR",
    GET_DATA_BANK: "GET_DATA_BANK",
    START_DATA_BANK: "START_DATA_BANK",
    ACTIVE_DISTRIBUTOR: "ACTIVE_DISTRIBUTOR",
    SUSPENDED_DISTRIBUTOR: "SUSPENDED_DISTRIBUTOR",
    NO_LOADING: "NO_LOADING",
    GET_CLIENT_DISTRIBUTOR: "GET_CLIENT_DISTRIBUTOR",
    UPDATE_DATA_DISTRIBUTOR: "UPDATE_DATA_DISTRIBUTOR",
    //Create Steps
    NEXT_STEP_CREATED:"NEXT_STEP_CREATED",
    PREV_STEP_CREATED:"PREV_STEP_CREATED",
    FINISH_CREATED:"FINISH_CREATED",


}

export default distriTypes;