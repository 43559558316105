import React from 'react';
import roles_access from '../Constants/roles_access'
import { loadState } from '../utilities/storage';
import _ from 'lodash';



export default function useRolesAccess(module: string = "") {


    const getAccessView = (module: string): boolean => {
        const user = loadState('user');
        return roles_access.VIEW[module].includes(_.get(user, 'tipo_cliente', ''));

    }

    const isViewRoleAccess = React.useMemo(() => {
        return module && getAccessView(module);
    }, [module]);


    return { isViewRoleAccess, getAccessView }
}


