import types from "./types";
import { loadSessionState } from "../../utilities/storage";
import ServicesCobros from "../../utilities/api/services/cobros";
import { PaymentFailurePopUp, PaymentSuccessPopUp } from '../../utilities/popUp';

export const initialState = {
  loading: false,
  paymentData: {},
  invoices: [],
  paymentRedsys: {},
  stateSubscriptionPayment: false,
  error: {}
};
export default function cobrospagosReducer(state = initialState, action) {
    switch (action.type) {
      case types.START_FETCH:
        return {
          ...state,
          loading: true,
        };
      case types.GET_PAYMENT:
        return {
          ...state,
          paymentData: action.payload,
          loading: false,
        }  
      /* case types.PAY_REDSYS:
        return {
          ...state,
          responseRedsys: action.payload,
          loading: false,
        } */
      case types.REDSYS_ASSIGNMENT:
        return {
          ...state,
          paymentRedsys: action.payload,
          loading: false,
        }  
      case types.FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }    
      case types.STATE_SUBSCRIPTION:
        return{
          ...state,
          loading: false,
          stateSubscriptionPayment:action.payload
        }
      case types.ASSIGNAMENT_INVOICES:
        return{
          ...state,
          loading:false,
          invoices:action.payload
        }   
      default:
        return state;
    }
  }

export const getPaymentStart = () => ({
  type: types.START_FETCH,
});  

export const setPaymentData = ( data = {} ) => {
  console.log('setPAYmentData:', data, typeof data);
  let factura = ( Object.keys(data).length === 0 || 
                  data === undefined || 
                  data === null ) 
                  ? {} : data; 
  return {
    type: types.GET_PAYMENT,
    payload: factura,
  }
};

export const handleErrorPayment = (error) => ({
  type: types.FAILURE,
  payload: error
})

export const getResponsePayRedsys = (response = {}) => {
  return {
    type: types.PAY_REDSYS,
    payload: response,
  }
};

export const getPaymentOfClient = () => {
    return (dispatch) => {
      dispatch( getPaymentStart() );

      const userJson = localStorage.getItem('user');
      const user = JSON.parse(userJson);

      console.log('dentro de funcion getPaymentOfClient', user );
      
      ServicesCobros.getPendingInvoice( user.idCliente )
      
      .then( res => {
        console.log(' dentro de ThEn de getPaymentOfClient....', res);
        dispatch( setPaymentData( res[0] ) );
      })
      
      .catch( error => {
        dispatch( handleErrorPayment(error) );
      }); 
          
    };
} 

export const getAllInvoices = () => {
  return (dispatch) => {
    dispatch( getPaymentStart() );

    console.log('dentro de funcion getAllInvoices' );    
    ServicesCobros.getAllInvoices()
    
    .then( res => {
      console.log(' dentro de ThEn de getAllInvoices....', res);
      dispatch( assignmentInvoices( res ) );
    })
    
    .catch( error => {
      dispatch( handleErrorPayment(error) );
    }); 
        
  };
} 

export const assignmentPaymentRedsys = (data) => {
    return {
      type: types.REDSYS_ASSIGNMENT,
      payload: data
    }  
}

export const assignmentStateSuscription = (data) => {
  return {
    type: types.STATE_SUBSCRIPTION,
    payload: data
  }  
}

export const assignmentInvoices = (data) => {
  return {
    type: types.ASSIGNAMENT_INVOICES,
    payload: data
  }
}

export const payByRedsys = ( payment ) => {
  return (dispatch) => {
    console.log('dentro de funcion payByRedsys', payment );
    ServicesCobros.sendPaymentByRedsys( payment )
    .then( response => {
      console.log('resp de PAY REDSYS:' + response );
      dispatch( assignmentPaymentRedsys(response) );
    })
  }
}

export const sendPayRedsys = ( payment ) => {
  return (dispatch) => {

    console.log('dentro de funcion sendPayRedsys', payment );
    
    ServicesCobros.processPaymentByRedsys( payment )
    .then( res => res.json() )
    .then( response => {
      /* 
        const res = window.atob( response );
        console.log('respuesta de ""sendPaymentByRedsys"": ', res );
        if ( res.Ds_Response !=  null && res.Ds_Response !=  undefined ){
          dispatch( getResponsePayRedsys(res.Ds_Response) );
          if( res.Ds_Response === '0000' ){
            dispatch( savePaymentSuccess(payment.DS_MERCHANT_ORDER) );
          }        
        }  
      */
        console.log( 'dentro de THEN de sendREDSYS: ' + response );
        dispatch( assignmentPaymentRedsys(response) );
    })
    .catch( error => {
        dispatch(handleErrorPayment(error));
    }); 
        
  };
}   

export const sendPayment = ( invoice, interruptor = true ) => {
  return (dispatch) =>{
    dispatch( getPaymentStart() );
    ServicesCobros.sendPayment( invoice )
    .then(  res   => {
      console.log('res SENDpayment', res);
      if ( interruptor ) PaymentSuccessPopUp(res);
      dispatch( getAllInvoices() );
    })
    .catch( error   => dispatch(handleErrorPayment(error)) )
  }
}

export const allCharge = (invoices) => {
  return (dispatch) =>{
    dispatch( getPaymentStart() );
    for (const invoice of invoices){
        dispatch( sendPayment( invoice.factura, false ) )  
    }
    PaymentSuccessPopUp("Se han realizado cobros exitosamente.")

    /* ServicesCobros.allChargeInvoices()
    .then( res => {
      console.log("dentro de action-redux CHARGE:", res);
      dispatch( getAllInvoices() )
    })
    .catch( error => dispatch(handleErrorPayment(error)));  */
  }     
}

export const savePaymentSuccess = ( invoice = '' ) => {
  return (dispatch) =>{
      ServicesCobros.savePaymentSuccessByRedsys( invoice )
      .then(  res   => console.log(res) )
      .catch( error   => dispatch(handleErrorPayment(error)) )
  }
}

export const getStateSubscription = () => {
  return (dispatch) => {
    dispatch( getPaymentStart() );
    const userJson = localStorage.getItem('user');
    const user = JSON.parse(userJson);
    ServicesCobros.getStateSubscription( user.idCliente )
      .then( res  => {
        let data = res[0]
        data = data.adhesion_debito;
        data = (data === 0 || data === '0') ? false : true; 
        console.log("RES getStateSubs:", data);
        dispatch(assignmentStateSuscription(data)); 
      })
      .catch( err => dispatch(handleErrorPayment(err)) )
  }
}

export const initSubscription = ( user = null ) => {
  return (dispatch) => {
    dispatch( getPaymentStart() );
    if( !user || user === null ){
      const userJson = localStorage.getItem('user');
      const user = JSON.parse(userJson);
    }
    const token = loadSessionState("token");
    //console.log("initSubscription USER:", user);
    ServicesCobros.initSubscriptionPayment( token, user )
      .then(  res  => {
          //console.log("initSubscription RES:", res);
          window.location.href = res.redirect_url;
      })
      .catch( err =>{
          console.log(err); 
          dispatch(handleErrorPayment(err)) 
        })
  }
}

export const manageRedirectionSubs = ( idRedirect, /* user = null */ ) => {
  return (dispatch) => {
    dispatch( getPaymentStart() );
    console.log("dentro MANAGEredirection");
    /* if (!user || user=== null || user === undefined) {
      const userJson = localStorage.getItem('user');
      const user = JSON.parse(userJson);
    } */
    const token = loadSessionState("token");
    ServicesCobros.confirmSubscriptionPayment( /* user.id, */ idRedirect, token )
      .then( res => {
          console.log("RES manageRedirect", res);
          let data = ( 0 === res || res === '0' ) ? false : true;
          console.log("DATAA manageRedirect", data);
          dispatch(assignmentStateSuscription(data));  
        }
      )
      .catch( err =>{
          console.log('error en respuesta a MANAGEredir', err);
          dispatch(assignmentStateSuscription(false));
          dispatch(handleErrorPayment(err));
        }
      )
  }
}