import React, { useState, useEffect } from "react";
import "./Login.scss";
import { useHistory } from "react-router-dom";
import { isValid } from "../../utilities/isValid";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { userLogin, keepUserEmail } from "../../redux/user/userReducer";
import { useSelector, useDispatch } from "react-redux";
import BasicInput from "../../components/BasicInput/BasicInput";
import CustomButton from "../CustomButton/CustomButton";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  //let failedAttempts =useSelector( state => state.user.failed_attemps );
  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
  });
  const { email, password } = userInput;
  const [inputDisable, setInputDisable] = useState(false);

  //Verificar si el usuario ha intentado loguear mas de 5 veces.
  const checkFailedAttemtps = (email) => {
    let loginAttempt = localStorage.getItem(`loginAttempt/${email}`);
    if (loginAttempt >= 5) {
      //setInputDisable(true);
      //history.push('/forgot')
      return true;
    }else{
      return false;
    }
  }


  //para mostrar mensajes de error en el input
  const [inputError, setInputError] = useState({
    emailError: false,
    emailErrorMessage: "",
    passwordError: false,
    passwordErrorMessage: "",
  });
  const {
    emailError,
    emailErrorMessage,
    passwordErrorMessage,
    passwordError,
  } = inputError;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserInput({ ...userInput, [name]: value });
  };

  const handleSubmit = () => {
    if (checkFailedAttemtps(userInput.email)) {
      setInputDisable(true);
      history.push('/forgot')
      return;
    };
    if (isValid(userInput, setInputError, inputError)) {
      dispatch(userLogin(userInput, history.push));
    }
  };

  const handleKeyPress = (target) => {
    if (target.charCode === 13 && isValid(userInput, setInputError, inputError)) {
      dispatch(userLogin(userInput, history.push));
    }
  }

  const onForgotPassword = () => {
    dispatch(keepUserEmail(email));
    history.push("/forgot");
  }

  return (
    <div className="loginContainer">
      <div className="loginInner">
        <div className="title">
          <p>Accede a tu cuenta</p>
        </div>
        <div className="loginForm">
          <div className="textInputs">
            <div>
              <BasicInput
                label={"Email"}
                icon={faUser}
                type="email"
                name="email"
                errorFlag={emailError}
                value={email}
                onChange={handleChange}
                handleKeyPress={handleKeyPress}
                disabled={inputDisable}
              />
              <div className="errorMsg">{emailErrorMessage}</div>
            </div>
            <div>
              <BasicInput
                label={"Contraseña"}
                icon={faLock}
                type="password"
                name="password"
                errorFlag={passwordError}
                value={password}
                onChange={handleChange}
                handleKeyPress={handleKeyPress}
                disabled={inputDisable}
              />
              <div className="errorMsg">{passwordErrorMessage}</div>
            </div>
          </div>
        </div>
        <div>
          <CustomButton text={"Iniciar sesión"} onClick={handleSubmit} disabled={inputDisable} />
        </div>
        <div className="forgot">
          <p onClick={onForgotPassword}>
            ¿Olvidaste tu contraseña?
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
