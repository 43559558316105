import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import "./App.css"
import ChangePage from "./pages/ChangePage/ChangePage"
import DashboardPage from "./pages/DashboardPages/DashboardPage"
import ForgotPage from "./pages/ForgotPage/ForgotPage"
import LoginPage from "./pages/LoginPage/LoginPage"
import ResetPage from "./pages/ResetPage/ResetPage"
import { checkUserSession } from "./redux/user/userReducer"
import "font-awesome/css/font-awesome.css";
import PrivateRoute from './routers/PrivateRoute';
import PublicRoute from './routers/PublicRoute';
import { DashboardRoutes } from './routers/routes'

function App() {
  const isLoggedIn = !!localStorage.getItem('user');

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(checkUserSession())
  })


  return (
    <BrowserRouter>
      <Switch>



      <PublicRoute
          isAuthenticated={isLoggedIn}
          component={LoginPage}
          path={'/'}
          exact
        />

        <PublicRoute
          isAuthenticated={isLoggedIn}
          component={LoginPage}
          path={'/login'}
          exact
        />

        <PublicRoute
          isAuthenticated={isLoggedIn}
          component={ForgotPage}
          path={'/forgot'}
          exact
        />

        <PublicRoute
          isAuthenticated={isLoggedIn}
          component={ResetPage}
          path={'/reset/:id/:token'}
          exact
        />

        <PublicRoute
          isAuthenticated={isLoggedIn}
          component={ChangePage}
          path={'/change'}
          exact
        />



        <DashboardRoutes />


        {/*<Route path="/dashboard/last" render={() => <DashboardPage />} />*/}


        {/* <Route path="/dashboard" render={() => isLoggedIn ?(<DashboardPage />) : (<Redirect to='/login'/>)}/> */}

      </Switch>
    </BrowserRouter>

  )
}

export default App
