import React, { useMemo } from "react";
import IcoMoon from "react-icomoon";
import classesModule from "./Icon.module.scss";

const iconSet = require("./icons/selection.json");
const iconClaraSet = require("./clara/selection.json");
const iconEcoColor = require("./ecoColor/selection.json");

const Icon = ({ isClara = false, type="", ...props }) => {

  const iconset = useMemo(() => {
    switch (type) {
      case "clara":
        return iconClaraSet;
      case "ecoColor":
        return iconEcoColor;
      default:
        return iconSet;
    }
  }, [])



  return <IcoMoon iconSet={iconset} disableFill={true} className={classesModule.Icon} {...props} />;
};

export default Icon;