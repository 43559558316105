import React from 'react';
import Form from '../../../../Form';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { nextStepDistributorCreate, prevStepDistributorCreate } from '../../../../../redux/distributor/distributorReducer';
import { SubmitButton, TextInput, ControllerInput, FormLabel } from '../../../../Form/components';
import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    nombre: Yup.string().required('Nombre Fiscal Es Requerido'),
    nombre_comercial: Yup.string().required('Nombre Comercial Requerido'),
    cif: Yup.string().required('CIF Es Requerido'),
})

const Step1 = () => {

    const { data } = useSelector(state => state.distributor.distributorCreate);
    const dispatch = useDispatch()

    const handleNextStep = (values) => {
        dispatch(nextStepDistributorCreate(values));
    }

    const router = useHistory()
    const handleCancelCreated = () => {
        router.goBack()
    }




    return (
        <Form initialValues={data} className={''} schemaForm={validationSchema} onSubmit={handleNextStep}>

            <Grid container className={'d-flex flex-column align-items-center my-3 '}>
                <Grid item xs={12} className={'w-50'}>
                    <FormLabel className="text-primary">
                        Nombre Fiscal *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        firstAutofocus={true}
                        name={'nombre'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >

                <Grid item xs={12} className={'mt-4 w-50'}>
                    <FormLabel className="text-primary">
                        Nombre Comercial *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={'nombre_comercial'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >

                <Grid item xs={12} className={'mt-4 w-50'}>
                    <FormLabel className="text-primary">
                        CIF *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={'cif'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >

            </Grid>

            <div className="formButtons">
                <Button variant="contained" onClick={handleCancelCreated} className="prevButton btn">Cancelar</Button>
                <SubmitButton labelButton={'Siguiente'}></SubmitButton>
            </div>


        </Form >
    )
}

export default Step1
