import React, { useState } from "react";
import "./BillingOverview.scss";
import DashBilling from "./DashBilling/DashBilling";
import BillingCreate from "./BillingCreate/BillingCreate";
import VIEW from "./BillingView";
import BillingDetailOverview from "./BillingDetailOverview/BillingDetailOverview";

const BillingOverview = () => {
  const [BillingView, setBillingView] = useState(VIEW.HOME);
  return (
    <div className="distriOverview">
      {BillingView === VIEW.HOME ? (
        <DashBilling setBillingView={setBillingView} />
      ) : BillingView === VIEW.DETAIL ? (
        <BillingDetailOverview setBillingView={setBillingView} />
      ) : (
        <BillingCreate setBillingView={setBillingView} />
      )}
    </div>
  );
};

export default BillingOverview;
