import React from 'react';
import Form from '../../../../../../../Form';
import { ControllerInput, SubmitSubForm, TextInput } from '../../../../../../../Form/components';
import { Button, FormLabel, Grid } from '@material-ui/core'
import _ from 'lodash';
import * as  Yup from 'yup'


const validateSchema = Yup.object().shape({})

const Ubication = ({ initialValues = {}, onSubmit }) => {


    const modeEdit = true;

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            className={'w-100'}
            schemaForm={validateSchema}
        >

            <Grid item xs={12} className="text-primary  w-100 d-flex flex-row"  >

                <Grid item xs={6} className={''}>
                    <FormLabel className="text-primary mr-3" >
                        Nombre Ubicacion
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={"nombre"}
                        modeEdit={modeEdit}
                    />

                </Grid>

                <Grid item xs={6} className={''}>
                    <FormLabel className="text-primary mr-3">
                        IP Maquina
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={"ip_impresora"}
                        type={"text"}
                        modeEdit={true}
                    />
                </Grid>

            </Grid>
            {_.isEmpty(initialValues) &&
                <div className="formButtons mr-6 ">
                    <SubmitSubForm onClick={onSubmit}  >Crear Ubicacion</SubmitSubForm>
                </div>
            }
        </Form>
    );
};


export default Ubication;