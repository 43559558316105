import { Button, CircularProgress } from '@material-ui/core';
import React from 'react'
import { useFormContext } from 'react-hook-form';

const SubmitSubForm = ({ onClick, disabled = false, labelButton = '' }) => {

    const { formState, handleSubmit } = useFormContext();

    const { isSubmitting, isValid } = formState;

    return (
        <Button variant="contained" onClick={handleSubmit(onClick)} disabled={disabled || !isValid || isSubmitting} className="nextButton btn" >{labelButton || 'Guardar Cambios'}
            {isSubmitting && <CircularProgress size={"1.75rem"} className={'position-absolute text-primary'} />}
        </Button>

    )
}

export default SubmitSubForm;
