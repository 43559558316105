import React from "react";
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { ReactComponent as SuccessIcon } from "../../assets/login/successIcon.svg";
import { ReactComponent as WarningIcon } from "../../assets/login/warningIcon.svg";
import "./Modal.scss";

export const SuccessDialog = ({ text, isOpen, handleClose }) => {
    return (
        <div className="SuccessDialogContainer">
            <Dialog
                fullWidth={true}
                maxWidth={"xs"}
                open={isOpen}
                onClose={handleClose}
                className="successDialog"
            >
                <DialogContent>
                    <SuccessIcon className="icon" />
                    <DialogContentText className="text">{text}</DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export const AlertDialog = ({ text, name, isOpen, handleYes, handleNo }) => {
    return (
        <div className="SuccessDialogContainer">
            <Dialog
                fullWidth={true}
                maxWidth={"xs"}
                open={isOpen}
                className="successDialog"
            >
                <DialogContent>
                    <WarningIcon className="icon" />
                    <DialogContentText className="text">{text}</DialogContentText>
                    {name ? <DialogContentText className="text">{name}</DialogContentText> : null}
                </DialogContent>

                <DialogActions >
                    <Button onClick={handleYes} variant="contained" color="primary">
                        Si
          </Button>
                    <Button onClick={handleNo} variant="contained" color="secondary">
                        No
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
