import React, { useEffect, useState, useRef } from "react";
import "./BillingCreate.scss";
import { Button } from "@material-ui/core";
import ServicesFacturacion from "../../../../utilities/api/services/facturacionServices";
import VIEW from "../BillingView";

import Form from "../../../Form";
import * as Yup from "yup";
//importing tabpanel
import BillingDetailOverview from "../BillingDetailOverview/BillingDetailOverview";

//importing Tabs
import Tabs from "../../../Tabs/Tabs";
import { TabPanel } from "../../../Tabs/components";
import axios from "axios";
import URI from "../../../../utilities/api/services/uris";

const BillingCreate = ({ setPrinterView }) => {
  const [actualTab, setActualTab] = useState(0);
  const [clientList, setClientList] = useState([]);
  const [commercialAddresseses, setCommercialAddresses] = useState([]);
  const [ubications, setUbications] = useState([]);
  //states de seleccion de cliente
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedComAddress, setSelectedComAddress] = useState(null);
  const [selectedUbication, setSelectedUbication] = useState(null);
  const [billName, setBillName] = useState(null);

  const formRef = useRef();
  const validationSchema = Yup.object().shape({
    estado_cliente: Yup.boolean(),
  });

  const createBill = async () => {
    const response = await ServicesFacturacion.createBill({
      id_cliente: +selectedClient,
      id_direcc_cli: +selectedComAddress,
      // TODO agregar id de distribuidor
      id_distribuidor: 763,
      id_ubicacion: selectedUbication.id_ubica_cli,
      id_impresora: selectedUbication.id_impresora,
    });
    console.log(response);
  };

  const handleSubmit = (event) => {
    createBill();
  };

  // Get ubication
  const getUbication = async (id_cliente, id_direcc_cli) => {
    const response = await axios.get(
      `${URI.FACTURACION}/ubication/${id_cliente}`,
      {
        params: {
          id_direcc_cli,
        },
      }
    );
    return response.data;
  };

  // get Comercial Address
  const getComAddress = async (id) => {
    const comercial_address = await axios.get(
      `${URI.FACTURACION}/directions/${id}`
    );
    return comercial_address.data;
  };

  const handleChangeClient = (event) => {
    const id = event.target.value;
    setSelectedClient(id);
    getComAddress(id).then((data) => {
      console.log("data", data);
      if (data.length > 0) {
        setCommercialAddresses(data);
      } else {
        setCommercialAddresses([]);
      }
    });
  };

  const handleChangecomAddress = (event) => {
    const id_cliente = selectedClient;
    const id_direcc_cli = event.target.value;
    setSelectedComAddress(id_direcc_cli);
    getUbication(id_cliente, id_direcc_cli).then((data) => {
      if (data.length > 0) {
        setSelectedUbication(data[0]);
        setUbications(data);
      } else {
        setUbications([]);
      }
    });
  };

  const handleChangeUbication = (event) => {
    const foundUbication = ubications.find(
      (x) => `${x.id_ubica_cli}` === event.target.value
    );
    setSelectedUbication(foundUbication);
  };

  useEffect(() => {
    ServicesFacturacion.getAllClients("").then((clients) => {
      setClientList(clients);
    });
  }, []);

  useEffect(() => {
    ServicesFacturacion.getNextBillName().then((data) => {
      setBillName(data.billName);
    });
  }, []);

  return (
    <div className="holder">
      <div className="holder">
        <Tabs
          className={"mt-3"}
          actualTab={actualTab}
          setActualTab={setActualTab}
          texts={[<p>Datos de la factura</p>, <p>Detalles</p>]}
        />
        <TabPanel index={0} selectTab={actualTab}>
          <Form
            initialValues={{}}
            onSubmit={handleSubmit}
            className={"containerView"}
            innerRef={formRef}
            schemaForm={validationSchema}
          >
            <div className="holder">
              <div style={{ textAlign: "center" }} className="title">
                <h2>Crear factura</h2>
              </div>
              <div
                style={{ margin: "4rem" }}
                className="billingHolder extended"
              >
                <div className="columnHolder">
                  <div>
                    <div className="column">
                      <div className="info">
                        <div className="info">
                          <p>Cliente</p>
                          <div className="searcherContainerFlex">
                            <select
                              name="client"
                              className="searcher"
                              onChange={handleChangeClient}
                            >
                              <option value="">Buscar...</option>
                              {clientList.map((client, i) => (
                                <option key={i} value={client.id_cliente}>
                                  {client.nombre}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="info">
                        <p>Nombre de la factura</p> <p>{billName}</p>{" "}
                      </div>
                      <div className="info">
                        <p>Email</p> <p>{selectedUbication?.email}</p>{" "}
                      </div>
                      <div className="info">
                        <p>Forma de pago</p>{" "}
                        <p>{selectedUbication?.forma_pago}</p>{" "}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="column">
                      <div className="info">
                        <p>Direccion comercial</p>
                        <div className="searcherContainerFlex">
                          <select
                            name="com-address"
                            className="searcher"
                            onChange={handleChangecomAddress}
                          >
                            {commercialAddresseses.length ? (
                              commercialAddresseses.map((element, i) => (
                                <option key={i} value={element.id_direcc_cli}>
                                  {element.nombre_comercial}
                                </option>
                              ))
                            ) : (
                              <option value="">Buscar...</option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="info">
                        <p>Marca</p> <p>{selectedUbication?.marca}</p>{" "}
                      </div>
                      <div className="info">
                        <p>Estado de factura</p> <p>Funcionando</p>{" "}
                      </div>
                      <div className="dummy">
                        <p></p> <p></p>{" "}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="column">
                      <div className="info">
                        <p>Ubicacion</p>
                        <div className="searcherContainerFlex">
                          <select
                            name="ubication"
                            className="searcher"
                            onChange={handleChangeUbication}
                          >
                            {ubications.length ? (
                              <option value={ubications[0].id_ubica_cli}>
                                {ubications[0].nombre}
                              </option>
                            ) : (
                              <option value="">Buscar...</option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="info">
                        <p>Modelo</p> <p>{selectedUbication?.modelo}</p>{" "}
                      </div>
                      <div className="info">
                        <p>Tarifa</p> <p>{selectedUbication?.tarifa}</p>{" "}
                      </div>
                      <div className="dummy">
                        <p></p> <p></p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tableHeader">
                  <Button variant="primary" className="createButtonBilling">
                    Cancelar
                  </Button>
                  <Button
                    variant="primary"
                    className="createButton2"
                    type="submit"
                  >
                    Crear factura
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </TabPanel>
        <TabPanel index={1} selectTab={actualTab}>
          <BillingDetailOverview />
        </TabPanel>
      </div>
    </div>
  );
};
export default BillingCreate;
