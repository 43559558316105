import axios, { Method, AxiosRequestConfig } from "axios";
import { loadSessionState } from "../storage";

export const methods = {
  DELETE: "DELETE",
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATH: "PATCH",
};
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  body?: any;
}

export default class RedsysConnect {
  //manager http conection
  static async fetchAxios(
    endpoint: string,
    method: Method,
    { params = {}, body = {} }: CustomAxiosRequestConfig
  ) {
    const token = loadSessionState("token");
    console.log('token pa redsys', token);
    
    const rawResponse = await axios({
      url: endpoint,
      method,
      params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //Authorization: `Bearer ${token}`,
        //"Access-Control-Allow-Origin": "*",
      },
      data: body,
    });

    return rawResponse.data;
  }
}
