import React from 'react';
import Form from '../../../../Form';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { finishTechCreate, prevStepTechCreate } from '../../../../../redux/technical/technicalReducer';
import { SubmitButton, TextInput, ControllerInput, FormLabel, TextInputPassword, TextArea } from '../../../../Form/components';
import { Button, Grid } from '@material-ui/core';
import ServicesUser from '../../../../../utilities/api/services/User';
import { useSnackbar } from 'notistack';
import { ServiceTechs } from '../../../../../utilities/api/services';
import { useHistory } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    telefono: Yup.string().required('Telefono es Requerido'),
    movil: Yup.string().required('Movil es Requerido'),
    observaciones: Yup.string().required('Notas es Requerido'),
})

const Step3 = () => {

    const { data } = useSelector(state => state.tecnico.techCreate);
    const dispatch = useDispatch();
    const router = useHistory()

    const { enqueueSnackbar } = useSnackbar();


    const handleNextStep = async (values) => {
        try {
            await ServiceTechs.createdTech({ tech: values });
            enqueueSnackbar('Tecnico Creado', { variant: "success" });
            dispatch(finishTechCreate());
            router.push('/dashboard/techs');

        } catch (error) {
            enqueueSnackbar(error.response.data, { variant: "error" });
        }

    }


    const handlePrevStep = () => dispatch(prevStepTechCreate())

    return (
        <Form initialValues={data} className={''} schemaForm={validationSchema} onSubmit={handleNextStep}>

            <Grid container xs={12} direction={'column'} justify={'center'} alignItems={'center'} className={'  my-3 '}>
                <Grid item className={'w-50'}>
                    <FormLabel className="text-primary">
                        Telefono *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={'telefono'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >

                <Grid item className={'w-50'}>
                    <FormLabel className="text-primary">
                        Movil *
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={'movil'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >

                <Grid item className={' w-50 mt-4 '}>
                    <FormLabel className="text-primary">
                        Notas *
                    </FormLabel>
                    <ControllerInput
                        render={TextArea}
                        name={'observaciones'}
                        modeEdit={true}
                        className={'w-100'}
                    />
                </Grid >


            </Grid>

            <div className="formButtons">
                <Button variant="contained" onClick={handlePrevStep} className="prevButton btn">Volver</Button>
                <SubmitButton labelButton={'Crear Tecnico'}></SubmitButton>
            </div>


        </Form>
    )
}

export default Step3;
