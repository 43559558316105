import React, { useState } from "react";
import "./ResetPassword.scss";
import { isValid } from "../../utilities/isValid";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import BasicInput from "../../components/BasicInput/BasicInput";
import CustomButton from "../CustomButton/CustomButton";
import { resetPassword } from "../../redux/user/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { SuccessDialog } from '../SuccessDialog/SuccessDialog';
import { closeDialog } from "../../redux/user/userReducer";
import { useHistory } from "react-router-dom";

const ResetPassword = ({id, token}) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const openSuccessDialog = useSelector(state => state.user.openSuccessDialog);
  const [userInput, setUserInput] = useState({
    pass: "",
    passwordConfirm: "",
    id, token
  });

  //para mostrar mensajes de error en el input
  const [inputError, setInputError] = useState({
    passwordError: false,
    passwordErrorMessage: "",
    passwordConfirmError: false,
    passwordConfirmErrorMessage: "",
  });

  const {
    passwordError,
    passwordErrorMessage,
    passwordConfirmError,
    passwordConfirmErrorMessage,
  } = inputError;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserInput({ ...userInput, [name]: value });
  };

  const handleSubmit = () => {
    if (isValid(userInput, setInputError, inputError)) {
      dispatch(resetPassword(userInput));
    }
  };

  const handleKeyPress = (target) => {
    if (target.charCode === 13 && isValid(userInput, setInputError, inputError)) {
      dispatch(resetPassword(userInput));
    }
  }

  const handleClose = () => {
    dispatch(closeDialog());
    history.push('/login');
  };

  return (
    <div className="resetContainer">
      <div className="resetInner">
        <div className="title">
          <p>Restablecer la contraseña</p>
        </div>
        <div className="resetForm">
          <div className="textInputs">
            <div>
              <BasicInput
                label={"Nueva contraseña"}
                icon={faLock}
                type={"password"}
                name={"pass"}
                errorFlag={passwordError}
                value={userInput.password}
                onChange={handleChange}
                handleKeyPress={handleKeyPress}
              />
              <div className="errorMsg">{passwordErrorMessage}</div>
            </div>

            <div>
              <BasicInput
                label={"Confirmar contraseña"}
                icon={faLock}
                type={"password"}
                name={"passwordConfirm"}
                errorFlag={passwordConfirmError}
                value={userInput.passwordConfirm}
                onChange={handleChange}
                handleKeyPress={handleKeyPress}
              />
              <div className="errorMsg">{passwordConfirmErrorMessage}</div>
            </div>
          </div>
        </div>
        <div>
          <CustomButton onClick={handleSubmit} text={"Restablecer"} />
        </div>
      </div>
      <SuccessDialog text={"Contraseña recuperada con éxito"} isOpen={openSuccessDialog} handleClose={handleClose}/>
    </div>
  );
};

export default ResetPassword;
