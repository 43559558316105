
import _ from 'lodash';

//Verfico el input del usuario.
export const isValid = (userInput, setInputError, inputError) => {
  const {
    email,
    emailForgot,
    pass,
    password,
    passwordConfirm,
    actualPassword,
  } = userInput
  let errors = {}
  let isValid = true

  if (email || emailForgot) {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    )
    if (!(pattern.test(email) || pattern.test(emailForgot))) {
      isValid = false
      errors["emailError"] = true
      if (email)
        errors["emailErrorMessage"] = "El email ingresado es incorrecto"
    }
  } else if (email === "" || emailForgot === "") {
    isValid = false
    errors["emailError"] = true
    if (email === "")
      errors["emailErrorMessage"] = "El email no puede estar vacío"
  }

  if (pass === "" || password === "") {
    isValid = false
    errors["passwordError"] = true
    errors["passwordErrorMessage"] = "La contraseña no puede estar vacía"
  }

  if (passwordConfirm === "") {
    isValid = false
    errors["passwordConfirmError"] = true
    errors["passwordConfirmErrorMessage"] = "La contraseña no puede estar vacía"
  }

  if (actualPassword === "") {
    isValid = false
    errors["actualPasswordError"] = true
    errors["actualPasswordErrorMessage"] = "La contraseña no puede estar vacía"
  }

  if ((pass && pass.length < 4) || (password && password.length < 4)) {
    isValid = false
    errors["passwordError"] = true
    errors["passwordErrorMessage"] =
      "La contraseña debe tener al menos 4 caracteres"
  } else if (passwordConfirm && passwordConfirm !== pass) {
    isValid = false
    errors["passwordConfirmError"] = true
    errors["passwordConfirmErrorMessage"] = "Las contraseñas no coinciden"
  }

  //se limpia el input del error anterior.
  if (!errors.emailError) {
    errors["emailError"] = false
    errors["emailErrorMessage"] = ""
  }
  if (!errors.passwordError) {
    errors["passwordError"] = false
    errors["passwordErrorMessage"] = ""
  }

  setInputError({ ...inputError, ...errors })
  return isValid
}

export const isNilCustom = (value) => {
  return _.isNull(value) || _.isUndefined(value) || value === '';
};