import React, { useState, useRef, useMemo } from 'react';
import {
    TextInput, ControllerInput, FormLabel,
    ButtonState, SubmitButton, TextArea,
    SelectInput
} from '../../../../../../Form/components';
import { Grid, Avatar, Button } from '@material-ui/core';
import { useFormContext, useController } from 'react-hook-form';

const FormComercialAddress = ({ modeEdit }) => {
    return (
        <Grid spacing={1} className={'mt-3'} style={{ display: "flex", flexDirection: "flex-row" }} >

            <Grid item xs={12}  >

                <Avatar className={'w-50 h-75'}  >
                    LG
                </Avatar>

            </Grid>

            <Grid item xs={12}  >
                <Grid item xs={12} className={'mt-1'}  >
                    <FormLabel className="text-primary">
                        Nombre Comercial

                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={"nombre_comercial"}
                        modeEdit={modeEdit}
                        className={''}
                    />
                </Grid >




                <Grid item xs={12} className={'mt-1'}>
                    <FormLabel className="text-primary">
                        Pais
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={"pais"}
                        modeEdit={modeEdit}
                    />


                </Grid >
                <Grid item xs={12} className={'mt-1'}>
                    <FormLabel className="text-primary">
                        Provincia
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={"provincia"}
                        type={"text"}
                        modeEdit={modeEdit}
                    />

                </Grid >
                <Grid item xs={12} className={'mt-1'}>
                    <FormLabel className="text-primary">
                        Estado
                    </FormLabel>
                    <ControllerInput
                        render={ButtonState}
                        name={"estado_cliente"}
                        modeEdit={modeEdit}
                        defaultValue={true}
                    />

                </Grid >


            </Grid>

            <Grid item xs={12}  >
                <Grid item xs={12} className={'mt-1'} >
                    <FormLabel className="text-primary">
                        Poblacion
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={"poblacion"}
                        modeEdit={modeEdit}
                    />
                </Grid >

                <Grid item xs={12} className={'mt-1'}>
                    <FormLabel className="text-primary">
                        Direccion
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={"direccion_fiscal"}
                        modeEdit={modeEdit}
                    />

                </Grid >

                <Grid item xs={12} className={'mt-1'}>
                    <FormLabel className="text-primary">
                        C.P.
                    </FormLabel>
                    <ControllerInput
                        render={TextInput}
                        name={"codpost"}
                        modeEdit={modeEdit}
                    />

                </Grid >



            </Grid >

        </Grid>
    )
}

export default FormComercialAddress
